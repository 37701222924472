import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						ValueContainer: {
							deleteAllRoles: 'Usuń wszystkie role',
							addRole: 'Dodaj role',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						ValueContainer: {
							deleteAllRoles: 'Delete all roles',
							addRole: 'Add role',
						},
					},
				},
			},
		},
	},
};
