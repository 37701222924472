import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@skillandchill/tasker-widgets2/dist/utils/theme';

export const useStyles = makeStyles(
	(theme: CustomTheme) => ({
		...getGlobalStyles(),
		root: {
			display: 'flex',
			alignItems: 'center',
		},
		search: {
			width: '100%',
			flexGrow: 1,
			height: 42,
			padding: theme.spacing(0),
			display: 'flex',
			alignItems: 'center',
		},
		searchInput: {
			flexGrow: 1,
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			flex: 1,
		},
		icon: {
			marginRight: '5px',
		},
		emptySpace: {
			marginLeft: '5px',
			height: '32px',
			width: '35px',
		},
	}),
	{ name: 'SearchBar_Search' }
);
