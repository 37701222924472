import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationPopover: {
			PlaceHolder: {
				noData: 'Brak danych...',
			},
		},
	},
	en: {
		NotificationPopover: {
			PlaceHolder: {
				noData: 'There is nothing here...',
			},
		},
	},
};
