import { useMemo, useState } from 'react';

import { CellContext, ColumnDef } from '@tanstack/react-table';

import { rowsPerTablePage } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';

import TileCreationDate from 'view/IssueList/TileComponents/CreationDate';
import TileIssueHeader from 'view/IssueList/TileComponents/IssueHeader';

import { Delete } from './Delete';
import { IIssueRelationship, ITableProps } from './model';
import { Resources } from './resources';

export const useTableData = (props: ITableProps) => {
	const { issuesPage, changeRelationToBasic, onDelete } = props;
	const { t } = useTrans('IssueModelNew.Relation.useTableData');

	const [tableData, setTableData] = useState({
		searchValue: '',
		page: 0,
		rowsPerPage: rowsPerTablePage,
	});

	const handleSearch = (value: string) => {
		setTableData(prevState => ({
			...prevState,
			searchValue: value,
		}));
	};

	const handlePageChange = (value: number) => {
		setTableData(prevState => ({
			...prevState,
			page: value,
		}));
	};

	const columns: ColumnDef<IIssueRelationship, any>[] = useMemo(
		() => [
			{
				id: 'name',
				accessorFn: row => row,
				header: t('name'),
				size: 0.35,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					if (item) return <TileIssueHeader onClick={changeRelationToBasic} issue={item} />;
					return Resources.GlobalResources.empty;
				},
			},
			{
				id: 'project',
				accessorFn: row => row,
				header: t('project'),
				size: 0.3,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					return item.Project?.Name;
				},
			},
			{
				id: 'creationDate',
				accessorFn: row => row,
				header: t('creationDate'),
				size: 0.3,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();
					return <TileCreationDate issue={item} />;
				},
			},
			{
				id: 'action',
				accessorFn: row => row,
				header: t('action'),
				size: 0.05,
				cell: (data: CellContext<IIssueRelationship, IIssueRelationship>) => {
					const item = data.getValue();

					return (
						<div>
							<Delete onDelete={onDelete} relationId={item?.RelationId} issueId={item?.Id as number} />
						</div>
					);
				},
			},
		],
		[t]
	);

	return {
		columns,
		data: issuesPage?.Data ?? [],
		handleSearch,
		handlePageChange,
		tableData,
	};
};
