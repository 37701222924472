import { EDelegationStep } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			TileComponents: {
				Status: {
					label: 'Status delegacji',
					1: 'Przygotowanie',
					2: 'Akceptacja',
					3: 'Rozliczenie',
					4: 'Akceptacja rozliczenia',
					5: 'Poprawa',
					6: 'Zakończono',
				},
			},
		},
	},
	en: {
		DelegationList: {
			TileComponents: {
				Status: {
					label: 'Delegation status',
					1: 'Preparation',
					2: 'Acceptance',
					3: 'Settlement',
					4: 'Settlement acceptance',
					5: 'Correction',
					6: 'Done',
				},
			},
		},
	},
};
