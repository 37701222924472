import { AsyncAction } from '@skillchill/redux-promisify';

import { IPasswordPolicy } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const FETCH_PASSWORD_POLICIES: ActionType = 'FETCH_PASSWORD_POLICY';
export const FETCH_SHARED_PASSWORD_POLICIES: ActionType = 'FETCH_SHARED_PASSWORD_POLICY';
export const FETCH_PASSWORD_POLICY_REGEX: ActionType = 'FETCH_PASSWORD_POLICY_REGEX';
export const UPDATE_PASSWORD_POLICY: ActionType = 'UPDATE_PASSWORD_POLICY';

const basePath = 'passwordPolicy';

function _fetchPasswordPolicies(policies: IPasswordPolicy[]): Action {
	return {
		type: FETCH_PASSWORD_POLICIES,
		policies,
	};
}

function _fetchSharedPasswordPolicies(policies: IPasswordPolicy[]): Action {
	return {
		type: FETCH_SHARED_PASSWORD_POLICIES,
		policies,
	};
}

function _fetchPasswordPolicyRegex(regex: string): Action {
	return {
		type: FETCH_PASSWORD_POLICIES,
		regex,
	};
}

function _updatePasswordPolicy(policies: IPasswordPolicy[]): Action {
	return {
		type: UPDATE_PASSWORD_POLICY,
		policies,
	};
}

export function get_PasswordPolicies(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPasswordPolicies((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function get_SharedPasswordPolicies(userId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSharedPasswordPolicies((json as IJson).data)));
		return axios().get(`${basePath}/shared/${userId}`);
	};
}

export function get_PasswordPolicyRegex(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPasswordPolicyRegex((json as IJson).data)));
		return axios().get(`${basePath}/regex`);
	};
}

export function update_PasswordPolicy(policies: Partial<IPasswordPolicy>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updatePasswordPolicy((json as IJson).data)));
		return axios().patch(basePath, policies);
	};
}
