import {
	EParameter,
	FullFilters,
	ICustomer,
	IIssue,
	IIssueForm,
	IPage,
	IProject,
	ITag,
	ITaskerUser,
	IWorkLog,
} from '@skillandchill/tasker-types';
import { IQuickFilter } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/quickfilter';
import { IFilterCustomer, IFilterSimplifiedCustomer } from '@skillandchill/tasker-types/dist/types/endPoints/filter';
import {
	IPortalFilterFieldDefinition,
	IPortalFilterForms,
	IPortalFilterTags,
} from '@skillandchill/tasker-types/dist/types/endPoints/portalFilter';

import * as actionTypes from '@/store/actions';
import { getDataCustomerFilters, getDataFromCustomerFiltersSimplify } from '@/store/reducers/utils/filterReducerUtils';
import { ObjectAction } from '@/store/types';

import { EPortalFilter } from 'view/IssueList/IssueListPagination/resources';
import { IFilters } from 'view/SearchBar/model';
import { getInitialFilter, getInitialFilterNew } from 'view/SearchBar/resources';

// function getIssueListFiltersCookie(): FullFilters {
// 	const cookies = new Cookies();
// 	const kanbanUserCookie = cookies.get('IssueListFilters');
// 	if (!kanbanUserCookie) return getInitialFilterNew();
// 	const object: FullFilters = kanbanUserCookie;
// 	return object?.Issue ? object : getInitialFilterNew();
// }

function getIssueListFiltersCookie(): FullFilters {
	const kanbanUserCookie = localStorage.getItem('IssueListFilters');
	if (!kanbanUserCookie) return getInitialFilterNew();
	const object: FullFilters = JSON.parse(kanbanUserCookie) as FullFilters;
	return object?.Issue ? object : getInitialFilterNew();
}

const State = {
	filtersIssue: getInitialFilter() as IFilters,
	filterKind: EPortalFilter.All, //ToDo poprawić robione na szybko
	filterIssueNew: getInitialFilterNew() as FullFilters,
	issuePage: {} as IPage<IIssue[]>,
	currentPage: 0 as number,
	filter: getIssueListFiltersCookie() as FullFilters,
	filters: {
		Options: {
			Project: [] as IProject[],
			Tag: [] as Partial<ITag>[],
			Customer: [] as ICustomer[],
			IssueForm: [] as IIssueForm[],
		},
		customerFilters: [] as IFilterCustomer[],
		customerFiltersSimplified: [] as IFilterSimplifiedCustomer[],
		projects: [] as IProject[],
		carouselUsers: [] as Partial<ITaskerUser>[],
		filter: getIssueListFiltersCookie() as FullFilters,
		Status: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		Tags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		ExcludedTags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
	},
	tags: [] as ITag[],
	refetchData: 0,
	QuickFilters: [] as IQuickFilter[],
	QuickFilterChoosen: undefined as IQuickFilter | undefined,
};
export type IIssueListState = typeof State;

const issueListReducer = (state = State, action: ObjectAction): IIssueListState => {
	switch (action.type) {
		case actionTypes.ISSUE_SET_FILTERS: {
			return {
				...state,
				filtersIssue: action.filter,
			};
		}
		case actionTypes.SET_PAGE: {
			return {
				...state,
				currentPage: action.data,
			};
		}
		case actionTypes.ISSUE_SET_FILTERS_NEW: {
			return {
				...state,
				filtersIssue: action?.filter?.filter,
				filterIssueNew: action?.filter?.filterNew,
			};
		}
		case actionTypes.UPDATE_FIELD_VALUE:
		case actionTypes.ISSUE_FETCH_NEW: {
			return {
				...state,
				issuePage: action?.issues,
			};
		}
		case actionTypes.REMOVE_ISSUE_FROM_FAVOURITES: {
			return {
				...state,
				issuePage: {
					...state.issuePage,
					Data: state?.issuePage?.Data?.map(item => {
						if (item?.Id == action?.issueId) return { ...item, IsFavourite: false };
						else return item;
					}),
				},
			};
		}
		case actionTypes.ADD_ISSUE_TO_FAVOURITES: {
			return {
				...state,
				issuePage: {
					...state.issuePage,
					Data: state?.issuePage?.Data?.map(item => {
						if (item?.Id == action?.issueId) return { ...item, FavouriteIssues: action?.favouriteIssue };
						else return item;
					}),
				},
			};
		}
		case actionTypes.ISSUE_DELETE: {
			const data = state?.issuePage?.Data?.filter(x => x?.Id != action?.id);
			const page: IPage<IIssue[]> = {
				Data: data,
				Count: state?.issuePage?.Count,
			};

			if (data?.length != state?.issuePage?.Data?.length) {
				page.Count = state?.issuePage?.Count - 1;
			}
			return {
				...state,
				issuePage: page,
			};
		}
		case actionTypes.USE_CLOCK_PAUSE_FINISH:
		case actionTypes.USE_CLOCK: {
			return {
				...state,
				issuePage: {
					Count: state?.issuePage?.Count,
					Data: state?.issuePage?.Data?.map(item => {
						const filteretWorkLog = action?.worklogs?.active?.filter((x: IWorkLog) => x.IssueId == item.Id);
						if (filteretWorkLog?.length > 0) {
							const workLog: IWorkLog = filteretWorkLog[0];
							return {
								...item,
								IssueField: workLog?.Issue?.IssueField,
							};
						}
						return item;
					}),
				},
			};
		}

		case actionTypes.KEY_WORKLOG_MANAGE: {
			return {
				...state,
				issuePage: {
					Count: state?.issuePage?.Count,
					Data: state?.issuePage?.Data?.map(item => {
						const startedWorkLog =
							action?.data?.StartWorkLog?.filter((x: IWorkLog) => x.IssueId == item.Id) ?? [];
						const stopedWorkLog =
							action?.data?.StopWorkLog?.filter((x: IWorkLog) => x.IssueId == item.Id) ?? [];
						const workLogs = [...startedWorkLog, ...stopedWorkLog];
						if (workLogs?.length > 0) {
							const workLog: IWorkLog = workLogs[0];
							return {
								...item,
								IssueField: workLog?.Issue?.IssueField,
							};
						}
						return item;
					}),
				},
			};
		}
		case actionTypes.FILTER_ISSUE_PROJECT_DELETE: {
			const projects = state?.filters?.projects?.filter(x => x?.Id != action.data);
			const tagsChoosenProjects = state?.filters?.Tags?.choosenProjects?.filter(x => x?.Id != action.data);
			const statusChoosenProjects = state?.filters?.Status?.choosenProjects?.filter(x => x?.Id != action.data);
			return {
				...state,
				filters: {
					...state?.filters,
					projects: projects,
					Tags: {
						...state?.filters?.Tags,
						choosenProjects: tagsChoosenProjects,
					},
					Status: {
						...state?.filters?.Status,
						choosenProjects: statusChoosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_STATUS_OPTIONS:
		case actionTypes.FILTER_STATUS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.FILTER_SET_LISTS: {
			// const cookies = new Cookies();
			// if (action?.filter) {
			// 	cookies.set('IssueListFilters', JSON.stringify(action?.filter as FullFilters), {
			// 		path: '/',
			// 	});
			// }

			if (action?.filter) localStorage.setItem('IssueListFilters', JSON.stringify(action?.filter as FullFilters));

			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}
		case actionTypes.FILTER_PROJECT_SET: {
			return {
				...state,
				filters: {
					...state?.filters,
					projects: action?.data?.choosenProjects,
				},
			};
		}
		case actionTypes.ISSUE_SET_STATUS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_TAGS_OPTIONS:
		case actionTypes.FILTER_TAGS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
					ExcludedTags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.PORTAL_SET_TAGS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_TAGS_NOT_IN: {
			return {
				...state,
				filters: {
					...state?.filters,
					ExcludedTags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.ISSUE_SET_FILTERS_NEW_V2: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.data,
				},
			};
		}
		case actionTypes.PORTAL_SET_EXCLUDED_TAGS: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				filters: {
					...state?.filters,
					carouselUsers: action.taskerUsers ?? [],
				},
			};
		}
		case actionTypes.GET_SINGLE_TAG_GROUP: {
			return {
				...state,
				tags: action.group?.Tag,
			};
		}
		case actionTypes.ISSUE_SET_QUICK_FILTERS:
		case actionTypes.ISSUE_RE_FETCH: {
			// const cookies = new Cookies();
			const filters: FullFilters = action?.filters;
			if (filters) localStorage.setItem('IssueListFilters', JSON.stringify(filters as FullFilters));
			// if (filters) cookies.set('IssueListFilters', JSON.stringify(filters as FullFilters), { path: '/' });

			return {
				...state,
				refetchData: state.refetchData + 1,
				filters: {
					...state?.filters,
					filter: filters,
				},
				filter: filters,
			};
		}
		case actionTypes.ISSUE_FETCH_UPDATE: {
			return {
				...state,
				refetchData: state.refetchData + 1,
			};
		}
		case actionTypes.GET_QUICK_FILTERS_ISSUE_LIST: {
			return {
				...state,
				QuickFilters: action?.data,
			};
		}
		case actionTypes.FILTER_CUSTOMER_FILTERS_SIMPLIFIED: {
			return {
				...state,
				filters: {
					...state?.filters,
					customerFiltersSimplified: action?.data,
					Options: {
						...state?.filters?.Options,
						...getDataFromCustomerFiltersSimplify(action?.data),
					},
				},
			};
		}
		case actionTypes.FILTER_CUSTOMER_FILTERS: {
			return {
				...state,
				filters: {
					...state?.filters,
					customerFilters: action?.data,
					Options: {
						...state?.filters?.Options,
						...getDataCustomerFilters(action?.data),
					},
				},
			};
		}
		case actionTypes.FETCH_ALL_PROJECTS: {
			return {
				...state,
				filters: {
					...state?.filters,
					projects: action.data,
				},
			};
		}
		case actionTypes.UPDATE_QUICK_FILTERS: {
			return {
				...state,
				QuickFilters: state?.QuickFilters?.map(x => {
					if (x?.Id === action?.id) return { ...x, Name: action?.name };
					else return x;
				}),
			};
		}
		case actionTypes.DELETE_QUICK_FILTERS: {
			return {
				...state,
				QuickFilters: state?.QuickFilters?.filter(x => x?.Id != action?.id),
			};
		}
		case actionTypes.CREATE_QUICK_FILTERS: {
			return {
				...state,
				QuickFilters: [...state?.QuickFilters, action?.data],
			};
		}
		case actionTypes.FILTER_SET_KIND: {
			//ToDo Poprawić robione nq szybko
			return {
				...state,
				filterKind: action?.data,
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_UPDATE_PREFERENCES: {
			if (action?.data?.ParameterId === EParameter.User_UISimplifyFilters)
				return {
					...state,
					filters: {
						...state?.filters,
						customerFiltersSimplified: [],
						customerFilters: [],
					},
				};

			return state;
		}

		default: {
			return state;
		}
	}
};

export default issueListReducer;
