import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { FieldsRequiredIndicator } from '@/views/DelegationsList/DelegationModal/DelegationModalActions/FieldsRequiredIndicator';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalActions: {
				FieldsRequiredIndicator: {
					fieldsRequired: 'Pola wymagane',
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalActions: {
				FieldsRequiredIndicator: {
					fieldsRequired: 'Fields required',
				},
			},
		},
	},
};
