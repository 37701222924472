import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: '100%',
			display: 'flex',
			alignItems: 'flex-end',
		},
		subRoot: {
			marginTop: '0.125em',
			marginBottom: '0.375em',
		},
		relative: {
			position: 'relative',
		},
		mandatorySign: {
			color: 'red',
		},
	}),
	{ name: `IssueModalEditor_ModalRecord` }
);
