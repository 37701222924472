import clsx from 'clsx';
import { useContext, useEffect } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Switch } from '@mui/material';

import { EFieldDefinitionType, IFieldValue } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { FieldValueFactory } from '../utils/fieldValueFactory';
import { useHandleResetError } from '../utils/useHandleResetError';

import { Props } from './model';
import { useStyles } from './styles';

export const BooleanIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);

	const { setFieldValues, setIssueState, issueState } = useContext(issueModalDataContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;
	const IsMandatory = issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory;
	const initialFieldValue = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const initialInputValue = initialFieldValue?.length ? (initialFieldValue[0]?.ValueBoolean as boolean) : undefined;

	const inputValue = (issueState?.Properties?.Boolean ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	useEffect(() => {
		if (typeof inputValue === 'undefined' && typeof initialInputValue === 'undefined' && IsMandatory)
			handleInputChange(false);
	}, [inputValue, initialInputValue]);

	const handleInputChange = (isChecked: boolean | null) => {
		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				Boolean: {
					...(prevState?.Properties?.Boolean ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: isChecked,
				},
			},
		}));
		handleResetInputError();
		handleFieldValuesChange(isChecked);
	};

	const handleFieldValuesChange = (isChecked: boolean | null) => {
		setFieldValues(prevState => {
			if (initialFieldValue?.length) {
				return {
					...prevState,
					fieldsValueToUpdate: [
						...(prevState?.fieldsValueToUpdate?.filter(
							getFieldValueWitouthFormSectionAttributeDefinitionId
						) ?? []),
						FieldValueFactory.create(
							EFieldDefinitionType.Boolean,
							AttributeDefinitionId as number,
							isChecked,
							initialFieldValue[0].Id
						),
					],
				};
			}

			return {
				...prevState,
				fieldsValueToCreate: [
					...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ??
						[]),
					FieldValueFactory.create(EFieldDefinitionType.Boolean, AttributeDefinitionId as number, isChecked),
				],
			};
		});
	};

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>
	) {
		return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<VisibilityControl
				condition={inEdit}
				alternative={
					<VisibilityControl
						condition={typeof initialInputValue !== 'undefined' && initialInputValue !== null}
						alternative={<div className={clsx(classes.noData)}>-</div>}
					>
						<VisibilityControl
							condition={!!initialInputValue}
							alternative={<CloseIcon className={clsx(classes.icon, classes.noIcon)} />}
						>
							<CheckIcon className={clsx(classes.icon, classes.yesIcon)} />
						</VisibilityControl>
					</VisibilityControl>
				}
			>
				<div className={clsx(classes.flexGrow, classes.booleanContainer)}>
					<Switch
						value={inputValue ?? initialInputValue}
						onChange={(e, checked) => handleInputChange(checked)}
						checked={inputValue ?? initialInputValue}
					/>
				</div>
			</VisibilityControl>
		</div>
	);
};
