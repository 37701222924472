import clsx from 'clsx';

import { Typography } from '@mui/material';

import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Placeholder = (props: Props): JSX.Element => {
	const { className, ...rest } = props;
	const { t } = useTrans('NotificationPopover.PlaceHolder');
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<div className={classes.image} />
			<Typography variant={Resources.GlobalResources.h4}>{t('noData')}</Typography>
		</div>
	);
};

export default Placeholder;
