import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	anonymous: 'Anonymous' as const,
	tagKey: 'Tag_Key_',
	portalKey: {
		name: 'Portal_Key_Name',
		user: 'Portal_Key_User',
	},
	startDateKey: 'Start_Date_Key',
	dueDateKey: 'Due_Date_Key',
	reportedByKey: 'Reported_By_Key',
	assignedToKey: 'Assigned_To_Key',
	issueTitleKey: 'Issue_Title_Key',
	customerKey: 'Customer_Key',
	projectKey: {
		name: 'Project_Name_Key',
		id: {
			basic: 'Project_Id_Key',
			inTags: 'Tags_Project_Id_Key',
		},
		dueDate: 'Project_Due_Date_Id_Key',
		startDate: 'Project_Start_Date_Id_Key',
	},
	deleteColor: {
		'& .MuiChip-deleteIcon': {
			color: 'red',
		},
		'&:hover': {
			'& .MuiChip-deleteIcon': {
				color: 'red',
			},
		},
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			Tags: {
				Project: {
					dueDate: 'Koniec projektu',
					startDate: 'Początek projektu',
					title: 'Nazwa projektu',
					id: 'Projekt',
				},
				Customer: {
					customer: 'Klient',
				},
				Issue: {
					title: 'Nazwa zgłoszenia',
					assignedTo: 'Przypisano zgłoszenie do',
					reportedBy: 'Zgłoszone przez',
					status: 'Status zgłoszenia',
					priority: 'Priorytet zgłoszenia',
					type: 'Typ zgłoszenia',
					dueDate: 'Data końca zgłoszenia',
					startDate: 'Data początku zgłoszenia',
				},
				Comment: {
					onlyWithComments: 'Tylko z komentarzami',
					onlyWithoutComments: 'Tylko bez komentarzy',
				},
				users: {
					reporter: 'Osoba raportująca o zgłoszeniu',
					assigned: 'Osoba wykonująca zgłoszenie',
					creator: 'Osoba tworząca zgłoszenie',
					auditor: 'Osoba sprawdzająca zgłoszenie',
					observer: 'Osoba obserwująca zgłoszenie',
				},
			},
		},
	},
	en: {
		SearchBar: {
			Tags: {
				Project: {
					dueDate: 'Project due date',
					startDate: 'Project start date',
					title: 'Project name',
					id: 'Projekt',
				},
				Customer: {
					customer: 'Client',
				},
				Issue: {
					title: 'Issue name',
					assignedTo: 'Assigned to',
					reportedBy: 'Reported by',
					status: 'Status',
					priority: 'Priority',
					type: 'Type Issue',
					dueDate: 'Issue due date',
					startDate: 'Issue start date',
				},
				Comment: {
					onlyWithComments: 'Only with comments',
					onlyWithoutComments: 'Only with out comments',
				},
				users: {
					reporter: 'Person who reported the issue',
					assigned: 'Person who doing the issue',
					observer: 'Person who watching the issue',
					creator: 'Person who created the issue',
					auditor: 'Person who checking the issue',
				},
			},
		},
	},
};
