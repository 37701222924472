export const Translation = {
	pl: {
		Error: {
			error_400: 'Niepoprawne żądanie',
			error_401: 'Błąd autoryzacji',
			error_403: 'Brak uprawnień',
			error_404: 'Nie znaleziono',
			error_500: 'Wewnętrzny błąd serwera',
			unknownError: 'Nierozpoznany błąd',
		},
	},
	en: {
		Error: {
			error_400: 'Bad request',
			error_401: 'Authorization error',
			error_403: 'No permission',
			error_404: 'Not found',
			error_500: 'Internal server error',
			unknownError: 'Unknown error',
		},
	},
};
