import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		WorkDayEndSummary: {
			Filters: {
				Options: {
					WorkdaySignedDateFilter: {
						searchByFillDate: 'Wyszukiwanie po dacie wypełnienia',
						fillDate: 'Data wypełnienia',
					},
				},
			},
		},
	},
	en: {
		WorkDayEndSummary: {
			Filters: {
				Options: {
					WorkdaySignedDateFilter: {
						searchByFillDate: 'Searching by fill date',
						fillDate: 'Fill date',
					},
				},
			},
		},
	},
};
