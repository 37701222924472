import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	nameError: 'Name',
	tagColorError: 'TagColor',
	initialErrorState: { labelError: false, colorError: false },
};

export const Translation = {
	pl: {
		TagGroupInfo: {
			TagsCard: {
				tags: 'Tagi',
				addTag: 'Dodaj tag',
				tagsTooltip: 'Tagi można przesuwać aby zmienić kolejność ich wyświetlania',
				successEditTag: 'Pomyślnie skonfigurowano tag',
				successCreateTag: 'Tag został stworzony',
			},
		},
	},
	en: {
		TagGroupInfo: {
			TagsCard: {
				tags: 'Tags',
				addTag: 'Add tag',
				tagsTooltip: 'Tags can be moved to change their order',
				successEditTag: 'Tag successfully configured',
				successCreateTag: 'Tag has been created',
			},
		},
	},
};

export enum sortType {
	string = 'string',
	boolean = 'boolean',
	number = 'number',
	date = 'date',
}
