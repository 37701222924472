import {
	IDelegation,
	IDelegationDictionaryGroup,
	IDelegationRole,
	IDictionary,
	IPage,
} from '@skillandchill/tasker-types';
import { IDelegationLimits } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/delegationLimits';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	Page: {} as IPage<IDelegation[]>,
	Dictionaries: {} as {
		Budget: IDictionary;
		Project: IDictionary;
	},
	DictionaryGroup: {} as IDelegationDictionaryGroup,
	Acceptance: {
		requiredRoleFromUser: [] as IDelegationRole[],
	},
	Delegation: {} as IDelegation,
	Limits: {} as IDelegationLimits[],
};
export type IDelegationListState = typeof State;

const delegationListReducer = (state = State, action: ObjectAction): IDelegationListState => {
	switch (action?.type) {
		case actionTypes.DELEGATION_FETCH_MANY:
		case actionTypes.DELEGATION_CREATE:
		case actionTypes.DELEGATION_UPDATE:
		case actionTypes.DELEGATION_REMOVE:
		case actionTypes.DELEGATION_REQUEST_TO_FETCH_MANY:
		case actionTypes.DELEGATION_REQUEST_TO_WITH_ID_FETCH_MANY: {
			return {
				...state,
				Page: action?.data,
			};
		}
		case actionTypes.GET_DELEGATION_DICTIONARIES: {
			return {
				...state,
				Dictionaries: action?.data,
			};
		}
		case actionTypes.DELEGATION_DICTIONARY_GROUP_FETCH: {
			return {
				...state,
				DictionaryGroup: action?.data?.[0],
			};
		}
		case actionTypes.DELEGATION_ACCEPTANCE:
		case actionTypes.ACCEPTANCE_IS_REQUIRED: {
			return {
				...state,
				Acceptance: {
					...state.Acceptance,
					requiredRoleFromUser: action?.data,
				},
			};
		}
		case actionTypes.DELEGATION_LIMITS_FETCH: {
			return {
				...state,
				Limits: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default delegationListReducer;
