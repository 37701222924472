import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						user: 'Użytkownik',
						organizationRoles: 'Role w organizacji',
						projectHover:
							'Po najechaniu na nazwę projektu wyświetlą się role projektowe użytkownika w tym projekcie',
						userProjects: 'Projekty użytkownika',
						projectRoles: 'Role projektowe',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						user: 'User',
						organizationRoles: 'Organization roles',
						projectHover: `When you hover over the name of a project, the user's project roles in that project will be displayed`,
						userProjects: 'User projects',
						projectRoles: 'Project roles',
					},
				},
			},
		},
	},
};
