import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as subResources } from '../../resources';

export const Resources = {
	tabId: subResources.reckonTab,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				Reckon: {
					addButton: 'Dodaj',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			Tabs: {
				Reckon: {
					addButton: 'Add',
				},
			},
		},
	},
};
