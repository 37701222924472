import { IWorkLog } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	imagePath: 'images/logos/tasker.png',
};

export const Translation = {
	pl: {
		TimeAssign: {
			Header: {
				accepted: 'Worklog został zaakceptowany.',
				notAccepted: 'Worklog do akceptacji.',
				accept: 'Zaakceptuj worklog.',
			},
			incorrectData: 'Data, czas pracy lub zgłoszenie jest niewłaściwy',
			didUpdate: 'Rekord został zaktualizowany',
		},
	},
	en: {
		TimeAssign: {
			Header: {
				accepted: 'Worklog has been accepted.',
				notAccepted: 'Worklog to accept.',
				accept: 'Accept worklog.',
			},
			incorrectData: 'Date, duration or issue is incorrect',
			didUpdate: 'WorkLog has been updated',
		},
	},
};

export const workLogDefault: Partial<IWorkLog> = {
	Id: undefined,
	DateTimeFrom: new Date(),
	Minutes: 0,
	Description: '',
	DescriptionHtml: '',
	TaskerUserId: 0,
	IssueId: 0,
	IsActive: false,
};

export enum ETab {
	General = 1,
	History = 2,
}
