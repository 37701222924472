import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryTypeStep: {
							stepSubTitle:
								'Wybierz typ wynagrodzenia, na podstawie którego będzie wyliczana wartość należnego wynagrodzenia',
							fixedSalarySubTitle: 'Wartość wynagrodzenia jest ustalona z góry',
							hourlySalarySubTitle:
								'Wartość wynagrodzenia jest zgodna z założonym etatem oraz wynagrodzeniem za godzinę',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryTypeStep: {
							stepSubTitle:
								'Select the type of salary on the basis of which the value of the salary due will be calculated',
							fixedSalarySubTitle: 'The value of the total salary is set in advance',
							hourlySalarySubTitle:
								'The value of salary is consistent with the assumed number of hours per week and salary per hour',
						},
					},
				},
			},
		},
	},
};
