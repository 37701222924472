import { IFieldValue } from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	key: (id: number, part: string) => `fieldDefinition${id}${part}`,
	h: 'h',
	string: 'string' as const,
	number: 'number' as const,
	dateField: 'dateField',
	dateTimeField: 'dateTimeField',
	tagField: 'tagField',
	dictionaryField: 'dictionaryField',
	boolField: 'boolField',
	textField: 'textField',
	value: {
		text: 'ValueText' as keyof IFieldValue,
		integer: 'ValueInteger' as keyof IFieldValue,
		decimal: 'ValueDecimal' as keyof IFieldValue,
	},
	checkListValue: {
		text: 'ValueText' as keyof ICheckListValue,
		integer: 'ValueInteger' as keyof ICheckListValue,
		decimal: 'ValueDecimal' as keyof ICheckListValue,
	},
	defaultValue: {
		text: 'DefaultValueText' as keyof IIssueFormSectionToIssueField,
		integer: 'DefaultValueInteger' as keyof IIssueFormSectionToIssueField,
		decimal: 'DefaultValueDecimal' as keyof IIssueFormSectionToIssueField,
	},
	...GlobalResources,
};
