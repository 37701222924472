import { AsyncAction } from '@skillchill/redux-promisify';

import { FullFilters, IFieldValue, IIssue } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'issueList';

export const UPDATE_FIELD_VALUE: ActionType = 'UPDATE_FIELD_VALUE';

function _addIssueToFavourites(issues: Partial<IIssue>[]): Action {
	return {
		type: UPDATE_FIELD_VALUE,
		issues,
	};
}

export function update_FieldValue(
	data: Partial<IFieldValue>,
	issueId: number,
	filters: FullFilters,
	from: number,
	count: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_addIssueToFavourites((data as IJson).data)));
		return axios().patch(`${basePath}/${data?.Id}`, { data, issueId, filters, from, count });
	};
}

export function fetchIssueCSV(filters: FullFilters): AsyncAction {
	return function(context, dispatch) {
		return axios().post(`${basePath}/csv`, { filters });
	};
}
