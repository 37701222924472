import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	new: '/admin/holidayType/new',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Holiday: {
			Type: {
				List: {
					pageTitle: 'Typy dni wolnych',
					headerType: 'Typy dni wolnych',
					createdDateTime: 'Stworzono: ',
					useTableData: {
						name: 'Nazwa',
						dateTime: 'Data stworzenia',
						creator: 'Twórca',
					},
				},
			},
		},
	},
	en: {
		Holiday: {
			Type: {
				List: {
					pageTitle: 'Holiday types',
					headerType: 'Holiday types',
					createdDateTime: 'Created at: ',
					useTableData: {
						name: 'Name',
						dateTime: 'Creation date',
						creator: 'Creator',
					},
				},
			},
		},
	},
};
