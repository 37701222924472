import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	string: 'string',
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				ProjectsAddTab: {
					successMessage: 'Projekt został dodany do klienta',
					failedMessage: 'Dodawanie projektu zakończone niepowodzeniem',
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				ProjectsAddTab: {
					successMessage: 'Project added to customer',
					failedMessage: 'Adding project to customer failed',
				},
			},
		},
	},
};
