import { Translation as FieldsRequiredIndicator } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalActions/FieldsRequiredIndicator/resources';
import { Translation as InvoiceModalActions } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalActions/resources';
import { Translation as General } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/General/resources';
import { Translation as ItemInEdit } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/ItemInEdit/resources';
import { Translation as ItemsTable } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/Items/hooks/resources';
import { Translation as Items } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/Items/resources';
import { Translation as Content } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/resources';
import { Translation as Series } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalContent/Series/resources';
import { Translation as InvoiceModalTitle } from './Content/Invoices/HeaderInvoice/InvoiceModal/InvoiceModalTitle/resources';
import { Translation as InvoiceModalOld } from './Content/Invoices/HeaderInvoice/InvoiceModal/resources';
import { Translation as HeaderInvoice } from './Content/Invoices/HeaderInvoice/resources';
import { Translation as InvoicesTable } from './Content/Invoices/hooks/resources';
import { Translation as Invoices } from './Content/Invoices/resources';
import { Translation as ProjectsAddTab } from './Content/ProjectsAddTab/resources';
import { Translation as CustomerCharts } from './Content/Summary/CustomerCharts/resources';
import { Translation as CustomerInfo } from './Content/Summary/CustomerInfo/resources';
import { Translation as InvoiceDetails } from './Content/Summary/InvoiceDetails/resources';
import { Translation as HeaderSuffix } from './DeleteCustomer/resources';
import { Translation as CustomerDetail } from './resources';

export const CustomerDetailTranslationEN = [
	CustomerDetail.en,
	CustomerInfo.en,
	HeaderSuffix.en,
	ProjectsAddTab.en,
	CustomerCharts.en,
	InvoiceDetails.en,
	Invoices.en,
	Content.en,
	InvoiceModalOld.en,
	HeaderInvoice.en,
	General.en,
	Items.en,
	ItemInEdit.en,
	Series.en,
	InvoicesTable.en,
	InvoiceModalTitle.en,
	InvoiceModalActions.en,
	FieldsRequiredIndicator.en,
	ItemsTable.en,
];

export const CustomerDetailTranslationPL = [
	CustomerDetail.pl,
	CustomerInfo.pl,
	HeaderSuffix.pl,
	ProjectsAddTab.pl,
	CustomerCharts.pl,
	InvoiceDetails.pl,
	Invoices.pl,
	Content.pl,
	InvoiceModalOld.pl,
	HeaderInvoice.pl,
	General.pl,
	Items.pl,
	ItemInEdit.pl,
	Series.pl,
	InvoicesTable.pl,
	InvoiceModalTitle.pl,
	InvoiceModalActions.pl,
	FieldsRequiredIndicator.pl,
	ItemsTable.pl,
];
