import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationsList: {
			DelegationModal: {
				createSuccess: 'Delegacja została zapisana',
				createError: 'Wystąpił błąd podczas zapisywania delegacji',
				updateSuccess: 'Delegacja została zaktualizowana',
				updateError: 'Wystąpił błąd podczas aktualizacji delegacji',
				requestToAcceptanceError:
					'Wybrana delegacja nie spełnia wszystkich wymagań, potrzebnych do wysłania do akcpetacji. Proszę sprawdzić czy wszystkie wymagane pola są wypełnione.',
				requestToAcceptanceSuccess: 'Delegacja została stworzona i wysłana do akceptacji.',
				requestToCorrectionError: 'Wystąpił błąd podczas wysyłania delegacji do korekty.',
				requestToCorrectionSuccess: 'Delegacja została wysłana do korekty.',
				requestToReturnAcceptanceSuccess: 'Delegacja została zwrócona do akceptacji.',
				requestToReturnAcceptanceError: 'Wystąpił błąd podczas zwracania delegacji do akceptacji.',
				requestToSettlementAcceptanceSuccess: 'Delegacja została wysłana do akceptacji rozliczenia.',
				requestToSettlementAcceptanceError:
					'Wystąpił błąd podczas wysyłania delegacji do akceptacji rozliczenia.',
				requestToDoneSuccess: 'Delegacja została zakończona.',
				requestToDoneError: 'Wystąpił błąd podczas zakończenia delegacji.',
				nameRequired: 'Do zapisu delegacji wymagana jest nazwa.',
				vatAndNetRequired: 'Załączniki muszą posiadać wypełnione pola VAT i Netto.',
			},
		},
	},
	en: {
		DelegationsList: {
			DelegationModal: {
				createSuccess: 'Delegation has been saved',
				createError: 'An error occurred while saving the delegation',
				updateSuccess: 'Delegation has been updated',
				updateError: 'An error occurred while updating the delegation',
				requestToAcceptanceError:
					'The selected delegation does not meet all the requirements needed to be sent for acceptance. Please check if all required fields are filled in.',
				requestToAcceptanceSuccess: 'Delegation has been created and sent for acceptance.',
				requestToCorrectionError: 'An error occurred while sending the delegation for correction.',
				requestToCorrectionSuccess: 'Delegation has been sent for correction.',
				requestToReturnAcceptanceSuccess: 'Delegation has been returned for acceptance.',
				requestToReturnAcceptanceError: 'An error occurred while returning the delegation for acceptance.',
				requestToSettlementAcceptanceSuccess: 'Delegation has been sent for settlement acceptance.',
				requestToSettlementAcceptanceError:
					'An error occurred while sending the delegation for settlement acceptance.',
				requestToDoneSuccess: 'Delegation has been completed.',
				requestToDoneError: 'An error occurred while completing the delegation.',
				nameRequired: 'Name is required to save the delegation.',
				vatAndNetRequired: 'Attachments must have VAT and Net fields filled in.',
			},
		},
	},
};
