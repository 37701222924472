import { matchPath, useLocation } from 'react-router';

import { Props } from './model';
import { NavItemBase } from './NavItemBase';

export const NavItemWrapper = (props: Props): JSX.Element => {
	const { item, depth = 0 } = props;

	const pathname = useLocation().pathname;
	const open = matchPath(pathname?.split('/')[1], {
		path: item.href?.split('/')[1],
		exact: false,
	});

	return <NavItemBase depth={depth} open={Boolean(open)} item={item} key={item.title} />;
};
