import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueIdPath: '?issueId=',
	hash: '#',
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				WorkingDetails: {
					issueDet: 'Zlecenie',
					timeDet: 'Czas pracy',
					timeDesc: 'Od: ',
					projectDet: 'Projekt',
					working: 'Aktualnie zajmuje się -  Pracuje',
					notWorking: 'Aktualnie zajmuje się - Nie pracuje',
					summary: 'Łączny tygodniowy czas:',
					chart: 'Wykres czasu pracy',
					popup: {
						title: 'Avatar',
						fieldLabel: 'Link to twojego awatara',
					},
					avatar: {
						updated: 'Avatar został zaaktualizowany',
						updateFailed: 'Aktualizowanie avatara zakończone niepowodzeniem proszę spróbować później',
					},
					noWorklogTime: '0 minut',
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				WorkingDetails: {
					timeDet: 'Time',
					timeDesc: 'For: ',
					projectDet: 'Project',
					working: 'Current activity - Working',
					notWorking: 'Current activity - Idle',
					issueDet: 'Issue',
					summary: 'Summary week time:',
					chart: 'Working hours chart',
					popup: {
						title: 'Avatar',
						fieldLabel: 'Link to your avatar',
					},
					avatar: {
						updated: 'Avatar updated',
						updateFailed: 'Avatar update failed please try again later',
					},
					noWorklogTime: '0 minutes',
				},
			},
		},
	},
};
