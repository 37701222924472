import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		button: {
			width: theme.spacing(5),
			height: theme.spacing(5),
			color: theme.palette.lightGray.main,
			minWidth: 'auto',
			padding: 0,
			'&:hover': {
				backgroundColor: `${theme.palette.lightGray.main}10`,
			},
		},
	}),
	{ name: `Dashboard_NavBar_DrawerContent_LogOutButton` }
);
