import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	divider: 'divider',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				Tabs: {
					profileSelector: 'Lista avatarów',
					tileConfiguration: 'Konfiguracja tile',
				},
			},
		},
	},
	en: {
		Settings: {
			WidgetsConfigurationMain: {
				Tabs: {
					profileSelector: 'Profile selector',
					tileConfiguration: 'Tile configuration',
				},
			},
		},
	},
};
