import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationPopover: {
			PopoverHeader: {
				markAllAsRead: 'Oznacz wszystkie jako przeczytane',
				notifications: 'Powiadomienia',
			},
		},
	},
	en: {
		NotificationPopover: {
			PopoverHeader: {
				markAllAsRead: 'Mark all as read',
				notifications: 'Notifications',
			},
		},
	},
};
