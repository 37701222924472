import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						filtersLabel: 'Filtry',
						applyFiltersButtonLabel: 'Zastosuj filtry',
						clearFiltersButtonLabel: 'Wyczyść filtry',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						filtersLabel: 'Filters',
						applyFiltersButtonLabel: 'Apply filters',
						clearFiltersButtonLabel: 'Clear filters',
					},
				},
			},
		},
	},
};
