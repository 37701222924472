import {
	IAttachment,
	IComment,
	IFieldValue,
	IIssue,
	IIssueForm,
	IIssueKanbanDetails,
	IPage,
	ITaskerUser,
} from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	////////////ISSUE////////////
	issue: {} as IIssue,
	///////////Form/////////////
	issueForms: { Count: 0, Data: [] } as IPage<IIssueForm[]>,
	issueFormChoosen: {} as IIssueForm,
	/////////Attachment//////////
	attachmentIssue: [] as IAttachment[],
	/////////Comments//////////
	comments: [] as IComment[],
	///////////Form/////////////
	createIssueValue: [] as Partial<IFieldValue>[],
	updateIssueValue: [] as Partial<IFieldValue>[],
	deleteIssueValue: [] as Partial<IFieldValue>[],
	//////////////Options/////////
	taskerUsers: [] as ITaskerUser[],
	kanbanPortalDetails: {} as IIssueKanbanDetails,
};

export type IPortalIssueState = typeof State;

const portalIssueReducer = (state = State, action: ObjectAction): IPortalIssueState => {
	switch (action?.type) {
		case actionTypes.ISSUE_FETCH_ONE_NEW: {
			return {
				...state,
				issue: action?.data?.Issue,
				comments: (action?.data?.Comments as IComment[]).reverse(),
				attachmentIssue: action?.data?.Issue?.Attachments,
			};
		}
		case actionTypes.ISSUE_MODAL_FETCH_COMMENTS: {
			return {
				...state,
				comments: state?.comments?.filter(x => x?.Id != action?.commentId),
			};
		}
		case actionTypes.ISSUE_MODAL_RESET: {
			return {
				...State,
				kanbanPortalDetails: state.kanbanPortalDetails,
			};
		}
		case actionTypes.ISSUE_MODAL_UPDATE_FORM_VALUES_LOCAL: {
			return {
				...state,
				createIssueValue: action?.create,
				updateIssueValue: action?.update,
				deleteIssueValue: action?.toDelete,
			};
		}
		case actionTypes.ISSUE_MODAL_RESET_FORM_VALUES_LOCAL: {
			return {
				...state,
				createIssueValue: [],
				updateIssueValue: [],
			};
		}
		case actionTypes.PASS_PORTAL_DATA: {
			return {
				...state,
				kanbanPortalDetails: action?.data,
			};
		}
		case actionTypes.GET_ISSUE_FORM_LIST: {
			return {
				...state,
				issueForms: {
					Data: action?.data?.Data,
					Count: action.data?.Count,
				},
			};
		}
		case actionTypes.GET_ISSUE_FORM: {
			return {
				...state,
				issueFormChoosen: action.data,
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		default: {
			return state;
		}
	}
};

export default portalIssueReducer;
