import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	changePassword: 'changePassword',
	changeLogin: 'changeLogin',
	changeEmail: 'changeEmail',

	textFieldNameOfNewLogin: 'newLogin',
	textFieldNameOfNewEmail: 'newEmail',

	initialVisibilityState: {
		changePassword: false,
		changeLogin: false,
		changeEmail: false,
	},

	newPasswordDefaultValues: {
		password: '',
		confirm: '',
		oldPassword: '',
	},

	initialNewLoginValues: { newLogin: '', password: '' },

	initialNewEmailValues: { newEmail: '', password: '' },
};

export const Translation = {
	pl: {
		Settings: {
			Security: {
				passwordChanged: 'Hasło zostało zmienione',
				passwordChangeFailed: 'Hasło nie zostało zmienione',
				loginChanged: 'Login został zmieniony',
				loginChangeFailed: 'Login nie został zmieniony',
				emailChanged: 'Email został zmieniony',
				emailChangeFailed: 'Email nie został zmieniony',
				cardHeaderChangePassword: 'Zmień hasło',
				cardHeaderChangeLogin: 'Zmień login',
				cardHeaderChangeEmail: 'Zmień email',
				inputChangeLoginLabel: 'Nowy login',
				inputChangeEmailLabel: 'Nowy email',
			},
		},
	},
	en: {
		Settings: {
			Security: {
				passwordChanged: 'Password changed',
				passwordChangeFailed: 'Password change failed',
				loginChanged: 'Login changed',
				loginChangeFailed: 'Login change failed',
				emailChanged: 'Email changed',
				emailChangeFailed: 'Email change failed',
				cardHeaderChangePassword: 'Change password',
				cardHeaderChangeLogin: 'Change login',
				cardHeaderChangeEmail: 'Change email',
				inputChangeLoginLabel: 'New login',
				inputChangeEmailLabel: 'New email',
			},
		},
	},
};
