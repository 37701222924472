import clsx from 'clsx';
import { useContext, useMemo } from 'react';

import { Typography } from '@mui/material';

import { EFieldDefinitionType, EInputTypes, IFieldValue } from '@skillandchill/tasker-types';
import { CustomInput } from '@skillandchill/tasker-widgets2';

import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { EIssueModalDetailsPageFields } from '../../../../model';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { FieldValueFactory } from '../utils/fieldValueFactory';
import { useHandleResetError } from '../utils/useHandleResetError';

import { Props } from './model';
import { useStyles } from './styles';

export const DecimalIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();
	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);

	const { setFieldValues, issueErrors, setIssueState, issueState } = useContext(issueModalDataContext);

	const isError = useMemo(() => {
		return (issueErrors.find(issueError => issueError.inputName === EIssueModalDetailsPageFields.properties)
			?.additionalData as number[])?.includes(issueFormSectionToIssueField?.AttributeDefinitionId as number);
	}, [issueErrors]);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;
	const initialFieldValue = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const initialInputValue = initialFieldValue?.length ? (initialFieldValue[0]?.ValueDecimal as number) : null;

	const inputValue = (issueState?.Properties?.Decimal ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	// useEffect(() => {
	// 	if (typeof inputValue === 'undefined') return;

	// 	handleInputChange(initialInputValue);
	// }, [initialInputValue]);

	// useEffect(() => {
	// 	// if (typeof inputValue === 'undefined') return;

	// 	if (Object.keys(issueState?.Properties?.Decimal ?? {})?.includes(String(AttributeDefinitionId))) {
	// 		handleInputChange(inputValue);
	// 	}
	// 	// if (inputValue) handleInputChange(inputValue);
	// 	// else handleInputChange(initialInputValue);
	// }, []);

	const handleInputChange = (newValue: number | null | string) => {
		if (!newValue) return;
		const floatNewValue = Number.parseFloat(newValue?.toString());
		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				Decimal: {
					...(prevState?.Properties?.Decimal ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: floatNewValue,
				},
			},
		}));
		handleResetInputError();

		handleFieldValuesChange(floatNewValue);
	};

	const handleFieldValuesChange = (newValue: number | null) => {
		setFieldValues(prevState => {
			if (initialFieldValue?.length) {
				return {
					...prevState,
					fieldsValueToUpdate: [
						...(prevState?.fieldsValueToUpdate?.filter(
							getFieldValueWitouthFormSectionAttributeDefinitionId
						) ?? []),
						FieldValueFactory.create(
							EFieldDefinitionType.Decimal,
							AttributeDefinitionId as number,
							newValue,
							initialFieldValue[0].Id
						),
					],
				};
			}

			return {
				...prevState,
				fieldsValueToCreate: [
					...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ??
						[]),
					FieldValueFactory.create(EFieldDefinitionType.Decimal, AttributeDefinitionId as number, newValue),
				],
			};
		});
	};

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>
	) {
		return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.inputContainer)}>
				<CustomInput
					type={EInputTypes.number}
					label=""
					className={clsx(isError ? classes.selectError : undefined)}
					handleChange={val => handleInputChange(val as number)}
					value={inputValue ?? initialInputValue}
					inEdit={inEdit}
					canSetInputInNonEdit
					nonEditComponent={<Typography className={classes.valueContainer}>{initialInputValue}</Typography>}
				/>
			</div>
		</div>
	);
};
