import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Budgets: {
			MoveBudgetForm: {
				save: 'Zapisz',
				cancel: 'Anuluj',
				error: 'Brak danych budżetowych niezbędnych do wykonania operacji.',
				serverError: 'Błąd serwerowy prosimy o zgłoszenie tego administracji.',
				targetBudgetId: 'Budżet docelowy',
				outcomeLine: 'Przeniesienie wydatku',
				incomeLine: 'Przeniesienie przychodu',
				budget: 'Przeniesienie budżetu',
			},
		},
	},
	en: {
		Budgets: {
			MoveBudgetForm: {
				save: 'Save',
				cancel: 'Cancel',
				error: 'Lack of data nesesery to perform an action.',
				serverError: 'Server error please call your administrator.',
				targetBudgetId: 'Target budget',
				outcomeLine: 'Move outcome',
				incomeLine: 'Move income',
				budget: 'Move budget',
			},
		},
	},
};
