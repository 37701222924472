import { t } from 'i18next';

import { Permission } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { defaultOrganization } from '@/utils/configuration';

export const Resources = {
	redirect: {
		general: '/admin/organization/tabs/general',
		notFound: '/errors/error-404',
	},
	tabs: {
		general: { name: 'general' },
		users: { name: 'users' },
		clock: { name: 'clock' },
		passwordPolicy: { name: 'passwordPolicy' },
		structure: { name: 'structure' },
		endWorkDay: { name: 'endWorkDay' },
		integration: { name: 'integrations' },
		createOrganization: { name: 'createOrganization' },
		manageUsers: { name: 'manageUsers' },
		delegations: { name: 'delegations' },
		preferences: { name: 'preferences' },
	},
	...GlobalResources,
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			pageTitle: 'Panel administracyjny tenanta',
			Header: { title: 'Organizacja' },
			tabs: {
				general: { label: 'Ogólne' },
				users: { label: 'Użytkownicy' },
				clock: { label: 'Konfiguracja tagów zegarka' },
				passwordPolicy: { label: 'Polityka haseł' },
				structure: { label: 'Struktura organizacyjna' },
				endWorkDay: { label: 'Zakończenie dnia pracy' },
				integration: { label: 'Integracje' },
				createOrganization: { label: 'Tworzenie organizacji' },
				manageUsers: { label: 'Zarządzaj użytkownikami' },
				delegations: { label: 'Delegacje' },
				preferences: { label: 'Preferencję organizacyjne' },
			},
			OrganizationStructure: {
				pageTitle: {
					loading: 'Ładowanie',
					title: 'Struktura organizacyjna',
				},
			},
			addStructure: 'Dodaj strukturę',
			addPosition: 'Dodaj pozycję',
			addCategory: 'Dodaj kategorię',
			Tab: {
				expand: 'Rozwiń wszystko',
				collapse: 'Zwiń wszystko',
				endWorkDay: { label: 'Zakończenia dnia pracy' },
			},
		},
	},
	en: {
		OrganizationPanel: {
			pageTitle: 'Organization admin panel',
			Header: { title: 'Organization' },
			tabs: {
				general: { label: 'General' },
				users: { label: 'Users' },
				clock: { label: 'Clock tags configuration' },
				passwordPolicy: { label: 'Password policy' },
				structure: { label: 'Organization structure' },
				endWorkDay: { label: 'End of workday' },
				integration: { label: 'Integrations' },
				createOrganization: { label: 'Creating organization' },
				manageUsers: { label: 'Manage users' },
				delegations: { label: 'Delegations' },
				preferences: { label: 'Organization preferences' },
			},
			OrganizationStructure: {
				pageTitle: {
					loading: 'Loading',
					title: 'Organization structure',
				},
			},
			addStructure: 'Add Structure',
			addPosition: 'Add Position',
			addCategory: 'Add Category',
			Tab: {
				expand: 'Expand all',
				collapse: 'Collapse all',
				endWorkDay: { label: 'End of workday' },
			},
		},
	},
};

export const getTabs = (permissions: number[] = [], organizationId?: number) => {
	const data = [];
	if (permissions?.includes(Permission.SuperAdmin) && organizationId === defaultOrganization) {
		data.push({
			value: Resources.tabs.createOrganization.name,
			label: t('OrganizationPanel.tabs.createOrganization.label'),
			requiredOrg: defaultOrganization,
		});
		data.push({
			value: Resources.tabs.manageUsers.name,
			label: t('OrganizationPanel.tabs.manageUsers.label'),
			requiredOrg: defaultOrganization,
		});
	}
	return [
		{ value: Resources.tabs.general.name, label: t('OrganizationPanel.tabs.general.label') },
		{ value: Resources.tabs.users.name, label: t('OrganizationPanel.tabs.users.label') },
		{ value: Resources.tabs.passwordPolicy.name, label: t('OrganizationPanel.tabs.passwordPolicy.label') },
		{ value: Resources.tabs.structure.name, label: t('OrganizationPanel.tabs.structure.label') },
		{ value: Resources.tabs.endWorkDay.name, label: t('OrganizationPanel.tabs.endWorkDay.label') },
		{ value: Resources.tabs.integration.name, label: t('OrganizationPanel.tabs.integration.label') },
		{ value: Resources.tabs.delegations.name, label: t('OrganizationPanel.tabs.delegations.label') },
		{ value: Resources.tabs.preferences.name, label: t('OrganizationPanel.tabs.preferences.label') },
		...data,
	];
};
