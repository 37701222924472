import { Action, ActionType } from '@/store/types';
import { IModalData } from '@/views/DelegationsList/DelegationModal/model';

export const SET_MODAL_DATA: ActionType = 'SET_MODAL_DATA';

export function delegation_setModalData(data: IModalData): Action {
	return {
		type: SET_MODAL_DATA,
		data,
	};
}
