import { AsyncAction } from '@skillchill/redux-promisify';

import { IInvoiceSeries } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const INVOICE_SERIES_DELETE: ActionType = 'INVOICE_SERIES_DELETE';
export const INVOICE_SERIES_UPDATE: ActionType = 'INVOICE_SERIES_UPDATE';
export const INVOICE_SERIES_CREATE: ActionType = 'INVOICE_SERIES_CREATE';

const basePath = 'InvoiceSeries';

function _deleteInvoiceSeries(id: number): Action {
	return {
		type: INVOICE_SERIES_DELETE,
		data: id,
	};
}

function _createInvoiceSeries(data: IInvoiceSeries): Action {
	return {
		type: INVOICE_SERIES_CREATE,
		data,
	};
}

function _updateInvoiceSeries(data: IInvoiceSeries): Action {
	return {
		type: INVOICE_SERIES_UPDATE,
		data,
	};
}

export function invoiceSeries_delete(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteInvoiceSeries((json as IJson).data)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function invoiceSeries_create(data: Partial<IInvoiceSeries>): AsyncAction | Promise<unknown> {
	return function(context, dispatch) {
		context.then(json => dispatch(_createInvoiceSeries((json as IJson).data)));
		return axios().post(basePath, { ...data });
	};
}

export function invoiceSeries_update(data: Partial<IInvoiceSeries>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateInvoiceSeries((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, { ...data });
	};
}
