import { AsyncAction } from '@skillchill/redux-promisify';

import { IRole, ITaskerUser, ITaskerUserRole } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import axios from '@/utils/axios';

import { Action } from '../types';

export const FETCH_ALL_ROLES_WITH_USER_ROLES = 'FETCH_ALL_ROLES_WITH_USER_ROLES';
export const RESET_ALL_ROLES_WITH_USER_ROLES = 'RESET_ALL_ROLES_WITH_USER_ROLES';

const basePath = 'OrganizationCreateOrUpdateUser';

interface IRolesWithUser {
	roles: IRole[];
	taskerUser?: ITaskerUser;
	rolesById?: ITaskerUserRole[];
}

function _fetchRolesWithUser(rolesWithUser: IRolesWithUser) {
	return {
		type: FETCH_ALL_ROLES_WITH_USER_ROLES,
		roles: rolesWithUser?.roles,
		taskerUser: rolesWithUser?.taskerUser,
		rolesById: rolesWithUser?.rolesById,
	};
}

export function reset_user_roles_with_possible_roles(): Action {
	return {
		type: RESET_ALL_ROLES_WITH_USER_ROLES,
		roles: [],
		taskerUser: undefined,
		rolesById: [],
	};
}
//////////////////////////////////////////////////////

export function fetch_user_roles_with_possible_roles(userId?: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchRolesWithUser((data as IJson).data)));
		return axios().post(basePath, { userId });
	};
}
