import { ITaskerUser } from '@skillandchill/tasker-types';
import { Profile } from '@skillandchill/tasker-widgets2/dist/utils/types';

export const getInitials = (profile?: Profile): string =>
	profile ? [profile.firstName, profile.lastName].map(v => v && v[0].toUpperCase()).join('') : '';

export const getFullName = (profile?: Profile, defaultValue?: string): string => {
	return profile?.firstName && profile?.lastName
		? [profile.firstName, profile.lastName].join(' ')
		: defaultValue ?? 'Anonymous';
};
export const getInitialsNew = (taskerUser?: Partial<ITaskerUser>): string =>
	taskerUser ? [taskerUser.Name, taskerUser.Surname].map(v => v && v[0].toUpperCase()).join('') : '';

export const getFullNameNew = (profile?: Partial<ITaskerUser>, defaultValue?: string): string => {
	return profile?.Name && profile?.Surname ? [profile.Name, profile.Surname].join(' ') : defaultValue ?? 'Anonymous';
};
