import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		alignCenter: {
			alignItems: 'center',
		},
		marginLeft: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		card: {
			position: 'relative',
			overflow: 'unset',
		},
	}),
	{ name: `IssueModalEditor_Tabs_General_IssueForm_IssueFormSectionWithCollapse` }
);
