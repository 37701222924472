import { Button } from '@mui/material';

import { Props } from './model';

export const ButtonWrapper = (props: Props): JSX.Element => {
	const { activeClassName, to, component, children, ...rest } = props;
	return (
		<Button {...rest} activeClassName={activeClassName} to={to} component={component}>
			{children}
		</Button>
	);
};
