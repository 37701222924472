import { IIssue } from '@skillandchill/tasker-types';

import { IIssueModalData } from '../IssueModalContent/IssueDetailsPage/model';

export const mapInitialStateToIssueState = (issue: Partial<IIssue>): Partial<IIssueModalData> => {
	return {
		Id: issue?.Id,
		Assigned: issue?.Members?.Assigned,
		Observer: issue?.Members?.Observer,
		Auditor: issue?.Members?.Auditor,
		Reporter: issue?.Members?.Reporter,
		DescriptionHtml: issue?.DescriptionHtml,
		ProjectId: issue?.ProjectId,
		Title: issue?.Title,
		ProjectIssueTypeId: issue?.ProjectIssueTypeId,
	};
};
