import { Translation as Error } from 'utils/error/resources';

import { LangResources } from '@skillandchill/tasker-widgets2/dist/utils/language/languageResources';
import { en_default as widgetResource } from '@skillandchill/tasker-widgets2/dist/utils/language/widgets.translation';
import translationReduced from '@skillandchill/translate/dist/en/default.json';

import { DashboardTranslationEN } from '@/layouts/Dashboard/index.translation';
import { TranslationNumberToText } from '@/locales/additionalTranslations';
import { multipleMerge } from '@/locales/utils';
import { ActivitiesTranslationEN } from '@/views/Activities/index.translation';
import { ReckonTranslationEN } from '@/views/Agreement/index.translation';
import { DelegationsAcceptanceListTranslationEN } from '@/views/DelegationsAcceptanceList/index.translation';
import { DelegationsListTranslationEN } from '@/views/DelegationsList/index.translation';
import { IssueModalNewTranslationEN } from '@/views/IssueModelNew/index.translation';
import { RolesEN } from '@/views/Roles/index.translation';

import { BudgetsTranslationEN } from 'view/Budgets/BudgetsWidget/index.translation';
import { CalendarTranslationEN } from 'view/Calendar/index.translation';
import { CustomerCreateTranslationEN } from 'view/CustomerCreate/index.translation';
import { CustomerDetailTranslationEN } from 'view/CustomerDetail/index.translation';
import { CustomerListTranslationEN } from 'view/CustomerList/index.translation';
import { DictionariesTranslationEN } from 'view/Dictionaries/index.translation';
import { DictionaryContentTranslationEN } from 'view/DictionaryContent/index.translation';
import { Translation as Error401 } from 'view/Error401/resources';
import { Translation as Error404 } from 'view/Error404/resources';
import { Translation as Error500 } from 'view/Error500/resources';
import { GantTranslationEN } from 'view/Gant/index.translation';
import { HolidayTranslationEN } from 'view/Holiday/index.translation';
import { IssueListTranslationEN } from 'view/IssueList/index.translation';
import { IssueModalEditorTranslationEN } from 'view/IssueModalEditor/index.translation';
import { KanbanTranslationEN } from 'view/KanbanBoard/index.translation';
import { KeyListenerTranslationEN } from 'view/KeyListener/index.translation';
import { Translation as KeyListener } from 'view/KeyListener/resources';
import { LeaveRequestCalendarEN } from 'view/LeaveRequestCalendar/index.translation';
import { LeaveTypeTranslationEN } from 'view/LeaveTypeList/index.translation';
import { Translation as Loading } from 'view/Loading/resources';
import { LoginTranslationEN } from 'view/Login/index.translation';
import { NotificationListTranslationEN } from 'view/NotificationList/index.translation';
import { OrganizationPanelTranslationEN } from 'view/OrganizationPanel/index.translation';
import { PortalIssueTranslationEN } from 'view/PortalIssue/index.translation';
import { PortalListTranslationEN } from 'view/PortalList/index.translation';
import { ProjectCreateTranslationEN } from 'view/ProjectCreate/index.translation';
import { ProjectDetailsTranslationEN } from 'view/ProjectDetails/index.translation';
import { ProjectListTranslationEN } from 'view/ProjectList/index.translation';
import { Translation as RecoverPassword } from 'view/RecoverPassword/resources';
import { RegisterTranslationEN } from 'view/Register/index.translation';
import { ReportsTranslationEN } from 'view/Reports/index.translation';
import { Translation as ResetPassword } from 'view/ResetPassword/resources';
import { RolePanelTranslationEN } from 'view/RolePanel/index.translation';
import { SearchBarTranslationEN } from 'view/SearchBar/index.translation';
import { SettingsTranslationEN } from 'view/Settings/index.translation';
import { Translation as SnackBarProviderWrapper } from 'view/SnackBarProviderWrapper/resources';
import { TagAdminPanelTranslationEN } from 'view/TagAdminPanel/index.translation';
import { TagGroupInfoTranslationEN } from 'view/TagGroupInfo/index.translation';
import { TimeAssignTranslationEN } from 'view/TimeAssign/index.translation';
import { TimeReportTranslationEN } from 'view/TimeReport/index.translation';
import { Translation as UnsavedChangesAlert } from 'view/UnsavedChangesAlert/resources';
import { Translation as UnsavedCommentAlert } from 'view/UnsavedCommentAlert/resources';
import { VersionsTranslationEN } from 'view/Versions/index.translation';
import { WorkDayEndSummaryTranslationEN } from 'view/WorkDayEndSummary/index.translation';

const translation = multipleMerge([
	translationReduced.FRONTEND,
	LangResources.en,
	Error401.en,
	Error404.en,
	Error500.en,
	Loading.en,
	RecoverPassword.en,
	ResetPassword.en,
	SnackBarProviderWrapper.en,
	UnsavedChangesAlert.en,
	UnsavedCommentAlert.en,
	Error.en,
	KeyListener.en,
	TranslationNumberToText.en,
	...DashboardTranslationEN,
	...ActivitiesTranslationEN,
	...CalendarTranslationEN,
	...CustomerCreateTranslationEN,
	...CustomerDetailTranslationEN,
	...CustomerListTranslationEN,
	...DictionariesTranslationEN,
	...DictionaryContentTranslationEN,
	...GantTranslationEN,
	...HolidayTranslationEN,
	...IssueListTranslationEN,
	...IssueModalEditorTranslationEN,
	...LoginTranslationEN,
	...NotificationListTranslationEN,
	...OrganizationPanelTranslationEN,
	...PortalIssueTranslationEN,
	...PortalListTranslationEN,
	...ProjectCreateTranslationEN,
	...ProjectDetailsTranslationEN,
	...ProjectListTranslationEN,
	...RegisterTranslationEN,
	...RolePanelTranslationEN,
	...SearchBarTranslationEN,
	...SettingsTranslationEN,
	...TagAdminPanelTranslationEN,
	...TagGroupInfoTranslationEN,
	...TimeAssignTranslationEN,
	...TimeReportTranslationEN,
	...KanbanTranslationEN,
	...IssueModalNewTranslationEN,
	...ReckonTranslationEN,
	...KeyListenerTranslationEN,
	...ReportsTranslationEN,
	...VersionsTranslationEN,
	...LeaveTypeTranslationEN,
	...LeaveRequestCalendarEN,
	...WorkDayEndSummaryTranslationEN,
	...BudgetsTranslationEN,
	...DelegationsListTranslationEN,
	...DelegationsAcceptanceListTranslationEN,
	...RolesEN,
]);
const widgets = multipleMerge([translationReduced.WIDGETS, widgetResource.WIDGETS]);
export default {
	en_default: {
		FRONTEND: translation,
		WIDGETS: widgets,
		BACKEND: translationReduced.BACKEND,
	},
};
