import uuid from 'uuid';

import { IDictionaryItem } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	getProjectKey: (item: IDictionaryItem) => `Project_${item?.Id}_${uuid()}`,
	getBudgetKey: (item: IDictionaryItem) => `Budget_${item?.Id}_${uuid()}`,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						nameInputLabel: 'Nazwa delegacji',
						projectSelectLabel: 'Projekt',
						budgetSelectLabel: 'Budżet',
						dateRangePickerLabel: 'Daty wyjazdu i powrotu',
						originInputLabel: 'Miejsce wyjazdu',
						destinationInputLabel: 'Miejsce docelowe',
						distanceTotalLabel: 'Całkowity dystans (km)',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						nameInputLabel: 'Delegation name',
						projectSelectLabel: 'Project',
						budgetSelectLabel: 'Budget',
						dateRangePickerLabel: 'Departure and return dates',
						originInputLabel: 'Origin',
						destinationInputLabel: 'Destination',
						distanceTotalLabel: 'Total distance (km)',
					},
				},
			},
		},
	},
};
