import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						AgreementSignedDateFilter: {
							label: 'Data zawarcia umowy',
							since: 'Od',
							until: 'Do',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						AgreementSignedDateFilter: {
							label: 'Agreement signing date',
							since: 'Since',
							until: 'Until',
						},
					},
				},
			},
		},
	},
};
