import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@skillchill/redux-promisify';

import { DeepPartial, ILeaveRequest, IPage } from '@skillandchill/tasker-types';
import { IDataCalendar } from '@skillandchill/tasker-types/dist/types/custom/leaveRequestCalendar';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'LeaveRequest';

export const LEAVE_REQUEST_FETCH_PAGE: ActionType = 'LEAVE_REQUEST_FETCH_PAGE';
export const LEAVE_REQUEST_FETCH_SINGLE: ActionType = 'LEAVE_REQUEST_FETCH_SINGLE';
export const LEAVE_REQUEST_DELETE: ActionType = 'LEAVE_REQUEST_DELETE';
export const LEAVE_REQUEST_CALENDAR_FETCH: ActionType = 'LEAVE_REQUEST_CALENDAR_FETCH';
export const LEAVE_REQUEST_CREATE_OR_UPDATE_SINGLE: ActionType = 'LEAVE_REQUEST_CREATE_OR_UPDATE_SINGLE';

export function _fetchPageLeaveRequest(data: IPage<ILeaveRequest[]>): Action {
	return {
		type: LEAVE_REQUEST_FETCH_PAGE,
		data,
	};
}

export function _fetchSingleLeaveRequest(data: ILeaveRequest): Action {
	return {
		type: LEAVE_REQUEST_FETCH_SINGLE,
		data,
	};
}

export function resetLeaveRequest(): Action {
	return {
		type: LEAVE_REQUEST_FETCH_SINGLE,
		data: {},
	};
}

export function _deleteLeaveRequest(id: number): Action {
	return {
		type: LEAVE_REQUEST_DELETE,
		data: id,
	};
}

export function _createOrUpdateLeaveRequest(data: ILeaveRequest): Action {
	return {
		type: LEAVE_REQUEST_CREATE_OR_UPDATE_SINGLE,
		data,
	};
}

export function _fetchCalendarLeaveRequest(data: DeepPartial<IDataCalendar<ILeaveRequest[]>>): Action {
	return {
		type: LEAVE_REQUEST_CALENDAR_FETCH,
		data,
	};
}

export function fetchLeaveRequestPage(from = 0, to = 6): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPageLeaveRequest((json as IJson).data)));
		return axios().get(`${basePath}/from/${from}/to/${to}`);
	};
}

export function fetchLeaveRequestSingle(id: number): AsyncAction {
	if (!id) throw new Error('Not found id');
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSingleLeaveRequest((json as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function deleteLeaveRequest(id: number): AsyncAction {
	if (!id) throw new Error('Not found id');
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteLeaveRequest(id)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function createLeaveRequest(leaveRequest: Partial<ILeaveRequest>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createOrUpdateLeaveRequest((json as IJson).data)));
		return axios().post(basePath, { ...leaveRequest });
	};
}

export function updateLeaveRequest(leaveRequest: Partial<ILeaveRequest>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createOrUpdateLeaveRequest((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, { ...leaveRequest });
	};
}

let source: CancelTokenSource | undefined;
export function calendar_getLeaveRequest(startDate: Date, endDate: Date, userIds: number[]): AsyncAction {
	if (source) source?.cancel();
	source = Axios.CancelToken.source();
	if (!startDate || !endDate || !userIds) throw 'To fetch calendar date assign startDate, endDate and user Id';

	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCalendarLeaveRequest((json as IJson)?.data)));
		return axios().post(
			`${basePath}/user`,
			{
				StartDate: startDate,
				EndDate: endDate,
				UserIds: userIds,
			},
			{ cancelToken: source?.token }
		);
	};
}
