import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					UsersToAddSelection: {
						searchUserAndEmail: 'Wyszukaj użytkownika ...',
						user: 'Użytkownik',
						organizationRoles: 'Role w organizacji',
						projectHover:
							'Po najechaniu na nazwę projektu wyświetlą się role projektowe użytkownika w tym projekcie',
						userProjects: 'Projekty użytkownika',
						noUsersToDisplay: 'Brak użytkowników do wyświetlenia',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					UsersToAddSelection: {
						searchUserAndEmail: 'Search for user ...',
						user: 'User',
						organizationRoles: 'Organization roles',
						projectHover: `On hover over the name of a project, the user's project roles in that project will be displayed`,
						userProjects: 'User projects',
						noUsersToDisplay: 'No users to display',
					},
				},
			},
		},
	},
};
