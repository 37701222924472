import { DateTimePicker } from '@mui/lab';
import { TextField as TextFieldMui } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';
import { DatePickerWrapper } from '@skillandchill/tasker-widgets2/dist/widgets/DatePickerWrapper/DatePickerWraper';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';

export const DateField = (props: Props): JSX.Element => {
	const { value, onChange, isDateTime, isRequired, validate, requiredClassName } = props;
	const { t } = useTrans(
		'IssueModalEditor.Tabs.General.IssueForm.IssueFormSectionWithCollapse.FieldDefinition.DateField'
	);

	return (
		<div className={isRequired && validate && value ? requiredClassName : Resources.GlobalResources.empty}>
			<VisibilityControl
				condition={isDateTime}
				alternative={
					<DatePickerWrapper
						isFullWidth
						showTodayButton
						todayText={t('today')}
						value={value}
						onChange={onChange}
						inputProps={{ style: Resources.style }}
					/>
				}
			>
				<DateTimePicker<Date>
					value={value as Date}
					onChange={onChange}
					renderInput={params => <TextFieldMui {...params} />}
					InputProps={{ style: Resources.style }}
				/>
			</VisibilityControl>
		</div>
	);
};
