import {
	ActivityWorklog,
	ICreateIssueForm,
	ICustomer,
	IDictionary,
	IIssueForm,
	IPage,
	IProject,
	IProjectRole,
	ITagGroup,
	ITaskerUser,
	ITaskerUserProjectsAndRoles,
	IWorkingUserNEW,
} from '@skillandchill/tasker-types';
import { ICurrency } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/currency';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';
import { IChart } from '@skillandchill/tasker-types/dist/types/endPoints/customerChart';
import { CommentBotConfiguration } from '@skillandchill/tasker-widgets2/dist/utils/types/CommentBotConfiguration';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	project: {} as IProject | undefined,
	/////////NEW IssueFormNew///////////////
	issueFormNew: {
		IssueFormName: '',
		IssueFormSection: [],
		ProjectId: -1,
	} as ICreateIssueForm,
	/////////NEW IssueFormUpdate///////////////
	issueFormUpdate: {} as IIssueForm,
	////////IssueFormCommon////////////////////
	issueTagGroups: [] as ITagGroup[],
	dictionaries: [] as IDictionary[],
	////////IssueFormList//////////////////////
	// issueFormListCount: 0 as number,
	projectForms: {} as IPage<IIssueForm[]>,
	// issueFormList: [] as IIssueForm[],
	filterIssueForm: {
		text: '' as string,
	},
	customersWithProjects: [] as ICustomer[],
	////////OverviewCommentConfiguration///////////////
	configuration: {} as CommentBotConfiguration,
	////////OverviewProjectCharts/////////////////////
	projectWorklogs: [] as ActivityWorklog[],
	////////OverviewProjectMembersOptions/////////////////////
	taskerUsers: [] as Partial<ITaskerUser>[], //Options
	////////ProjectMembersConfiguration//////////////////////
	projectUsers: [] as ITaskerUserProjectsAndRoles[],
	projectRoles: [] as IProjectRole[],
	taskerUsersToAddToProject: [] as ITaskerUserProjectsAndRoles[],
	projectUsersCount: 0 as number,
	allTaskerUsersWithCurrentProjects: [] as ITaskerUserProjectsAndRoles[],
	allTaskerUsersWithCurrentProjectsCount: 0 as number,
	usersNotInvolvedInProject: [] as ITaskerUserProjectsAndRoles[],
	countUsersNotInvolvedInProject: 0 as number,
	////////ProjectUsersActiveTasksList//////////////////////
	projectUsersActiveTasks: [] as IWorkingUserNEW[],
	projectUsersActiveTasksCount: 0 as number,
	clockDidChange: 0 as number,
	//////////////////Global////////////////
	isSaving: false as boolean,
	/////////////////Relations////////////////
	projectChildren: [] as IProject[],
	projectParents: [] as IProject[],

	budget: {
		value: {} as any,
		incomeLine: [] as any[],
		outcomeLine: [] as any[],
		options: {
			currency: [] as ICurrency[],
		},
	},

	/////////////////ProjectChart////////////////
	workerChartData: {} as IChart,
	projectChartData: {} as IChart,
	Total: 0 as number,
	costByYearChartData: {} as IChart,
};
export type IProjectDetailEditorState = typeof State;

const projectDetailReducer = (state = State, action: ObjectAction): IProjectDetailEditorState => {
	switch (action?.type) {
		case actionTypes.SET_ISSUE_FORM_CREATE: {
			return {
				...state,
				issueFormNew: action.data,
			};
		}
		case actionTypes.CREATE_ISSUE_FORM: {
			return { ...state, issueFormNew: State.issueFormNew, issueFormUpdate: State.issueFormUpdate };
		}
		case actionTypes.SET_ISSUE_FORM_UPDATE:
		case actionTypes.GET_ISSUE_FORM: {
			return {
				...state,
				issueFormUpdate: action.data,
			};
		}
		case actionTypes.GET_ALL_DICTIONARIES: {
			return { ...state, dictionaries: action.data };
		}
		case actionTypes.FETCH_ISSUE_TAG_GROUP: {
			return { ...state, issueTagGroups: action.data };
		}
		case actionTypes.CREATE_DICTIONARYWITHITEM: {
			const dictionaries = state?.dictionaries;
			dictionaries.push(action.data);
			return { ...state, dictionaries: dictionaries };
		}
		case actionTypes.GET_ISSUE_FORM_LIST: {
			return {
				...state,
				projectForms: action.data,
				// issueFormList: action?.data?.Data,
				// issueFormListCount: action.data?.Count,
			};
		}
		case actionTypes.ISSUE_FORM_SET_FILTERS: {
			return {
				...state,
				filterIssueForm: action?.filter,
			};
		}
		case actionTypes.CUSTOMER_FETCH_WITH_PROJECTS: {
			return {
				...state,
				customersWithProjects: action?.data,
			};
		}
		case actionTypes.COMMENT_BOT_CONFIGURATION_FETCH: {
			return { ...state, configuration: action?.configuration ?? {} };
		}
		case actionTypes.FETCH_PROJECT_WORKLOGS: {
			return {
				...state,
				projectWorklogs: action?.worklogs,
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		case actionTypes.SET_PROJECT_USERS: {
			return {
				...state,
				projectUsers: action.projectUsers,
				projectRoles: action.projectRoles,
				projectUsersCount: action.projectUsersCount,
				isSaving: false,
			};
		}

		case actionTypes.CLEAR_TASKER_USERS: {
			return {
				...state,
				projectUsers: [],
				projectRoles: [],
				projectUsersCount: 0,
			};
		}
		case actionTypes.PROJECT_USERS_ACTIVE_TASKS_FETCH: {
			return {
				...state,
				projectUsersActiveTasks: action.projectUsersAndIssues,
				projectUsersActiveTasksCount: action.projectUsersCount,
			};
		}
		case actionTypes.USE_CLOCK_PAUSE_FINISH:
		case actionTypes.USE_CLOCK: {
			return {
				...state,
				clockDidChange: state.clockDidChange + 1,
			};
		}
		case actionTypes.CLEAR_CLOCK_DID_CHANGE: {
			return {
				...state,
				clockDidChange: 0,
			};
		}
		case actionTypes.SET_USERS_NOT_INVOLVED_IN_PROJECT: {
			return {
				...state,
				usersNotInvolvedInProject: action.usersNotInvolvedInProject,
				countUsersNotInvolvedInProject: action.countUsersNotInvolvedInProject,
			};
		}
		case actionTypes.PROJECT_FETCH_ONE: {
			return {
				...state,
				project: action.project,
			};
		}
		case actionTypes.PORTAL_ANSWER_POST: {
			return {
				...state,
				project: {
					...state?.project,
					PortalAnswer: action.data,
				} as IProject,
			};
		}
		case actionTypes.PORTAL_ANSWER_PATCH: {
			return {
				...state,
				project: {
					...state?.project,
					PortalAnswer: action.data,
				} as IProject,
			};
		}
		case actionTypes.DELETE_PORTAL_ANSWER: {
			return {
				...state,
				project: {
					...state?.project,
					PortalAnswer: state.project?.PortalAnswer?.filter(filter => filter?.Id != action?.id),
				} as IProject,
			};
		}
		case actionTypes.TURN_ON_SAVING: {
			return {
				...state,
				isSaving: true,
			};
		}
		case actionTypes.TURN_OFF_SAVING: {
			return {
				...state,
				isSaving: false,
			};
		}
		case actionTypes.PROJECT_CLEAR: {
			return {
				...state,
				project: undefined,
			};
		}
		case actionTypes.FETCH_PROJECT_PARENT: {
			return {
				...state,
				projectParents: action?.data,
			};
		}
		case actionTypes.FETCH_PROJECT_CHILDREN: {
			return {
				...state,
				projectChildren: action?.data,
			};
		}
		case actionTypes.DELETE_PROJECT_RELATION: {
			return {
				...state,
				projectChildren: state?.projectChildren?.filter(x => action?.data != x?.Id),
				projectParents: state?.projectChildren?.filter(x => action?.data != x?.Id),
			};
		}
		case actionTypes.ADD_PROJECT_RELATION:
		case actionTypes.DELETE_PROJECT_RELATION_BY: {
			return {
				...state,
				projectChildren: action?.data?.Child,
				projectParents: action?.data?.Parent,
			};
		}
		case actionTypes.BUDGET_SAVE: {
			return {
				...state,
				budget: {
					...state?.budget,
					outcomeLine: action?.data?.OutcomeLine ?? [],
					incomeLine: action?.data?.IncomeLine2 ?? [],
					value: action?.data ?? [],
				},
			};
		}
		case actionTypes.FETCH_ALL_CURRENCIES: {
			return {
				...state,
				budget: {
					...state?.budget,
					options: {
						...state?.budget?.options,
						currency: action?.currencies,
					},
				},
			};
		}
		case actionTypes.CHART_PROJECT_GET: {
			return {
				...state,
				projectChartData: action.data,
				Total: action?.data?.Total,
			};
		}
		case actionTypes.CHART_TASKER_USER_PROJECT_GET: {
			return {
				...state,
				workerChartData: action.data,
			};
		}
		case actionTypes.CHART_COSTS_BY_YEAR: {
			return {
				...state,
				costByYearChartData: action.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default projectDetailReducer;
