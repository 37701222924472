import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						Actions: {
							popup: {
								save: 'Zapisz',
								cancel: 'Anuluj',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						Actions: {
							popup: {
								save: 'Save',
								cancel: 'Cancel',
							},
						},
					},
				},
			},
		},
	},
};
