import clsx from 'clsx';
import { uniqueId } from 'lodash';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDownloader from 'react-use-downloader';

import DeleteIcon from '@mui/icons-material/Delete';
import { Collapse, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';

import { useOpenContext } from '@skillandchill/tasker-widgets2/dist/utils/controlContext';
import { ConfirmationMessage } from '@skillandchill/tasker-widgets2/dist/widgets/ConfirmationMessage';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';
import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { issueModalDataContext } from '@/views/IssueModelNew';

import { SummaryDownload } from 'view/IssueModalEditor/Attachment/Summary';

import { EIssueModalDetailsPageFields, IAttachmentOnCreate } from '../../../model';

import { Resources } from './resources';
import { useStyles } from './styles';

export const AttachmentOnCreate = (): JSX.Element => {
	const classes = useStyles();
	const context = useOpenContext();
	const { t, tString } = useTrans(ETranslationPrefix.IssueModalEditorAttachment);

	const currentUser = useSelector((state: IReducerState) => state?.Session?.profile);
	const token = useSelector((state: IReducerState) => state?.Session?.access_token);

	const { handleInputChange, issueState } = useContext(issueModalDataContext);

	const [open, setOpen] = useState<boolean>(true);
	const [downloadButton, setDownloadButton] = useState<HTMLElement>();
	const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader({
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	useEffect(() => {
		if (downloadButton) {
			const button = document.getElementById(Resources.downloadButtonId);
			button?.click();
			setDownloadButton(undefined);
		}
	}, [downloadButton]);

	const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		let files: IAttachmentOnCreate[] = [];

		for (let i = 0; i < (event?.target?.files?.length ?? 0); i++) {
			const file: IAttachmentOnCreate = {
				FileName: (event?.target?.files as FileList)[i].name,
				File: (event?.target?.files as FileList)[i],
				CreatedByUserId: currentUser?.Id,
				uId: uniqueId(),
			};

			files = [...files, file];
		}

		handleInputChange(EIssueModalDetailsPageFields.attachment, [...(issueState?.Attachment ?? []), ...files]);
	};

	const didClickDelete = (uId: string): void => {
		handleInputChange(
			EIssueModalDetailsPageFields.attachment,
			issueState?.Attachment?.filter((attch: any) => attch?.uId !== uId)
		);
	};

	// const downloadWrapper = (attachment?: Partial<IAttachment>) => {
	// 	download(Resources.urlDownload(attachment?.Id ?? -1), attachment?.FileName ?? '');
	// };

	return (
		<>
			<Paper className={clsx(classes.defaultCursor, classes.fullWidth, classes.attachmentsTile)}>
				<Collapse addEndListener={() => {}} in={open}>
					<div>{downloadButton}</div>
					<input
						multiple
						id={Resources.htmlFor}
						type={Resources.file}
						onChange={onFileChange}
						className={classes.addFile}
					/>
					<div>
						<VisibilityControl
							condition={!!issueState?.Attachment?.length}
							alternative={
								<div className={clsx(classes.fullWidth, classes.flexRow, classes.noAttachments)}>
									{t('noAttachments')}
								</div>
							}
						>
							<Table>
								<colgroup>
									<col className={classes.attachmentName} />
									<col className={classes.attachmentOperations} />
								</colgroup>
								<TableHead>
									<TableRow>
										<TableCell className={clsx(classes.noBold)}>{t('attachmentName')}</TableCell>
										{/* <TableCell className={clsx(classes.w2, classes.noBold)}>
											{t('creator')}
										</TableCell>
										<TableCell className={clsx(classes.w2, classes.noBold)}>
											{t('attachmentUploadDate')}
										</TableCell> */}
										<TableCell className={clsx(classes.noBold)}>{t('operations')}</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									<VisibilityControl condition={isInProgress}>
										<SummaryDownload
											download={download}
											size={size}
											cancel={cancel}
											time={elapsed}
											error={error}
											isInProgress={isInProgress}
											percentage={percentage}
											t={t}
										/>
									</VisibilityControl>
									{issueState?.Attachment?.map((attachment, index) => {
										const encodedString = attachment.FileName ?? Resources.GlobalResources.empty;
										// const escapedString = escape(encodedString);
										const decodedString = decodeURIComponent(encodedString);
										return (
											<>
												<TableRow
													key={`${attachment?.Id}${decodedString}`}
													className={classes.fullWidth}
												>
													<TableCell className={classes.text}>{`${index + 1}. ${
														attachment?.FileName
													}`}</TableCell>
													{/* <TableCell className={classes.w1}>
														{getFullNameNew(attachment?.AddedByTaskerUser)}
													</TableCell>
													<TableCell className={classes.w1}>
														{format(
															attachment?.AddedDatetime
																? new Date(attachment?.AddedDatetime)
																: new Date(),
															EFormats.EDateFNS.PortalDateFormat24
														)}
													</TableCell> */}
													<TableCell
														className={clsx(classes.flexYCenter, classes.flexXCenter)}
													>
														{/* <Tooltip title={tString('download')}>
															<Button
																disabled={isInProgress}
																size={Resources.GlobalResources.small}
																color={Resources.GlobalResources.primary}
																onClick={() => downloadWrapper(attachment)}
															>
																<GetAppIcon />
															</Button>
														</Tooltip> */}
														<div>
															<Tooltip title={tString('delete')}>
																<IconButton
																	onClick={() => didClickDelete(attachment?.uId)}
																>
																	<DeleteIcon />
																</IconButton>
															</Tooltip>
														</div>
													</TableCell>
												</TableRow>
											</>
										);
									})}
								</TableBody>
							</Table>
						</VisibilityControl>
					</div>
				</Collapse>
				<ConfirmationMessage popupContext={context} />
			</Paper>
		</>
	);
};
