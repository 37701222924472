import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationsAcceptanceList: {
			DelegationAcceptanceListFilters: {
				all: 'Wszystkie',
				toAccept: 'Do akceptacji',
				done: 'Zakończone',
			},
		},
	},
	en: {
		DelegationsAcceptanceList: {
			DelegationAcceptanceListFilters: {
				all: 'All',
				toAccept: 'To accept',
				done: 'Done',
			},
		},
	},
};
