import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				Chart: {
					CustomChartTooltip: {
						day: 'Dzień: ',
						activeWorklog: 'Aktywne zadania: ',
						loggedTime: 'Zalogowany czas: ',
						worklogSum: 'Całkowity czas: ',
					},
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				Chart: {
					CustomChartTooltip: {
						day: 'Day: ',
						activeWorklog: 'Active issues: ',
						loggedTime: 'Logged time: ',
						worklogSum: 'Total: ',
					},
				},
			},
		},
	},
};
