import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ariaValue: 'primary checkbox' as const,
	hashIssue: '#',
	https: 'https://',
	http: 'http://',
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalTitle: {
				newIssue: 'Nowe zgłoszenie',
				archive:
					'Archiwizuj zgłoszenie na tym kanbanie(Będzie ono niewidoczne chyba, że w filtrach zostanie włączony filtr pokazujący zgłoszenia)',
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalTitle: {
				newIssue: 'New Issue',
				archive: 'Archive issue on this kanban(It will be invisible unless filter show archived will be on)',
			},
		},
	},
};
