import clsx from 'clsx';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import { IconButton, Tooltip, Typography } from '@mui/material';

import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

const PopoverHeader = (props: Props) => {
	const { closePopver, setAllNotificationAsRead } = props;
	const classes = useStyles();
	const { t } = useTrans('NotificationPopover.PopoverHeader');

	const countNotReadNotifications: number = useSelector(
		(state: IReducerState) => state.DashBoard.countOfNotReadUserNotifications
	);

	return (
		<div className={clsx(classes.contentSpaceBetween, classes.flexYCenter, classes.container)}>
			<div className={classes.iconContainer}>
				<Tooltip title={<span>{t('markAllAsRead')}</span>}>
					<IconButton onClick={setAllNotificationAsRead} disabled={!countNotReadNotifications}>
						<MarkAsUnreadIcon />
					</IconButton>
				</Tooltip>
			</div>
			<Typography className={classes.notifHeader} variant={Resources.GlobalResources.h5}>
				{t('notifications')}
			</Typography>
			<IconButton onClick={closePopver}>
				<CloseIcon />
			</IconButton>
		</div>
	);
};

export default PopoverHeader;
