import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import { IconButton, Tooltip } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { add_issue_to_favourites, remove_issue_from_favourites } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { errorVariant } from '@/utils/snackBar';

import { Props } from './model';
import { useStyles } from './styles';

export const FavouriteIssue = (props: Props) => {
	const { issue } = props;
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();
	const snackbar = useSnackbar();
	const { tString } = useTrans('IssueModelNew.IssueTitle');

	const [isFavourite, setIsFavourite] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if (!issue) return;

		setIsFavourite(!!issue?.FavouriteIssues?.length);
	}, [issue]);

	const handleManageFavorite = () => {
		if (isFavourite) {
			setIsDisabled(true);

			dispatch(remove_issue_from_favourites(issue?.Id))
				.catch(() => {
					snackbar.enqueueSnackbar('error', errorVariant);
				})
				.then(() => {
					setIsFavourite(false);
				})
				.finally(() => {
					setIsDisabled(false);
				});
		} else {
			setIsFavourite(true);
			setIsDisabled(true);
			dispatch(add_issue_to_favourites(issue?.Id))
				.catch(() => {
					snackbar.enqueueSnackbar('error', errorVariant);
				})
				.then(() => {
					setIsFavourite(true);
				})
				.finally(() => {
					setIsDisabled(false);
				});
		}
	};

	return (
		<div>
			<Tooltip title={isFavourite ? tString('removeFromFavourite') : tString('addAsFavourite')}>
				<IconButton onClick={handleManageFavorite} disabled={isDisabled}>
					<VisibilityControl condition={isFavourite} alternative={<StarOutlineRoundedIcon />}>
						<GradeRoundedIcon className={classes.issueSetAsFavourited} />
					</VisibilityControl>
				</IconButton>
			</Tooltip>
		</div>
	);
};
