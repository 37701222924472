import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			Toolbar: {
				monthLabel: 'Miesiąc',
				weekLabel: 'Tydzień',
				dayLabel: 'Dzień',
				agendaLabel: 'Agenda',
				dayGridLabel: 'Niestandardowy',
				DefaultSearchValue: 'Wpisz wyszukiwanego użytkownika',
			},
		},
	},
	en: {
		LeaveRequestCalendar: {
			Toolbar: {
				monthLabel: 'Month',
				weekLabel: 'Week',
				dayLabel: 'Day',
				agendaLabel: 'Agenda',
				dayGridLabel: 'Custom',
				DefaultSearchValue: 'Type user',
			},
		},
	},
};
