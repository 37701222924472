import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		tooltip: {
			alignItems: 'center',
			width: 'max-content',
		},
	}),
	{ name: `IssueList_TileComponents_CreationDate` }
);
