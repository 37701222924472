import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				Shared: {
					Components: {
						ErrorIndicator: {
							errorInForm: 'Błąd w formularzu',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				Shared: {
					Components: {
						ErrorIndicator: {
							errorInForm: 'Error occured in form',
						},
					},
				},
			},
		},
	},
};
