import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		container: {
			padding: theme.spacing(0.5),
		},

		notifHeader: {
			fontFamily: 'Jost',
			fontWeight: '500',
		},

		iconContainer: {
			minWidth: '40px',
		},
	}),
	{ name: `PopoverHeader` }
);
