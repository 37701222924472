import clsx from 'clsx';
import { differenceInSeconds, format, formatDistance } from 'date-fns';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDownloader from 'react-use-downloader';
import { IUseDownloader } from 'react-use-downloader/dist/types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
	Button,
	Collapse,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';

import { EAnswer, IAttachment } from '@skillandchill/tasker-types';
import { ConfirmationMessage } from '@skillandchill/tasker-widgets2';
import { EFormats, getFullNameNew } from '@skillandchill/tasker-widgets2/dist/utils';
import { useOpenContext } from '@skillandchill/tasker-widgets2/dist/utils/controlContext';
import { errorVariant, successVariant } from '@skillandchill/tasker-widgets2/dist/utils/snackBar';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { addLocalAttachment, attachment_delete, attachment_getExternal, attachment_issue_post } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { getLanguage } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';
import { ModalsEvent } from '@/utils/modals';

import { SummaryDownload } from 'view/IssueModalEditor/Attachment/Summary';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const AttachmentOnUpdate = (props: Props): JSX.Element => {
	const { issueId, didAddFile, saveOnlyLocal = false, projectId = -1 } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const snackBar = useSnackbar();
	const context = useOpenContext();
	const { t, tString } = useTrans(
		'IssueModalNew.IssueModalContent.IssueDetailsPage.TabsContent.IssueAttch.AttachmentOnUpdate'
	);

	const currentUser = useSelector((state: IReducerState) => state?.Session?.profile);
	const token = useSelector((state: IReducerState) => state?.Session?.access_token);
	const attachmentIssueSelector: Partial<IAttachment>[] = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.attachmentIssue
	);

	const [isSortedAsc, setIsSortedAsc] = useState(true);
	const [downloadButton, setDownloadButton] = useState<HTMLElement>();
	const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader({
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});

	const [uploadStatus, setUploadStatus] = useState<Partial<IUseDownloader>>();
	const [percentageUpload, setPercentageUpload] = useState<number>();
	const [startDateUpload, setStartDateUpload] = useState<Date>(new Date());

	useEffect(() => {
		if (downloadButton) {
			const button = document.getElementById(Resources.downloadButtonId);
			button?.click();
			setDownloadButton(undefined);
		}
	}, [downloadButton]);

	useEffect(() => {
		if (error) snackBar.enqueueSnackbar(tString('errorDownloading'), errorVariant);
	}, [error]);

	const getSortedAttachments = () => {
		const sortedArray = attachmentIssueSelector.sort((a, b) =>
			(a.CreatedDateTime as Date) > (b.CreatedDateTime as Date) ? 1 : -1
		);

		if (isSortedAsc) return sortedArray;
		else return sortedArray.reverse();
	};

	const clearUpload = () => {
		setUploadStatus({});
		setPercentageUpload(0);
		setStartDateUpload(new Date());
	};

	const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		// if (!isEdittingIssue) {
		// 	handleInputChange(EIssueModalDetailsPageFields.files, event?.target?.files);
		// 	return;
		// }
		let errorMessage = '';
		const catchFileUpload = (fileName: string) => {
			if (errorMessage == '') {
				errorMessage = t('addFileFailed');
			}
			errorMessage += fileName;
		};

		const promisses = [];
		let file;
		if (event?.target?.files) {
			for (let i = 0; i <= event?.target?.files?.length - 1; i++) {
				file = event?.target?.files[i];

				if (file) {
					const tempAttachment: Partial<IAttachment> = {
						FileName: file?.name,
						Size: file?.size,
					};
					if (didAddFile) {
						didAddFile(file, tempAttachment);
						const tempAttachmentGroup = _.cloneDeep(attachmentIssueSelector) ?? [];
						tempAttachmentGroup?.push(tempAttachment);
						try {
							promisses.push(dispatch(addLocalAttachment(tempAttachmentGroup)));
						} catch (e) {
							catchFileUpload(tempAttachment?.FileName ?? '');
						}
					} else
						setUploadStatus({
							isInProgress: true,
							size: file?.size,
						});
					if (saveOnlyLocal === false) {
						setStartDateUpload(new Date());
						promisses.push(
							dispatch(attachment_issue_post(tempAttachment, file, issueId, setPercentageUpload))
								.catch(() => catchFileUpload(tempAttachment?.FileName ?? ''))
								.finally(clearUpload)
						);
					}
				} else snackBar.enqueueSnackbar(t('noFile'), errorVariant);
			}
			Promise.all(promisses).finally(() => {
				if (errorMessage != '') snackBar.enqueueSnackbar(errorMessage, errorVariant);
			});
		}
	};

	const didClickDelete = (id: number): void => {
		const handleDeleteAttachment = (x: EAnswer.YES | EAnswer.NO) => {
			if (x == EAnswer.YES && id) {
				dispatch(attachment_delete(id)).finally(() => {
					snackBar.enqueueSnackbar(t('deleteSuccess'), successVariant);
				});
			}
		};
		context.once(ModalsEvent.Close, handleDeleteAttachment);
		context.open();
	};

	const downloadWrapper = (attachment?: Partial<IAttachment>) => {
		const downloadLocal = () => download(Resources.urlDownload(attachment?.Id ?? -1), attachment?.FileName ?? '');

		if (attachment?.ExternalId && attachment?.FileContentInDB && attachment?.Id) {
			dispatch(attachment_getExternal(attachment?.Id, projectId))
				?.then(x => {
					window.open(x?.data, '_blank');
				})
				?.catch(downloadLocal);
		} else downloadLocal();
	};

	return (
		<>
			<VisibilityControl condition={!!uploadStatus?.isInProgress}>
				<Paper className={clsx(classes.defaultCursor, classes.fullWidth, classes.attachmentsTile)}>
					<SummaryDownload
						size={uploadStatus?.size}
						cancel={uploadStatus?.cancel}
						time={formatDistance(new Date(), startDateUpload, {
							includeSeconds: true,
							locale: getLanguage(),
						})}
						error={uploadStatus?.error}
						isInProgress={uploadStatus?.isInProgress}
						percentage={percentageUpload ?? 0}
						t={t}
						estimatedTime={{
							timeInSeconds: differenceInSeconds(new Date(), startDateUpload),
							active: true,
						}}
					/>
				</Paper>
			</VisibilityControl>
			<Paper className={clsx(classes.defaultCursor, classes.fullWidth, classes.attachmentsTile)}>
				<Collapse addEndListener={() => {}} in={true}>
					<div>{downloadButton}</div>
					<input
						multiple
						id={Resources.htmlFor}
						type={Resources.file}
						onChange={onFileChange}
						className={classes.addFile}
					/>
					<div>
						<VisibilityControl
							condition={attachmentIssueSelector?.length > 0}
							alternative={
								<div className={clsx(classes.fullWidth, classes.flexRow, classes.noAttachments)}>
									{t('noAttachments')}
								</div>
							}
						>
							<Table>
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell className={clsx(classes.w1, classes.noBold)}>
											{t('attachmentName')}
										</TableCell>
										<TableCell className={clsx(classes.w2, classes.noBold)}>
											{t('creator')}
										</TableCell>
										<TableCell className={clsx(classes.w2, classes.noBold)}>
											<div className={classes.flexYCenter}>
												<div>{t('attachmentUploadDate')}</div>

												<IconButton
													className={clsx(classes.flexColumn, classes.headButton)}
													onClick={() => setIsSortedAsc(!isSortedAsc)}
												>
													<VisibilityControl
														condition={isSortedAsc}
														alternative={<ArrowDropDownIcon />}
													>
														<ArrowDropUpIcon />
													</VisibilityControl>
												</IconButton>
											</div>
										</TableCell>
										<TableCell className={clsx(classes.w2, classes.noBold)} />
									</TableRow>
								</TableHead>

								<TableBody>
									<VisibilityControl condition={isInProgress}>
										<SummaryDownload
											download={download}
											size={size}
											cancel={cancel}
											time={elapsed}
											error={error}
											isInProgress={isInProgress}
											percentage={percentage}
											t={t}
										/>
									</VisibilityControl>
									{getSortedAttachments()?.map((attachment, index) => {
										const encodedString = attachment.FileName ?? Resources.GlobalResources.empty;
										// const escaped = escape(encodedString);// powodował czasami bład
										const decodedString = decodeURIComponent(encodedString);
										return (
											<>
												<TableRow
													key={`${attachment?.Id}${decodedString}`}
													className={classes.fullWidth}
												>
													<TableCell className={classes.w1}>{`${index +
														1}. ${encodedString}`}</TableCell>
													<TableCell className={classes.w1}>
														{getFullNameNew(attachment?.AddedByTaskerUser)}
													</TableCell>
													<TableCell className={classes.w1}>
														{format(
															attachment?.AddedDatetime
																? new Date(attachment?.AddedDatetime)
																: new Date(),
															EFormats.EDateFNS.PortalDateFormat24
														)}
													</TableCell>
													<TableCell className={clsx(classes.w2)}>
														<div className={classes.flexYCenter}>
															<Tooltip title={tString('download')}>
																<Button
																	disabled={isInProgress}
																	size={Resources.GlobalResources.small}
																	color={Resources.GlobalResources.primary}
																	onClick={() => downloadWrapper(attachment)}
																>
																	<GetAppIcon />
																</Button>
															</Tooltip>
															<VisibilityControl
																condition={
																	currentUser?.Id == attachment?.CreatedByUserId
																}
															>
																<Tooltip title={tString('delete')}>
																	<IconButton
																		onClick={() =>
																			didClickDelete(attachment?.Id as number)
																		}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Tooltip>
															</VisibilityControl>
														</div>
													</TableCell>
												</TableRow>
											</>
										);
									})}
								</TableBody>
							</Table>
						</VisibilityControl>
					</div>
				</Collapse>
				<ConfirmationMessage
					popupContext={context}
					title={t('deleteAttachmentTitle')}
					subTitle={t('deleteAttachmentSubtitle')}
					submitButtonText={t('deleteAttachmentSubmit')}
					cancelButtonText={t('deleteAttachmentCancel')}
				/>
			</Paper>
		</>
	);
};
