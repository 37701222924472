import _ from 'lodash';

import { ITaskerUser } from '@skillandchill/tasker-types';

export const getDataForTaskerUserSelector = (profiles: Partial<ITaskerUser>[]): Partial<ITaskerUser>[] => {
	let customTaskerUsers: Partial<ITaskerUser>[] = _.cloneDeep(profiles ?? []);
	customTaskerUsers = customTaskerUsers?.filter(x => x?.Name != 'Unassigned' && x?.Name != 'Clear');
	customTaskerUsers.push({
		AvatarPath: process?.env?.PUBLIC_URL + '/favicon.png',
		Id: -1,
		Name: 'Unassigned',
		Surname: 'issues',
		CreatedByUserId: 0,
		CreatedDateTime: new Date(`2021-12-12 00:00:00`),
		Email: ``,
		IsActive: false,
		LastUpdateByUserId: 0,
		LastUpdateDateTime: new Date(`2021-12-12 00:00:00`),
	});
	customTaskerUsers.push({
		AvatarPath: process?.env?.PUBLIC_URL + '/favicon2.png',
		Id: 0,
		Name: 'Clear',
		Surname: 'all',
		CreatedByUserId: 0,
		CreatedDateTime: new Date(`2021-12-12 00:00:00`),
		Email: ``,
		IsActive: false,
		LastUpdateByUserId: 0,
		LastUpdateDateTime: new Date(`2021-12-12 00:00:00`),
	});

	return customTaskerUsers.reverse();
};
