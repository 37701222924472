import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dash: '-',
	avatar: 'avatar',
	avatarSize: '10px',
};

export const Translation = {
	pl: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					Team: {
						disactivateTeam: 'Dezaktywuj listę',
						activateTeam: 'Aktywuj listę',
						description: 'Opis',
						details: 'Szczegóły',
						active: 'Aktywny',
						inActive: 'Nieaktywny',
						status: 'Status',
						members: 'Członkowie',
						teamCreator: 'Twórca',
						dateCreated: 'Data stworzenia',
					},
				},
			},
		},
	},

	en: {
		Settings: {
			WidgetsConfigurationMain: {
				ProfileSelectorConfiguration: {
					Team: {
						disactivateTeam: 'Disactivate team',
						activateTeam: 'Activate team',
						description: 'Description',
						details: 'Details',
						active: 'Active',
						inActive: 'Inactive',
						status: 'Status',
						members: 'Members',
						teamCreator: 'Team creator',
						dateCreated: 'Date Created',
					},
				},
			},
		},
	},
};
