import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	Reports: {
		Customer: { name: 'customer' },
		Project: { name: 'project' },
	},
	path: (tab: string) => `/reports/${tab}`,
};

export const Translation = {
	pl: {
		Reports: {
			pageTitle: 'Raporty',
			Customer: { label: 'Raporty klienta' },
			Project: { label: 'Raporty projektowe' },
		},
	},
	en: {
		Reports: {
			pageTitle: 'Reports',
			Customer: { label: 'Client reports' },
			Project: { label: 'Project reports' },
		},
	},
};

export const getTabs = (): { value: string; label?: string; visible: boolean }[] => {
	return [
		{
			value: Resources.Reports.Customer.name,
			label: t('Reports.Customer.label'),
			visible: true,
		},
		{
			value: Resources.Reports.Project.name,
			label: t('Reports.Project.label'),
			visible: true,
		},
	];
};
