import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			Preferences: {
				Organization: {
					label: 'Wybrana organizacja',
					changeButton: 'Zmień organizacje',
					organizationChanged: 'Organizacja została zmieniona',
				},
			},
		},
	},
	en: {
		Settings: {
			Preferences: {
				Organization: {
					label: 'Chosen organization',
					changeButton: 'Change organization',
					organizationChanged: 'Organization has been changed',
				},
			},
		},
	},
};
