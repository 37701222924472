import { TableRow } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { formatSeconds } from '@/utils/utils';

import { Props } from './model';

export const SummaryDownload = (props: Props): JSX.Element => {
	const { t, isInProgress = false, cancel, size = 0, percentage = 0, time, estimatedTime } = props;

	const cancelWrapper = () => {
		if (cancel) cancel();
	};

	const estimateTime = (percentage?: number, time?: number) => {
		if (!percentage || !time) return '';
		const seconds = time / (percentage / 100) - time;
		return formatSeconds(seconds);
	};

	return (
		<TableRow>
			<div>
				<p>
					{t('FormInformation.downloadStatus')}
					{isInProgress ? t('FormInformation.inProgress') : t('FormInformation.stopped')}
				</p>
				<VisibilityControl condition={!!cancel}>
					<button onClick={cancelWrapper}>{t('FormInformation.cancel')}</button>
				</VisibilityControl>
				<p>
					{t('FormInformation.size')} {Math.round((size / 1048576) * 1000) / 1000}
				</p>
				<label htmlFor="file">{t('FormInformation.progress')}</label>
				<progress id="file" value={percentage} max="100" />
				<VisibilityControl condition={!!time}>
					<p>
						{t('FormInformation.time')} {time}
					</p>
				</VisibilityControl>
				<VisibilityControl
					condition={!!estimatedTime?.active && !!estimatedTime?.timeInSeconds && !!percentage}
				>
					<p>
						{t('FormInformation.estimate')} {estimateTime(percentage, estimatedTime?.timeInSeconds)}
					</p>
				</VisibilityControl>
			</div>
		</TableRow>
	);
};
