import { EFieldDefinitionType, IDictionaryItem, IFieldValue } from '@skillandchill/tasker-types';

import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

export class FieldValueFactory {
	static create(
		fieldValueType: EFieldDefinitionType,
		AttributeDefinitionId: number,
		value: string | number | null | boolean | Date,
		fieldValueId?: number
	): Partial<IFieldValue> {
		if (fieldValueType === EFieldDefinitionType.TagGroup) {
			return new TagFieldValue(AttributeDefinitionId, value as number);
		}

		if (fieldValueType === EFieldDefinitionType.Text) {
			return new TextFieldValue(AttributeDefinitionId, value as string, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.Integer) {
			return new IntegerFieldValue(AttributeDefinitionId, value as number, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.Decimal) {
			return new DecimalFieldValue(AttributeDefinitionId, value as number, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.Boolean) {
			return new BooleanFieldValue(AttributeDefinitionId, value as boolean, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.Date) {
			return new DateFieldValue(AttributeDefinitionId, value as Date, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.DateTime) {
			return new DateTimeFieldValue(AttributeDefinitionId, value as Date, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.Dictionary) {
			return new DictionaryItemFieldValue(AttributeDefinitionId, value as number, fieldValueId);
		}

		if (fieldValueType === EFieldDefinitionType.TextArea) {
			return new TextAreaFieldValue(AttributeDefinitionId, value as string, fieldValueId);
		}

		return {};
	}
}

class FieldValue implements Partial<IFieldValue> {
	AttributeDefinitionId: number;
	Id?: number | undefined;

	constructor(AttributeDefinitionId: number, fieldValueId?: number) {
		this.AttributeDefinitionId = AttributeDefinitionId;
		this.Id = fieldValueId;
	}
}

class TagFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueTagId: number;

	constructor(AttributeDefinitionId: number, tagId: number) {
		super(AttributeDefinitionId);
		this.ValueTagId = tagId;
	}
}

class TextFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueText: string;

	constructor(AttributeDefinitionId: number, text: string, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueText = text;
	}
}

class IntegerFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueInteger: number;

	constructor(AttributeDefinitionId: number, number: number, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueInteger = number;
	}
}

class DecimalFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueDecimal: number;

	constructor(AttributeDefinitionId: number, number: number, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueDecimal = number;
	}
}

class BooleanFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueBoolean: boolean;

	constructor(AttributeDefinitionId: number, value: boolean, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueBoolean = value;
	}
}

class DateFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueDate: Date;

	constructor(AttributeDefinitionId: number, value: Date, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueDate = value;
	}
}

class DateTimeFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueDateTime: Date;

	constructor(AttributeDefinitionId: number, value: Date, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueDateTime = value;
	}
}

class DictionaryItemFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueDictionaryItemId: number;

	constructor(AttributeDefinitionId: number, value: number, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueDictionaryItemId = value;
	}
}

class TextAreaFieldValue extends FieldValue implements Partial<IFieldValue> {
	ValueText: string;

	constructor(AttributeDefinitionId: number, text: string, fieldValueId?: number) {
		super(AttributeDefinitionId, fieldValueId);
		this.ValueText = text;
	}
}
