import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	invalidDate: 'Invalid Date',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Calendar: {
			Toolbar: {
				Header: {
					today: 'Dziś',
					date: 'Data',
					addEvent: 'Dodaj nowy czas pracy',
					addEventTooltip:
						'Klikając ten przycisk będziesz mógł dodać nowy czas do zgłoszenia do którego masz uprawnienia.',
					selectDate: 'Wybierz datę',
					acceptAll: 'Zaakceptuj wybrany okres',
				},
			},
		},
	},
	en: {
		Calendar: {
			Toolbar: {
				Header: {
					today: 'Today',
					date: 'Data',
					addEvent: 'Add work log',
					selectDate: 'Select date',
					acceptAll: 'Accept displayed worklogs',
				},
			},
		},
	},
};
