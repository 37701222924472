import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		tab: {
			fontSize: '13px',
			padding: '12px 18px',
			textTransform: 'none',
			minHeight: '0px',
			color: theme.palette.darkGray.contrastText,
		},

		hidden: {
			display: 'none',
		},

		tabSelected: {
			color: theme.palette.darkGray.dark,
		},

		tabNotSelected: {
			color: theme.palette.darkGray.contrastText,
		},

		tabList: {
			minHeight: '0px',

			'& .MuiTabs-indicator': {
				backgroundColor: '#263238',
				height: '3px',
			},
		},

		notReadCount: {
			marginLeft: theme.spacing(0.5),
			color: 'white',
			padding: '0px 4px',
			borderRadius: '5px',
		},

		notReadCountTabSelected: {
			backgroundColor: '#263238',
		},

		notReadCountTabNotSelected: {
			backgroundColor: 'rgba(156,152,149,0.3)',
			color: '#9c9895',
		},

		divider: {
			height: '20px',
			borderWidth: '0px',
			borderColor: 'rgba(0,0,0,0.5)',
			alignSelf: 'center',
		},

		tabIcon: {
			marginRight: theme.spacing(0.5),
		},

		iconRotated: {
			transform: 'rotate(180deg)',
		},
	}),
	{ name: `Tabs` }
);
