import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	textFieldPasswordName: 'password',
	textFieldConfirmName: 'confirm',
	textFieldOldPasswordName: 'oldPassword',
	password: 'password' as const,
	autoComplete: {
		password: 'current-password',
	},
};

export const Translation = {
	pl: {
		Settings: {
			Security: {
				CardChangePasswordContent: {
					passwordLabel: {
						password: 'Nowe hasło',
						confirm: 'Powtórz hasło',
						oldPassword: 'Stare hasło',
					},
				},
			},
		},
	},
	en: {
		Settings: {
			Security: {
				CardChangePasswordContent: {
					passwordLabel: {
						password: 'New password',
						confirm: 'Repeat password',
						oldPassword: 'Old password',
					},
				},
			},
		},
	},
};
