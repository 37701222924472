import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: (key: string) => `Delegation_General_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						General: {
							title: 'Ogólne',
							description: 'Zaznaczenie elementu spowoduje wyłączenie lub włączenie danej opcji',
							Labels: {
								[10]: 'Ukryj ostrzeżenie o limicie diety',
								[11]: 'Ukryj przekroczenie granic w przypadku pojazdu typu samolot',
								[12]: 'Użyj domyślnego środka transportu',
								[13]: 'Ukryj pole dystansu przy wybranym środku transportu innym niż samochód',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						General: {
							title: 'General',
							description: 'Checking the checkbox will disable or enable specified option',
							Labels: {
								[10]: 'Hide per diem limit warning',
								[11]: 'Hide border crossing for aircraft type',
								[12]: 'Use default means of transport',
								[13]: 'Hide distance field for selected means of transport other than car',
							},
						},
					},
				},
			},
		},
	},
};
