import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IForms } from './model';

export const Resources = {
	...GlobalResources,
	redirect: {
		register: '/auth/register',
		login: '/auth/login',
		empty: '#',
	},
	targetType: 'checkbox',
	firstName: {
		error: 'firstName',
		name: 'firstName',
	},
	lastName: {
		error: 'lastName',
		name: 'lastName',
	},
	phone: {
		error: 'phone',
		name: 'phone',
	},
	email: {
		error: 'email',
		name: 'email',
	},
	password: {
		error: 'password',
		name: 'password',
	},
	policy: {
		error: 'policy',
	},

	passwordType: 'password' as const,
};

export const Translation = {
	pl: {
		Register: {
			RegisterForm: {
				isRequired: 'Wymagane',
				firstName: {
					label: 'Imię',
				},
				lastName: {
					label: 'Nazwisko',
				},
				phone: {
					label: 'Numer telefonu',
				},
				email: {
					label: 'Adres mailowy',
				},
				password: {
					label: 'Hasło',
				},
				read: 'Przeczytałem',
				terms: 'Warunki',
				createAccount: 'Stwórz konto',
			},
		},
	},
	en: {
		Register: {
			RegisterForm: {
				isRequired: 'Is required',
				firstName: {
					label: 'First name',
				},
				lastName: {
					label: 'Last name',
				},
				phone: {
					label: 'Telephone number',
				},
				email: {
					label: 'Email address',
				},
				password: {
					label: 'Password',
				},
				read: 'I have read the',
				terms: 'Terms and Conditions',
				createAccount: 'Create account',
			},
		},
	},
};

export const schema = {
	firstName: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		length: {
			maximum: 32,
		},
	},
	lastName: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		length: {
			maximum: 32,
		},
	},
	email: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		email: true,
		length: {
			maximum: 64,
		},
	},
	phone: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		numericality: true,
		length: {
			maximum: 12,
		},
	},
	password: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		length: {
			maximum: 128,
		},
	},
	policy: {
		presence: { allowEmpty: false, message: t('Register.RegisterForm.isRequired') },
		checked: true,
	},
};

export const formDefault: IForms = {
	isValid: false,
	values: {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		password: '',
		policy: false,
	},
	touched: {} as { [x: string]: boolean },
	errors: {
		firstName: [],
		lastName: [],
		email: [],
		phone: [],
		password: [],
		policy: [],
	},
};
