import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	simpleTable: 'simple-table',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					placeholder: 'Wyszukaj użytkownika ...',
					warningSavingWithNoRoles:
						'Zapisując użytkownika bez roli usuniesz go z projektu. Czy chcesz kontynuować ?',
					changesSaved: 'Zapisano zmiany',
					userDeleted: 'Użytkownik został usunięty z projektu',
					deleteConfirmation: 'Czy na pewno chcesz usunąć tego użytkownika?',
					hoverProject:
						'Po najechaniu  na nazwę projektu wyświetlą się role projektowe użytkownika w tym projekcie',
					user: 'Użytkownik',
					organizationRoles: 'Role w organizacji',
					projectHover:
						'Po najechaniu na nazwę projektu wyświetlą się role projektowe użytkownika w tym projekcie',
					userProjects: 'Projekty użytkownika',
					projectRoles: 'Role projektowe',
					constraintErrorOnAddedUserProjectRoles:
						'Użytkownik posiada już następujące role: {{alreadyAddedRoles}}',
					constraintErrorOnDeletedUserProjectRoles:
						'Użytkownik nie posiada następujących ról: {{alreadyDeletedRoles}}',
					constraintErrorOnUserDelete: 'Dany użytkownik nie jest w projekcie',
					allUsersDeleted: 'Usunięto wszystkich użytkowników z projektu',
					Table: {
						user: 'Użytkownik',
						tenantRoles: 'Role w organizacji',
						projectsUser: 'Projekty użytkownika',
						projectRoles: 'Role projektowe',
						deleteEveryone: 'Usuń wszystkich',
						deleteEveryoneTooltip: 'Usuń wszystkich użytkowników w tym projekcie.',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				ProjectUsers: {
					placeholder: 'Search user ...',
					warningSavingWithNoRoles: 'The user without the role will be deleted. Do you want to continue?',
					changesSaved: 'Changes saved',
					userDeleted: 'User has been deleted from project',
					deleteConfirmation: 'Do you really want to delete this user?',
					hoverProject: `On hover over the name of a project, the user's project roles in that project will be displayed`,
					user: 'User',
					organizationRoles: 'Organization roles',
					projectHover: `On hover over the name of a project, the user's project roles in that project will be displayed`,
					userProjects: 'User projects',
					projectRoles: 'Project roles',
					constraintErrorOnAddedUserProjectRoles:
						'The user already has the following roles: {{alreadyAddedRoles}}',
					constraintErrorOnDeletedUserProjectRoles:
						'The user does not have the following roles: {{alreadyDeletedRoles}}',
					constraintErrorOnUserDelete: 'The user is not in the project',
					allUsersDeleted: 'All users have been deleted from project',
					Table: {
						user: 'Użytkownik',
						tenantRoles: 'Roles in organization',
						projectsUser: `Projects of the user`,
						projectRoles: 'Project roles',
						deleteEveryone: 'Delete everyone',
						deleteEveryoneTooltip: 'Delete everyone in this project.',
					},
				},
			},
		},
	},
};
