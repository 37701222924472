import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { EFieldDefinitionType, EInputTypes, IFieldValue } from '@skillandchill/tasker-types';
import { EKeys } from '@skillandchill/tasker-types/dist/types/common/keys';
import { CustomInput } from '@skillandchill/tasker-widgets2';

import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { EIssueModalDetailsPageFields } from '../../../../model';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { FieldValueFactory } from '../utils/fieldValueFactory';
import { useHandleResetError } from '../utils/useHandleResetError';

import { Props } from './model';
import { useStyles } from './styles';

export const TextAreaIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);
	const { setFieldValues, issueErrors, setIssueState, issueState } = useContext(issueModalDataContext);
	const [rows, setRows] = useState(3);

	useEffect(() => {
		let rowsTmp = rows;
		function handleClick(e: KeyboardEvent) {
			if (e.shiftKey && e.altKey && e.key === EKeys.Minus) rowsTmp = rowsTmp - 1;
			if (e.shiftKey && e.altKey && e.key === EKeys.Plus) rowsTmp = rowsTmp + 1;

			setRows(rowsTmp);
		}

		window.addEventListener('keydown', handleClick);

		return () => {
			window.removeEventListener('keydown', handleClick);
		};
	}, []);

	const isError = useMemo(() => {
		return (issueErrors.find(issueError => issueError.inputName === EIssueModalDetailsPageFields.properties)
			?.additionalData as number[])?.includes(issueFormSectionToIssueField?.AttributeDefinitionId as number);
	}, [issueErrors]);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;
	const initialFieldValue = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const initialInputValue = initialFieldValue?.length ? (initialFieldValue[0]?.ValueText as string) : '';

	const inputValue: string | undefined = (issueState?.Properties?.TextArea ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	const handleInputChange = (newValue: string) => {
		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				TextArea: {
					...(prevState?.Properties?.TextArea ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: newValue,
				},
			},
		}));

		handleResetInputError();
		handleFieldValuesChange(newValue);
	};

	const handleFieldValuesChange = (newValue: string) => {
		setFieldValues(prevState => {
			if (initialFieldValue?.length) {
				return {
					...prevState,
					fieldsValueToUpdate: [
						...(prevState?.fieldsValueToUpdate?.filter(
							getFieldValueWitouthFormSectionAttributeDefinitionId
						) ?? []),
						FieldValueFactory.create(
							EFieldDefinitionType.TextArea,
							AttributeDefinitionId as number,
							newValue,
							initialFieldValue[0].Id
						),
					],
				};
			}

			return {
				...prevState,
				fieldsValueToCreate: [
					...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ??
						[]),
					FieldValueFactory.create(EFieldDefinitionType.TextArea, AttributeDefinitionId as number, newValue),
				],
			};
		});
	};

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>
	) {
		return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
	}

	function getNonEditValue() {
		if (initialInputValue) return initialInputValue;
		else return '';
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.editorContainer)}>
				<CustomInput
					type={EInputTypes.text}
					label=""
					className={clsx(isError ? classes.selectError : undefined)}
					handleChange={val => handleInputChange(val as string)}
					value={inputValue ?? initialInputValue}
					inEdit={inEdit}
					canSetInputInNonEdit
					multiline={true}
					rows={rows}
					hideErrorMessage
					errorMessage={isError ? '-' : undefined}
					nonEditComponent={
						<CustomInput
							type={EInputTypes.text}
							label=""
							value={getNonEditValue()}
							multiline={true}
							rows={rows}
							disabled
						/>
					}
				/>
			</div>
		</div>
	);
};
