import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			CommentCard: {
				Footer: {
					delete: 'Usuń',
					edit: 'Edytuj',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			CommentCard: {
				Footer: {
					delete: 'Delete',
					edit: 'Edit',
				},
			},
		},
	},
};
