import { Translation as DelegationsAcceptanceListFilters } from './DelegationAcceptanceListFilters/resources';
import { Translation as DelegationsAcceptanceList } from './resources';
import { Translation as DelegationMenu } from './TileComponents/DelegationMenu/resources';
import { Translation as Status } from './TileComponents/Status/resources';

export const DelegationsAcceptanceListTranslationEN = [
	DelegationsAcceptanceList.en,
	DelegationMenu.en,
	Status.en,
	DelegationsAcceptanceListFilters.en,
];

export const DelegationsAcceptanceListTranslationPL = [
	DelegationsAcceptanceList.pl,
	DelegationMenu.pl,
	Status.pl,
	DelegationsAcceptanceListFilters.pl,
];
