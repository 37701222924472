import { t } from 'i18next';

import { IIssue } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';
import { OptionProps } from '@skillandchill/tasker-widgets2/dist/widgets/SelectTextField/model';

export const Resources = {
	connector: '-',
	fixed: 'fixed',
	...GlobalResources,
	filterIssue: {
		Text: '',
		SearchPartWord: true,
		TextBy: {
			Title: true,
			DisplayId: true,
			Description: false,
			Comment: false,
			User: false,
		},
	},
	emptySpecialText: '!$empty$!',
};

export const getInfoRecord = (): OptionProps<IIssue> => {
	return {
		label: t('IssueModalEditor.Tabs.Relation.moreRecords'),
		value: t('IssueModalEditor.Tabs.Relation.moreRecords'),
		isDisabled: true,
	};
};
