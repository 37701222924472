import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dash: '-',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						NavSection: {
							FormSteps: {
								step: 'Krok',
								outOf: 'z',
								error: 'Błąd w:',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						NavSection: {
							FormSteps: {
								step: 'Step',
								outOf: 'out of',
								error: 'Error:',
							},
						},
					},
				},
			},
		},
	},
};
