import uuid from 'uuid';

import { IDelegationDictionaryItem } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IDelegationRouteElement } from '@/views/DelegationsList/DelegationModal/model';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
	getTransportTypeKey: (item: IDelegationDictionaryItem) => `Transport_${item?.Id}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							AdditionalRoute: {
								tooltipRemoveBeforeDestination: 'Usuń etap docelowy delegacji',
								tooltipRemoveAfterDestination: 'Usuń etap powrotny delegacji',
								legendBeforeDestination: 'Etap docelowy delegacji',
								legendAfterDestination: 'Etap powrotny delegacji',
								dateTimePickerLabel: 'Data wyjazdu',
								distanceInputLabel: 'Dystans (km)',
								locationInputLabel: 'Miejsce wyjazdu',
								transportSelectLabel: 'Środek transportu',
								carPlateNumberLabel: 'Numer rejestracyjny pojazdu',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							AdditionalRoute: {
								tooltipRemoveBeforeDestination: 'Remove destination route',
								tooltipRemoveAfterDestination: 'Remove return route',
								legendBeforeDestination: 'Destination route',
								legendAfterDestination: 'Return route',
								dateTimePickerLabel: 'Departure date',
								distanceInputLabel: 'Distance (km)',
								locationInputLabel: 'Departure location',
								transportSelectLabel: 'Mean of transport',
								carPlateNumberLabel: 'Car plate number',
							},
						},
					},
				},
			},
		},
	},
};

export const getLegendSuffix = (route: IDelegationRouteElement, index: number) => {
	const location = route?.location?.structured_formatting?.main_text;
	return location ? ` - ${location}` : ` - ${index + 1}`;
};
