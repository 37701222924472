import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { io, Socket } from 'socket.io-client';

import { EWebsocketsEvents, ITaskerUser } from '@skillandchill/tasker-types';

import {
	getUsersFromActiveTeam,
	get_count_notRead_user_notifications,
	set_Count_Of_Not_Read_User_Notifications,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { Resources } from './resources';

let isFetched = false;
export const useSetInitialSettingsAfterLogin = () => {
	const dispatch: Dispatch = useDispatch();

	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const currentLoggedOrgId: number = useSelector((state: IReducerState) => state?.Session?.loggedIntoOrganizationId);
	const countOfNotReadUserNotifications = useSelector(
		(state: IReducerState) => state.DashBoard.countOfNotReadUserNotifications
	);

	useEffect(() => {
		if (isFetched === false) {
			isFetched = true;
			dispatch(get_count_notRead_user_notifications());
			dispatch(getUsersFromActiveTeam());
		}

		if (!taskerUser?.Id) return;

		const newSocket: Socket = io(Resources.backendPath, {
			transports: [Resources.websocket],
			query: { userId: taskerUser.Id, orgId: currentLoggedOrgId },
			protocols: ['wss'],
		});

		newSocket.on(EWebsocketsEvents.notReadNotifications, (numberOfNotReadNotifications: number) => {
			dispatch(set_Count_Of_Not_Read_User_Notifications(numberOfNotReadNotifications));
		});

		return () => {
			newSocket.removeAllListeners();
			newSocket.disconnect();
			isFetched = false;
		};
	}, []);

	useEffect(() => {
		const favicon = document.getElementById(Resources.favIconId);
		if (!favicon) return;

		let notificationFavIconPath = process.env.PUBLIC_URL;

		if (countOfNotReadUserNotifications && countOfNotReadUserNotifications <= 9) {
			notificationFavIconPath += `${Resources.favIconBasePath}${countOfNotReadUserNotifications}${Resources.png}`;
		} else if (countOfNotReadUserNotifications && countOfNotReadUserNotifications > 9) {
			notificationFavIconPath += `${Resources.favIconBasePath}${Resources.moreThan9}${Resources.png}`;
		} else notificationFavIconPath += `${Resources.basicFavIcon}${Resources.png}`;

		(favicon as HTMLLinkElement).href = notificationFavIconPath;
	}, [countOfNotReadUserNotifications]);

	return true;
};
