import uuid from 'uuid';

import { ECurrency, EDelegationAttachmentType } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							DelegationSettlementAttachmentsFormInfo: {
								descriptionLabel: 'Opis załącznika',
								dateLabel: 'Data wystawienia faktury',
								typeLabel: 'Typ załącznika',
								attachmentType1: 'Zakwaterowanie',
								attachmentType2: 'Inny',
								valueLabel: 'Wartość brutto',
								taxLabel: '% VAT',
								netValueLabel: 'Wartość netto',
								directionLabel: 'Status wydatku',
								unspecified: 'Nieokreślono',
								direction1: 'Podlega zwrotowi',
								direction2: 'Nie podlega zwrotowi',
								direction3: 'Inny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							DelegationSettlementAttachmentsFormInfo: {
								descriptionLabel: 'Attachment description',
								dateLabel: 'Invoice date',
								typeLabel: 'Attachment type',
								attachmentType1: 'Accommodation',
								attachmentType2: 'Other',
								valueLabel: 'Gross value',
								taxLabel: '% VAT',
								netValueLabel: 'Net value',
								directionLabel: 'Expense status',
								unspecified: 'Unspecified',
								direction1: 'Reimbursable',
								direction2: 'Non-reimbursable',
								direction3: 'Other',
							},
						},
					},
				},
			},
		},
	},
};
