import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useTrans } from '@/utils/hooks/useTrans';
import { issueModalDataContext } from '@/views/IssueModelNew';

import { EIssueModalDetailsPageTabs, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueDetailsTabs = (props: Props) => {
	const { selectedTab, setSelectedTab } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModelNew.IssueModalContent.IssueDetailsPage.Tabs');

	useEffect(() => {
		setSelectedTab(EIssueModalDetailsPageTabs.Description);
	}, []);

	const handleChange = (event: React.SyntheticEvent, newTab: EIssueModalDetailsPageTabs) => {
		setSelectedTab(newTab);
	};

	const { isEdittingIssue } = useContext(issueModalDataContext);

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: Resources.divider }}>
				<TabList
					onChange={handleChange}
					className={classes.tabList}
					textColor={Resources.GlobalResources.inherit}
				>
					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalDetailsPageTabs.Description
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<span>{t('description')}</span>
							</div>
						}
						value={EIssueModalDetailsPageTabs.Description}
					/>
					<Divider orientation="vertical" className={classes.divider} />
					<Tab
						className={clsx(
							classes.tab,
							!isEdittingIssue && classes.hidden,
							selectedTab === EIssueModalDetailsPageTabs.Comments
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<span>{t('comments')}</span>
							</div>
						}
						value={EIssueModalDetailsPageTabs.Comments}
					/>

					<Tab
						className={clsx(
							classes.tab,

							selectedTab === EIssueModalDetailsPageTabs.Attachments
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<span>{t('attachments')}</span>
							</div>
						}
						value={EIssueModalDetailsPageTabs.Attachments}
					/>
				</TabList>
			</Box>
		</TabContext>
	);
};
