import clsx from 'clsx';
import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Tooltip, Typography } from '@mui/material';

import { IFieldValue, IIssueKanbanDetails, ITag } from '@skillandchill/tasker-types';
import { CustomSelect, TooltipTextBase, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { EIssueModalDetailsPageFields } from '../../../../model';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useHandleResetError } from '../utils/useHandleResetError';

import { Props } from './model';
import { MultiSelectValueLabelCustom } from './MultiSelectValueLabelCustom';
import { CustomOption } from './Option';
import { Resources } from './resources';
import { SingleValueCustom } from './SingleValueCustom';
import { useStyles } from './styles';
import { FieldValue } from './utils';
import { ValueContainer } from './ValueContainer';

export const TagGroupIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();
	const { setFieldValues, issueErrors, isEdittingIssue, setIssueState, issueState } = useContext(
		issueModalDataContext
	);
	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);

	const kanbanDetails: IIssueKanbanDetails = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueModalKanbanDetails
	);
	const isDarkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const isMultiValue = issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue;
	const initialFieldValues = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? [];
	const initialTags = initialFieldValues?.map(fieldValue => fieldValue?.ValueTag)?.filter(tag => tag?.Id) as ITag[];

	const selectValues = (issueState?.Properties?.Tags ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	const canClearSelect = !issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory;

	const isError = useMemo(() => {
		return (issueErrors.find(issueError => issueError.inputName === EIssueModalDetailsPageFields.properties)
			?.additionalData as number[])?.includes(issueFormSectionToIssueField?.AttributeDefinitionId as number);
	}, [issueErrors]);

	// useEffect(() => {
	// 	// if (typeof inputValue === 'undefined') return;
	// 	if (
	// 		Object.keys(issueState?.Properties?.Tags ?? {})?.includes(
	// 			String(issueFormSectionToIssueField?.AttributeDefinitionId)
	// 		)
	// 	) {
	// 		handleTagsChangeV3(selectValues);
	// 	}
	// 	// } else handleTagsChangeV3(initialTags);
	// }, []);

	const getValue = () => {
		if (selectValues) {
			if (isMultiValue) return selectValues ?? [];
			else return selectValues?.length ? selectValues[0] : undefined;
		} else {
			if (isMultiValue) return initialTags ?? [];
			else return (initialTags?.length ?? 0) > 0 ? initialTags[0] : undefined;
		}
	};

	const handleTagsChangeV3 = (newTags: ITag[] | ITag | undefined) => {
		const selectHasBeenReset = !newTags || (Array.isArray(newTags) && !(newTags as ITag[])?.length);

		let arr: ITag[] = [];
		if (Array.isArray(newTags)) arr = newTags;
		else if (newTags) arr = [newTags];

		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				Tags: {
					...(prevState?.Properties?.Tags ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: arr,
				},
			},
		}));
		handleResetInputError();

		if (selectHasBeenReset) {
			handleClearSelect();
			return;
		}
		handleFieldValuesChange(arr);
	};

	const handleFieldValuesChange = (tags: ITag[]) => {
		let arr: ITag[] = [];

		if (Array.isArray(tags)) arr = tags;
		else arr = [tags];

		const addedTags = arr?.filter(tag => !initialTags?.map(tag => tag?.Id)?.includes(tag?.Id));
		const deletedTags = initialTags?.filter(tag => !arr?.map(tag => tag?.Id)?.includes(tag?.Id));

		const fieldValuesToDelete = initialFieldValues?.filter(initialFieldValue =>
			deletedTags?.map(deletedTag => deletedTag?.Id)?.includes(initialFieldValue?.ValueTag?.Id as number)
		);
		setFieldValues(prevState => ({
			...prevState,
			fieldsValueToCreate: [
				...(prevState?.fieldsValueToCreate?.filter(filter =>
					getFieldValueWitouthFormSectionAttributeDefinitionId(
						filter,
						issueFormSectionToIssueField?.AttributeDefinition?.IsStatus
					)
				) ?? []),
				...addedTags?.map(newTag => new FieldValue(newTag, issueFormSectionToIssueField)),
			],
			fieldsValueToDelete: [
				...(prevState?.fieldsValueToDelete?.filter(filter =>
					getFieldValueWitouthFormSectionAttributeDefinitionId(
						filter,
						issueFormSectionToIssueField?.AttributeDefinition?.IsStatus
					)
				) ?? []),
				...fieldValuesToDelete,
			],
		}));
	};

	const handleClearSelect = () => {
		setFieldValues(prevState => ({
			...prevState,
			fieldsValueToCreate: [
				...(prevState?.fieldsValueToCreate?.filter(filter =>
					getFieldValueWitouthFormSectionAttributeDefinitionId(
						filter,
						issueFormSectionToIssueField?.AttributeDefinition?.IsStatus
					)
				) ?? []),
			],
			fieldsValueToDelete: [
				...(prevState?.fieldsValueToDelete?.filter(filter =>
					getFieldValueWitouthFormSectionAttributeDefinitionId(
						filter,
						issueFormSectionToIssueField?.AttributeDefinition?.IsStatus
					)
				) ?? []),
				...initialFieldValues,
			],
		}));
	};

	useEffect(() => {
		if (issueFormSectionToIssueField.AttributeDefinition?.IsStatus) {
			if (kanbanDetails?.isKanban && !isEdittingIssue) {
				const newTag: ITag | undefined = kanbanDetails?.mappedTags?.find(find => {
					return (
						find.FieldDefinitionId == issueFormSectionToIssueField.AttributeDefinitionId &&
						find.TagId == kanbanDetails.columnTagId
					);
				})?.FieldDefinitionTag;

				if (!newTag) return;

				handleTagsChangeV3(newTag as ITag);
			}
		}
	}, []);

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>,
		isStatus = false
	) {
		return (
			fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId &&
			!(isStatus && fieldValue?.AttributeDefinition?.IsStatus)
		);
	}

	const getCustomComponents = () => {
		if (issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue) {
			return {
				ValueContainer: ValueContainer,
				MultiValueLabel: MultiSelectValueLabelCustom,
				Option: CustomOption,
			};
		} else {
			return {
				SingleValue: SingleValueCustom,
				Option: CustomOption,
			};
		}
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.root)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.selectContainer)}>
				<CustomSelect
					canSetInputInNonEdit
					inEdit={inEdit}
					isDarkMode={isDarkMode}
					label=""
					className={clsx(
						isError ? classes.selectError : undefined,
						!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue && classes.multiValueContainer
					)}
					items={(issueFormSectionToIssueField?.AttributeDefinition?.TagGroup?.Tag as ITag[]) ?? []}
					getName={(tag: ITag) => tag?.Label ?? ''}
					getKey={(tag: ITag) => tag?.Id}
					values={getValue()}
					onChange={handleTagsChangeV3}
					args={{
						['components']: {
							...getCustomComponents(),
						},
						valuesLimit: 1,
						menuPosition: Resources.fixed,
						isClearable: canClearSelect,
						isSearchable: false,
						closeMenuOnSelect: !issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue,
					}}
					isMulti={!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue}
					nonEditComponent={
						<div className={clsx(classes.flexYCenter, classes.valueContainer)}>
							<VisibilityControl
								condition={!!initialTags?.length}
								alternative={<div className={clsx(classes.tag)}>-</div>}
							>
								{[...initialTags]?.slice(0, 2)?.map(initialTag => (
									<div
										key={initialTag?.Id}
										className={clsx(classes.tag)}
										style={{ backgroundColor: initialTag?.TagColor }}
									>
										<TooltipTextBase className={classes.tagText} text={initialTag?.Label} />
									</div>
								))}
								<VisibilityControl condition={initialTags?.length > 2}>
									<Tooltip
										title={[...initialTags]?.slice(2)?.map(initialTag => (
											<div
												key={initialTag?.Id}
												className={clsx(classes.tag)}
												style={{ backgroundColor: initialTag?.TagColor }}
											>
												<TooltipTextBase className={classes.tagText} text={initialTag?.Label} />
											</div>
										))}
									>
										<div className={clsx(classes.flexYCenter, classes.counter)}>
											<AddIcon className={classes.icon} />
											<Typography className={classes.counterText}>
												{initialTags?.length - 2}
											</Typography>
										</div>
									</Tooltip>
								</VisibilityControl>
							</VisibilityControl>
						</div>
					}
				/>
			</div>
		</div>
	);
};
