import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultDictionaryContent: {
		Order: 1,
		IsActive: true,
		Id: -1,
		DictionaryItemTypeId: 4,
	},
	redirect: {
		notFound: '/errors/error-404',
	},
	...GlobalResources,
};

export const Translation = {
	pl: {
		DictionaryContent: {
			description: 'Opis',
			name: 'Nazwa',
			isActive: 'Czy aktywny',
			actions: 'Akcje',
			title: 'Słowniki',
			value: 'Wartość',
			saveOrDeleteNewDictionaryMessage: 'Zapisz lub usuń nowo dodaną wartość w słowniku',
			changesNotSaved: 'Zmiany nie zostały zapisane. Czy chcesz kontynuować?',
			unsavedDictionaryMessage: 'Posiadasz obecnie nie zapisany rekord. Czy napewno chcesz utworzyć nowy?',
			noValue: 'Błąd podczas zapisu. Brak wartości',
			recordType: 'Typ wartości',
			onDeleteConfirmationMessage: 'Czy na pewno usunąć?',
			changesSaved: 'Zmiany zostały zapisane',
			newRecordAdded: 'Dodano nową wartość w słowniku',
			dictionaryItemDeleted: 'Wartość słownika została usunięta',
			pageTitle: 'Zawartość słownika',
			abandonMessage: 'Czy na pewno chcesz porzucić zmiany',
		},
	},
	en: {
		DictionaryContent: {
			description: 'Description',
			name: 'Name',
			isActive: 'Is active',
			actions: 'Actions',
			title: 'Dictionaries',
			value: 'Value',
			saveOrDeleteNewDictionaryMessage: 'Save or delete the newly added value in the dictionary',
			changesNotSaved: 'Changes have not been saved. Do you wish to continue?',
			unsavedDictionaryMessage:
				'You currently have an unsaved rekord. Are you sure you want to create a new one?',
			noValue: 'The value to save is missing',
			recordType: 'Type of value',
			onDeleteConfirmationMessage: 'Are you sure you want to delete?',
			changesSaved: 'Changes have been saved',
			newRecordAdded: 'New dictionary value has been added',
			dictionaryItemDeleted: 'Dictionary value has been deleted',
			abandonMessage: 'Are you sure you want to abandon the changes',
			pageTitle: 'Dictionary values',
		},
	},
};
