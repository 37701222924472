import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				width: '100%',
			},

			avatar: {
				width: '20px',
				height: '20px',
				margin: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
			},

			createdText: {
				marginLeft: theme.spacing(0.5),
			},

			errorButton: {
				backgroundColor: theme.palette.red.light,
				color: theme.palette.white.light,

				'&:hover': {
					backgroundColor: theme.palette.red.light,
				},
			},

			buttonsContainer: {
				'& :not(:first-child)': {
					marginLeft: theme.spacing(1),
				},
			},

			divider: {
				height: '20px',
				borderColor: theme.palette.grey[500],
				margin: `0 ${theme.spacing(1)}`,
			},
		};
	},
	{ name: `IssueModalNew_IssueModalActions` }
);
