import clsx from 'clsx';
import parse from 'html-react-parser';

import { Divider, TextField } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { Props } from './model';
import { useStyles } from './styles';

export const Content = (props: Props): JSX.Element => {
	const { comment, inEdit, setCommentText, handleKeyPress } = props;
	const classes = useStyles();

	return (
		<div className={clsx(classes.defaultCursor, classes.content)}>
			<VisibilityControl
				condition={!inEdit}
				alternative={
					<TextField
						fullWidth
						onChange={event => setCommentText(event.target.value)}
						value={comment}
						onKeyPress={handleKeyPress}
					/>
				}
			>
				{parse(comment)}
			</VisibilityControl>
		</div>
	);
};
