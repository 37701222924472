import clsx from 'clsx';
import React, { useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, IconButton } from '@mui/material';

import { TooltipTextBase, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IssuePropertyFactory } from './IssuePropertyFactory';
import { Props } from './model';
import { useStyles } from './styles';

export const IssueFormSection = (props: Props) => {
	const { issueFormSection, shouldHideLines, isNested = false, inEdit } = props;
	const classes = useStyles();

	const [isExtended, setIsExtended] = useState<boolean>(false);

	if (!issueFormSection?.IssueFormSectionToIssueField?.length && !issueFormSection?.IssueFormSection?.length) {
		return <div />;
	}

	const getSortedIssuePropertiesByMandatory = () => {
		return [
			...(issueFormSection?.IssueFormSectionToIssueField?.filter(
				item => item?.AttributeDefinition?.IsMandatory
			) ?? []),
			...(issueFormSection?.IssueFormSectionToIssueField?.filter(
				item => !item?.AttributeDefinition?.IsMandatory
			) ?? []),
		];
	};

	return (
		<div key={`issueFormSection-${issueFormSection?.Id}`} className={classes.container}>
			<div className={clsx(classes.issueFormSectionTitleContainer, classes.flexYCenter)}>
				{/* <Typography className={classes.issueFormSectionTitle}>{issueFormSection?.Name ?? ''}</Typography> */}
				<div className={classes.flexYCenter}>
					{/* <TooltipTextBase
						text={<Typography variant="body2">{isNested ? `(podgrupa)` : `(grupa)`}</Typography>}
					/> */}
					<TooltipTextBase className={classes.issueFormSectionTitle} text={issueFormSection?.Name ?? ''} />
				</div>
				<VisibilityControl condition={isNested}>
					<IconButton className={classes.iconButton} onClick={() => setIsExtended(!isExtended)}>
						<VisibilityControl condition={!isExtended} alternative={<ExpandLessIcon />}>
							<KeyboardArrowDownIcon />
						</VisibilityControl>
					</IconButton>
				</VisibilityControl>
				{/* <VisibilityControl condition={!shouldHideLines}>
					<div className={clsx(classes.verticaLine, isLast && classes.vericalLineForLastElement)} />
					<div className={clsx(classes.horizontalLine, classes.horizontalLineForLastElement)} />
				</VisibilityControl> */}
			</div>
			<Collapse in={isExtended || !isNested}>
				<div className={classes.values}>
					{(getSortedIssuePropertiesByMandatory() ?? [])?.map((issueFormSectionToIssueField, i, arr) => (
						<IssuePropertyFactory
							key={`issueFormSectionToIssueField-${issueFormSectionToIssueField.Id}`}
							issueFormSectionToIssueField={issueFormSectionToIssueField}
							inEdit={inEdit}
						/>
					))}
					{(issueFormSection?.IssueFormSection ?? []).map((issueFormSection, i, arr) => (
						<div
							key={`issueFormSectionToIssueField-${issueFormSection?.Id}`}
							className={clsx(classes.flexYCenter, classes.valueContainer)}
						>
							<IssueFormSection
								key={`issueFormSection-${issueFormSection.Id}`}
								issueFormSection={issueFormSection}
								isLast={i === arr.length - 1}
								isNested
								inEdit={inEdit}
								// shouldHideLines={shouldHideLines}
							/>
						</div>
					))}
				</div>
			</Collapse>
			{/* <VisibilityControl condition={!shouldHideLines && isNested}>
				<div className={clsx(classes.verticaLine, isLast && classes.vericalLineForLastElement)} />
				<div className={clsx(classes.horizontalLine, classes.horizontalLineForLastElement)} />
			</VisibilityControl> */}
		</div>
	);
};
