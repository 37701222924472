import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { Button, IconButton, Typography } from '@mui/material';

import { IArchivedIssues } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { archiveIssue, unArchiveIssue } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';
import { getFirst } from '@/utils/utils';

import { useStyles } from './styles';

export const Archiv = () => {
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();

	const { t, tString } = useTrans('IssueModalNew.IssueModalTitle');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const kanbanDetails: { IsKanban: boolean; KanbanId?: number } = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.kanbanDetails
	);

	const isArchived = useMemo(() => {
		return issue?.ArchivedIssues && (issue?.ArchivedIssues?.length ?? 0) > 0;
	}, [issue]);

	const handleManageArchive = () => {
		if (isArchived) {
			const id = getFirst(issue?.ArchivedIssues as IArchivedIssues[])?.Id;
			dispatch(unArchiveIssue(id));
		} else if (kanbanDetails?.KanbanId) {
			dispatch(archiveIssue(issue?.Id, kanbanDetails?.KanbanId));
		}
	};

	return (
		<div className={clsx(classes.flexColumn, classes.container)}>
			<Button
				onClick={handleManageArchive}
				variant="contained"
				className={clsx(isArchived && classes.deleteArchiv)}
			>
				<VisibilityControl condition={!!isArchived} alternative={'Archiwizuj'}>
					Usuń archiwizacje zadania
				</VisibilityControl>
			</Button>
			<Typography className={classes.subText} variant="body2">
				<VisibilityControl
					condition={!isArchived}
					alternative={'Anuluj archiwizacje zgłoszenia na tym kanbanie. Bedzie ono ponownie widoczne'}
				>
					Archiwizuj zgłoszenie na tym kanbanie ( Będzie ono niewidoczne chyba, że w filtrach zostanie
					włączony filtr pokazujący zgłoszenia )
				</VisibilityControl>
			</Typography>
		</div>
	);
};
