import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf, faProjectDiagram, faTasks } from '@fortawesome/free-solid-svg-icons';

import App from './App';

library.add(faTasks, faProjectDiagram, faHourglassHalf);

ReactDOM.render(<App />, document.getElementById('app'));
