import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	max: 'max',
	inputId: 'dropzone-file',
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								alert: 'Niektóre pliki są niedozwolone lub za duże.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								alert: 'Some files are not allowed or too big.',
							},
						},
					},
				},
			},
		},
	},
};
