import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.5),
				width: 'max-content',
				// color: theme.palette.text.secondary,
			},

			checkbox: {
				padding: 0,
				marginRight: theme.spacing(1),
			},
		};
	},
	{ name: `IssueModalEditor` }
);
