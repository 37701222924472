import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Summary: {
					IInvoiceDetails: {
						title: 'Dane adresowe',
						cityName: 'Nazwa miasta',
						zipCode: 'Kod pocztowy',
						nip: 'NIP',
						edit: 'Edytuj',
						cancel: 'Anuluj',
						save: 'Zapisz',
						customerTitle: 'Klient',
						customerName: 'Nazwa klienta:',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Summary: {
					IInvoiceDetails: {
						title: 'Address data',
						cityName: 'City name',
						zipCode: 'Zip code',
						nip: 'VAT code',
						edit: 'Edit',
						cancel: 'Cancel',
						save: 'Save',
						customerTitle: 'Customer',
						customerName: 'Customer name:',
					},
				},
			},
		},
	},
};
