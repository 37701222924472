import { differenceBy } from 'lodash';

import { IComplexUpdate, ITaskerUser } from '@skillandchill/tasker-types';

// export const getChangesInMembers = (initialState: Partial<ITaskerUser>, newState: Partial<ITaskerUser>) => {};

export const getChangesInUsers = (
	oldUsers: Partial<ITaskerUser>[],
	newUsers: Partial<ITaskerUser>[]
): IComplexUpdate => {
	const oldUsersIds = oldUsers?.map(user => user?.Id) ?? [];
	const newUsersIds = newUsers?.map(user => user?.Id) ?? [];

	const addedUsersIds = differenceBy(newUsersIds, oldUsersIds);
	const deletedUsersIds = differenceBy(oldUsersIds, newUsersIds);

	return { addItems: addedUsersIds, deleteItems: deletedUsersIds };
};
