import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalActions: {
								cancel: 'Anuluj',
								save: 'Zapisz fakturę',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							InvoiceModalActions: {
								cancel: 'Cancel',
								save: 'Save invoice',
							},
						},
					},
				},
			},
		},
	},
};
