import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	user: 'user/',
};

export const Translation = {
	pl: {
		ProjectList: {
			TileComponents: {
				ProjectOwner: {
					ownerName: 'Właściciel projektu',
				},
			},
		},
	},
	en: {
		ProjectList: {
			TileComponents: {
				ProjectOwner: {
					ownerName: 'Project owner',
				},
			},
		},
	},
};
