import React from 'react';
import { components } from 'react-select';

import { Checkbox, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { useStyles } from './styles';

export const CustomOption = (props: any) => {
	// export const CustomOption = ({ children, hasValue, ...rest }: any) => {
	const { isMulti } = props.selectProps; // HERE

	const classes = useStyles();
	const theme: Theme = useTheme();

	return (
		<components.Option {...props}>
			<div className={classes.flexYCenter}>
				<VisibilityControl condition={!!isMulti}>
					<Checkbox checked={props.isSelected} className={classes.checkbox} />
				</VisibilityControl>
				<div
					className={classes.tag}
					style={{
						backgroundColor: props?.data?.object?.TagColor,
						color: props?.data?.object?.TagColor,
					}}
				>
					<Typography style={{ color: props?.data?.object?.TagColor, filter: 'invert(100%)' }}>
						{props.children}
					</Typography>
				</div>
			</div>
		</components.Option>
	);
};
