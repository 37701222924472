import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	key: (x: number) => `${uuid()}_Projects_${x}`,
	...GlobalResources,
	extended: 'extended' as const,
	add: 'add',
};

export const Translation = {
	pl: {
		PortalList: {
			Filters: {
				Tags: {
					name: 'Nazwa po, której wyszukujemy: ',
					Status: 'Status:',
					Tag: 'Tag:',
					project: 'Project w, którym wyszukujemy: ',
				},
			},
		},
	},
	en: {
		PortalList: {
			Filters: {
				Tags: {
					name: 'Search name: ',
					Status: 'Status:',
					Tag: 'Tag:',
					project: 'Search by project: ',
				},
			},
		},
	},
};
