import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		const dimension = '80%';
		return {
			...getGlobalStyles(),
			modal: {
				// backgroundColor: '#f7f7f7',
				backgroundColor: theme.palette.white.light,
				outline: 'none',
				width: '1300px',
				// minWidth: dimension,
				// maxWidth: dimension,
				maxHeight: dimension,
				height: dimension,
				marginBottom: theme.spacing(2),
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				'& .MuiCardHeader-root': {
					padding: theme.spacing(3, 3),
					display: 'flex',
					alignItems: 'center',
					// backgroundColor: 'white',
					// backgroundColor: '#f7f7f7',
					backgroundColor: theme.palette.background.default,
				},
				'&>.MuiCardActions-root': {
					// backgroundColor: 'white',
					display: 'flex',
					padding: '8px 24px',
					alignItems: 'center',
					height: 'auto',
					// backgroundColor: '#f7f7f7',
					// backgroundColor: theme.palette.white.light,
					backgroundColor: theme.palette.background.default,

					// backgroundColor: 'white',
				},

				'&>.MuiCardContent-root': {
					paddingTop: '0px',
					// backgroundColor: 'white',
					// backgroundColor: theme.palette.white.light,
					backgroundColor: theme.palette.background.default,

					// backgroundColor: '#f7f7f7',
				},
			},
			container: {
				maxWidth: 'md',
				minWidth: '100%',
				padding: 0,
			},
			kanbanPopupContent: {
				display: `flex`,
				flexDirection: `column`,
				alignItems: `center`,
			},
			maringBottomS: {
				marginBottom: `12px`,
			},
		};
	},
	{ name: `IssueModalEditor` }
);
