import { GoogleLoginResponse } from 'react-google-login';

import {
	EBoolean,
	EParameter,
	False,
	IOrganization,
	IPasswordPolicy,
	ITaskerUser,
	ITaskerUserSetting,
} from '@skillandchill/tasker-types';
import { IVersion } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/version';
import { isMobile } from '@skillandchill/tasker-widgets2/dist/utils';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const sessionInitialState = {
	access_token: '' as string,
	customerFiltersNeedReset: 0,
	isLoggedIn: false as boolean,
	rememberMe: true as boolean,
	permissions: [] as number[],
	roles: [] as string[],
	profiles: [] as Partial<ITaskerUser>[],
	profile: undefined as Partial<ITaskerUser> | undefined,
	changeToken: '' as string,
	organization: [] as IOrganization[],
	chosenOrganization: (undefined as unknown) as IOrganization,
	taskerUser: {} as ITaskerUser,
	canOpenIssueModal: false as boolean,
	uuid: '' as string,
	passwordPolicies: [] as IPasswordPolicy[],
	sharedPasswordPolicies: [] as IPasswordPolicy[],
	isMobile: false as boolean,
	language: '' as string,
	languageId: (undefined as unknown) as number,
	hasOrgCustomTranslation: false,
	customTransaltionKey: '' as string,
	loggedIntoOrganizationId: (undefined as unknown) as number,
	preferences: [] as ITaskerUserSetting[],
	darkMode: false as boolean,
	userHasEndedDay: False as EBoolean,
	loadingQueue: [] as string[],
	isLoading: false as boolean,
	version: {} as IVersion,
	SSO: {} as GoogleLoginResponse,
};
export type ISessionState = typeof sessionInitialState;

const sessionReducer = (state = sessionInitialState, action: ObjectAction): ISessionState => {
	switch (action.type) {
		case '@@INIT': {
			return {
				...sessionInitialState,
				...state,
			};
		}
		case actionTypes.SESSION_RESET: {
			return {
				...state,
				permissions: [],
			};
		}
		case actionTypes.SESSION_START_LOGIN: {
			return {
				...state,
				profiles: action.profiles,
			};
		}
		case actionTypes.SESSION_SET_LOCAL_AVATAR: {
			return {
				...state,
				profile: ({ ...state.profile, avatar: action.avatar } as unknown) as Partial<ITaskerUser>,
			};
		}

		case actionTypes.SESSION_RESET_PASSWORD_RECEIVE:
		case actionTypes.SESSION_LOGOUT: {
			return {
				...sessionInitialState,
			};
		}
		case actionTypes.SESSION_UPDATE_AUTH_INFO:
		case actionTypes.SESSION_FINISH_LOGIN: {
			return {
				...state,
				access_token: action?.access_token,
				isLoggedIn: true,
				profiles: [],
				profile: { ...action?.profileData, avatar: action?.profileData.AvatarPath },
				roles: action?.roles,
				permissions: action?.permissions,
				organization: action?.organizations,
				chosenOrganization: action?.chosenOrganization,
				taskerUser: action?.profileData,
				canOpenIssueModal: action?.canOpenIssueModal,
				uuid: action?.uuid ?? '',
				passwordPolicies: action?.passwordPolicies ?? [],
				sharedPasswordPolicies: action?.sharedPasswordPolicies ?? [],
				language: action?.language ?? '',
				languageId: action?.languageId ?? undefined,
				loggedIntoOrganizationId: action?.loggedIntoOrganizationId ?? undefined,
				hasOrgCustomTranslation: action?.hasOrgCustomTranslation ?? undefined,
				customTransaltionKey: action?.customTranslationOrgKey ?? '',
				preferences: action?.preferences,
				userHasEndedDay: action?.userHasEndedDay,
				darkMode: Boolean(
					action?.preferences?.filter(
						(pref: ITaskerUserSetting) => pref?.ParameterId == EParameter.User_DarkModeOn
					)[0]?.ValueBoolean
				),
				isMobile: isMobile(),
			};
		}
		case actionTypes.SESSION_REMEMBER_ME_SET: {
			return {
				...state,
				rememberMe: action.value,
			};
		}
		case actionTypes.SESSION_CHECK_PASSWORD_RECOVERY: {
			return {
				...state,
				changeToken: action.recoverToken,
			};
		}
		case actionTypes.USER_UPDATE_SESSION: {
			return {
				...state,
				taskerUser: { ...state.taskerUser, ...action.taskerUser },
			};
		}
		case actionTypes.FETCH_PASSWORD_POLICIES: {
			return {
				...state,
				passwordPolicies: action.policies,
			};
		}
		case actionTypes.SESSION_SET_IS_SMALL_DEVICE: {
			return {
				...state,
				isMobile: action.isMobile,
			};
		}
		// case actionTypes.SESSION_SET_IS_LOADING: {
		// 	let queue = state?.loadingQueue ?? [];
		// 	if (action?.isAdd) queue?.push(action?.value);
		// 	else queue = queue?.filter((filter: string) => filter != action?.value);
		// 	return {
		// 		...state,
		// 		loadingQueue: queue,
		// 		isLoading: queue?.length > 0,
		// 	};
		// }
		case actionTypes.SESSION_SET_IS_LOADING_V2: {
			return {
				...state,
				isLoading: action?.data,
			};
		}
		case actionTypes.SESSION_SET_LANGUAGE: {
			return {
				...state,
				language: action?.language ?? {},
				languageId: action?.languageId,
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_UPDATE_PREFERENCES: {
			const simplifiedFilters = action?.data?.ParameterId === EParameter.User_UISimplifyFilters;

			const refresher = state?.customerFiltersNeedReset;

			return {
				...state,
				customerFiltersNeedReset: simplifiedFilters ? refresher + 1 : refresher,
				preferences: state?.preferences?.map(x =>
					x?.ParameterId == action?.data?.ParameterId ? action?.data : x
				),
				darkMode:
					action?.data?.ParameterId == EParameter.User_DarkModeOn
						? action?.data?.ValueBoolean
						: state?.darkMode,
			};
		}
		case actionTypes.CHECKLIST_VALUE_CREATE:
		case actionTypes.SET_COMPLETED_DAY: {
			return {
				...state,
				userHasEndedDay: action?.data?.userHasEndedDay,
			};
		}
		case actionTypes.GET_NEWEST_VERSION: {
			return {
				...state,
				version: action?.data,
			};
		}
		case actionTypes.SESSION_SSO_SAVE: {
			return {
				...state,
				SSO: action?.data,
			};
		}
		case actionTypes.CUSTOMER_CREATE:
		case actionTypes.CREATE_ISSUE_FORM:
		case actionTypes.CREATE_OR_UPDATE_PROJECT: {
			return {
				...state,
				customerFiltersNeedReset: state?.customerFiltersNeedReset + 1,
			};
		}

		//Jeśli po przypisaniu roli w projekcie jest tam użytkownik i ma role, która umożliwia otworzenie isssue modal to zmień canOpenIssueModal
		case actionTypes?.SET_PROJECT_USERS: {
			return {
				...state,
				canOpenIssueModal: action?.projectUsers?.some((user: any) => {
					if (user?.Id === state?.taskerUser?.Id) {
						return user?.ProjectToRoleToProjectToUserTaskerUser?.some(
							(role: any) => role?.projectRoleId !== 6 && role?.projectRoleId !== 7
						);
					}
					return state.canOpenIssueModal;
				}),
			};
		}
		case actionTypes?.PROJECT_FETCH_ONE: {
			return {
				...state,
				canOpenIssueModal: action?.project?.MembersInProject?.Members?.some((user: ITaskerUser) =>
					user?.Id === state?.taskerUser?.Id ? true : state?.canOpenIssueModal
				),
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
