import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton } from '@mui/material';

import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';

import { checkListValue_Create } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { Form } from './Form';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const EndOfDay = (props: Props): JSX.Element => {
	const { onClose, onSave: onSaveListener } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();

	const formState: IForm = useSelector((state: IReducerState) => state?.DashBoard?.EndOfWork.form);
	const values: { [x: number]: ICheckListValue[] } = useSelector(
		(state: IReducerState) => state?.DashBoard?.EndOfWork.values
	);

	const onSave = () => {
		const data = _.flatMap(Object.values(values));
		dispatch(checkListValue_Create(data, formState?.Id)).then(onSaveListener);
	};

	return (
		<div className={clsx(classes.root)}>
			<Form />
			<IconButton onClick={onSave}>
				<SaveIcon fontSize={Resources.GlobalResources.small} />
			</IconButton>
			<IconButton onClick={onClose}>
				<DeleteIcon fontSize={Resources.GlobalResources.small} />
			</IconButton>
		</div>
	);
};

export default EndOfDay;
