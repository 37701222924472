import { IIssue, IValidationErrorV2, IWorkLog } from '@skillandchill/tasker-types';

export interface Props {
	handleHideForm: () => void;
	handleSave: (worklogData: Partial<IWorkLog>) => void;
	initialWorklogFormValues: IWorklogForm;
	formErrors: IValidationErrorV2[];
	handleHideFormError: (inputName: EWorklogInputName) => void;
	handleDelete: (workLogId: number) => void;
	isWorklogInEdit: boolean;
}

export enum EWorklogInputName {
	Issue = 'Issue',
	DateTimeFrom = 'DateTimeFrom',
	DateTimeTo = 'DateTimeTo',
	Minutes = 'Minutes',
	Description = 'Description',
}

export interface IWorklogForm {
	Issue: IIssue;
	DateTimeFrom?: Date;
	DateTimeTo?: Date;
	Minutes?: number;
	Description?: string;
	Id?: number;
	WantToSetEndDateWithMinutes?: boolean;
}
