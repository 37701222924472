import { EDictionaryItemType } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						Form: {
							type: 'Typ rekordu słownika',
							isActive: 'Status',
							[EDictionaryItemType.DateTime]: 'Data z czasem',
							[EDictionaryItemType.Date]: 'Data',
							[EDictionaryItemType.TagGroup]: 'Grupa tagów',
							[EDictionaryItemType.Decimal]: 'Liczba dziesiętna',
							[EDictionaryItemType.Integer]: 'Liczba całkowita',
							[EDictionaryItemType.Text]: 'Text',
							[EDictionaryItemType.Boolean]: 'Prawda/Fałsz',
							[EDictionaryItemType.Dictionary]: 'Słownik',
							true: 'Prawda',
							false: 'Fałsz',
						},
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					Items: {
						Form: {
							type: 'Type of record',
							isActive: 'Status',
							[EDictionaryItemType.DateTime]: 'Datetime',
							[EDictionaryItemType.Date]: 'Date',
							[EDictionaryItemType.TagGroup]: 'Tag group',
							[EDictionaryItemType.Decimal]: 'Decimal',
							[EDictionaryItemType.Integer]: 'Integer',
							[EDictionaryItemType.Text]: 'Text',
							[EDictionaryItemType.Boolean]: 'Boolean',
							[EDictionaryItemType.Dictionary]: 'Dictionary',
						},
					},
				},
			},
		},
	},
};
