import { components } from 'react-select';

import { TooltipTextBase } from '@skillandchill/tasker-widgets2';

import { getFullNameNew } from '@/utils/user';

import { useStyles } from './styles';

export const MultiSelectValueLabelCustom = (props: any) => {
	const classes = useStyles();

	return (
		<div>
			<components.MultiValueLabel {...props}>
				<div>
					<TooltipTextBase text={getFullNameNew(props.data.object)} className={classes.nameContainer} />
				</div>
			</components.MultiValueLabel>
		</div>
	);
};
