import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					AcceptanceActions: {
						rejectAcceptance: 'Sprawdzam i odrzucam',
						submitAcceptance: 'Sprawdzam i akceptuję',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					AcceptanceActions: {
						rejectAcceptance: 'Validate and reject',
						submitAcceptance: 'Validate and accept',
					},
				},
			},
		},
	},
};
