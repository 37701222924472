import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	projectPath: '/projects/',
	user: 'user',
};

export const Translation = {
	pl: {
		ProjectList: {
			TileComponents: {
				AvatarAndCreator: {
					by: 'Stworzony przez: ',
					imageAlt: 'Autor',
				},
			},
		},
	},
	en: {
		ProjectList: {
			TileComponents: {
				AvatarAndCreator: {
					by: 'Created by: ',
					imageAlt: 'Author',
				},
			},
		},
	},
};
