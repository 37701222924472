import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dateColumnWidth: 100,
	nameColumnWidth: 500,
	keyColumnWidth: 100,
	timeLoggedTotalWidth: 100,
	nameField: 'Name',
	keyField: 'Key',
	timeLoggedTotalField: 'TimeLoggedTotal',
	totalField: 'Total',
	darkMode: 'rdg-dark',
	lightMode: 'rdg-light',
	defaultColumnOptions: {
		sortable: true,
		resizable: true,
	},
	asc: 'asc',
	desc: 'DESC',
	filterCell: 'filter-cell',
	issuePath: '?issueId=',
	csv: {
		headers: (customized = false) => {
			const returnValue = [
				{ label: t('TimeReport.csv.date'), key: 'date' },
				{ label: t('TimeReport.csv.url'), key: 'url' },
				{ label: t('TimeReport.csv.time'), key: 'time' },
				{ label: t('TimeReport.csv.displayId'), key: 'displayId' },
				{ label: t('TimeReport.csv.type'), key: 'type' },
			];
			if (customized === true) {
				returnValue.push({ label: t('TimeReport.csv.answerTime'), key: 'answerTime' });
				returnValue.push({ label: t('TimeReport.csv.isPortal'), key: 'isPortal' });
				returnValue.push({ label: t('TimeReport.csv.analise'), key: 'analise' });
				returnValue.push({ label: t('TimeReport.csv.extension'), key: 'extensions' });
				returnValue.push({ label: t('TimeReport.csv.support'), key: 'support' });
			}
			return returnValue;
		},
	},
	csvProject: {
		headers: () => {
			return [
				{ label: t('TimeReport.csvProject.id'), key: 'id' },
				{ label: t('TimeReport.csvProject.name'), key: 'name' },
				{ label: t('TimeReport.csvProject.time'), key: 'time' },
				{ label: t('TimeReport.csvProject.members'), key: 'members' },
			];
		},
		filename: () => t('TimeReport.fileProject'),
	},
	csvCustomer: {
		headers: () => {
			return [
				{ label: t('TimeReport.csvProject.id'), key: 'id' },
				{ label: t('TimeReport.csvCustomer.name'), key: 'name' },
				{ label: t('TimeReport.csvProject.time'), key: 'time' },
			];
		},
		filename: () => t('TimeReport.fileCustomer'),
	},
	csvOrlen: {
		headers: () => {
			return [
				{ label: t('TimeReport.csvOrlen.issueName'), key: 'IssueName' },
				{ label: t('TimeReport.csvOrlen.estimatedTime'), key: 'EstimatedTime' },
				{ label: t('TimeReport.csvOrlen.actualTime'), key: 'ActualTime' },
				{ label: t('TimeReport.csvOrlen.user'), key: 'User' },
				{ label: t('TimeReport.csvOrlen.difference'), key: 'Difference' },
			];
		},
		filename: () => t('TimeReport.fileOrlen'),
	},
	filename: () => t('TimeReport.file'),
	csvStyles: { visibility: 'hidden', height: 300 } as { visibility: 'hidden' },
	comma: ';',
	id: 'simple-popover',
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	} as const,
};

export const Translation = {
	pl: {
		TimeReport: {
			fileCustomer: 'Raport klienta.csv',
			fileProject: 'Raport projektowy.csv',
			fileOrlen: 'Raport użytkownika.csv',
			file: 'Raport czasowy.csv',
			acceptanceLabel: 'Akceptacja',
			nameLabel: 'Nazwa',
			keyLabel: 'Klucz',
			timeLoggedTotalLabel: 'Suma',
			wrongColumnMessage: 'Wybierz poprawne pole!',
			pageTitle: 'Raport czasu pracy',
			csv: {
				date: 'Data stworzenia',
				url: 'Url',
				time: 'Czas przepracowany',
				displayId: 'Klucz zgłoszenia',
				type: 'Typ',
				answerTime: 'Czas odpowiedzi',
				isPortal: 'Czy jest to zgłoszenie portalowe',
				file: 'Raport czasu pracy',
				analise: 'Analiza',
				extension: 'Rozszerzenia',
				support: 'Utrzymanie',
			},
			csvProject: {
				id: 'Id projektu',
				name: 'Nazwa projektu',
				time: 'Czas przepracowany',
				members: 'Członkowie projektu',
			},
			csvOrlen: {
				issueName: 'Tytuł zgłoszenia',
				estimatedTime: 'Szacowany czas zadania',
				actualTime: 'Przepracowany czas zadania',
				user: 'Użytkownik',
				difference: 'Różnica pomiędzy czasem zaplanowanym a zalogowanym',
			},
			csvCustomer: { name: 'Nazwa klienta' },
			Customer: 'Raport klienta',
			Project: 'Raport projektowy',
			WorkLog: 'Raport czasu pracy',
			reports: 'Pobierz raport',
			Orlen: 'Raport użytkownika',
			worklogAcceptanceMessage: 'Czy chcesz zaakceptować worklogi dla zadania:\n',
			worklogAcceptanceTotalTime: 'Czas pracy: ',
			worklogLegend: {
				freeDay: 'Dzień wolny',
				vacation: 'Urlop',
				notAccepted: 'Nie zaakceptowano ',
				accepted: 'Zaakceptowano',
				partiallyAccepted: 'Częściowo zaakceptowano',
				acceptedWithException: 'Zaakceptowano z wyjątkiem',
			},
			acceptAll: 'Zaakceptuj wybrany okres',
			requiredPermission: 'Brak uprawnień do akceptacji workloga',
		},
	},
	en: {
		TimeReport: {
			fileCustomer: 'Client Raport.csv',
			fileProject: 'Project Raport.csv',
			file: 'Time report.csv',
			fileOrlen: 'User report.csv',
			acceptanceLabel: 'Acceptance',
			nameLabel: 'Name',
			keyLabel: 'Key',
			timeLoggedTotalLabel: 'Total',
			wrongColumnMessage: 'Select correct field!',
			pageTitle: 'Time report',
			csv: {
				date: 'Creation Date',
				url: 'Url',
				time: 'Work time',
				displayId: 'Issue key',
				type: 'Type',
				answerTime: 'Answer time',
				isPortal: 'Is it portal issue',
				file: 'Work time report',
				analise: 'Analise',
				extension: 'Extension',
				support: 'Support',
			},
			csvProject: {
				id: 'Data stworzenia',
				name: 'Url',
				time: 'Czas przepracowany',
				members: 'Klucz zgłoszenia',
			},
			csvOrlen: {
				issueName: 'Issue title',
				estimatedTime: 'Estimated time',
				actualTime: 'Worked time',
				user: 'User',
				difference: 'Difference between planned and logged time',
			},
			csvCustomer: { name: 'Nazwa klienta' },
			Customer: 'Client report',
			Project: 'Project report',
			WorkLog: 'Worklog report',
			reports: 'Download report',
			Orlen: 'User report',
			worklogAcceptanceMessage: 'Do you want to accept worklogs for the task:\n',
			worklogAcceptanceTotalTime: 'Working time: ',
			worklogLegend: {
				freeDay: 'Free ady',
				vacation: 'Vacation',
				notAccepted: 'Not accepted',
				accepted: 'Accepted',
				partiallyAccepted: 'Partially accepted',
				acceptedWithException: 'Accepted with exception',
			},
			acceptAll: 'Accept displayed worklogs',
			requiredPermission: 'Missing permission to accept the worklog',
		},
	},
};
