import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Grid, Toolbar } from '@mui/material';

import Flag from '@/layouts/Dashboard/TopBar/LanguageButton';
import { IReducerState } from '@/store/reducers/types';

import { Props } from './model';
import { Resources } from './resources';

export const TopBar = (props: Props): JSX.Element => {
	const { className, ...rest } = props;

	const darkMode = useSelector((state: IReducerState) => state.Session?.darkMode);

	return (
		<AppBar {...rest} className={className} color={Resources.GlobalResources.inherit}>
			<Toolbar>
				<Grid container justifyContent={Resources.GlobalResources.spaceBetween}>
					<RouterLink to={Resources.to}>
						<img alt={Resources.imageAlternative} src={darkMode ? Resources.index : Resources.indexWhite} />
					</RouterLink>
					<Flag logged={false} />
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default TopBar;
