import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	downloadButtonId: 'DownloadButton',
	htmlFor: 'file-upload',
	file: 'file',
	dataLink: 'data:application/octet-stream;base64,',
	dataLink2: 'stream,',
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/streamable/${id}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						IssueAttch: {
							AttachmentOnUpdate: {
								FormInformation: {
									downloadStatus: 'Status: ',
									inProgress: 'W trakcie ',
									stopped: 'Zakończono ',
									size: 'Wielkość pliku w MB ',
									progress: 'Postęp ',
									time: 'Czas: ',
									estimate: 'Szacowany pozostały czas: ',
									cancel: 'Anuluj ',
								},
								noFile: 'Nie udało się pobrać pliku',
								attachmentName: 'Nazwa załącznika',
								attachmentUploadDate: 'Data przesłania',
								creator: 'Twórca',
								operations: 'Operacje',
								delete: 'Usuń',
								download: 'Pobierz',
								deleteSuccess: 'Usunięto pomyślnie',
								noAttachments: 'Brak załączników',
								attachments: 'Załączniki',
								addFileFailed: 'Następujące pliki zostały zakończone niepowodzeniem: ',
								deleteAttachmentTitle: 'Czy chcesz usunąć załącznik?',
								deleteAttachmentSubtitle: 'Usunięcie załącznika jest pernamentne i nieodwracalne',
								deleteAttachmentSubmit: 'Tak',
								deleteAttachmentCancel: 'Nie',
								errorDownloading:
									'Wystąpił błąd podczas pobieranie plików proszę skontaktować się z administracją.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						IssueAttch: {
							AttachmentOnUpdate: {
								FormInformation: {
									downloadStatus: 'Download Status: ',
									inProgress: 'In progress ',
									stopped: 'Done ',
									size: 'Size in MB ',
									progress: 'Progression ',
									time: 'Downloading time ',
									estimate: 'Estimated time: ',
									cancel: 'Cancel ',
								},
								noFile: 'Unable to retrieve file',
								attachmentName: 'Attachment name',
								attachmentUploadDate: 'upload date',
								creator: 'Creator',
								operations: 'Operations',
								delete: 'Delete',
								download: 'Download',
								deleteSuccess: 'Delete success',
								noAttachments: 'No attachments',
								attachments: 'Attachments',
								addFileFailed: 'Files adding failed: ',
								deleteAttachmentTitle: 'Are you sure, you want to delete the attachment?',
								deleteAttachmentSubtitle: 'Deleting the attachment is permanent and irreversible',
								deleteAttachmentSubmit: 'Yes',
								deleteAttachmentCancel: 'No',
								errorDownloading:
									'Error occurs while downloading file. Please contact administration to solve that problem.',
							},
						},
					},
				},
			},
		},
	},
};
