import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dimensions: { width: 36, height: 36 },
	keyChoosen: (x: number, y: number) => `ChoosenKey_${x}_${y}_${uuid()}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Users: {
								choosen: 'Wybrani użytkownicy',
								choose: 'Wybierz użytkowników',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Users: {
								choosen: 'Choosen users',
								choose: 'Choose users',
							},
						},
					},
				},
			},
		},
	},
};

export enum EActiveStep {
	Form = 0,
	Url = 1,
	Body = 2,
	Users = 3,
}
