import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LanIcon from '@mui/icons-material/Lan';
import ListIcon from '@mui/icons-material/List';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { EIssueModalTabs } from '@skillandchill/tasker-types';

import { useTrans } from '@/utils/hooks/useTrans';

import { issueModalDataContext } from '../..';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Tabs = (props: Props) => {
	const { selectedTab, setSelectedTab } = props;
	const classes = useStyles();

	const { t } = useTrans('IssueModelNew.IssueModalContent');

	const { isEdittingIssue } = useContext(issueModalDataContext);

	useEffect(() => {
		setSelectedTab(EIssueModalTabs.details);
	}, []);

	const handleChange = (event: React.SyntheticEvent, newTab: EIssueModalTabs) => {
		setSelectedTab(newTab);
	};

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: Resources.divider }}>
				<TabList
					onChange={handleChange}
					className={classes.tabList}
					textColor={Resources.GlobalResources.inherit}
				>
					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalTabs.details ? classes.tabSelected : classes.tabNotSelected,
							selectedTab === EIssueModalTabs.details && classes.tabIconSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<AssignmentIcon className={classes.tabIcon} />
								<span>{t('detailsTab')}</span>
							</div>
						}
						value={EIssueModalTabs.details}
					/>

					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalTabs.childTasks ? classes.tabSelected : classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<LanIcon className={clsx(classes.tabIcon, classes.iconRotated)} />
								<span>{t('subTasksTab')}</span>
							</div>
						}
						value={EIssueModalTabs.childTasks}
					/>

					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalTabs.parentTasks ? classes.tabSelected : classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<LanIcon className={classes.tabIcon} />
								<span>{t('higherTasksTab')}</span>
							</div>
						}
						value={EIssueModalTabs.parentTasks}
					/>

					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalTabs.equivalentTasks
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<ListIcon className={classes.tabIcon} />
								<span>{t('equivalentTab')}</span>
							</div>
						}
						value={EIssueModalTabs.equivalentTasks}
					/>

					<Tab
						className={clsx(
							classes.tab,
							selectedTab === EIssueModalTabs.worklogs ? classes.tabSelected : classes.tabNotSelected
						)}
						label={
							<div className={classes.flexYCenter}>
								<FormatListNumberedIcon className={classes.tabIcon} />
								<span>{t('worklogsTab')}</span>
							</div>
						}
						value={EIssueModalTabs.worklogs}
						disabled={!isEdittingIssue}
					/>
				</TabList>
			</Box>
		</TabContext>
	);
};
