import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	keyPart: 'Form',
	portalPath: '/forms/',
	formPathNew: 'formNew',
	formPathName: '?formId=',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueFormList: {
					title: 'Formularze',
					didSaveFailed: 'Zapis zakończony niepowodzeniem',
					loadingError: 'Błąd',
					error: 'Błąd serwera',
					addForm: 'Dodaj formularz',
					autoReply: 'Automatyczna odpowiedź',
					Table: {
						name: 'Nazwa',
						isPortal: 'Formularz portalowy',
						creationDate: 'Data stworzenia',
						copyForm: 'Kopiuj formularz do projektu',
						shareForm: 'Udostępnij formularz do projektu',
						more: 'Więcej',
						creator: 'Utworzony przez',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueFormList: {
					title: 'Form',
					didSaveFailed: 'Save Failed',
					loadingError: 'Error',
					error: 'Server Error',
					addForm: 'Add form',
					autoReply: 'Auto reply',
					Table: {
						name: 'Name',
						isPortal: 'Portal form',
						creationDate: 'Creation date',
						copyForm: 'Copy form to project',
						shareForm: 'Share form to project',
						more: 'More',
						creator: 'Created by',
					},
				},
			},
		},
	},
};
