import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryList: {
								dictionaryName: 'Nazwa słownika',
								dictionaryType: 'Rodzaj słownika',
								type1: 'Rodzaje załączników',
								type2: 'Rodzaje środków transportu',
								dictionaryDescription: 'Opis',
								dictionaryCreationDate: 'Data utworzenia',
								dictionaryCreatedBy: 'Utworzony przez',
								detailsTooltip: 'Otwórz słownik',
								menuTooltip: 'Opcje słownika',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryList: {
								dictionaryName: 'Dictionary name',
								dictionaryType: 'Dictionary type',
								type1: 'Attachment types',
								type2: 'Means of transport types',
								dictionaryDescription: 'Description',
								dictionaryCreationDate: 'Creation date',
								dictionaryCreatedBy: 'Created by',
								detailsTooltip: 'Open dictionary',
								menuTooltip: 'Dictionary options',
							},
						},
					},
				},
			},
		},
	},
};
