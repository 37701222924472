import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	redirect: {
		general: '/admin/organization/tabs/general',
		notFound: '/errors/error-404',
	},
	tabs: {
		visibilityConfiguration: { name: '/admin/organization/delegations/tabs/visibilityConfiguration' },
		defaultsConfiguration: { name: '/admin/organization/delegations/tabs/defaultsConfiguration' },
		usersRolesConfiguration: { name: '/admin/organization/delegations/tabs/usersRolesConfiguration' },
		customDictionariesConfiguration: {
			name: '/admin/organization/delegations/tabs/customDictionariesConfiguration',
		},
		dictionaryConfiguration: { name: '/admin/organization/delegations/tabs/dictionaryConfiguration' },
		general: { name: '/admin/organization/delegations/tabs/general' },
	},
	...GlobalResources,
};
export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					pageTitle: 'Panel konfiguracji delegacji',
					tabs: {
						visibilityConfiguration: { label: 'Konfiguracja widoczności' },
						defaultsConfiguration: { label: 'Konfiguracja wartości domyślnych' },
						usersRolesConfiguration: { label: 'Konfiguracja ról użytkowników' },
						dictionaryConfiguration: { label: 'Konfiguracja używanych słowników' },
						customDictionariesConfiguration: { label: 'Konfiguracja własnych słowników' },
						general: { label: 'Ogólne' },
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					pageTitle: 'Delegation configuration panel',
					tabs: {
						visibilityConfiguration: { label: 'Visibility configuration' },
						defaultsConfiguration: { label: 'Defaults configuration' },
						usersRolesConfiguration: { label: 'Users roles configuration' },
						dictionaryConfiguration: { label: 'Used dictionaries configuration' },
						customDictionariesConfiguration: { label: 'Custom dictionaries configuration' },
						general: { label: 'General' },
					},
				},
			},
		},
	},
};

export const getTabs = () => {
	return [
		{
			value: Resources.tabs.general.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.general.label'),
		},
		{
			value: Resources.tabs.visibilityConfiguration.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.visibilityConfiguration.label'),
		},
		{
			value: Resources.tabs.defaultsConfiguration.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.defaultsConfiguration.label'),
		},
		{
			value: Resources.tabs.usersRolesConfiguration.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.usersRolesConfiguration.label'),
		},
		{
			value: Resources.tabs.customDictionariesConfiguration.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.customDictionariesConfiguration.label'),
		},
		{
			value: Resources.tabs.dictionaryConfiguration.name,
			label: t('OrganizationPanel.TabsContent.Delegation.tabs.dictionaryConfiguration.label'),
		},
	];
};
