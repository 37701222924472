import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	menuId: 'menuId',
	aariaLabelledby: 'aria-labelledby',
	basicButton: 'basic-button',
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			TableOptions: {
				markAsRead: 'Oznacz wszystkie jako przeczytane',
			},
		},
	},
	en: {
		NotificationList: {
			TableOptions: {
				markAsRead: 'Mark all as read',
			},
		},
	},
};
