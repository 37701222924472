import { AsyncAction } from '@skillchill/redux-promisify';

import { IDelegationComment, IDelegationCommentCreate, IDelegationCommentUpdate } from '@skillandchill/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_COMMENT_CREATE: ActionType = 'DELEGATION_COMMENT_CREATE';
export const DELEGATION_COMMENT_UPDATE: ActionType = 'DELEGATION_COMMENT_UPDATE';
export const DELEGATION_COMMENT_DELETE: ActionType = 'DELEGATION_COMMENT_DELETE';

const basePath = 'DelegationComments';

function _getDelegationCommentsOnCreate(data: IDelegationComment[]): Action {
	return {
		type: DELEGATION_COMMENT_CREATE,
		data,
	};
}

function _getDelegationCommentsOnUpdate(data: IDelegationComment[]): Action {
	return {
		type: DELEGATION_COMMENT_UPDATE,
		data,
	};
}

function _getDelegationCommentsOnDelete(data: IDelegationComment[]): Action {
	return {
		type: DELEGATION_COMMENT_DELETE,
		data,
	};
}

export function delegationComments_create(data: IDelegationCommentCreate): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getDelegationCommentsOnCreate((json as IJson).data)));
		return axios().post(`${basePath}/create`, {
			data: data,
		});
	};
}

export function delegationComments_update(data: IDelegationCommentUpdate): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getDelegationCommentsOnUpdate((json as IJson).data)));
		return axios().patch(`${basePath}/update`, {
			data: data,
		});
	};
}

export function delegationComments_delete(delegationId: number, delegationCommentId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getDelegationCommentsOnDelete((json as IJson).data)));
		return axios().delete(`${basePath}/${delegationId}/delete/${delegationCommentId}`);
	};
}
