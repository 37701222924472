import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	redirectCustomers: '/customers',
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			HeaderSuffix: {
				deleteCustomer: 'Usuń klienta',
				onDeleteError: 'Błąd usuwania klienta',
				onDeleteSuccess: 'Klient usunięty pomyślnie',
				deleteTitle: 'Czy na pewno chcesz usunąć klienta?',
				deleteSubtitle: 'Usunięcie klienta jest permanentne i nieodwracalne',
				deleteSubmitText: 'Tak',
				deleteCancelText: 'Nie',
			},
		},
	},
	en: {
		CustomerDetail: {
			HeaderSuffix: {
				deleteCustomer: 'Delete customer',
				onDeleteError: 'Error while deleting customer',
				onDeleteSuccess: 'Customer successfully deleted',
				deleteTitle: 'Are you sure you want to delete this customer?',
				deleteSubtitle: 'Deleting customer is permanent and irreversible',
				deleteSubmitText: 'Yes',
				deleteCancelText: 'No',
			},
		},
	},
};
