import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	id: -1,
	dictionaryDefault: {
		Name: '',
		Description: '',
		Id: -1,
		IsActive: true,
	},
	formPath: '/dictionaries/',
	fieldset: 'fieldset' as const,
	standard: 'standard' as const,
	...GlobalResources,
	// description: 'description',
	// name: 'name',
	// type: 'type',
	// isActive: 'isActive',
	// actions: 'actions',
	// title: 'title',
	// onDeleteConfirmationMessage: 'onDeleteConfirmationMessage',
	// saveOrDeleteNewDictionaryMessage: 'saveOrDeleteNewDictionaryMessage',
	// changesNotSaved: 'changesNotSaved',
	// unsavedDictionaryMessage: 'unsavedDictionaryMessage',
	// noName: 'noName',
	// dictionaryCreated: 'dictionaryCreated',
	// changesSaved: 'changesSaved',
	// dictionaryDeleted: 'dictionaryDeleted',
};

export const Translation = {
	pl: {
		Dictionaries: {
			description: 'Opis',
			name: 'nazwa',
			type: 'Typ',
			isActive: 'Czy aktywne',
			actions: 'Akcje',
			title: 'Słowniki',
			onDeleteConfirmationMessage: 'Czy napewno usunąć?',
			saveOrDeleteNewDictionaryMessage: 'Zapisz lub usuń nowo dodany słownik',
			changesNotSaved: 'Zmiany nie zostały zapisane. Czy chcesz kontynuować?',
			unsavedDictionaryMessage: 'Posiadasz obecnie nie zapisany słownik. Czy napewno chcesz utworzyć nowy?',
			noName: 'Brakuje nazwy słownika',
			dictionaryCreated: 'Słownik został stworzony',
			changesSaved: 'Zmiany zostały zapisane',
			dictionaryDeleted: 'Słownik został usunięty',
			abandonMessage: 'Czy na pewno chcesz porzucić zmiany',
			changedPathUnsavedDictionary: 'Posiadasz obecnie niezapisany słownik. Czy na pewno chcesz kontynuować?',
			useTableData: {
				dictionaryName: 'Nazwa słownika',
				dictionaryDesc: 'Opis',
				dictionaryIsActive: 'Status',
				actions: 'Akcje',
				active: 'Aktywny',
				inactive: 'Nieaktywny',
				dictionaryInputPlaceholder: {
					name: 'Nazwa słownika',
					description: 'Opis słownika',
				},
				abandonChanges: 'Porzuć zmiany',
			},
		},
	},
	en: {
		Dictionaries: {
			description: 'Description',
			name: 'Name',
			type: 'Type',
			isActive: 'Is active',
			actions: 'Actions',
			title: 'Dictionaries',
			onDeleteConfirmationMessage: 'Are you sure you want to delete?',
			saveOrDeleteNewDictionaryMessage: 'Save or delete the newly added dictionary',
			changesNotSaved: 'Changes have not been saved. Do you wish to continue?',
			unsavedDictionaryMessage:
				'You currently have an unsaved dictionary. Are you sure you want to create a new one?',
			noName: 'The name of the dictionary is missing',
			dictionaryCreated: 'Dictionary has been created',
			changesSaved: 'Changes have been saved',
			dictionaryDeleted: 'Dictionary has been deleted',
			changedPathUnsavedDictionary:
				'You currently have an unsaved dictionary. Are you sure you want to continue?',
			abandonMessage: 'Are you sure you want to abandon the changes',
			useTableData: {
				dictionaryName: 'Dictionary name',
				dictionaryDesc: 'Description',
				dictionaryIsActive: 'Status',
				actions: 'Actions',
				active: 'Active',
				inactive: 'Inactive',
				dictionaryInputPlaceholder: {
					name: 'Name of dictionary',
					description: 'Description of dictionary',
				},
				abandonChanges: 'Abandon the changes',
			},
		},
	},
};
