import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	tabs: {
		structure: {
			name: 'structure' as const,
		},
		categories: {
			name: 'category' as const,
		},
	},
	fullWidth: 'fullWidth' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				OrganizationStructure: {
					pageTitle: {
						loading: 'Ładowanie',
						title: 'Struktura organizacyjna',
					},
					tabs: {
						structure: {
							name: 'Struktura',
						},
						categories: {
							name: 'Kategorie pozycji',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				OrganizationStructure: {
					pageTitle: {
						loading: 'Loading',
						title: 'Organization structure',
					},
					tabs: {
						structure: {
							name: 'Structure',
						},
						categories: {
							name: 'Position category',
						},
					},
				},
			},
		},
	},
};

export enum EAddParameter {
	AddStructure = 'newStructure',
	AddPosition = 'newPosition',
	AddCategory = 'newCategory',
}

export const getTabs = () => {
	return [
		{
			value: Resources.tabs.structure.name,
			label: t('OrganizationPanel.TabsContent.OrganizationStructure.tabs.structure.name'),
		},
		{
			value: Resources.tabs.categories.name,
			label: t('OrganizationPanel.TabsContent.OrganizationStructure.tabs.categories.name'),
		},
	];
};

export const enum EStructureTag {
	structure = 'str',
	category = 'cat',
	position = 'pos',
}

export const divideSignStructure = '**';
export const divideSignPosition = '***';
export const divideSignCategory = '*$*';
