import { EEmployeeAgreementSalaryPeriod } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,

	fixed: 'fixed' as const,
	number: 'number' as const,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryAmountStep: {
							mainLabel: 'Suma wynagrodzenia',
							mainSubTitleHourlySalaryType:
								'Wszystkie wynagrodzenia powinny być podawane jako kwoty brutto',
							mainSubTitleFixedSalaryType: 'Podawana kwota wynagrodzenia powinna być kwotą brutto',
							salary100PercentLabelHourlySalaryType:
								'Poniższa wartość nie może być bezpośrednio modyfikowana. Została wyliczona na podstawie wielkości wynagrodzenia za godzinę oraz okresu wynagrodzenia. ( Przyjęto 100% etatu (160h w miesiącu czyli 4 tygodnie po 40 godzin ).)',
							EffectiveSalaryLabelHourlySalaryType:
								'Poniższa wartość nie może być bezpośrednio edytyowana przez użytkownika. Została wyliczona na podstawie wielkości wynagrodzenia za godzinę, okresu wynagrodzenia oraz liczby godzin do przepracowania w tygodniu',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryAmountStep: {
							mainLabel: 'Salary amount',
							mainSubTitleHourlySalaryType: 'All salaries should be given as gross amounts',
							mainSubTitleFixedSalaryType: 'The amount of remuneration given should be the gross amount',
							salary100PercentLabelHourlySalaryType:
								'The following value cannot be directly modified. It was calculated based on the amount of remuneration per hour and the remuneration period. (100% full-time employment was assumed(160 hours per month so 40 hours a week).)',
							EffectiveSalaryLabelHourlySalaryType:
								'The value cannot be directly edited by the user. It was calculated on the basis of the amount of salary per hour, the period of salary and the number of hours to be worked in a week',
						},
					},
				},
			},
		},
	},
};

export const SalaryPeriods = [EEmployeeAgreementSalaryPeriod.annual, EEmployeeAgreementSalaryPeriod.monthly];
