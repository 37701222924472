import { AsyncAction } from '@skillchill/redux-promisify';

import { IPage, ITag, ITagGroup, ITagGroupContext, IValidationError } from '@skillandchill/tasker-types';
import { Tag, TagGroup } from '@skillandchill/tasker-widgets2/dist/utils/types/tag';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const TAG_FETCH: ActionType = 'TAG_FETCH';
export const TAG_UPDATE: ActionType = 'TAG_UPDATE';

export const SET_ERRORS: ActionType = 'SET_ERRORS';
export const PORTAL_INFO_TAGS: ActionType = 'PORTAL_INFO_TAGS';
export const PORTAL_FORM_TAGS: ActionType = 'PORTAL_FORM_TAGS';
export const TAG_GROUP_FETCH: ActionType = 'TAG_GROUP_FETCH';
export const TAG_GROUP_FETCH_NEW: ActionType = 'TAG_GROUP_FETCH_NEW';
export const FETCH_TAG_GROUPS_WITH_TAGS: ActionType = 'FETCH_TAG_GROUPS_WITH_TAGS';
export const FETCH_TAG_CLOCK_GROUPS_WITH_TAGS: ActionType = 'FETCH_TAG_CLOCK_GROUPS_WITH_TAGS';
export const FETCH_TAG_GROUPS_WITH_TAGS_NO_MULTIPLE: ActionType = 'FETCH_TAG_GROUPS_WITH_TAGS_NO_MULTIPLE';
export const GET_SINGLE_TAG_GROUP: ActionType = 'GET_SINGLE_TAG_GROUP';
export const TAG_GROUP_CONTEXTS_FETCH: ActionType = 'TAG_GROUP_CONTEXTS_FETCH';
export const TAGS_BY_TAG_GROUP: ActionType = 'TAGS_BY_TAG_GROUP';
export const CLEAR_TAG_GROUPS: ActionType = 'CLEAR_TAG_GROUPS';
export const FETCH_TAG_GROUPS_BY_CONTEXT: ActionType = 'FETCH_TAG_GROUPS_BY_CONTEXT';
export const FETCH_ISSUE_TYPE_TAGS: ActionType = 'FETCH_ISSUE_TYPE_TAGS';
export const FETCH_ISSUE_TAG_GROUP: ActionType = 'FETCH_ISSUE_TAG_GROUP';
export const TAG_GROUP_ISSUE_FILTERS_FETCH_NEW: ActionType = 'TAG_GROUP_ISSUE_FILTERS_FETCH_NEW';

const baseTagPath = 'Tag';
const baseTagGroupPath = 'TagGroup';
const baseContextPath = 'TagGroupContext';

function _fetchGroupsIssueFilters(groups: TagGroup[]): Action {
	return {
		type: TAG_GROUP_ISSUE_FILTERS_FETCH_NEW,
		groups,
	};
}

function _fetchTags(tags: Tag[]): Action {
	return {
		type: TAG_FETCH,
		tags,
	};
}
function _fetchTagsByTagGroup(tags: ITag[]): Action {
	return {
		type: TAGS_BY_TAG_GROUP,
		tags,
	};
}

function _fetchTagGroupContexts(contexts: ITagGroupContext[]): Action {
	return {
		type: TAG_GROUP_CONTEXTS_FETCH,
		contexts,
	};
}

function _fetchTagGroupsWithTags(groups: IPage<ITagGroup[]>, clearTagGroups = false): Action {
	return {
		type: FETCH_TAG_GROUPS_WITH_TAGS,
		groups: groups.Data,
		count: groups.Count,
		clearTagGroups,
	};
}

function _fetchClockTagsGroupsWithTags(groups: ITagGroup[]): Action {
	return {
		type: FETCH_TAG_CLOCK_GROUPS_WITH_TAGS,
		groups,
	};
}

function _getSingleTagGroup(group: ITagGroup): Action {
	return {
		type: GET_SINGLE_TAG_GROUP,
		group,
	};
}
function _fetchTagGroupsByContext(groups: ITagGroup[]): Action {
	return {
		type: FETCH_TAG_GROUPS_BY_CONTEXT,
		groups,
	};
}

function _fetchIssueTagGroups(tagGroups: ITagGroup[]): Action {
	return {
		type: FETCH_ISSUE_TAG_GROUP,
		data: tagGroups,
	};
}

export function setErrors(errors: IValidationError[]): Action {
	return {
		type: SET_ERRORS,
		data: errors,
	};
}

export function tag_fetchGroupsIssueFilters(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchGroupsIssueFilters((data as IJson)?.data)));
		return axios().get(`TagGroup/tags/issueFilters`);
	};
}

export function tag_fetch(): AsyncAction {
	return function(context, dispatch) {
		context.chain(x => (x as IJson).data);
		context.then(data => dispatch(_fetchTags(data as Tag[])));
		return axios().get(`tag`);
	};
}

export function create_tag(tag: ITag): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getSingleTagGroup((data as IJson).data)));
		return axios().post(baseTagPath, tag);
	};
}

export function fetch_new_page_tagGroups(
	from: number,
	count: number,
	clearTagGroups: boolean,
	searchValue: string,
	contextFilterValue: number | null
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagGroupsWithTags((data as IJson).data, clearTagGroups)));
		return axios().post(`${baseTagGroupPath}/from/${from}/count/${count}`, {
			searchValue,
			contextFilterValue,
		});
	};
}

export function get_singleTagGroupWithTags(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getSingleTagGroup((data as IJson).data)));
		return axios().get(`${baseTagGroupPath}/full/${id}`);
	};
}

export function create_tagGroup(
	tagGroup: ITagGroup,
	skip: number,
	take: number,
	searchValue?: string,
	contextFilterValue?: number | null
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagGroupsWithTags((data as IJson).data, true)));
		return axios().post(baseTagGroupPath, { tagGroup, skip, take, searchValue, contextFilterValue });
	};
}

export function copy_tagGroup(
	tagGroup: Partial<ITagGroup>,
	skip: number,
	take: number,
	searchValue?: string,
	contextFilterValue?: number | null
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagGroupsWithTags((data as IJson).data, true)));
		return axios().post(`${baseTagGroupPath}/copy`, { tagGroup, skip, take, searchValue, contextFilterValue });
	};
}

export function delete_tagGroup(
	id: number,
	skip?: number,
	take?: number,
	searchValue?: string,
	contextFilterValue?: number | null
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => {
			if (!skip && !take) return;
			dispatch(_fetchTagGroupsWithTags((data as IJson).data, true));
		});

		return axios().delete(`${baseTagGroupPath}/${id}`, { data: { skip, take, searchValue, contextFilterValue } });
	};
}

export function update_tag(tagId: number, tag: Partial<ITag>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getSingleTagGroup((data as IJson).data)));
		return axios().patch(`${baseTagPath}/${tagId}`, tag);
	};
}

export function update_tagGroup(id: number, tagGroup: Partial<ITagGroup>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_getSingleTagGroup((data as IJson).data)));
		return axios().patch(`${baseTagGroupPath}/${id}`, tagGroup);
	};
}

export function clear_tagGroups(): Action {
	return {
		type: CLEAR_TAG_GROUPS,
	};
}

export function get_tagGroup_Contexts(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagGroupContexts((data as IJson).data)));
		return axios().get(baseContextPath);
	};
}

export function tag_setGroupTags(id: number, data: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagsByTagGroup((data as IJson).data)));
		return axios().patch(`tag/group/${id}`, data);
	};
}

export function tag_delete(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagsByTagGroup((data as IJson).data)));
		return axios().delete(`tag/${id}`);
	};
}

export function fetch_clockTagsGroupsWithTags(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchClockTagsGroupsWithTags((data as IJson).data)));
		return axios().get(`${baseTagGroupPath}/taskerObject/clock`);
	};
}
export function fetchTagGroupsByContext(taskerObjectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchTagGroupsByContext((data as IJson).data)));
		return axios().get(`${baseTagGroupPath}/context/${taskerObjectId}`);
	};
}

export function getIssueTagGroup(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchIssueTagGroups((data as IJson).data)));
		return axios().get(`${baseTagGroupPath}/taskerObject/issue`);
	};
}
