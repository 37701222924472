import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IFormState } from '@/views/Login/LoginForm/model';

export const Resources = {
	...GlobalResources,
	hash: '#/auth/login',
	FormState: {
		targetType: 'checkbox',
		touched: true,
	},
	HandleLoginSubmit: {
		history: '/',
		variant: 'error',
	},
	Email: {
		name: 'email',
		field: 'email',
	},
	Password: {
		name: 'password',
		field: 'password',
	},
	loggedHref: '/activity',
	firstLoginHref: '/changepassword',
	password: 'password' as const,
	autoComplete: {
		userName: 'username',
		password: 'current-password',
	},
	googlePolicy: 'single_host_origin',
	//create varriable store path to google.png inside folder public
	googleIcon: `/images/google.svg`,
	Index: {
		RecoverPassword: {
			link: '/auth/recover',
		},
	},
};

export const Translation = {
	pl: {
		Login: {
			LoginForm: {
				HandleLoginSubmit: {
					message: 'Coś poszło nie tak. Spróbuj ponownie później',
				},
				Email: {
					label: 'Adres mailowy',
				},
				Password: {
					label: 'Hasło',
				},
				signIn: 'Zaloguj się',
				signInGoogle: 'Zaloguj się za pomocą google',
				Error: {
					error_400: 'Serwer nie był w stanie przetworzyć żądania',
					error_401: 'Nieprawidłowy login lub hasło',
					error_403: 'Nieprawidłowy login lub hasło',
					error_500: 'Błąd połączenia z serwerem',
				},
				ForgotPassword: 'Zapomniałeś hasła?',
				or: 'lub kontynuuj z',
			},
		},
	},
	en: {
		Login: {
			LoginForm: {
				HandleLoginSubmit: {
					message: 'Something went wrong! Please retry later.',
				},
				Email: {
					label: 'Email address',
				},
				Password: {
					label: 'Password',
				},
				signIn: 'Sign in',
				signInGoogle: 'Sign in with Google',
				Error: {
					error_400: 'Server was unable to process the request',
					error_401: 'Incorrect login or password',
					error_403: 'Incorrect login or password',
					error_500: 'Connection error with the server',
				},
				ForgotPassword: 'Forgot password?',
				or: 'or continue with',
			},
		},
	},
};

export const schema = {
	email: {
		presence: { allowEmpty: false, message: 'is required' },
		email: false,
	},
	password: {
		presence: { allowEmpty: false, message: 'is required' },
	},
};

export const defaultFormState: IFormState = {
	isValid: false,
	values: { email: '', password: '' },
	touched: {} as { [x: string]: boolean },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: {} as { [x: string]: any },
};
