import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	canvasBarCustomHeight: 400,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectBudgetShow: {
				budgetInProjectTitlePercent: 'Procentowy podział budżetu w projekcie',
				budgetInProjectTitleTotal: 'Podział budżetu w projekcie',
				budgetInProjectDesc:
					'Przedstawienie graficzne budżetu dla wybranego projektu. Podział opiera się na pracowników, pozostałe wydatki i wolny budżet. Czerwona linia jest dopuszczalnym budżetem.',
				totalBudget: 'Całkowity budżet projektu',
				workersInProjectTitlePercent: 'Procentowy podział budżetu na pracowników',
				workersInProjectTitleTotal: 'Podział budżetu na pracowników',
				workersInProjectDesc:
					'Przedstawienie graficzne budżetu przeznaczonego na pracowników z podziałem na każdego z nich.',
				workersYearsTitle: 'Budżety przeznaczone na pracowników w latach',
				workersYearsDesc:
					'Przedstawienie graficzne budżetu przeznaczonego na każdego z pracowników od początku rejestrowanych rekordów.',
				showPrecentage: 'Pokaż dane w procentach',
				showValue: 'Pokaż dane w pełnych wartościach',
				TaskerUsers: 'Wydatki',
				Income: 'Zyski',
				Free: 'Wolny budżet',
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectBudgetShow: {
				budgetInProjectTitlePercent: 'Percentage breakdown of the project budget',
				budgetInProjectTitleTotal: 'Breakdown of the project budget',
				budgetInProjectDesc:
					'Graphical depiction of the project budget. The allocation is based on employees, other outcomes and available budget. The red line marks the total budget.',
				totalBudget: 'Total budget',
				workersInProjectTitlePercent: 'Percentage breakdown of the budget to employees',
				workersInProjectTitleTotal: 'Breakdown of the budget to employees',
				workersInProjectDesc:
					'Graphical representation of the budget allocated to employees, broken down for each individual.',
				workersYearsTitle: 'Employee budget breakdown over the years.',
				workersYearsDesc:
					'Graphical depiction of the budget allocated to each employee since the beginning of recorded data.',
				showPrecentage: 'Show precentage values',
				showValue: 'Show full values',
				TaskerUsers: 'Expenses',
				Income: 'Income',
				Free: 'Free budget',
			},
		},
	},
};
