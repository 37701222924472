import { ICustomer } from '@skillandchill/tasker-types';
import { IWorkMgtTemplGroup } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/workMgtTemplGroup';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	template: [] as IWorkMgtTemplGroup[],
	//////////Options///////////
	customers: [] as ICustomer[],
	//////////Options///////////
};
export type IProjectCreateEditorState = typeof State;

const projectCreateReducer = (state = State, action: ObjectAction): IProjectCreateEditorState => {
	switch (action?.type) {
		case actionTypes.CUSTOMER_FETCH_LIST_NEW: {
			return {
				...state,
				customers: action.customers,
			};
		}
		case actionTypes.FETCH_TEMPLATE_OPTIONS: {
			return {
				...state,
				template: action?.templates,
			};
		}
		default: {
			return state;
		}
	}
};

export default projectCreateReducer;
