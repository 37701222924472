export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					Activity: {
						Avatars: {
							add: 'Stworzyłeś zgłoszenie',
							assigned: 'Zostałeś przypisany do zgłoszenia',
							portalIssue: 'Nowe zgłoszenie portalowe',
						},
					},
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					Activity: {
						Avatars: {
							add: 'You have created a issue',
							assigned: 'You have been assigned to the issue',
							portalIssue: 'New portal issue',
						},
					},
				},
			},
		},
	},
};
