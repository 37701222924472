import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	Activity: {
		portal: 'portal',
		portalLink: (id: number | string) => `/portal/${id}`,
		issueLink: (id: number | string) => `?issueId=${id}`,
	},
	issueIdPath: '?issueId=',
	addHash: '#/',
	hover: 'hover' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					Activity: {
						creationDate: 'Data stworzenia',
						issueTitle: 'Nazwa zgłoszenia',
						labelModal: 'Treść komentarza:',
					},
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				ActivityIssues: {
					Activity: {
						creationDate: 'Creation date',
						issueTitle: 'Issue name',
						labelModal: 'Comment content:',
					},
				},
			},
		},
	},
};

export enum EIssueIconType {
	IssueAdded = 'issue_added',
	PortalIssueAdded = 'portal_issue_added',
	AssignedToIssue = 'assigned_to_issue',
	ContestCreated = 'contest_created',
}

export type SubjectType = 'portal' | 'other';

export enum SubjectTypeEnum {
	Portal = 'portal',
	Other = 'other',
}
