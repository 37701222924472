import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: (x: string | number, y: string | number) => `Version_${x}_${y}_${uuid()}`,
};

export const Translation = {
	pl: {
		Versions: {
			pageTitle: 'Wersje',
		},
	},
	en: {
		Versions: {
			pageTitle: 'Versions',
		},
	},
};
