import clsx from 'clsx';

import { useTrans } from '@/utils/hooks/useTrans';

import { ExtraIssueInfo } from './ExtraIssueInfo';
import { useStyles } from './styles';

export const RightSideIssueData = () => {
	const classes = useStyles();

	const { t } = useTrans('IssueModelNew.IssueModalContent.IssueDetailsPage.RightSideIssueData');

	return (
		<div className={clsx(classes.container, classes.fullHeight, classes.flexRow)}>
			<div className={clsx(classes.innerContainer, classes.fullWidth)}>
				<ExtraIssueInfo />
			</div>
		</div>
	);
};
