import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	list: 'list' as const,
	rounded: 'rounded' as const,
	caption: 'caption' as const,
	warning: 'warning' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							CommentCard: {
								commented: ' dodał komentarz',
								ago: 'temu',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							CommentCard: {
								commented: ' commented',
								ago: 'ago',
							},
						},
					},
				},
			},
		},
	},
};
