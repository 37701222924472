import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	meet: 'Meet',
	string: 'string',
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			ModalTitle: {
				CreateUrl: {
					cancel: 'Anuluj',
					save: 'Zapisz',
					url: 'URL',
					label: 'Nazwa',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			ModalTitle: {
				CreateUrl: {
					cancel: 'Anuluj',
					save: 'Save',
					url: 'URL',
					label: 'Label',
				},
			},
		},
	},
};
