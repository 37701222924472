import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		RolePanel: {
			RolesGrid: {
				RoleCard: {
					description: 'Opis:',
				},
			},
		},
	},
	en: {
		RolePanel: {
			RolesGrid: {
				RoleCard: {
					description: 'Description:',
				},
			},
		},
	},
};
