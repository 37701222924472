import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				PortalTabFilter: {
					TagsFilter: {
						tagTitle: 'Tagi',
						Group: {
							label: 'Grupa tagów',
						},
						label: 'Tag',
					},
				},
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				PortalTabFilter: {
					TagsFilter: {
						tagTitle: 'Tags',
						Group: {
							label: 'Tag group',
						},
						label: 'Tag',
					},
				},
			},
		},
	},
};
