export function applyFetch<T extends { id: number }>(data: T[]): { [x: number]: T } {
	return data.reduce((x: any, y: any) => {
		if (y.id) return { ...x, [y.id]: y };
	}, {});
}

export function applyUpdate<T extends { id: number }>(data: T, current: { [x: number]: T }): { [x: number]: T } {
	return { ...current, [data.id]: { ...current[data.id], ...data } };
}

export function applyFetchI<T extends { Id: number }>(data: T[]): { [x: number]: T } {
	return data.reduce((x, y) => {
		return { ...x, [y.Id]: y };
	}, {});
}

export function applyUpdateI<T extends { Id: number }>(data: T, current: { [x: number]: T }): { [x: number]: T } {
	return { ...current, [data.Id]: { ...current[data.Id], ...data } };
}

export const applyUpdateInList = <T>(
	listElements: (T & { Id: number })[],
	elementToReplace: T & { Id: number }
): T[] => {
	return listElements?.map(listElement => (listElement?.Id == elementToReplace?.Id ? elementToReplace : listElement));
};
