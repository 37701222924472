import { AsyncAction } from '@skillchill/redux-promisify';

import { IIssueUrl } from '@skillandchill/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

const basePath = 'IssueUrl';

export const ISSUE_URL: ActionType = 'ISSUE_URL';
export const ISSUE_URL_RESET: ActionType = 'ISSUE_URL_RESET';

function _issueUrl(data: IIssueUrl): Action {
	return {
		type: ISSUE_URL,
		data,
	};
}

export function createIssueUrl(issueUrl: Partial<IIssueUrl>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_issueUrl((json as IJson)?.data)));
		return axios().post(`${basePath}`, issueUrl);
	};
}

export function updateIssueUrl(issueUrl: Partial<IIssueUrl>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_issueUrl((json as IJson)?.data)));
		return axios().patch(`${basePath}`, issueUrl);
	};
}

export function deleteIssueUrlMeet(issueId: number, issueUrlId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_issueUrl((json as IJson)?.data)));
		return axios().delete(`${basePath}/issue/${issueId}/url/${issueUrlId}`);
	};
}
