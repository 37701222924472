import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ariaValue: 'primary checkbox' as const,
	hashIssue: '#',
	https: 'https://',
	http: 'http://',
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			ModalTitle: {
				createAnother: 'Zgłoś kolejne',
			},
		},
	},
	en: {
		IssueModalEditor: {
			ModalTitle: {
				createAnother: 'Create another',
			},
		},
	},
};
