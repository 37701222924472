import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		popover: {
			width: '470px',
		},
		readAll: {
			width: '20%',
			marginLeft: 'auto',
		},
		readPosition: {
			float: 'right',
			display: 'fit-content',
		},
		showAllButton: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},

		loader: {
			height: theme.spacing(1),
		},

		notificationContainer: {
			maxHeight: '80vh',
			overflowY: 'scroll',
			overflowX: 'clip',
		},
	}),
	{ name: `Dashboard_NotificationsPopover` }
);
