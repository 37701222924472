export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemToggleButton: {
									1: 'Jeśli w danym dniu pracownik miał zapewnione śniadanie, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} od wyliczonej kwoty, zostanie odjęta wartość śniadanie w rozliczeniu delegacji.',
									2: 'Jeśli w danym dniu pracownik miał zapewniony obiad, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} od wyliczonej kwoty, zostanie odjęta wartość obiad w rozliczeniu delegacji.',
									3: 'Jeśli w danym dniu pracownik miał zapewnioną kolację, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} od wyliczonej kwoty, zostanie odjęta wartość kolacja w rozliczeniu delegacji.',
									4: 'Jeśli w danym dniu pracownik miał zapewnioną opiekę medyczną, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} do wyliczonej kwoty, zostanie dodana wartość pobyt w szpitalu w rozliczeniu delegacji.',
									5: 'Jeśli w danym dniu pracownik miał zapewnione zakwaterowanie, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} zakwaterowanie pracownika jest już rozliczone. Brak zaznaczenia oznacza, że pracownik powinien w załączniku przedstawić dokument, w którym wskazuje na samodzielną organizację zakwaterowania oraz kwotę wskazaną w załączniku. W przypadku braku załączenia rachunków, pracownikowi przysługuje ryczałt 150% diety dla noclegów trwających przynajmniej 6 godzin między godzinami 21-7.',
									6: 'Jeśli w danym dniu pracownik miał zapewniony transport, należy zaznaczyć element. Zaznaczenie oznacza, że w dniu {{date}} do wyliczonej kwoty, zostanie dodana wartość transport publiczny w rozliczeniu delegacji.',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemToggleButton: {
									1: 'If the employee was provided with breakfast on a given day, it should be toggled. Marking indicates that on {{date}}, the calculated amount will be reduced by the value of breakfast in the delegation settlement.',
									2: 'If the employee was provided with dinner on a given day, it should be toggled. Marking indicates that on {{date}}, the calculated amount will be reduced by the value of dinner in the delegation settlement.',
									3: 'If the employee was provided with supper on a given day, it should be toggled. Marking indicates that on {{date}}, the calculated amount will be reduced by the value of supper in the delegation settlement.',
									4: 'If the employee was provided with medical care on a given day, it should be toggled. Marking indicates that on {{date}}, the calculated amount will have the value of hospital stay added to the delegation settlement.',
									5: "If the employee had accommodation provided on a given day, the item should be toggled. Toggling means that on {{date}} the employee's accommodation is already settled. The lack of a toggle means that the employee should present a document in the attachment, indicating independent organization of accommodation and the amount indicated in the attachment. In the absence of invoices, the employee is entitled to a 150% flat rate for overnight stays lasting at least 6 hours between 9 pm and 7 am.",
									6: 'If the employee was provided with transportation  on a given day, it should be toggled. Marking indicates that on {{date}}, the calculated amount will have the value of public transportation added to the delegation settlement.',
								},
							},
						},
					},
				},
			},
		},
	},
};
