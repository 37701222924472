import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	temporary: 'temporary' as const,
};

export const Translation = {
	pl: {
		WorkDayEndSummary: {
			Filters: {
				Options: {
					UserFilter: {
						searchByName: 'Wyszukiwanie użytkowników po nazwie',
					},
				},
			},
		},
	},
	en: {
		WorkDayEndSummary: {
			Filters: {
				Options: {
					UserFilter: {
						searchByName: 'Searching for users by name',
					},
				},
			},
		},
	},
};
