import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				// display: 'grid',
				// gridTemplateColumns: '1fr 1fr',
				margin: `${theme.spacing(2)} 0`,
			},

			leftSideContainer: {
				// marginTop: theme.spacing(1),
				// display: 'grid',
				gridTemplateColumns: '1fr',
				width: '70%',
				// width: '600px',

				'& .select__multi-value': {
					// backgroundColor: 'transparent',
					// margin: '0',
					// position: 'relative',

					maxWidth: '130px',
					display: 'flex',
					justifyContent: 'space-between',
				},

				// '& .select__multi-value__label': {
				// 	padding: '0px',
				// 	marginLeft: '2px',
				// },

				// '& .select__multi-value__remove': {
				// 	position: 'absolute',
				// 	right: '0px',
				// 	top: '-8px',
				// 	// backgroundColor: 'white',
				// 	padding: '2px',
				// 	borderRadius: '50%',
				// 	cursor: 'pointer',
				// 	backgroundColor: 'rgba(230,230,230)',
				// 	border: '2px solid white',

				// 	'&:hover': {
				// 		backgroundColor: 'rgba(200,200,200)',
				// 	},
				// },
			},

			// profileSelector: {
			// 	paddingLeft: '10px',
			// },

			// select: {
			// 	'& .select__value-container ': {
			// 		padding: `0 ${theme.spacing(1)}`,
			// 	},

			// 	'& .select__control': {
			// 		minHeight: '34px',
			// 		backgroundColor: 'white',
			// 		border: '2px solid #e3e3e3',
			// 		borderRadius: '5px',
			// 		boxShadow: 'none',

			// 		'&--is-focused': {
			// 			border: '2px solid #33b35c',
			// 		},

			// 		'& .select__single-value ': {
			// 			fontSize: '15px',
			// 		},

			// 		'& .select__indicator': {
			// 			padding: '4px',
			// 		},
			// 	},

			// 	'& .basic-multi-select': {
			// 		margin: '0',
			// 	},
			// },

			// avatarSelect: {
			// 	'& .select__control': {
			// 		border: 'none',
			// 		boxShadow: 'none',
			// 	},

			// 	'& .select__value-container': {
			// 		padding: '0px',
			// 		overflow: 'visible',
			// 	},
			// },
		};
	},
	{ name: `IssueModalEditor` }
);
