import { Translation as ReckonAgreementsAgreementDetailsAgreementDetailsGroup } from './Agreements/AgreementDetails/AgreementDetailsGroup/resources';
import { Translation as ReckonAgreementsAgreementDetailsCardHeader } from './Agreements/AgreementDetails/CardHeader/resources';
import { Translation as ReckonAgreementsAgreementDetailsCardHeaderReturnToAgreementsList } from './Agreements/AgreementDetails/CardHeader/ReturnToAgreementList/resources';
import { Translation as ReckonAgreementsAgreementDetailsContractedPersonDetailsGroup } from './Agreements/AgreementDetails/ContractedPersonDetailsGroup/resources';
import { Translation as ReckonAgreements } from './Agreements/AgreementDetails/resources';
import { Translation as ReckonAgreementsAgreementEditAddNewSalaryButton } from './Agreements/AgreementEdit/AddNewSalaryButton/resources';
import { Translation as ReckonAgreementsAgreementEditFieldsRequiredHelperText } from './Agreements/AgreementEdit/FieldsRequiredHelperText/resources';
import { Translation as ReckonAgreementAgreementEditHeaderSection } from './Agreements/AgreementEdit/HeaderSection/resources';
import { Translation as ReckonAgreementsAgreementEditHooksUseSalariesForm } from './Agreements/AgreementEdit/hooks/useSalariesForm/resources';
import { Translation as ReckonAgreementForm } from './Agreements/AgreementEdit/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormNavSectionFormSteps } from './Agreements/AgreementEdit/SalaryForm/NavSection/FormSteps/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormNavSection } from './Agreements/AgreementEdit/SalaryForm/NavSection/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormSalaryAmountStep } from './Agreements/AgreementEdit/SalaryForm/SalaryAmountStep/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormSalaryDescriptionStep } from './Agreements/AgreementEdit/SalaryForm/SalaryDescriptionStep/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormSalarySummaryStep } from './Agreements/AgreementEdit/SalaryForm/SalarySummaryStep/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormSalaryTimeStep } from './Agreements/AgreementEdit/SalaryForm/SalaryTimeStep/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryFormSalaryTypeStep } from './Agreements/AgreementEdit/SalaryForm/SalaryTypeStep/resources';
import { Translation as ReckonAgreementsAgreementEditSalaryTopButtons } from './Agreements/AgreementEdit/SalaryTopButtons/resources';
import { Translation as ReckonAgreementsAgreementsListAppliedFilters } from './Agreements/AgreementsList/ApplitedFilters/resources';
import { Translation as ReckonAgreementsAgreementsListFiltersAgreementSignedDateFilter } from './Agreements/AgreementsList/Filters/AgreementSignedDateFilter/resources';
import { Translation as ReckonAgreementsAgreementsListFiltersContractTimeFilter } from './Agreements/AgreementsList/Filters/ContractTimeFilter/resources';
import { Translation as ReckonAgreementsAgreementsListFilters } from './Agreements/AgreementsList/Filters/resources';
import { Translation as ReckonAgreementsAgreementsListFiltersStatusFilter } from './Agreements/AgreementsList/Filters/StatusFilter/resources';
import { Translation as ReckonAgreementsAgreementsListHeaderCell } from './Agreements/AgreementsList/HeaderCell/resources';
import { Translation as ReckonAgreementsAgreementDetails } from './Agreements/AgreementsList/resources';
import { Translation as ReckonAgreementsAgreementsListShared } from './Agreements/AgreementsList/shared/utils/resources';
import { Translation as ReckonAgreementsSingleAgreementActionsCell } from './Agreements/AgreementsList/SingleAgreement/ActionsCell/resources';
import { Translation as ReckonAgreementsSingleAgreementContractTimeCell } from './Agreements/AgreementsList/SingleAgreement/ContractTimeCell/resources';
import { Translation as ReckonAgreementsAgreementsListTableOptions } from './Agreements/AgreementsList/TableOptions/resources';
import { Translation as ReckonAgreementsSharedComponentsErrorIndicator } from './Agreements/Shared/components/ErrorIndicator/resources';
import { Translation as ReckonAgreementsSharedComponentsInputsContainer } from './Agreements/Shared/components/InputsContainer/resources';
import { Translation as ReckonAgreementsSharedComponentsInputsContainerSalaryInputsContainerLabel } from './Agreements/Shared/components/InputsContainer/SalaryInputsContainerLabel/resources';
import { Translation as ReckonAgreementsShared } from './Agreements/Shared/translations';
import { Translation as Reckon } from './resources';

export const ReckonTranslationEN = [
	Reckon.en,
	ReckonAgreements.en,
	ReckonAgreementsSingleAgreementActionsCell.en,
	ReckonAgreementForm.en,
	ReckonAgreementsShared.en,
	ReckonAgreementsAgreementDetailsAgreementDetailsGroup.en,
	ReckonAgreementsAgreementDetailsCardHeader.en,
	ReckonAgreementsAgreementDetailsContractedPersonDetailsGroup.en,
	ReckonAgreementsAgreementDetailsCardHeaderReturnToAgreementsList.en,
	ReckonAgreementsAgreementDetails.en,
	ReckonAgreementsAgreementEditAddNewSalaryButton.en,
	ReckonAgreementsSharedComponentsInputsContainer.en,
	ReckonAgreementAgreementEditHeaderSection.en,
	ReckonAgreementsAgreementEditSalaryTopButtons.en,
	ReckonAgreementsAgreementsListTableOptions.en,
	ReckonAgreementsAgreementsListAppliedFilters.en,
	ReckonAgreementsAgreementsListFilters.en,
	ReckonAgreementsAgreementsListFiltersAgreementSignedDateFilter.en,
	ReckonAgreementsAgreementsListFiltersContractTimeFilter.en,
	ReckonAgreementsAgreementsListFiltersStatusFilter.en,
	ReckonAgreementsAgreementEditSalaryFormSalaryTypeStep.en,
	ReckonAgreementsAgreementEditSalaryFormSalaryAmountStep.en,
	ReckonAgreementsAgreementEditSalaryFormSalaryTimeStep.en,
	ReckonAgreementsAgreementEditSalaryFormSalaryDescriptionStep.en,
	ReckonAgreementsAgreementEditSalaryFormSalarySummaryStep.en,
	ReckonAgreementsAgreementEditSalaryFormNavSection.en,
	ReckonAgreementsAgreementEditSalaryFormNavSectionFormSteps.en,
	ReckonAgreementsAgreementEditFieldsRequiredHelperText.en,
	ReckonAgreementsSharedComponentsInputsContainerSalaryInputsContainerLabel.en,
	ReckonAgreementsAgreementsListHeaderCell.en,
	ReckonAgreementsAgreementsListShared.en,
	ReckonAgreementsSingleAgreementContractTimeCell.en,
	ReckonAgreementsAgreementEditHooksUseSalariesForm.en,
	ReckonAgreementsSharedComponentsErrorIndicator.en,
];

export const ReckonTranslationPL = [
	Reckon.pl,
	ReckonAgreements.pl,
	ReckonAgreementsSingleAgreementActionsCell.pl,
	ReckonAgreementForm.pl,
	ReckonAgreementsShared.pl,
	ReckonAgreementsAgreementDetailsAgreementDetailsGroup.pl,
	ReckonAgreementsAgreementDetailsCardHeader.pl,
	ReckonAgreementsAgreementDetailsContractedPersonDetailsGroup.pl,
	ReckonAgreementsAgreementDetailsCardHeaderReturnToAgreementsList.pl,
	ReckonAgreementsAgreementDetails.pl,
	ReckonAgreementsAgreementEditAddNewSalaryButton.pl,
	ReckonAgreementsSharedComponentsInputsContainer.pl,
	ReckonAgreementAgreementEditHeaderSection.pl,
	ReckonAgreementsAgreementEditSalaryTopButtons.pl,
	ReckonAgreementsAgreementsListTableOptions.pl,
	ReckonAgreementsAgreementsListAppliedFilters.pl,
	ReckonAgreementsAgreementsListFilters.pl,
	ReckonAgreementsAgreementsListFiltersAgreementSignedDateFilter.pl,
	ReckonAgreementsAgreementsListFiltersContractTimeFilter.pl,
	ReckonAgreementsAgreementsListFiltersStatusFilter.pl,
	ReckonAgreementsAgreementEditSalaryFormSalaryTypeStep.pl,
	ReckonAgreementsAgreementEditSalaryFormSalaryAmountStep.pl,
	ReckonAgreementsAgreementEditSalaryFormSalaryTimeStep.pl,
	ReckonAgreementsAgreementEditSalaryFormSalaryDescriptionStep.pl,
	ReckonAgreementsAgreementEditSalaryFormSalarySummaryStep.pl,
	ReckonAgreementsAgreementEditSalaryFormNavSection.pl,
	ReckonAgreementsAgreementEditSalaryFormNavSectionFormSteps.pl,
	ReckonAgreementsAgreementEditFieldsRequiredHelperText.pl,
	ReckonAgreementsSharedComponentsInputsContainerSalaryInputsContainerLabel.pl,
	ReckonAgreementsAgreementsListHeaderCell.pl,
	ReckonAgreementsAgreementsListShared.pl,
	ReckonAgreementsSingleAgreementContractTimeCell.pl,
	ReckonAgreementsAgreementEditHooksUseSalariesForm.pl,
	ReckonAgreementsSharedComponentsErrorIndicator.pl,
];
