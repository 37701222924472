import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	mainKey: 'UserList',
	password: 'password' as const,
	newPassword: 'new-password' as const,
	new: 'new' as const,
	usersPath: '/admin/organization/tabs/users' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			PasswordPolicy: {
				settings: 'Ustawienia',
				maxLifetime: 'Maksymalny czas życia hasła (dni)',
				minDigit: 'Minimalna liczba cyfr',
				minLowerCase: 'Minimalna liczba małych liter',
				minUpperCase: 'Minimalna liczba dużych liter',
				minLength: 'Minimalna długość hasła',
				minSpecialChar: 'Minimalna ilość znaków specjalnych',
				success: 'Pomyślnie zapisano',
				inactive: 'Pole nieaktywne',
			},
		},
	},
	en: {
		OrganizationPanel: {
			PasswordPolicy: {
				settings: 'Settings',
				maxLifetime: 'Maximum password lifetime (days)',
				minDigit: 'Minimum number of digits',
				minLowerCase: 'Minimum number of lowercase letters',
				minUpperCase: 'Minimum number of uppercase letters',
				minLength: 'Minimum password length',
				minSpecialChar: 'Minimum number of special characters',
				success: 'Successfully saved',
				inactive: 'Field inactive',
			},
		},
	},
};
