import { ICustomer, IFieldDefinition, IIssueForm, IProject, ITag } from '@skillandchill/tasker-types';
import {
	IFilterCustomer,
	IFilterForm,
	IFilterProject,
	IFilterSimplifiedCustomer,
	IFilterSimplifiedProject,
} from '@skillandchill/tasker-types/dist/types/endPoints/filter';

export const getDataFromCustomerFiltersSimplify = (
	data: IFilterSimplifiedCustomer[]
): { Project: IProject[]; Customer: ICustomer[]; IssueForm: IIssueForm[]; Tag: Partial<ITag>[] } => {
	const Project: IProject[] = [];
	const Customer: ICustomer[] = [];
	const IssueForm: IIssueForm[] = [];
	let Tag: Partial<ITag>[] = [];

	for (let i = 0; i < data?.length; i++) {
		const customer: IFilterSimplifiedCustomer = data[i];
		Customer.push({ ...customer, Projects: undefined });
		for (let j = 0; j < customer.Projects?.length; j++) {
			const project: IFilterSimplifiedProject = customer.Projects[j];
			const formattedProject = { ...project, Tag: undefined };
			Project.push(formattedProject);
			Tag = Tag.concat(project.Tag);
		}
	}

	return { Project, Customer, IssueForm, Tag };
};

export const getDataCustomerFilters = (
	data: IFilterCustomer[]
): { Project: IProject[]; Customer: ICustomer[]; IssueForm: IIssueForm[]; Tag: Partial<ITag>[] } => {
	const Project: IProject[] = [];
	const Customer: ICustomer[] = [];
	const IssueForm: IIssueForm[] = [];
	let Tag: Partial<ITag>[] = [];

	for (let i = 0; i < data?.length; i++) {
		const customer: IFilterCustomer = data[i];
		Customer.push({ ...customer, Projects: undefined });
		for (let j = 0; j < customer.Projects?.length; j++) {
			const project: IFilterProject = customer.Projects[j];
			const formattedProject = { ...project, IssueForm: undefined };
			Project.push(formattedProject);
			for (let k = 0; k < project.IssueForm?.length; k++) {
				const issueForm: IFilterForm = project.IssueForm[k];
				const formattedIssueForm = { ...issueForm, Tag: undefined };
				IssueForm.push(formattedIssueForm);
				for (let l = 0; l < issueForm.Tag?.length; l++) {
					const fieldDefinition: IFieldDefinition = issueForm.Tag[l];
					const tags = fieldDefinition?.TagGroup?.Tag;
					if (tags && tags?.length > 0) Tag = Tag.concat(tags);
				}
			}
		}
	}

	return { Project, Customer, IssueForm, Tag };
};
