import { addMinutes, addSeconds, formatDistanceStrict } from 'date-fns';
import { Theme } from 'react-select';

import { EIssueRole, IRoleToIssueRole } from '@skillandchill/tasker-types';

import { getLanguage } from '@/utils/configuration';

export const collapseEndListener = (): void => {};

const fallbackCopyTextToClipboard = (text: string): void => {
	const textArea = document.createElement('textarea');
	textArea.value = text;

	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.select();
	textArea.focus();
	try {
		const successful = document.execCommand('copy');
		const msg = successful ? 'successful' : 'unsuccessful';
		console.log('Fallback: Copying text command was ' + msg);
	} catch (err) {
		console.error('Fallback: Oops, unable to copy', err);
	}
	document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text: string, container: HTMLTextAreaElement | undefined): void => {
	if (!navigator.clipboard || !container) {
		fallbackCopyTextToClipboard(text);
		return;
	}
	container.focus();
	container.select();
	navigator.clipboard.writeText(text).then(
		() => console.log('Async: Copying to clipboard was successful!'),
		err => console.error('Async: Could not copy text: ', err)
	);
};

export const calculateTheme = (theme: Theme): Theme => {
	const primary25 = 'rgba(75,253,0,0.71)';
	const primaryColor = '#33b35c';
	return {
		...theme,
		colors: {
			...theme.colors,
			primary25: primary25,
			primary: primaryColor,
		},
	};
};

export const formatSeconds = (totalSeconds: number): string => {
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;

	const minutesInString = formatDistanceStrict(new Date(), addMinutes(new Date(), minutes), {
		unit: 'minute',
		locale: getLanguage(),
	});

	const secondsInString = formatDistanceStrict(new Date(), addSeconds(new Date(), seconds), {
		unit: 'second',
		locale: getLanguage(),
	});

	return `${minutes == 1 ? 1 : ''} ${minutes > 0 ? minutesInString : ''} ${secondsInString}`;
};

export const getFirst = <T>(list: T[]): T => {
	if (list?.length > 0) return list[0];
	return {} as T;
};

export function showTimer(issueRoles: IRoleToIssueRole[] | undefined, loggedUserId: number): boolean {
	return (issueRoles?.filter(x => filterAtShowTimer(x, loggedUserId)) ?? [])?.length > 0;
}

function filterAtShowTimer(data: IRoleToIssueRole, loggedUserId: number): boolean {
	return (
		data?.TaskerUserId == loggedUserId &&
		(data?.IssueRoleId == EIssueRole.Assigned || data?.IssueRoleId == EIssueRole.Auditor)
	);
}
