import { Translation as Roles } from './resources';
import { Translation as RoleAssignment } from './TabsContent/RoleAssignment/resources';
import { Translation as FeatureSelection } from './TabsContent/RoleConfiguration/FeatureSelection/resources';
import { Translation as PermissionManagement } from './TabsContent/RoleConfiguration/PermissionManagement/resources';
import { Translation as RoleConfiguration } from './TabsContent/RoleConfiguration/resources';
import { Translation as RoleManagement } from './TabsContent/RoleConfiguration/RoleManagement/resources';

export const RolesEN = [
	Roles.en,
	RoleConfiguration.en,
	RoleManagement.en,
	FeatureSelection.en,
	PermissionManagement.en,
	RoleAssignment.en,
];

export const RolesPL = [
	Roles.pl,
	RoleConfiguration.pl,
	RoleManagement.pl,
	FeatureSelection.pl,
	PermissionManagement.pl,
	RoleAssignment.pl,
];
