import { IDictionary, IDictionaryItem, IDictionaryOption, ITagGroup } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	dictionary: {} as IDictionary,
	dictionaryItems: [] as IDictionaryItem[],
	allDictionariesForSelectField: [] as IDictionaryOption[],
	allTagGroupsForSelectField: [] as ITagGroup[],
	dictionaryItemsCount: 0 as number,
};
export type IDictionariesContentState = typeof State;

const dictionariesContentReducer = (state = State, action: ObjectAction): IDictionariesContentState => {
	switch (action.type) {
		case actionTypes.SET_ONE_DICTIONARY: {
			return {
				...state,
				dictionary: action.dictionary,
			};
		}

		case actionTypes.CREATE_DICTIONARYITEM_NEW: {
			return {
				...state,
				dictionaryItems: [
					action.dictionaryItem,
					...state.dictionaryItems.filter(dictionaryItem => dictionaryItem.Id !== -1),
				],
			};
		}

		case actionTypes.DELETE_DICTIONARYITEMS_WITH_DEFAULTVALUES: {
			return {
				...state,
				dictionaryItems: [...state.dictionaryItems.filter(dictionaryItem => dictionaryItem.Id !== -1)],
			};
		}

		case actionTypes.GET_ALL_DICTIONARIES_AND_TAG_GROUPS: {
			return {
				...state,
				allDictionariesForSelectField: [...action.allDictionariesIdAndLabel],
				allTagGroupsForSelectField: [...action.allTagGroupsIdAndLabel],
			};
		}

		case actionTypes.SET_DICTIONARY_ITEMS: {
			return {
				...state,
				dictionaryItems: [
					...state.dictionaryItems.filter(dictionaryItem => dictionaryItem.Id === -1),
					...(action?.dictionaryItems ?? []),
				],
				dictionaryItemsCount: action.dictionaryItemsCount,
			};
		}

		case actionTypes.CLEAR_ALL_DICTIONARYITEMS: {
			return {
				...state,
				dictionaryItems: [],
				dictionaryItemsCount: 0,
			};
		}

		default: {
			return state;
		}
	}
};

export default dictionariesContentReducer;
