import React from 'react';
import { components } from 'react-select';

import { Checkbox } from '@mui/material';

import { useStyles } from './styles';

export const Option = (props: any) => {
	const classes = useStyles();

	return (
		<components.Option {...props}>
			<div className={classes.flexYCenter}>
				<Checkbox checked={props.isSelected} className={classes.checkbox} />
				{props.children}
			</div>
		</components.Option>
	);
};
