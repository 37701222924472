import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	projectPath: 'projects/',
};

export const Translation = {
	pl: {
		ProjectList: {
			TileComponents: {
				DetailsButton: {
					details: 'Szczegóły',
				},
			},
		},
	},
	en: {
		ProjectList: {
			TileComponents: {
				DetailsButton: {
					details: 'Details',
				},
			},
		},
	},
};
