import { IDictionary, IDictionaryOption, ITagGroup } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	dictionaries: [] as IDictionary[],
	dictionariesCount: 0 as number,

	dictionary: {} as IDictionary,
	allDictionariesForSelectField: [] as IDictionaryOption[],
	allTagGroupsForSelectField: [] as ITagGroup[],
};
export type IDictionariesState = typeof State;

const dictionariesReducer = (state = State, action: ObjectAction): IDictionariesState => {
	switch (action.type) {
		case actionTypes.SET_DICTIONARY_PAGE: {
			return {
				...state,
				dictionaries: [
					...state.dictionaries.filter(dictionary => dictionary.Id === -1),
					...action.dictionaries,
				],
				dictionariesCount: action.count,
			};
		}

		case actionTypes.CREATE_DICTIONARY_NEW_WITH_DEFAULTVALUES: {
			return {
				...state,
				dictionaries: [action.dictionary, ...state.dictionaries],
			};
		}

		case actionTypes.DELETE_DICTIONARY_WITH_DEFAULTVALUES: {
			return {
				...state,
				dictionaries: [...state.dictionaries.filter(dictionary => dictionary.Id !== -1)],
			};
		}

		case actionTypes.SET_ONE_DICTIONARY: {
			return {
				...state,
				dictionary: action.dictionary,
			};
		}

		case actionTypes.GET_ALL_DICTIONARIES_AND_TAG_GROUPS: {
			return {
				...state,
				allDictionariesForSelectField: [...action.allDictionariesIdAndLabel],
				allTagGroupsForSelectField: [...action.allTagGroupsIdAndLabel],
			};
		}

		default: {
			return state;
		}
	}
};

export default dictionariesReducer;
