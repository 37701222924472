import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	() => ({
		...getGlobalStyles(),
		avatar: {
			marginRight: '5px',
			marginTop: '5px',
			width: 20,
			height: 20,
		},
		title: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontWeight: 500,
		},
		avatarContainer: {
			alignItems: 'center',
			paddingBottom: '4px',
		},
	}),
	{ name: `Dashboard_WorklogStarter_MultiIssueInfo` }
);
