import { AsyncAction } from '@skillchill/redux-promisify';

import { IDelegationToDictionaries } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/delegationToDictionaries';
import { IDelegationToDictionariesGet } from '@skillandchill/tasker-types/dist/types/endPoints/delegationToDictionaries';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const DELEGATION_TO_DICTIONARIES_FETCH_DATA: ActionType = 'DELEGATION_TO_DICTIONARIES_FETCH_DATA';
export const DELEGATION_TO_DICTIONARIES_UPDATE_DATA: ActionType = 'DELEGATION_TO_DICTIONARIES_UPDATE_DATA';

const basePath = 'DelegationToDictionaries';

function _getData(data: IDelegationToDictionariesGet): Action {
	return {
		type: DELEGATION_TO_DICTIONARIES_FETCH_DATA,
		data,
	};
}

function _manage(data: IDelegationToDictionaries): Action {
	return {
		type: DELEGATION_TO_DICTIONARIES_UPDATE_DATA,
		data,
	};
}

export function delegationToDictionaries_getData(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getData((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function delegationToDictionaries_updateData(data: Partial<IDelegationToDictionaries>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_manage((json as IJson).data)));
		return axios().post(`${basePath}/manage`, { ...data });
	};
}
