import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources.js';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								typeLabel: 'Typ wartości',
								type1: 'Wartość logiczna',
								type2: 'Liczba całkowita',
								type3: 'Liczba dziesiętna',
								type4: 'Wartość tekstowa',
								type7: 'Data',
								type8: 'Data i czas',
								itemTypeLabel: 'Rodzaj wartości',
								itemType1: 'Załącznik zakwaterowania',
								itemType2: 'Inny',
								itemType3: 'Samochód prywatny do 900 cm3',
								itemType4: 'Samochód prywatny powyżej 900 cm3',
								itemType5: 'Samochód wypożyczony do 900 cm3',
								itemType6: 'Samochód wypożyczony powyżej 900 cm3',
								itemType8: 'Samolot',
								itemType7: 'Inny',
								itemValue: 'Wartość',
								itemCreationDate: 'Data utworzenia',
								itemCreatedBy: 'Utworzona przez',
								menuTooltip: 'Opcje',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemList: {
								typeLabel: 'Value type',
								type1: 'Logical value',
								type2: 'Integer',
								type3: 'Decimal',
								type4: 'Text value',
								type7: 'Date',
								type8: 'Date and time',
								itemTypeLabel: 'Value kind',
								itemType1: 'Accommodation attachment',
								itemType2: 'Other',
								itemType3: 'Private car up to 900 cm3',
								itemType4: 'Private car above 900 cm3',
								itemType5: 'Rented car up to 900 cm3',
								itemType6: 'Rented car above 900 cm3',
								itemType8: 'Aircraft',
								itemType7: 'Other',
								itemValue: 'Value',
								itemCreationDate: 'Creation date',
								itemCreatedBy: 'Created by',
								menuTooltip: 'Options',
							},
						},
					},
				},
			},
		},
	},
};
