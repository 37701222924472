import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,

	row: 'row' as const,
	wrap: 'wrap' as const,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					AppliedFilters: {
						or: 'lub',
						since: 'Od',
						untilIndefinitePeriod: 'do na czas nieokreślony',
						until: 'do',
						clearFilters: 'Wyczyść filtry',
						signedDate: 'Data zawarcia umowy:',
						contractTime: 'Okres umowy:',
						agreementStatus: 'Status umowy:',
						agreementType: 'Typ umowy:',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					AppliedFilters: {
						or: 'or',
						since: 'Since',
						untilIndefinitePeriod: 'for indefinite period',
						until: 'until',
						clearFilters: 'Clear filters',
						signedDate: 'Agreement signing date:',
						contractTime: 'Agreement period:',
						agreementStatus: 'Agreement status:',
						agreementType: 'Agreement type:',
					},
				},
			},
		},
	},
};
