import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { IReducerState } from '@/store/reducers/types';

import { Comments } from './Comments';
import { StyleProps } from './model';
import { useStyles } from './styles';

export const IssueComments = () => {
	const comments = useSelector((state: IReducerState) => state?.IssueModalEditor?.comments);

	const styleProps: StyleProps = {
		extraPadding: !!comments?.length,
	};

	const classes = useStyles(styleProps);

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	return (
		<div className={clsx(classes.flexColumn, classes.fullWidth)}>
			<Comments issueIsPortal={issue?.IsPortal ?? false} issueId={issue?.Id as number} />
		</div>
	);
};
