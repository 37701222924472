import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mousedown: 'mousedown' as const,
	onBlur: 'onBlur',
	menuPortalTarget: 'menuPortalTarget',
	menuIsOpen: 'menuIsOpen',
	openMenuOnClick: 'openMenuOnClick',
	body: 'body',
	ref: 'ref',
	issue: 'Issue',
};

export const multiValueStyles = {
	fontWeight: '600',
	borderRadius: '7px',
};

export const multiValueRemove = {
	color: 'white',
	cursor: 'pointer',
};
