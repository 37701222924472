import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	user: '/user/',
	key: 'Profile_',
	keys: {
		link: '_link_',
		avatar: '_avatar_',
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			ProfileSelector: {
				clear: 'Wyczyść zaznaczone',
				unassigned: 'Nieprzypisane',
			},
		},
	},
	en: {
		SearchBar: {
			ProfileSelector: {
				clear: 'Clear all selected',
				unassigned: 'Unassigned',
			},
		},
	},
};
