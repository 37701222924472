import { IMeasureKind } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { getMeasureKindPLLabel } from 'view/CustomerDetail/Content/Invoices/utils';

export const Resources = {
	...GlobalResources,
	measureKind: (x: IMeasureKind) => `MeasureKind${x?.Label}_${x?.Id}`,
	number: 'number' as const,
	MeasureKindLabel: (id: number): string => getMeasureKindPLLabel(id),
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							ItemsInEdit: {
								name: 'Nazwa',
								quantity: 'Ilość',
								netPrice: 'Netto za sztukę',
								grossPrice: 'Brutto za sztukę',
								measureKind: 'Jednostki',
								vat: 'Vat',
								save: 'Zapisz przedmiot faktury',
								cancel: 'Anuluj',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							ItemsInEdit: {
								name: 'Name',
								quantity: 'Quantity',
								netPrice: 'Net price unit',
								grossPrice: 'Gross price unit',
								measureKind: 'Measure kind',
								vat: 'Vat',
								save: 'Save invoice item',
								cancel: 'Cancel',
							},
						},
					},
				},
			},
		},
	},
};
