import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		flag: {
			width: theme.spacing(3),
			padding: '1px',
			background: `${theme.palette.lightGray.main}80`,
			borderRadius: '1px',
			marginRight: theme.spacing(1),
		},

		button: {
			height: theme.spacing(5),
			color: theme.palette.lightGray.main,
			minWidth: 'auto',
			padding: theme.spacing(0, 1.125),
			gap: theme.spacing(0.5),
			'&:hover': {
				backgroundColor: `${theme.palette.lightGray.main}10`,
			},
		},
	}),
	{ name: `Dashboard_TopBar_Flag` }
);
