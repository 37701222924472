import { t } from 'i18next';

import { IIssue } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';
import { OptionProps } from '@skillandchill/tasker-widgets2/dist/widgets/SelectTextField/model';

export const Resources = {
	connector: '-',
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				Relation: {
					children: 'Zgłoszenia niższego rzędu',
					parent: 'Zgłoszenia wyższego rzędu',
					link: 'Dodaj powiązanie',
					tile: {
						project: 'Projekt',
						customer: 'Klient',
					},
					error: 'Niewystarczające dane do stworzenia relacji',
					successSave: 'Zapisano relacje',
					moreRecords: 'Istnieje więcej rekordów aby je zobaczyć wpisz więcej znaków',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			Tabs: {
				Relation: {
					children: 'Children issues',
					parent: 'Parent issues',
					link: 'Link issue',
					tile: {
						project: 'Project',
						customer: 'Klient',
					},
					error: 'Not enough data to create connection',
					successSave: 'Relation saved',
					moreRecords: 'There is more records to find your please type more characters',
				},
			},
		},
	},
};

export const getInfoRecord = (): OptionProps<IIssue> => {
	return {
		label: t('IssueModalEditor.Tabs.Relation.moreRecords'),
		value: t('IssueModalEditor.Tabs.Relation.moreRecords'),
		isDisabled: true,
	};
};
