import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	delegation: (id: number) => `/${id}`,
};

export const Translation = {
	pl: {
		DelegationsAcceptanceList: {
			pageTitle: 'Lista akceptowalnych delegacji',
			name: 'Nazwa',
			fetchError: 'Wystąpił błąd podczas pobierania danych.',
			requestToCorrectionSuccess: 'Delegacja został wysłana do poprawy.',
			requestToCorrectionError: 'Wystąpił błąd podczas wysyłania delegacji do poprawy.',
			requestToSettlementAcceptanceSuccess: 'Delegacja została wysłana do akceptacji rozliczenia.',
			requestToSettlementAcceptanceError: 'Wystąpił błąd podczas wysyłania delegacji do akceptacji rozliczenia.',
			requestToDoneSuccess: 'Delegacja została zakończona.',
			requestToDoneError: 'Wystąpił błąd podczas zakończania delegacji.',
			requestToReturnAcceptanceSuccess: 'Delegacja została zwrócona do akceptacji.',
			requestToReturnAcceptanceError: 'Wystąpił błąd podczas zwracania delegacji do akceptacji.',
		},
	},
	en: {
		DelegationsAcceptanceList: {
			pageTitle: 'List of acceptable delegations',
			name: 'Name',
			fetchError: 'An error occurred while fetching data.',
			requestToCorrectionSuccess: 'The delegation has been sent for correction.',
			requestToCorrectionError: 'An error occurred while sending the delegation for correction.',
			requestToSettlementAcceptanceSuccess: 'The delegation has been sent for settlement acceptance.',
			requestToSettlementAcceptanceError:
				'An error occurred while sending the delegation for settlement acceptance.',
			requestToDoneSuccess: 'The delegation has been completed.',
			requestToDoneError: 'An error occurred while completing the delegation.',
			requestToReturnAcceptanceSuccess: 'The delegation has been returned for acceptance.',
			requestToReturnAcceptanceError: 'An error occurred while returning the delegation for acceptance.',
		},
	},
};
