export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					Name: {
						name: 'Nazwa',
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					Name: {
						name: 'Label',
					},
				},
			},
		},
	},
};
