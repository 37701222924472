import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				Shared: {
					Components: {
						InputsContainer: {
							deleteSalary: 'Usuń wynagrodzenie',
							salaryValidIn: 'Obowiązuje w okresie:',
							resetForm: 'Zresetuj formularz wynagrodzenia do stanu początkowego',
							Description: 'Opis:',
							deleteAgreementConfirmationData: {
								title: 'Czy na pewno chcesz usunąć wynagrodzenie?',
								subTitle: 'Usunięcie wynagrodzenia jest pernamentne i nieodwracalne.',
								submitText: 'Tak',
								cancelText: 'Nie',
							},
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				Shared: {
					Components: {
						InputsContainer: {
							deleteSalary: 'Delete salary',
							salaryValidIn: 'Salary valid in:',
							resetForm: 'Reset form to its initial form',
							Description: 'Description:',
							deleteAgreementConfirmationData: {
								title: 'Are you sure you want to delete the salary?',
								subTitle: 'Deleting the salary is permanent and irreversible.',
								submitText: 'Yes',
								cancelText: 'No',
							},
						},
					},
				},
			},
		},
	},
};

export const defaultConfirmationMessageData = {
	title: '',
};
