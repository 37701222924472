import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	anchorOrigin: {
		horizontal: 'right',
		vertical: 'bottom',
	},
	settings: '/settings/general',
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					CardContentCustom: {
						avatar: {
							edit: 'Edytuj zdjęcie profilowe',
							add: 'Dodaj zdjęcie profilowe',
						},
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					CardContentCustom: {
						avatar: {
							edit: 'Edit avatar',
							add: 'Add avatar',
						},
					},
				},
			},
		},
	},
};
