import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed',
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					BasicIssueDate: {
						issueNo: 'Numer zadania',
						issueName: 'Nazwa zgłoszenia',
						assigned: 'Osoby wykonujące',
						observer: 'Obserwatorzy',
						reporter: 'Osoby zgłaszające',
						auditor: 'Osoby weryfikujące',
						project: 'Projekt',
						issueType: 'Typ zgłoszenia',
					},
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					BasicIssueDate: {
						issueNo: 'Issue number',
						issueName: 'Issue name',
						assigned: 'Assigned',
						observer: 'Observer',
						reporter: 'Reporter',
						auditor: 'Auditor',
						project: 'Project',
						issueType: 'Issue type',
					},
				},
			},
		},
	},
};
