import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	iconId: 'markAsReadIcon',
	rounded: 'rounded' as const,
	readStatusColumnId: 'readStatusColumn',
	linkClasses: '.userLink, .instanceObjectName',
	click: 'click',
};
export const Translation = {
	pl: {
		NotificationPopover: {
			Notification: {
				hasBeenRead: 'Odczytano',
				markAsRead: 'Oznacz jako przeczytane',
				criticalPriority: 'Bardzo wysoki priorytet',
				highPriority: 'Wysoki priorytet',
				mediumPriority: 'Średni priorytet',
				lowPriority: 'Niski priorytet',
			},
		},
	},
	en: {
		NotificationPopover: {
			Notification: {
				hasBeenRead: 'Has been read',
				markAsRead: 'Mark as read',
				criticalPriority: 'Critical priority',
				highPriority: 'High priority',
				mediumPriority: 'Medium priority',
				lowPriority: 'Low priority',
			},
		},
	},
};
