import { Translation as Avatars } from 'view/Activities/ActivityList/ActivityIssues/Activity/Avatars/resources';
import { Translation as Activity } from 'view/Activities/ActivityList/ActivityIssues/Activity/resources';
import { Translation as ActivityIssues } from 'view/Activities/ActivityList/ActivityIssues/resources';
import { Translation as ActivityIssuesToDos } from 'view/Activities/ActivityList/ActivityToDosIssues/resources';
import { Translation as CustomChartTooltip } from 'view/Activities/ActivityList/Chart/CustomChartTooltip/resources';
import { Translation as Chart } from 'view/Activities/ActivityList/Chart/resources';
import { Translation as ActivityList } from 'view/Activities/ActivityList/resources';
import { Translation as WorkingUsers } from 'view/Activities/ActivityList/WorkingUsers/resources';
import { Translation as WorkingUserCard } from 'view/Activities/ActivityList/WorkingUsers/WorkingUserCard/resources';
import { Translation as Activities } from 'view/Activities/resources';

export const ActivitiesTranslationEN = [
	Activities.en,
	Avatars.en,
	Activity.en,
	ActivityIssues.en,
	ActivityList.en,
	WorkingUsers.en,
	WorkingUserCard.en,
	Chart.en,
	ActivityIssuesToDos.en,
	CustomChartTooltip.en,
];

export const ActivitiesTranslationPL = [
	Activities.pl,
	Avatars.pl,
	Activity.pl,
	ActivityIssues.pl,
	ActivityList.pl,
	WorkingUsers.pl,
	WorkingUserCard.pl,
	Chart.pl,
	ActivityIssuesToDos.pl,
	CustomChartTooltip.pl,
];
