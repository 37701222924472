import { Translation as AboutCustomer } from './AboutCustomer/resources';
import { Translation as AboutProject } from './AboutProject/resources';
import { Translation as ProjectCreate } from './resources';
import { Translation as Template } from './Template/resources';
import { Translation as TempleteCard } from './Template/TempleteCard/resources';

export const ProjectCreateTranslationEN = [
	AboutCustomer.en,
	AboutProject.en,
	ProjectCreate.en,
	Template.en,
	TempleteCard.en,
];

export const ProjectCreateTranslationPL = [
	AboutCustomer.pl,
	AboutProject.pl,
	ProjectCreate.pl,
	Template.pl,
	TempleteCard.pl,
];
