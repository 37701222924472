import { Theme } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		fabProgress: {
			color: green[500],
			position: 'absolute',
			top: -6,
			left: -6,
			zIndex: 1,
		},

		wrapper: {
			zIndex: 999,
			position: 'absolute',
			left: '50%',
			transform: 'translate(-50%, 30px)',
			transition: '.3s',
			opacity: 1,
		},

		wrapperHidden: {
			transform: 'translate(-50%, 0px)',
			visibility: 'hidden',
			opacity: 0,
		},

		img: {
			top: '16px',
			left: '7px',
			position: 'absolute',
		},
	}),

	{ name: `Reckon_Agreements_AgreementEdit_TaskerLogoLoader` }
);
