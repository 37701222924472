import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	key: (x: number) => `${x}_Permission_${uuid()}`,
};

export const Translation = {
	pl: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					PermissionManagement: {
						selectFeature: 'Aby wyświetlić listę uprawnień, najpierw wybierz funkcję systemu...',
						success: 'Role zostały zaktualizowane pomyślnie',
						error: 'Wystąpił błąd podczas aktualizacji ról',
						features: {
							1: 'Zgłoszenia',
							2: 'Projekty',
							4: 'Klienci',
							5: 'Komentarze',
							7: 'Ogólne',
							8: 'Konfiguracja zegarka',
							9: 'Konfiguracja bota auto-odpowiedzi',
							10: 'Formularze',
							11: 'Organizacje',
							12: 'Kanbany',
							13: 'Portale',
							14: 'Użytkownicy',
							15: 'Rachunki',
							16: 'Raporty',
							17: 'Role',
							18: 'Tagi',
							19: 'Web',
							21: 'Grupy tagów',
							29: 'Kontrakty',
							30: 'Delegacje',
						},
						0: 'Wszystkie',
						1: 'Wyświetlanie danych konfiguracji',
						2: 'Tworzenie danych konfiguracji',
						3: 'Aktualizacja danych konfiguracji',
						4: 'Usuwanie danych konfiguracji',
						5: 'Wyświetlanie paska logowania czasu w górnym menu',
						6: 'Dodawanie komentarzy',
						7: 'Wyświetlanie komentarzy',
						8: 'Usuwanie komentarzy',
						9: 'Aktualizowanie własnych komentarzy',
						10: 'Dodawanie konfiguracji bota odpowiadającego na zgłoszenia portalowe',
						11: 'Pobieranie konfiguracji bota odpowiadającego na zgłoszenia portalowe',
						12: 'Usuwanie konfiguracji bota odpowiadającego na zgłoszenia portalowe',
						13: 'Aktualizacja konfiguracji bota odpowiadającego na zgłoszenia portalowe',
						49: 'Tworzenie zgłoszeń',
						50: 'Wyświetlanie zgłoszeń',
						51: 'Wyświetlanie szczegółów zgłoszenia',
						52: 'Usuwanie zgłoszeń',
						53: 'Edycja zgłoszeń',
						54: 'Wyświetlanie priorytetów zgłoszeń',
						55: 'Wyświetlanie typów zgłoszeń',
						56: 'Wyświetlanie statusów zgłoszeń',
						57: 'Nadawanie typów zgłoszeniom',
						58: 'Nadawanie statusów zgłoszeniom',
						173: 'Nadawanie priorytetów zgłoszeniom',
						80: 'Tworzenie projektów',
						81: 'Edycja projektów',
						82: 'Wyświetlanie szczegółów projektu',
						83: 'Usuwanie projektów',
						14: 'Tworzenie nowych klientów',
						15: 'Usuwanie klientów',
						16: 'Wyświetlanie klientów',
						17: 'Oznaczenie klienta jako nieaktywny',
						18: 'Edycja klientów',
						19: 'Dodawanie formularzy',
						20: 'Usuwanie formularzy',
						21: 'Wyświetlanie formularzy',
						22: 'Aktualizowanie formularzy',
						23: 'Wyświetlanie uprawnień profilu',
						24: 'Aktualizowanie zgłoszeń',
						25: 'Ustawianie reguł tworzenia haseł',
						26: 'Zaloguj się za pomocą tokenu',
						27: 'Rejestracja użytkownika',
						28: 'Tworzenie pełnych profili',
						29: 'Tworzenie profili',
						30: 'Logowanie',
						31: 'Logowanie',
						32: 'Wysyłanie maila z instrukcjami dotyczącymi odzyskania hasła',
						33: 'Odzyskiwanie hasła',
						34: 'Możliwość zmiany hasła',
						35: 'Tworzenie najemcy',
						36: 'Generowanie linków rejestracyjnych',
						37: 'Tworzenie schematów',
						39: 'Usuwanie pól schematu',
						40: 'Edycja schematów',
						41: 'Wyświetlanie schematów',
						42: 'Usuwanie zawartości formularzy',
						43: 'Wyświetlanie zawartości formularzy',
						44: 'Tworzenie zawartości formularzy',
						45: 'Ustaw zawartości formularzy',
						46: 'Wyświetlanie aktualnej stawki godzinowej',
						47: 'Wyświetlanie rozliczeń',
						48: 'Wyświetlanie stawek godzinowych',
						59: 'Wyświetlanie informacji na temat kanbana',
						60: 'Wyświetlanie kanbanów',
						61: 'Tworzenie kanbanów',
						62: 'Tworzenie list kanbanów',
						63: 'Edycja kanbanów',
						64: 'Edycja list kanbanów',
						65: 'Usuwanie kanbanów',
						66: 'Ustawianie statusów, na których podstawie kanban powinien się tworzyć',
						67: 'Ustawanie projektów, na których podstawie kanban powinien się tworzyć',
						68: 'Wyświetlanie zgłoszenia portalowe',
						69: 'Pobieranie konfiguracji portalowej',
						70: 'Dodawanie konfiguracji portalowej',
						71: 'Aktualizacja konfiguracji portalowej',
						72: 'Usunięcie konfiguracji portalowej',
						73: 'Wyświetlanie użytkowników',
						74: 'Wyświetlanie szczegółów użytkowników',
						75: 'Zmiana własnego hasła',
						76: 'Zmiana hasła innych użytkowników',
						77: 'Edycja profilu',
						78: 'Usuwanie profilu',
						79: 'Przywracanie profilu',
						84: 'Wyświetlanie budżetu',
						85: 'Wyświetlanie kosztów',
						86: 'Wyświetl własny koszt implementacji',
						87: 'Wyświetl koszt implementacji',
						88: 'Wyświetl zaakceptowany koszt',
						89: 'Wyświetl własny zaakceptowany koszt',
						90: 'Wyświetl opracowywane zadania',
						91: 'Wyświetlanie rozliczeń projektów',
						92: 'Wyświetlanie rozliczeń zgłoszeń',
						93: 'Pobieranie rozliczenie zgłoszenia',
						94: 'Ustaw rozliczenie zgłoszenia',
						95: 'Ustaw rozliczenie projektu',
						96: 'Ustaw rozliczenie profilu',
						97: 'Utwórz rozliczenie profilu',
						98: 'Usuń arkusz rozliczenia',
						99: 'Rozpoczynanie logowania czasu pracy',
						100: 'Wyświetlanie aktywnego logu czasu pracy',
						101: 'Zatrzymanie aktywnego logu czasu pracy',
						102: 'Zakończenie logowania czasu pracy',
						103: 'Wyświetlanie raportów czasu pracy',
						104: 'Wyświetlanie pojedynczego rekordu czasu pracy',
						105: 'Tworzenie rekordu czasu pracy',
						106: 'Edycja rekordu czasu pracy',
						107: 'Usuwanie rekordu czasu pracy',
						108: 'Tworzenie raportów z projektu',
						109: 'Tworzenie raportów programisty',
						110: 'Zmiana wyświetlanego użytkownika w raportach czasów pracy',
						111: 'Tworzenie ról',
						112: 'Przyznawanie uprawnień',
						113: 'Zabieranie uprawnień',
						114: 'Edycja uprawnień',
						115: 'Pobieranie akcji',
						116: 'Pobieranie dostępnych uprawnień',
						117: 'Pobieranie ról',
						118: 'Wyświetlanie ról profilu',
						119: 'Ustawianie ról',
						120: 'Usuwanie ról',
						121: 'Usuwanie ról',
						122: 'Wyświetlanie wszystkich tagów',
						123: 'Wyświetlanie tagu',
						124: 'Wyświetlanie grup tagów',
						125: 'Wyświetlanie tagów zgłoszenia',
						126: 'Tworzenie tagów',
						127: 'Usuwanie tagów',
						128: 'Przypisywanie tagów',
						129: 'Ustawianie tagów',
						130: 'Edycja tagów',
						131: 'Tworzenie grup tagów',
						132: 'Edycja grup tagów',
						133: 'Usuwanie grup tagów',
						134: 'Wyświetlanie grup tagów',
						136: 'Wyświetlanie grup tagów z zawartymi tagami',
						137: 'Ustawianie grup tagów',
						138: 'Ustawianie tagów projektowych',
						140: 'Przypinanie tagów do projektu',
						141: 'Odpinanie tagów od projektu',
						142: 'Wyświetlanie tagów po projektach',
						143: 'Wyświetlanie tagów po projektach',
						144: 'Wyświetlanie projektów po tagach',
						145: 'Wyświetlenie danych rejestru',
						146: 'Wyświetlanie danych organizacji',
						147: 'Edycja danych organizacji',
						148: 'Usunięcie danych organizacji',
						149: 'Dostęp do strony aktywności',
						150: 'Dostęp do strony projektów',
						151: 'Dostęp do strony zgłoszeń',
						152: 'Tworzenie zgłoszeń',
						153: 'Szczegóły zgłoszeń',
						154: 'Dostęp do strony klientów',
						155: 'Tworzenie klientów',
						156: 'Szczegóły klientów',
						157: 'Tworzenie projektów',
						158: 'Szczegóły projektów',
						159: 'Dostęp do strony kalendarza',
						160: 'Dostęp do strony ustawień',
						161: 'Dostęp do strony czasu',
						162: 'Dostęp do strony rozliczeń',
						163: 'Dostęp do strony zarządzania uprawnieniami',
						164: 'Dostęp do strony zarządzania rolami',
						165: 'Dostęp do strony zarządzania użytkownikami',
						166: 'Dostęp do strony zarządzania tenantami',
						167: 'Dostęp do strony kanbanu',
						168: 'Dostęp do strony zarządzania tagami',
						169: 'Strona ustawień',
						170: 'Strona portalu',
						171: 'Strona form',
						172: 'Unique',
						182: 'Strona typów dni wolnych',
						183: 'Strona Holiday',
						174: 'Tworzenie typow dni wolnych',
						175: 'Aktualizacja typow dni wolnych',
						176: 'Usuwanie typow dni wolnych',
						177: 'Pobieranie typow dni wolnych',
						178: 'Tworzenie dni wolnych',
						179: 'Aktualizacja dni wolnych',
						180: 'Usuwanie dni wolnych',
						181: 'Pobieranie dni wolnych',
						184: 'Pobieranie aktualnie pracujących ludzi',
						185: 'Oglądanie dokumentami innych osób',
						186: 'Tworzenie umów',
						187: 'Aktualizacja umów',
						188: 'Dostęp do ekranu umów',
						189: 'Tworzenie załączników',
						190: 'Usuwanie załączników z issue',
						191: 'Pobieranie załączników z issue',
						192: 'Tworzenie budżetu',
						193: 'Aktualizacja budżetu',
						194: 'Usunięcie budżetu',
						195: 'Zarządzanie strukturą',
						196: 'Pobieranie struktury',
						197: 'Pobieranie odpowiedzi zakończenia pracy',
						198: 'Ustalanie odpowiedzi zakończenia pracy',
						199: 'Konfiguracja formularza zakończenia pracy',
						200: 'Usunięcie formularza zakończenia pracy',
						201: 'Pobranie warunków',
						202: 'Utworzenie warunków',
						203: 'Aktualizacja warunków',
						204: 'Usuwanie warunków',
						205: 'Pobieranie typu warunku',
						206: 'Tworzenie typu warunku',
						207: 'Aktualizacja typu warunku',
						208: 'Usuwanie typu warunku',
						209: 'Pobierz walutę',
						210: 'Pobierz słownik',
						211: 'Stwórz słownik',
						212: 'Zaktualizuj słownik',
						213: 'Usuń słownik',
						214: 'Pobierz elementy słownika',
						215: 'Stwórz elementy słownika',
						216: 'Zaktualizuj elementy słownika',
						217: 'Usuń elementy słownika',
						218: 'Pobierz umowy',
						219: 'Zaktualizuj umowę',
						220: 'Stwórz umowę',
						221: 'Usuń umowę',
						222: 'Pobierz pensję',
						223: 'Zaktualizuj pensję',
						224: 'Stwórz pensję',
						225: 'Usuń pensję',
						226: 'Dodaj do ulubionych zgłoszenie',
						227: 'Usuń z ulubionych zgłoszenie',
						228: 'Pobierz ulubione zgłoszenia',
						229: 'Ustal kolejność ulubionych zgłoszenie',
						230: 'Pobierz wartość na formularzu',
						231: 'Stwórz wartość na formularzu',
						232: 'Zaktualizuj wartość na formularzu',
						233: 'Pobierz dane google drive',
						234: 'Zaktualizuj dane google drive',
						235: 'Stwórz dane google drive',
						236: 'Pobierz dodatkowe linki',
						237: 'Stwórz dodatkowe linki',
						238: 'Zaktualizuj dodatkowe linki',
						239: 'Usuń dodatkowe linki',
						240: 'Stwórz swim line',
						241: 'Zaktualizuj swim line',
						242: 'Usuń swim line',
						243: 'Pobierz swim line',
						244: 'Stwórz organizacje',
						245: 'Zaktualizuj organizacje',
						246: 'Pobierz organizacje',
						247: 'Pobierz ustawienia organizacji',
						248: 'Stwórz ustawienia organizacji',
						249: 'Zaktualizuj ustawienia organizacji',
						250: 'Usuń ustawienia organizacji',
						251: 'Pobierz strukturę organizacyjną',
						252: 'Stwórz strukturę organizacyjną',
						253: 'Zaktualizuj strukturę organizacyjną',
						254: 'Usuń strukturę organizacyjną',
						255: 'Pobranie odpowiedzi z portalu',
						256: 'Utworzenie odpowiedzi na portalu',
						257: 'Aktualizacja odpowiedzi na portalu',
						258: 'Skasowanie odpowiedzi na portalu',
						259: 'Stwórz pozycje w strukturze organizacyjnej',
						260: 'Zaktualizuj pozycje w strukturze organizacyjnej',
						261: 'Usuń pozycje w strukturze organizacyjnej',
						262: 'Pobierz pozycje w strukturze organizacyjnej',
						263: 'Pobierz projekt',
						264: 'Stwórz projekt',
						265: 'Zaktualizuj projekt',
						266: 'Usuń projekt',
						267: 'Pobierz typ zgłoszenia',
						268: 'Stwórz typ zgłoszenia',
						269: 'Zaktualizuj typ zgłoszenia',
						270: 'Usuń typ zgłoszenia',
						271: 'Pobierz rolę projektu dla użytkownika',
						272: 'Utwórz rolę projektu dla użytkownika',
						273: 'Zaktualizuj rolę projektu dla użytkownika',
						274: 'Usuń rolę projektu dla użytkownika',
						275: 'Pobierz projekt dla projektu',
						276: 'Utwórz projekt dla projektu',
						277: 'Zaktualizuj projekt dla projektu',
						278: 'Usuń projekt dla projektu',
						279: 'Aktualizacja roli',
						280: 'Pobierz kontekst grupy tagów',
						281: 'Utwórz kontekst grupy tagów',
						282: 'Zaktualizuj kontekst grupy tagów',
						283: 'Usuń kontekst grupy tagów',
						284: 'Pobierz obiekt Tasker',
						285: 'Pobranie użytkownika',
						286: 'Utworzenie użytkownika',
						287: 'Aktualizacja użytkownika',
						288: 'Skasowanie użytkownika',
						289: 'TaskerUserUpdateOther',
						290: 'Pobranie roli użytkownika',
						291: 'Aktualizacja roli użytkownika',
						292: 'Utworzenie roli użytkownika',
						293: 'Skasowanie roli użytkownika',
						294: 'Nadanie roli użytkownika',
						295: 'Odebranie roli użytkownika',
						296: 'Pobranie ustawień użytkownika',
						297: 'Utworzenie ustawień użytkownika',
						298: 'Aktualizacja ustawień użytkownika',
						299: 'Skasowanie ustawień użytkownika',
						300: 'Utworzenie filtra Kanban',
						301: 'Aktualizacja filtra Kanban',
						302: 'Skasowanie filtra Kanban',
						303: 'Pobranie podsumowania dnia pracy',
						304: 'Utwórz typ urlopu',
						305: 'Zaktualizuj typ urlopu',
						306: 'Usuń typ urlopu',
						307: 'Pobierz typ urlopu',
						308: 'Utwórz wnioski urlopowe',
						309: 'Zaktualizuj wnioski urlopowe',
						310: 'Usuń wnioski urlopowe',
						311: 'Pobierz własne wnioski urlopowe',
						312: 'Utwórz wnioski urlopowe innych',
						313: 'Zaktualizuj wnioski urlopowe innych',
						314: 'Usuń wnioski urlopowe innych',
						315: 'Pobierz wnioski urlopowe innych',
						316: 'Strona typu urlopu',
						317: 'Konfiguracja delegacji',
						318: 'Tworzenie delegacji',
						319: 'Podgląd delegacji innych osób (niezbędne dla akceptacji)',
						320: 'Strona delegacji',
						321: 'Modal delegacji',
						322: 'Aktualizacja delegacji',
						323: 'Akceptacja delegacji',
						324: 'Akceptacja ewidencji zgłoszeń',
						325: 'Pokaż wszystkie projekty',
						326: 'Ekran słowników',
					},
				},
			},
		},
	},
	en: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					PermissionManagement: {
						selectFeature: 'To display the list of permissions, first select a system feature...',
						success: 'Roles have been updated successfully',
						error: 'An error occurred while updating roles',
						features: {
							1: 'Issues',
							2: 'Projects',
							4: 'Customers',
							5: 'Comments',
							7: 'General',
							8: 'Clock configuration',
							9: 'Comment bot configuration',
							10: 'Forms',
							11: 'Organizations',
							12: 'Kanbans',
							13: 'Portals',
							14: 'Users',
							15: 'Reckons',
							16: 'Reports',
							17: 'Roles',
							18: 'Tags',
							19: 'Web',
							21: 'Tag groups',
							29: 'Contracts',
							30: 'Delegations',
						},
						0: 'All',
						1: 'Show clock configuration',
						2: 'Create clock configuration',
						3: 'Update clock configuration',
						4: 'Remove clock configuration',
						5: 'Show worklog in top menu',
						6: 'Post comments',
						7: 'Show comments',
						8: 'Remove comments',
						9: 'Edit own comments',
						10: 'Add bot configuration for portal issues',
						11: 'Show bot configuration for portal issues',
						12: 'Remove bot configuration for portal issues',
						13: 'Update bot configuration for portal issues',
						14: 'Create customers',
						15: 'Remove customers',
						16: 'Show customers',
						17: 'Mark client as inactive',
						18: 'Edit customers',
						19: 'Create form',
						20: 'Remove form',
						21: 'Show form',
						22: 'Update form',
						23: 'Show profile permissions',
						24: 'Update issue',
						25: 'Set password creation rules',
						26: 'Login with Token',
						27: 'Register user',
						28: 'Create full profile',
						29: 'Create profile',
						30: 'Login',
						31: 'Login',
						32: 'Send email to recover password',
						33: 'Recover password',
						34: 'Change password',
						35: 'Create organization',
						36: 'Generate register link',
						37: 'Create schemas',
						38: 'CheckList',
						39: 'Remove schema field',
						40: 'Edit schema',
						41: 'Show schema',
						42: 'Remove form data',
						43: 'Show form data',
						44: 'Create form data',
						45: 'Set form data',
						46: 'Show current hourly rate',
						47: 'Show reckons',
						48: 'Show hourly rates',
						49: 'Create issues',
						50: 'Show issues',
						51: 'Show issue details',
						52: 'Delete issues',
						53: 'Edit issues',
						54: 'Show issue priority',
						55: 'Show issue types',
						56: 'Show issue status',
						57: 'Assign type to issue',
						58: 'Assign status to issue',
						59: 'Show kanban info',
						60: 'Show kanban',
						61: 'Create kanban',
						62: 'Create kanban list',
						63: 'Edit kanban',
						64: 'Edit kanban list',
						65: 'Remove kanban',
						66: 'Set status that kanban is based on',
						67: 'Set project that kanban is based on',
						68: 'Show portal issues',
						69: 'Show portal configuration',
						70: 'Add portal configuration',
						71: 'Update portal configuration',
						72: 'Remove portal configuration',
						73: 'Show users',
						74: 'Show user details',
						75: 'Change own password',
						76: 'Change other people passwords',
						77: 'Edit profile',
						78: 'Remove profile',
						79: 'Restore profile',
						80: 'Create projects',
						81: 'Edit projects',
						82: 'Show project details',
						83: 'Remove projects',
						84: 'Show budget',
						85: 'Show costs',
						86: 'Show own implementation cost',
						87: 'Show implementation cost',
						88: 'Show accepted cost',
						89: 'Show own accepted cost',
						90: 'Show developing tasks',
						91: 'Show profile reckon',
						92: 'Show project reckon',
						93: 'Get reckon for issue',
						94: 'Set reckon for issue',
						95: 'Set reckon for project',
						96: 'Set reckon for profile',
						97: 'Create reckon for profile',
						98: 'Delete reckon sheet',
						99: 'Start worklog',
						100: 'Show worklog',
						101: 'Stop worklog',
						102: 'End worklog',
						103: 'Show worklogs',
						104: 'Show single worklog',
						105: 'Create worklog',
						106: 'Edit worklog',
						107: 'Delete worklog',
						108: 'Compose project report',
						109: 'Compose developer report',
						110: 'Change user in report',
						111: 'Create roles',
						112: 'Assign roles',
						113: 'Revoke roles',
						114: 'Edit roles',
						115: 'Show actions',
						116: 'Show available permissions',
						117: 'Show roles',
						118: 'Show profile roles',
						119: 'Set roles',
						120: 'Delete roles',
						121: 'Delete roles',
						122: 'Show all tags',
						123: 'Show tag',
						124: 'Show tag group',
						125: 'Show issue tags',
						126: 'Create tags',
						127: 'Remove tags',
						128: 'Assign tags',
						129: 'Set tags',
						130: 'Edit tags',
						131: 'Create tag group',
						132: 'Edit tag group',
						133: 'Delete tag group',
						134: 'Show all tag groups',
						136: 'Show tag groups with tags',
						137: 'Set tag groups',
						138: 'Set project tags',
						140: 'Assign tags to project',
						141: 'Un assign tag',
						142: 'Show tags by project',
						143: 'Show tags by project',
						144: 'Show projects by tags',
						145: 'Show register info',
						146: 'Show organization data',
						147: 'Edit organization data',
						148: 'Remove organization data',
						149: 'Show activity page',
						150: 'Show project page',
						151: 'Show issue page',
						152: 'Create issues',
						153: 'Show issue details',
						154: 'Show customer page',
						155: 'Create customers',
						156: 'Show customer details',
						157: 'Create projects',
						158: 'Show project details',
						159: 'Show calendar page',
						160: 'Show settings page',
						161: 'Show report page',
						162: 'Show recon page',
						163: 'Access to permissions management page',
						164: 'Access to roles management page',
						165: 'Access to users management page',
						166: 'Access to tenants management page',
						167: 'Show kanban page',
						168: 'Access to tags management page',
						169: 'Settings page',
						170: 'Portal page',
						171: 'Forms page',
						172: 'Unique',
						173: 'Assign priority to issue',
						174: 'Create holiday type',
						175: 'Update holiday type',
						176: 'Remove holiday type',
						177: 'Show holiday type',
						178: 'Create holiday',
						179: 'Update holiday ',
						180: 'Remove holiday',
						181: 'Show holidays',
						182: 'Show holiday type page',
						183: 'Show holiday page',
						184: 'Get working users',
						185: 'Get Documents of other people',
						186: 'Create documents',
						187: 'Update documents',
						188: 'Access to documents site',
						189: 'Create attachments',
						190: 'Delete attachment',
						191: 'Download attachment',
						192: 'Create budget',
						193: 'Update budget',
						194: 'Delete budget',
						195: 'Structure management',
						196: 'Get structure',
						197: 'Downloading job completion responses',
						198: 'Determining job completion responses',
						199: 'Configuring job completion form',
						200: 'Deleting job completion form',
						201: 'Downloading conditions',
						202: 'Creating conditions',
						203: 'Updating conditions',
						204: 'Deleting conditions',
						205: 'Downloading condition type',
						206: 'Creating condition type',
						207: 'Updating condition type',
						208: 'Deleting condition type',
						209: 'Retrieve currency',
						210: 'Retrieve dictionary',
						211: 'Create dictionary',
						212: 'Update dictionary',
						213: 'Delete dictionary',
						214: 'Retrieve dictionary items',
						215: 'Create dictionary items',
						216: 'Update dictionary items',
						217: 'Delete dictionary items',
						218: 'Retrieve agreements',
						219: 'Update agreement',
						220: 'Create agreement',
						221: 'Delete agreement',
						222: 'Retrieve salary',
						223: 'Update salary',
						224: 'Create salary',
						225: 'Delete salary',
						226: 'Add to favorites report',
						227: 'Remove from favorites report',
						228: 'Retrieve favorite reports',
						229: 'Set favorite report order',
						230: 'Retrieve form value',
						231: 'Create form value',
						232: 'Update form value',
						233: 'Retrieve Google Drive data',
						234: 'Update Google Drive data',
						235: 'Create Google Drive data',
						236: 'Retrieve additional links',
						237: 'Create additional links',
						238: 'Update additional links',
						239: 'Delete additional links',
						240: 'Create swim line',
						241: 'Update swim line',
						242: 'Delete swim line',
						243: 'Retrieve swim line',
						244: 'Create organizations',
						245: 'Update organizations',
						246: 'Retrieve organizations',
						247: 'Retrieve organization settings',
						248: 'Create organization settings',
						249: 'Update organization settings',
						250: 'Delete organization settings',
						251: 'Retrieve organizational structure',
						252: 'Create organizational structure',
						253: 'Update organizational structure',
						254: 'Delete organizational structure',
						255: 'Retrieve response from portal',
						256: 'Create response on portal',
						257: 'Update response on portal',
						258: 'Delete response on portal',
						259: 'Create items in organizational structure',
						260: 'Update items in organizational structure',
						261: 'Delete items in organizational structure',
						262: 'Retrieve items in organizational structure',
						263: 'Retrieve project',
						264: 'Create project',
						265: 'Update project',
						266: 'Delete project',
						267: 'Retrieve submission type',
						268: 'Create submission type',
						269: 'Update submission type',
						270: 'Delete submission type',
						271: 'Project Role To Project To User Get',
						272: 'Project Role To Project To User Create',
						273: 'Project Role To Project To User Update',
						274: 'Project Role To Project To User Delete',
						275: 'Project To Project Get',
						276: 'Project To Project Create',
						277: 'Project To Project Update',
						278: 'Project To Project Delete',
						279: 'Update role',
						280: 'Tag Group Context Get',
						281: 'Tag Group Context Create',
						282: 'Tag Group Context Update',
						283: 'Tag Group Context Delete',
						284: 'Tasker Object Get',
						285: 'Retrieve user',
						286: 'Create user',
						287: 'Update user',
						288: 'Delete user',
						289: 'Tasker User Update Other',
						290: 'Retrieve user role',
						291: 'Update user role',
						292: 'Create user role',
						293: 'Delete user role',
						294: 'Assign user role',
						295: 'Revoke user role',
						296: 'Retrieve user settings',
						297: 'Create user settings',
						298: 'Update user settings',
						299: 'Delete user settings',
						300: 'Create Kanban filter',
						301: 'Update Kanban filter',
						302: 'Delete Kanban filter',
						303: 'Retrieve daily work summary',
						304: 'Create Leave Type',
						305: 'Update Leave Type',
						306: 'Delete Leave Type',
						307: 'Retrieve Leave Type',
						308: 'Create Leave Requests',
						309: 'Update Leave Requests',
						310: 'Delete Leave Requests',
						311: 'Retrieve Own Leave Requests',
						312: "Create Others' Leave Requests",
						313: "Update Others' Leave Requests",
						314: "Delete Others' Leave Requests",
						315: "Retrieve Others' Leave Requests",
						316: 'Leave Type Site',
						317: 'Configuration delegation',
						318: 'Create delegation',
						319: 'Delegation see others',
						320: 'Delegation site',
						321: 'Delegation modal',
						322: 'Delegation update',
						323: 'Delegation acceptance',
						324: 'Acceptance evidence Issues',
						325: 'Show all projects',
						326: 'Dictionary site',
					},
				},
			},
		},
	},
};
