import { IIssueFormCreate } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueForm: {
		Fields: [],
		TypeId: -1,
		FormName: '',
	} as IIssueFormCreate,
	path: '/issueForms',
	key: 'issueForm_Main_Section',
	dash: '_',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Form: {
								Edit: {
									formCardTitle: 'Formularz zgłoszeniowy',
									pageTitle: 'Formularz',
									Header: {
										formPropertiesTitle: 'Konfiguracja właściwości formularza',
										subTitleNew: 'Nowy formularz',
									},
									createForm: 'Zaktualizuj formularz',
									saveForm: 'Zapisz',
									cancel: 'Anuluj',
									addGroupIcon: 'Dodaj grupę',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Form: {
								Edit: {
									formCardTitle: 'Issue form',
									pageTitle: 'Form',
									Header: {
										formPropertiesTitle: 'Configuration form properties',
										subTitleNew: 'Update form',
									},
									createForm: 'Update form',
									saveForm: 'Save',
									cancel: 'Cancel',
									addGroupIcon: 'Dodaj grupę',
								},
							},
						},
					},
				},
			},
		},
	},
};
