import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	noData: '-',
	comma: ', ',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserTaskerRolesCell: {
						rolesInOrg: 'Wszystkie role użytkownika.',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserTaskerRolesCell: {
						rolesInOrg: 'All user roles.',
					},
				},
			},
		},
	},
};
