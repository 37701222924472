import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TimeReport: {
			TopBar: {
				TimeComponent: {
					monthBack: 'Miesiąc w tył',
					weekBack: 'Tydzień w tył',
					dayBack: 'Dzień w tył',
					monthForward: 'Miesiąc w przód',
					weekForward: 'Tydzień w przód',
					dayForward: 'Dzień w przód',
					currentWeek: 'Aktualny tydzień',
				},
			},
		},
	},
	en: {
		TimeReport: {
			TopBar: {
				TimeComponent: {
					monthBack: 'Month back',
					weekBack: 'Week back',
					dayBack: 'Day back',
					monthForward: 'Month forward',
					weekForward: 'Week forward',
					dayForward: 'Day forward',
					currentWeek: 'Current week',
				},
			},
		},
	},
};
