import { makeStyles } from '@mui/styles';

import { CustomTheme, isRtl } from '@skillandchill/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: CustomTheme) => ({
		...getGlobalStyles(),
		container: {
			display: 'block',

			'&:not(:last-of-type)': {
				margin: '0px 0px 15px 0px',
			},
		},
		card: {
			position: 'relative',
			transition: theme.transitions.fast,
			overflow: 'visible',

			'& a': {
				color: 'inherit',
			},

			'&:hover': {
				'& #dotsId': {
					visibility: 'visible',
				},

				'& #markAsReadIcon': {
					visibility: 'visible',
					opacity: 1,
				},

				'& #readStatusColumn': {
					visibility: 'hidden',
					opacity: 0,
				},
			},

			'& strong': {
				fontStyle: 'italic',
			},

			'&:nth-last-child(2)': {
				'& .userLink div.roles': {
					bottom: 'calc(100% + 5px)',
					top: 'auto',
				},
			},
		},
		notificationPriority: {
			position: 'absolute',
			left: '28px',
			bottom: '20px',
			borderRadius: '8px 8px 8px 8px',
			border: '0px solid white',
			backgroundColor: theme.palette.background.paper,
		},
		lowPriorityColor: {
			color: theme.palette.green.main,
		},
		mediumPriorityColor: {
			color: theme.palette.blue.main,
		},
		highPriorityColor: {
			color: theme.palette.yellow.light,
		},
		criticalPriorityColor: {
			color: theme.palette.red.dark,
		},
		notificationMessage: {
			marginTop: '3px',
			fontSize: '15px',
			fontWeight: 400,

			'& ul': {
				paddingLeft: '20px',

				'& li i': {
					whiteSpace: 'normal',
					hyphens: 'auto',
					wordBreak: 'break-all',
				},
			},

			'& strong': {
				fontWeight: '500',
				color: theme.palette.darkGray.main,
			},

			'& a div': {
				display: 'none',
			},

			'& .comment': {
				backgroundColor: 'rgba(96,144,223,0.1)',
				borderRadius: '5px',
				fontStyle: 'italic',
				padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
				textAlign: 'center',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				maxWidth: '300px',
				overflow: 'hidden',
				margin: `${theme.spacing(0.5)} auto 0 auto`,
				fontSize: '13px',
				fontWeight: 450,
			},

			'& a': {
				position: 'relative',
				fontStyle: 'italic',
				color: theme.palette.blue.light,
				whiteSpace: 'nowrap',

				'&.instanceObjectName': {
					whiteSpace: 'normal',
					hyphens: 'auto',
					wordBreak: 'break-all',
				},

				'&:hover': {
					textDecoration: 'underline',
				},

				'&:hover div.roles': {
					visibility: 'visible',
					opacity: '1',
				},

				'& div.roles': {
					fontStyle: 'normal',
					visibility: 'hidden',
					backgroundColor: theme.palette.lightGray.dark,
					color: theme.palette.background.default,
					borderRadius: '6px',
					position: 'absolute',
					zIndex: 99999,
					top: 'calc(100% + 10px)',
					left: '-10px',
					opacity: '0',
					transition: '.2s',
					padding: '4px 8px',
					fontSize: '11px',
					fontWeight: '500',
					width: 'min-content',
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'wrap',

					'& span': {
						whiteSpace: 'nowrap',
					},

					'& ul li': {
						whiteSpace: 'nowrap',
					},
				},
			},
		},

		notificationReadStatus: {
			marginLeft: theme.spacing(0.5),
		},

		badge: {
			alignSelf: 'flex-start',
			color: theme.palette.background.paper,
			marginRight: theme.spacing(1.5),
		},

		badgeContent: {
			backgroundColor: theme.palette.green.light,
			borderRadius: '50%',
			padding: '1.5px',
			border: '1px solid white',
			width: '22px',
			height: '22px',
		},

		newDate: {
			fontWeight: 450,
			marginLeft: theme.spacing(0.5),
			fontSize: '12px',
			color: theme.palette.lightGray.main,
		},

		newDateColor: {
			color: theme.palette.lightGray.main,
		},

		readStatusColumn: {
			width: '10px',
			height: '10px',
			backgroundColor: theme.palette.blue.dark,
			borderRadius: '50%',
			position: 'absolute',
			right: isRtl() ? 'unset' : '5px',
			left: isRtl() ? '5px' : 'unset',
			top: '8px',
			transition: '.2s',
			opacity: 1,
		},

		avatar: {
			borderRadius: '10px',
		},

		avatarColumn: {
			marginRight: theme.spacing(1),
		},

		notificationContent: {
			margin: '0 0 0 10px',
			padding: '15px 0px',
			borderBottom: '2px solid #D2D6DD',
		},

		notificationHeader: {
			color: theme.palette.lightGray.main,
			fontWeight: 450,
			fontSize: '13px',
		},

		headerSeparator: {
			width: '4px',
			height: '4px',
			borderRadius: '50%',
			marginRight: theme.spacing(0.7),
			marginLeft: theme.spacing(0.7),
			backgroundColor: theme.palette.lightGray.main,
		},

		headerDivider: {
			width: '2px',
			height: '16px',
			color: theme.palette.lightGray.main,
			backgroundColor: theme.palette.lightGray.main,
			marginRight: theme.spacing(0.7),
			marginLeft: theme.spacing(1),
		},

		markAsReadIcon: {
			position: 'absolute',
			transition: '.2s',
			padding: '3px',
			visibility: 'hidden',
			opacity: 0,
			right: isRtl() ? 'unset' : '-2px',
			left: isRtl() ? '-2px' : 'unset',
			top: '3px',
		},

		bookmarkIcon: {
			fontSize: '10px',
		},
	}),
	{ name: `Dashboard_NotificationPopover_Notification` }
);
