import {
	ActivityWorklog,
	EParameter,
	IParameter,
	ITaskerUser,
	ITaskerUserSetting,
	IPage,
	ITaskerUserToTeam,
	IWorkingUserNEW,
	IIssue,
	IWorkLog,
} from '@skillandchill/tasker-types';
import { ITileContext } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tileContext';
import { ITileParameter } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tileParameter';
import { ITileConfiguration } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tileRecordConfiguration';

import * as actionTypes from '@/store/actions';
import { TILE_CONTEXT_FETCH } from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	workingUser: {} as IWorkingUserNEW,
	activityWorklogs: [] as ActivityWorklog[],
	taskerUser: {} as Partial<ITaskerUser>,
	preferencesOptions: [] as IParameter[],
	taskerUserTeams: [] as ITaskerUserToTeam[],
	tileContext: [] as ITileContext[],
	tileContextCount: 0 as number,
	filterTileContext: {
		text: '' as string,
	},
	tileContexts: {} as IPage<ITileContext[]>,
	tileParameters: [] as ITileParameter[],
	tileConfigurations: [] as ITileConfiguration[],
	taskerUsers: [] as Partial<ITaskerUser>[],
	usersFromActiveUserTeam: [] as Partial<ITaskerUser>[],
	workableIssue: [] as IIssue[],
	time: undefined as number | undefined,
	workableIssuesCount: undefined as number | undefined,
	activeWorklogs: [] as IWorkLog[],
	issueUserWorklogNew: [] as IWorkLog[],
};
export type ISettingsEditorState = typeof State;

const settingsReducer = (state = State, action: ObjectAction): ISettingsEditorState => {
	switch (action?.type) {
		case actionTypes.TILE_CONTEXT_SET_FILTERS: {
			return {
				...state,
				filterTileContext: action?.filter,
			};
		}

		case actionTypes.TILE_CONTEXT_FETCH: {
			return {
				...state,
				tileContexts: action.contexts,
			};
		}
		case actionTypes.TILE_CONFIGURATE_FETCH: {
			return {
				...state,
				tileConfigurations: action.data,
			};
		}
		case actionTypes.ISSUE_FETCH_WORKABLE_LAST: {
			return {
				...state,
				workableIssue: action?.data,
			};
		}
		case actionTypes.WORKINGUSER_FETCH: {
			return {
				...state,
				workingUser: action.workingUser,
			};
		}
		case actionTypes.ACTIVITY_WORKLOG_GET: {
			return {
				...state,
				activityWorklogs: action.activity,
			};
		}
		case actionTypes.USER_FETCH_ONE: {
			return {
				...state,
				taskerUser: action.taskerUser,
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_FETCH_PREFERENCES: {
			return {
				...state,
				preferencesOptions: action.data,
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_UPDATE_PREFERENCES: {
			return {
				...state,
				preferencesOptions: state?.preferencesOptions?.map(option =>
					option?.Id == action?.data?.ParameterId
						? {
								...option,
								TaskerUserSetting:
									option?.TaskerUserSetting?.length && option?.TaskerUserSetting?.length > 0
										? option?.TaskerUserSetting?.map(setting =>
												setting?.Id == action?.data?.Id ? action?.data : setting
										  )
										: [action?.data],
						  }
						: option
				),
			};
		}
		case actionTypes.SET_TASKER_USER_TEAMS: {
			return {
				...state,
				taskerUserTeams: action.data,
			};
		}

		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}

		case actionTypes.KANBAN_USERS_GET:
		case actionTypes.SET_USERS_FROM_ACTIVE_USER_TEAM: {
			return {
				...state,
				usersFromActiveUserTeam: action.data,
			};
		}

		case actionTypes.SET_TASKER_USER_TEAMS_DATA: {
			return {
				...state,
				taskerUserTeams: action.teams,
				usersFromActiveUserTeam: action.usersFromActiveUserTeam,
			};
		}

		default: {
			return state;
		}
	}
};

export default settingsReducer;
