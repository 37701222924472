import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectList: {
			PlusIcon: {
				add: 'Dodaj',
			},
		},
	},
	en: {
		ProjectList: {
			PlusIcon: {
				add: 'Add',
			},
		},
	},
};
