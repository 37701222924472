import { Dispatch, SetStateAction } from 'react';

export interface Props {
	activeFilter: EDelegationListFilter;
	setActiveFilter: Dispatch<SetStateAction<EDelegationListFilter>>;
	setPage: Dispatch<SetStateAction<number>>;
}

export enum EDelegationListFilter {
	All = 1,
	PreparationAndCorrection = 2,
	ToAccept = 3,
	Done = 4,
}
