import i18n, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en_custom_2 from '@skillandchill/translate/dist/en/customOrg.json';
import pl_custom_2 from '@skillandchill/translate/dist/pl/customOrg.json';

import ar from './locales/ar';
import en from './locales/en';
import pl from './locales/pl';

export const resources = {
	en_default: en.en_default,
	pl_default: pl.pl_default,
	ar_default: ar.ar_default,
	en_custom_2: en_custom_2,
	pl_custom_2: pl_custom_2,
};

export const availableLanguages = Object.keys(resources);

use(XHR)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		ns: ['FRONTEND'],
		fallbackNS: 'FRONTEND',
		returnObjects: true,
		fallbackLng: {
			en_custom_2: ['en_default'],
			pl_custom_2: ['pl_default'],
			pl_default: ['en_default'],
			ar_default: ['en_default'],
		},

		supportedLngs: ['pl_default', 'en_default', 'en_custom_2', 'pl_custom_2', 'ar_default', 'ar_custom'],
		nonExplicitSupportedLngs: true,
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	});

export default i18n;
