import React from 'react';

import { Typography } from '@mui/material';

import { Props } from './model';
import { useStyles } from './styles';

export const DictionaryItemTooltip = (props: Props) => {
	const { dictionaryItemType, label } = props;
	const classes = useStyles();

	return (
		<div>
			<Typography
				variant={'body2'}
				className={classes.text}
			>{`Typ wartości słownika -  ${dictionaryItemType}:`}</Typography>
			<Typography className={classes.text}>{`Wartość: ${label}`}</Typography>
		</div>
	);
};
