import { format } from 'date-fns';

import { EFormats } from '@skillandchill/tasker-widgets2/dist/utils';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';
import { Reckon, ReckonType, TimeStatements } from '@skillandchill/tasker-widgets2/dist/utils/types/reckon';

import { IIssueModal } from 'view/IssueModalEditor/model';

export const IssueModalSelectTextFieldHeight = 35;
export const IssueModalSelectTextFieldFontSize = 12;

export enum ETab {
	GeneralTab = 0,
	ReckonTab = 1,
	TimeTab = 2,
	FormTab = 3,
	Children = 4,
	Parent = 5,
}

export const Resources = {
	generalTab: ETab.GeneralTab,
	reckonTab: ETab.ReckonTab,
	timeTab: ETab.TimeTab,
	formTab: ETab.FormTab,
	issueId: 'issueId',
	keydown: 'keydown' as const,
	imagePath: 'images/logos/tasker.png',
	...GlobalResources,
	issuePath: (id: number) => `?issueId=${id}`,
	backSlash: '/',
	kanbanPathPart: 'kanban',
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			title: 'Zgłoszenie',
			didUpdateIssue: 'Pomyślnie zaktualizowano zgłoszenie',
			didNotUpdateIssue: 'Nie zaktualizowano zgłoszenia',
			didAddIssue: 'Pomyślnie utworzono zgłoszenie',
			didNotAddIssue: 'Nie stworzono zgłoszenia',
			startWorkLogErrorOtherIsActive: 'Nie możesz edytować zadania gdy ktoś loguje na nim czas.',
			deleteSuccess: 'Pomyślnie usunięto zgłoszenie',
			deleteFail: 'Błąd usunięcia zgłoszenia',
			noData: 'Brak takiego zgłoszenia',
		},
	},
	en: {
		IssueModalEditor: {
			title: 'Issue',
			didUpdateIssue: 'Issue updated',
			didNotUpdateIssue: 'Issue did not updated',
			didAddIssue: 'Issue created',
			didNotAddIssue: 'Issue not created',
			startWorkLogErrorOtherIsActive: 'You cannot edit issue when someone logs time on it.',
			deleteSuccess: 'Issue successfully deleted',
			deleteFail: 'Deleting issue failed ',
			noData: 'No such issue',
		},
	},
};

export const defaultInitialReckon: Partial<Reckon> = {
	timePeriod: TimeStatements.HOURLY as const,
	type: ReckonType.FIXED as const,
	startDate: format(new Date(), EFormats.EDateFNS.DefaultDateFormatForServer),
	endDate: format(new Date(), EFormats.EDateFNS.DefaultDateFormatForServer),
	rate: 0,
	overageRate: 0,
	vatRate: 0,
	bank: 0,
};

export const defaultInitialIssueNew: Partial<IIssueModal> = {
	DisplayId: '0',
	Title: '',
	Description: '',
	DescriptionHtml: '',
	ProjectId: 0,
	ProjectIssueTypeId: 1,
	IsNew: true,
};

export const ModalIssueWidthLeftPart = '500px';
export const ModalIssuePadding = '24px';
