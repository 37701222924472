import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		IssueList: {
			Filters: {
				Options: {
					QuickFilters: {
						quickFilter: 'Szybkie filtry',
						filterSet: 'Filtry zostały ustawione',
						loadQuickFilter: 'Wczytaj szybkie filtry',
						load: 'Wczytaj',
						close: 'Zamknij',
						filterRequired: 'Wybierz filter aby móc go zastosować.',
						confirmDelete: 'Czy na pewno chcesz usunąć szybki filtr?',
						deleted: 'Usunięto',
						deleteFailed: 'Usuwanie zakończone niepowodzeniem.',
					},
				},
			},
		},
	},
	en: {
		IssueList: {
			Filters: {
				Options: {
					QuickFilters: {
						quickFilter: 'Quick filter',
						filterSet: 'Filter has been set',
						loadQuickFilter: 'Load quick filters',
						load: 'Load',
						close: 'Close',
						filterRequired: 'You need to choose filter before you use them.',
						confirmDelete: 'Are you sure you want to delete quick filter?',
						deleted: 'Deleted',
						deleteFailed: 'Delete failed.',
					},
				},
			},
		},
	},
};
