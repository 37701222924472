import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import overrides from '@skillandchill/tasker-widgets2/dist/utils/theme/overrides';
import overridesDark from '@skillandchill/tasker-widgets2/dist/utils/theme/overridesDark';
import { Palette, PaletteDark } from '@skillandchill/tasker-widgets2/dist/utils/theme/palette';
import { Transitions } from '@skillandchill/tasker-widgets2/dist/utils/theme/transition';
import { Typography } from '@skillandchill/tasker-widgets2/dist/utils/theme/typography';
import { Typography as TypographyDark } from '@skillandchill/tasker-widgets2/dist/utils/theme/typographyDark';

import { IReducerState } from '@/store/reducers/types';

export function useCurrentTheme(): { currentTheme: Theme } {
	const darkMode = useSelector((state: IReducerState) => state.Session.darkMode);
	const currentTheme = useMemo(
		() =>
			createTheme({
				palette: darkMode ? PaletteDark : Palette,
				typography: darkMode ? TypographyDark : Typography,
				components: darkMode ? overridesDark : overrides,
				transitions: Transitions,
			}),
		[darkMode]
	);
	return { currentTheme };
}
