import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	path: '/auth/login',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Error500: {
			pageTitle: 'Błąd 500',
			serverError: '500: Błąd serwera',
			info: 'Wystąpił błąd serwera.',
			goHome: 'Wróć do strony głównej',
		},
	},
	en: {
		Error500: {
			pageTitle: 'Error 500',
			serverError: '500: Ooops, something went terribly wrong!',
			info: 'Server error occurred.',
			goHome: 'Back to home',
		},
	},
};
