import { AsyncAction } from '@skillchill/redux-promisify';

import { IDelegationDictionary, IDelegationDictionaryGroup, IPage } from '@skillandchill/tasker-types';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export const DELEGATION_DICTIONARY_GROUP_FETCH: ActionType = 'DELEGATION_DICTIONARY_GROUP_FETCH';
export const DELEGATION_DICTIONARY_GROUP_CREATE: ActionType = 'DELEGATION_DICTIONARY_GROUP_CREATE';
export const DELEGATION_DICTIONARY_GROUP_UPDATE: ActionType = 'DELEGATION_DICTIONARY_GROUP_UPDATE';

const basePath = 'DelegationDictionaryGroup';

function _fetchDelegationDictionaryGroup(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_DICTIONARY_GROUP_FETCH,
		data,
	};
}

function _createDelegationDictionaryGroup(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_DICTIONARY_GROUP_CREATE,
		data,
	};
}

function _updateDelegationDictionaryGroup(data: IPage<IDelegationDictionary[]>): Action {
	return {
		type: DELEGATION_DICTIONARY_GROUP_UPDATE,
		data,
	};
}

export function delegation_fetchDictionaryGroup(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationDictionaryGroup((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function delegation_createDictionaryGroup(data: Partial<IDelegationDictionaryGroup>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createDelegationDictionaryGroup((json as IJson).data)));
		return axios().post(basePath, data);
	};
}

export function delegation_updateDictionaryGroup(data: Partial<IDelegationDictionaryGroup>, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateDelegationDictionaryGroup((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, data);
	};
}
