import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	slash: '/',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				add: 'Dodaj',
				tileTittle: 'Formularz',
				creationDate: 'Data stworzenia',
				LabelAvatar: {
					multi: 'Przypisani użytkownicy do formularza',
					single: 'Osoba przypisana do formularza',
				},
				useTableData: {
					name: 'Nazwa',
					users: 'Użytkownicy przypisani do formularza',
					creationDate: 'Data stworzenia',
					actions: 'Akcje',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				add: 'Add',
				creationDate: 'Creation date',
				tileTittle: 'Form',
				LabelAvatar: {
					multi: 'Assigned to form',
					single: 'Assigned to form',
				},
				useTableData: {
					name: 'Name',
					users: 'Users assigned to form',
					creationDate: 'Creation date',
					actions: 'Actions',
				},
			},
		},
	},
};
