import { AsyncAction } from '@skillchill/redux-promisify';

import { IOrganizationParameter, IOrganizationSetting } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const GET_GOOGLE_DRIVE: ActionType = 'GET_GOOGLE_DRIVE';
export const LOCAL_UPDATE_GOOGLE_DRIVE: ActionType = 'LOCAL_UPDATE_GOOGLE_DRIVE';

const basePath = 'GoogleDrive';
const basePathSettings = `OrganizationSetting/${basePath}`;

function _getGoogleDriveSettings(data: IOrganizationParameter[]): Action {
	return {
		type: GET_GOOGLE_DRIVE,
		data,
	};
}

export function getGoogleDriveSettings(): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getGoogleDriveSettings((response as IJson)?.data)));
		return axios().get(basePath);
	};
}

export function localUpdateGoogleDrive(data: Partial<IOrganizationParameter>[]): Action {
	return {
		type: LOCAL_UPDATE_GOOGLE_DRIVE,
		data,
	};
}

export function saveGoogleDriveSettings(settings: IOrganizationSetting[]): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getGoogleDriveSettings((response as IJson)?.data)));
		return axios().post(`${basePathSettings}/Many`, settings);
	};
}
