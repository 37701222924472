import { AsyncAction } from '@skillchill/redux-promisify';

import { IPage, IWorkingUser, IWorkingUserNEW } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'workingUsers';

export const WORKINGUSER_FETCH: ActionType = 'WORKINGUSER_FETCH';

function _fetchWorkingUsers(workingUsers: IPage<IWorkingUserNEW>): Action {
	return {
		type: WORKINGUSER_FETCH,
		workingUsers: workingUsers.Data,
		workingUsersCount: workingUsers.Count,
	};
}

function _fetchWorkingUser(workingUser: IWorkingUserNEW): Action {
	return {
		type: WORKINGUSER_FETCH,
		workingUser: workingUser,
	};
}

export function workingUsersFiltered_fetch(filterReq: string, page = 0, rowsPerPage = 7): AsyncAction {
	const secondPartOfPath = `/new/filtered/from/${page}/count/${rowsPerPage}`;
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchWorkingUsers((json as IJson)?.data)));
		return axios().post(`${basePath}${secondPartOfPath}`, { filterReq });
	};
}

export function workingUserById(userId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchWorkingUser((json as IJson)?.data)));
		return axios().get(`${basePath}/new/${userId}`);
	};
}
