import React from 'react';
import { components } from 'react-select';

import { Typography } from '@mui/material';

import { useStyles } from './styles';

export const CustomOption = (props: any) => {
	const classes = useStyles();

	return (
		<components.Option {...props}>
			<div
				className={classes.tag}
				style={{
					backgroundColor: props?.data?.object?.IssueType?.TagColor,
				}}
			>
				<Typography style={{ color: props?.data?.object?.IssueType?.TagColor, filter: 'invert(100%)' }}>
					{props.children}
				</Typography>
			</div>
		</components.Option>
	);
};
