import { Middleware, MiddlewareAPI } from 'redux';
// @ts-ignore
import storejs from 'store';

import { Options, Path, PathList } from '@/store/middleware/localStorage/types';
import { Action } from '@/store/types';
// @ts-ignore

/**
 * Middleware to persist state in cookies.
 * @param path
 * @param state
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueByPath = (path: Path, state: any) => {
	let subState = state;
	const pathParts = path.path?.split('.') ?? [];
	try {
		for (const part of pathParts) {
			subState = subState[part];
		}
		return subState;
	} catch (e) {
		console.warn(`Failed to extract data from state with path: ${path}`);
	}
};
const getFullOptions = (options?: Partial<Options>): Options => {
	return {
		...options,
		equalityCheck: <T>(a: T, b: T) => a === b,
		pathPrefix: '',
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		logger: () => {},
	};
};

const storageMiddleware = (paths: PathList, options?: Partial<Options>): Middleware => {
	const defaults = getFullOptions(options);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (store: MiddlewareAPI<any>) => (next: any) => (action: Action) => {
		const prevState = store.getState();
		const result = next(action);
		const nextState = store.getState();

		paths.forEach(pathToState => {
			const prevVal = getValueByPath(pathToState, prevState);
			const nextVal = getValueByPath(pathToState, nextState);

			const finalPath = defaults.pathPrefix + pathToState.path;

			if (pathToState.saveCheck && !pathToState.saveCheck(prevVal, nextVal, store)) return;
			const equalityCheck = pathToState.equalityCheck ?? defaults.equalityCheck;
			if (equalityCheck && !equalityCheck(prevVal, nextVal)) {
				if (!nextVal) storejs.remove(finalPath);
				else storejs.set(finalPath, nextVal);
			}
		});

		return result;
	};
};
export { getFullOptions };

export default storageMiddleware;
