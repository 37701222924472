import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	settlementGroupSummaryKey: (key: string) => `SettlementGroupSummary_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementTableGroupRow: {
								day: 'Dzień',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementTableGroupRow: {
								day: 'Day',
							},
						},
					},
				},
			},
		},
	},
};
