import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EParameter, ITaskerUserSetting } from '@skillandchill/tasker-types';
import { IFilterCustomer, IFilterSimplifiedCustomer } from '@skillandchill/tasker-types/dist/types/endPoints/filter';

import { get_Customer_Filter_Options, get_Customer_Filter_Simplified_Options } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { isEmpty } from './utils';

export const FilterLazyLoading = () => {
	const dispatch: Dispatch = useDispatch();

	const simplyFilterPreferences: ITaskerUserSetting | undefined = useSelector((state: IReducerState) =>
		state?.Session?.preferences?.find(x => x?.ParameterId === EParameter.User_UISimplifyFilters)
	);

	const token: string = useSelector((state: IReducerState) => state?.Session?.access_token);
	const issueListFilters: IFilterCustomer[] = useSelector(
		(state: IReducerState) => state?.IssueList?.filters?.customerFilters
	);
	const issueListFiltersSimplified: IFilterSimplifiedCustomer[] = useSelector(
		(state: IReducerState) => state?.IssueList?.filters?.customerFiltersSimplified
	);
	const kanbanFilters: IFilterCustomer[] = useSelector(
		(state: IReducerState) => state?.Kanban?.filters?.customerFilters
	);
	const kanbanFiltersSimplified: IFilterSimplifiedCustomer[] = useSelector(
		(state: IReducerState) => state?.Kanban?.filters?.customerFiltersSimplified
	);
	const customerFiltersNeedReset: number = useSelector(
		(state: IReducerState) => state?.Session?.customerFiltersNeedReset
	);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fetch();
	}, [simplyFilterPreferences, token]);

	useEffect(() => {
		const filtersAreEmpty: boolean =
			isEmpty(issueListFilters) ||
			isEmpty(issueListFiltersSimplified) ||
			isEmpty(kanbanFilters) ||
			isEmpty(kanbanFiltersSimplified);
		fetch(customerFiltersNeedReset > 0 || filtersAreEmpty);
	}, [customerFiltersNeedReset]);

	const fetch = (additionalQuery = true) => {
		if (token && isLoading === false && additionalQuery) {
			load();
			if (simplyFilterPreferences?.ValueBoolean ?? false)
				dispatch(get_Customer_Filter_Simplified_Options()).finally(loaded);
			else dispatch(get_Customer_Filter_Options()).finally(loaded);
		}
	};

	const loaded = () => setIsLoading(false);
	const load = () => setIsLoading(true);
	return <></>;
};
