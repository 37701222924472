import { Translation as ProjectTabFilter } from './Filters/ProjectTabFilter/resources';
import { Translation as Filters } from './Filters/resources';
import { Translation as TaskerObjectFilter } from './Filters/TaskerObjectFilter/resources';
import { Translation as TaskerUserFilter } from './Filters/TaskerUserFilter/resources';
import { Translation as MoreOptions } from './MoreOptions/resources';
import { Translation as RadioGroupRow } from './RadioGroupRow/resources';
import { Translation as NotificationList } from './resources';
import { Translation as TableOptions } from './TableOptions/resources';
export const NotificationListTranslationEN = [
	NotificationList.en,
	TableOptions.en,
	RadioGroupRow.en,
	MoreOptions.en,
	TaskerUserFilter.en,
	TaskerObjectFilter.en,
	Filters.en,
	ProjectTabFilter.en,
];

export const NotificationListTranslationPL = [
	NotificationList.pl,
	TableOptions.pl,
	RadioGroupRow.pl,
	MoreOptions.pl,
	TaskerUserFilter.pl,
	TaskerObjectFilter.pl,
	Filters.pl,
	ProjectTabFilter.pl,
];
