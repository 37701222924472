import { ECountry } from '@skillandchill/tasker-types';

export const TranslationNumberToText = {
	pl: {
		NumberToText: {
			Unity: {
				0: ''.toUpperCase(),
				1: ' jeden'.toUpperCase(),
				2: ' dwa'.toUpperCase(),
				3: ' trzy'.toUpperCase(),
				4: ' cztery'.toUpperCase(),
				5: ' pięć'.toUpperCase(),
				6: ' sześć'.toUpperCase(),
				7: ' siedem'.toUpperCase(),
				8: ' osiem'.toUpperCase(),
				9: ' dziewięć'.toUpperCase(),
			},
			Next: {
				10: ''.toUpperCase(),
				11: ' jedenaście'.toUpperCase(),
				12: ' dwanaście'.toUpperCase(),
				13: ' trzynaście'.toUpperCase(),
				14: ' czternaście'.toUpperCase(),
				15: ' piętnaście'.toUpperCase(),
				16: ' szesnaście'.toUpperCase(),
				17: ' siedemnaście'.toUpperCase(),
				18: ' osiemnaście'.toUpperCase(),
				19: ' dziewiętnaście'.toUpperCase(),
			},
			Tens: {
				10: ' dziesięć'.toUpperCase(),
				20: ' dwadzieścia'.toUpperCase(),
				30: ' trzydzieści'.toUpperCase(),
				40: ' czterdzieści'.toUpperCase(),
				50: ' pięćdziesiąt'.toUpperCase(),
				60: ' sześćdziesiąt'.toUpperCase(),
				70: ' siedemdziesiąt'.toUpperCase(),
				80: ' osiemdziesiąt'.toUpperCase(),
				90: ' dziewięćdziesiąt'.toUpperCase(),
			},
			Hundreds: {
				100: ' sto'.toUpperCase(),
				200: ' dwieście'.toUpperCase(),
				300: ' trzysta'.toUpperCase(),
				400: ' czterysta'.toUpperCase(),
				500: ' pięćset'.toUpperCase(),
				600: ' sześćset'.toUpperCase(),
				700: ' siedemset'.toUpperCase(),
				800: ' osiemset'.toUpperCase(),
				900: ' dziewięćset'.toUpperCase(),
			},
			Uppers: {
				Thousand: {
					1: ' tysiąc'.toUpperCase(),
					2: ' tysiące'.toUpperCase(),
					3: ' tysięcy'.toUpperCase(),
				},
				Million: { 1: ' milion'.toUpperCase(), 2: ' miliony'.toUpperCase(), 3: ' milionów'.toUpperCase() },
				Billion: { 1: ' miliard'.toUpperCase(), 2: ' miliardy'.toUpperCase(), 3: ' miliardów'.toUpperCase() },
				Trillion: { 1: ' bilion'.toUpperCase(), 2: ' biliony'.toUpperCase(), 3: ' bilionów'.toUpperCase() },
				Billiard: { 1: ' biliard'.toUpperCase(), 2: ' biliardy'.toUpperCase(), 3: ' biliardów'.toUpperCase() },
				ATrillion: { 1: ' trylion'.toUpperCase(), 2: ' tryliony'.toUpperCase(), 3: ' trylionów'.toUpperCase() },
			},
		},
		Countries: {
			[ECountry.Bulgaria]: 'Bułgaria',
			[ECountry.Canada]: 'Kanada',
			[ECountry.Switzerland]: 'Szwajcaria',
			[ECountry.Czech]: 'Czech',
			[ECountry.Denmark]: 'Dania',
			[ECountry.GreatBritain]: 'Wielka Brytania',
			[ECountry.Croatia]: 'Chorwacja',
			[ECountry.Hungary]: 'Węgry',
			[ECountry.Japan]: 'Japonia',
			[ECountry.Poland]: 'Polska',
			[ECountry.Romania]: 'Rumunia',
			[ECountry.Sweden]: 'Szwecja',
			[ECountry.UnitedStates]: 'Stany zjednoczone',
			[ECountry.Afghanistan]: 'Afghanistan',
			[ECountry.Albania]: 'Albania',
			[ECountry.Algeria]: 'Algeria',
			[ECountry.Andorra]: 'Andorra',
			[ECountry.Angola]: 'Angola',
			[ECountry.SaudiArabia]: 'Arabia Saudyjska',
			[ECountry.Argentina]: 'Argentyna',
			[ECountry.Armenia]: 'Armenia',
			[ECountry.Australia]: 'Australia',
			[ECountry.Austria]: 'Austria',
			[ECountry.Azerbaijan]: 'Azerbaijan',
			[ECountry.Bangladesh]: 'Bangladesh',
			[ECountry.Belgium]: 'Belgia',
			[ECountry.Belarus]: 'Białoruś',
			[ECountry.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
			[ECountry.Brazil]: 'Brazylia',
			[ECountry.Chile]: 'Chile',
			[ECountry.China]: 'Chiny',
			[ECountry.Cyprus]: 'Cypr',
			[ECountry.CzechRepublic]: 'Czechy',
			[ECountry.Egypt]: 'Egipt',
			[ECountry.Ecuador]: 'Ekwador',
			[ECountry.Estonia]: 'Estonia',
			[ECountry.Ethiopia]: 'Etiopia',
			[ECountry.Finland]: 'Finlandia',
			[ECountry.France]: 'Francja',
			[ECountry.Greece]: 'Grecja',
			[ECountry.Georgia]: 'Gruzja',
			[ECountry.Spain]: 'Hiszpania',
			[ECountry.India]: 'Indie',
			[ECountry.Indonesia]: 'Indonezja',
			[ECountry.Iraq]: 'Irak',
			[ECountry.Iran]: 'Iran',
			[ECountry.Ireland]: 'Irlandia',
			[ECountry.Iceland]: 'Islandia',
			[ECountry.Israel]: 'Izrael',
			[ECountry.Yemen]: 'Yemen',
			[ECountry.Jordan]: 'Jordan',
			[ECountry.Cambodia]: 'Kambodża',
			[ECountry.Qatar]: 'Katar',
			[ECountry.Kazakhstan]: 'Kazakhstan',
			[ECountry.Kenya]: 'Kenia',
			[ECountry.Kyrgyzstan]: 'Kirgistan',
			[ECountry.Colombia]: 'Kolumbia',
			[ECountry.CongoDemocraticRepublicOfTheCongo]: 'Kongo Demokratyczna Republika Konga',
			[ECountry.SouthKorea]: 'Korea Południowa',
			[ECountry.NorthKorea]: 'Korea Północna',
			[ECountry.CostaRica]: 'Kostaryka',
			[ECountry.Cuba]: 'Kuba',
			[ECountry.Kuwait]: 'Kuwejt',
			[ECountry.Laos]: 'Laos',
			[ECountry.Lebanon]: 'Liban',
			[ECountry.Libya]: 'Libia',
			[ECountry.Liechtenstein]: 'Liechtenstein',
			[ECountry.Lithuania]: 'Litwa',
			[ECountry.Luxembourg]: 'Luksemburg',
			[ECountry.Latvia]: 'Łotwa',
			[ECountry.Macedonia]: 'Macedonia',
			[ECountry.Malaysia]: 'Malezja',
			[ECountry.Malta]: 'Malta',
			[ECountry.Morocco]: 'Maroko',
			[ECountry.Mexico]: 'Meksyk',
			[ECountry.Moldova]: 'Mołdawia',
			[ECountry.Monaco]: 'Monako',
			[ECountry.Mongolia]: 'Mongolia',
			[ECountry.Netherlands]: 'Niderlandy',
			[ECountry.Germany]: 'Niemcy',
			[ECountry.Nigeria]: 'Nigeria',
			[ECountry.Norway]: 'Norwegia',
			[ECountry.NewZealand]: 'Nowa Zelandia',
			[ECountry.Oman]: 'Oman',
			[ECountry.Pakistan]: 'Pakistan',
			[ECountry.PalestinianNationalAuthority]: 'Autonomia Palestyńska',
			[ECountry.Panama]: 'Panama',
			[ECountry.Peru]: 'Peru',
			[ECountry.Portugal]: 'Portugalia',
			[ECountry.RepublicOfSouthAfrica]: 'Republika Afryki Południowej',
			[ECountry.Russia]: 'Rosja',
			[ECountry.SanMarino]: 'San Marino',
			[ECountry.Senegal]: 'Senegal',
			[ECountry.RepublicOfSerbiaAndRepublicOfMontenegro]: 'Serbia i Czarnogóra',
			[ECountry.Singapore]: 'Singapur',
			[ECountry.Slovakia]: 'Słowacja',
			[ECountry.Slovenia]: 'Słowenia',
			[ECountry.UnitedStatesOfAmericaIncludingNewYorkWashington]:
				'Stany Zjednoczone Ameryki (w tym Nowy Jork i Waszyngton)',
			[ECountry.Syria]: 'Syria',
			[ECountry.Tajikistan]: 'Tadżykistan',
			[ECountry.Thailand]: 'Tajlandia',
			[ECountry.Tanzania]: 'Tanzania',
			[ECountry.Tunisia]: 'Tunezja',
			[ECountry.Turkey]: 'Turcja',
			[ECountry.Turkmenistan]: 'Turkmenia',
			[ECountry.Ukraine]: 'Ukraina',
			[ECountry.Uruguay]: 'Urugwaj',
			[ECountry.Uzbekistan]: 'Uzbekistan',
			[ECountry.Venezuela]: 'Venezuela',
			[ECountry.Vietnam]: 'Vietnam',
			[ECountry.Italy]: 'Włochy',
			[ECountry.IvoryCoast]: 'Wybrzeże Kości Słoniowej',
			[ECountry.Zimbabwe]: 'Zimbabwe',
			[ECountry.UnitedArabEmirates]: 'Zjednoczone Emiraty Arabskie',
			[ECountry.OtherCountries]: 'Inne kraje',
		},
	},
	en: {
		NumberToText: {
			Unity: {
				0: ''.toUpperCase(),
				1: ' jeden'.toUpperCase(),
				2: ' dwa'.toUpperCase(),
				3: ' trzy'.toUpperCase(),
				4: ' cztery'.toUpperCase(),
				5: ' pięć'.toUpperCase(),
				6: ' sześć'.toUpperCase(),
				7: ' siedem'.toUpperCase(),
				8: ' osiem'.toUpperCase(),
				9: ' dziewięć'.toUpperCase(),
			},
			Next: {
				10: ''.toUpperCase(),
				11: ' jedenaście'.toUpperCase(),
				12: ' dwanaście'.toUpperCase(),
				13: ' trzynaście'.toUpperCase(),
				14: ' czternaście'.toUpperCase(),
				15: ' piętnaście'.toUpperCase(),
				16: ' szesnaście'.toUpperCase(),
				17: ' siedemnaście'.toUpperCase(),
				18: ' osiemnaście'.toUpperCase(),
				19: ' dziewiętnaście'.toUpperCase(),
			},
			Tens: {
				10: ' dziesięć'.toUpperCase(),
				20: ' dwadzieścia'.toUpperCase(),
				30: ' trzydzieści'.toUpperCase(),
				40: ' czterdzieści'.toUpperCase(),
				50: ' pięćdziesiąt'.toUpperCase(),
				60: ' sześćdziesiąt'.toUpperCase(),
				70: ' siedemdziesiąt'.toUpperCase(),
				80: ' osiemdziesiąt'.toUpperCase(),
				90: ' dziewięćdziesiąt'.toUpperCase(),
			},
			Hundreds: {
				100: ' sto'.toUpperCase(),
				200: ' dwieście'.toUpperCase(),
				300: ' trzysta'.toUpperCase(),
				400: ' czterysta'.toUpperCase(),
				500: ' pięćset'.toUpperCase(),
				600: ' sześćset'.toUpperCase(),
				700: ' siedemset'.toUpperCase(),
				800: ' osiemset'.toUpperCase(),
				900: ' dziewięćset'.toUpperCase(),
			},
			Uppers: {
				Thousand: {
					1: ' tysiąc'.toUpperCase(),
					2: ' tysiące'.toUpperCase(),
					3: ' tysięcy'.toUpperCase(),
				},
				Million: { 1: ' milion'.toUpperCase(), 2: ' miliony'.toUpperCase(), 3: ' milionów'.toUpperCase() },
				Billion: { 1: ' miliard'.toUpperCase(), 2: ' miliardy'.toUpperCase(), 3: ' miliardów'.toUpperCase() },
				Trillion: { 1: ' bilion'.toUpperCase(), 2: ' biliony'.toUpperCase(), 3: ' bilionów'.toUpperCase() },
				Billiard: { 1: ' biliard'.toUpperCase(), 2: ' biliardy'.toUpperCase(), 3: ' biliardów'.toUpperCase() },
				ATrillion: { 1: ' trylion'.toUpperCase(), 2: ' tryliony'.toUpperCase(), 3: ' trylionów'.toUpperCase() },
			},
		},
		TMP: {
			NumberToText: {
				Unity: {
					1: ' one'.toUpperCase(),
					2: ' two'.toUpperCase(),
					3: ' three'.toUpperCase(),
					4: ' four'.toUpperCase(),
					5: ' five'.toUpperCase(),
					6: ' six'.toUpperCase(),
					7: ' seven'.toUpperCase(),
					8: ' eight'.toUpperCase(),
					9: ' nine'.toUpperCase(),
				},
				Next: {
					11: ' eleven'.toUpperCase(),
					12: ' twelve'.toUpperCase(),
					13: ' thirteen'.toUpperCase(),
					14: ' fourteen'.toUpperCase(),
					15: ' fifteen'.toUpperCase(),
					16: ' sixteen'.toUpperCase(),
					17: ' seventeen'.toUpperCase(),
					18: ' eighteen'.toUpperCase(),
					19: ' nineteen'.toUpperCase(),
				},
				Tens: {
					10: ' ten'.toUpperCase(),
					20: ' twenty'.toUpperCase(),
					30: ' thirty'.toUpperCase(),
					40: ' forty'.toUpperCase(),
					50: ' fifty'.toUpperCase(),
					60: ' sixty'.toUpperCase(),
					70: ' seventy'.toUpperCase(),
					80: ' eighty'.toUpperCase(),
					90: ' ninety'.toUpperCase(),
				},
				Hundreds: {
					100: 'hundred'.toUpperCase(),
					200: 'two hundred'.toUpperCase(),
					300: 'three hundred'.toUpperCase(),
					400: 'four hundred'.toUpperCase(),
					500: 'five hundred'.toUpperCase(),
					600: 'six hundred'.toUpperCase(),
					700: 'seven hundred'.toUpperCase(),
					800: 'eight hundred'.toUpperCase(),
					900: 'nine hundred'.toUpperCase(),
				},
				Uppers: {
					Thousand: {
						1: ' thousand'.toUpperCase(),
						2: ' thousand'.toUpperCase(),
						3: ' thousand'.toUpperCase(),
					},
					Million: { 1: ' million'.toUpperCase(), 2: ' million'.toUpperCase(), 3: ' million'.toUpperCase() },
					Billion: { 1: ' billion'.toUpperCase(), 2: ' billion'.toUpperCase(), 3: ' billion'.toUpperCase() },
					Trillion: {
						1: ' trillion'.toUpperCase(),
						2: ' trillion'.toUpperCase(),
						3: ' trillion'.toUpperCase(),
					},
					Billiard: { 1: ' billiard'.toUpperCase(), 2: ' billiard'.toUpperCase(), 3: ' billiard' },
					ATrillion: {
						1: ' a Trillion'.toUpperCase(),
						2: ' a Trillion'.toUpperCase(),
						3: ' a Trillion'.toUpperCase(),
					},
				},
			},
		},
		Countries: {
			[ECountry.Bulgaria]: 'Bulgaria',
			[ECountry.Canada]: 'Canada',
			[ECountry.Switzerland]: 'Switzerland',
			[ECountry.Czech]: 'Czech',
			[ECountry.Denmark]: 'Denmark',
			[ECountry.GreatBritain]: 'Great Britain',
			[ECountry.Croatia]: 'Croatia',
			[ECountry.Hungary]: 'Hungary',
			[ECountry.Japan]: 'Japan',
			[ECountry.Poland]: 'Poland',
			[ECountry.Romania]: 'Romania',
			[ECountry.Sweden]: 'Sweden',
			[ECountry.UnitedStates]: 'United States',
			[ECountry.Afghanistan]: 'Afghanistan',
			[ECountry.Albania]: 'Albania',
			[ECountry.Algeria]: 'Algeria',
			[ECountry.Andorra]: 'Andorra',
			[ECountry.Angola]: 'Angola',
			[ECountry.SaudiArabia]: 'Saudi Arabia',
			[ECountry.Argentina]: 'Argentina',
			[ECountry.Armenia]: 'Armenia',
			[ECountry.Australia]: 'Australia',
			[ECountry.Austria]: 'Austria',
			[ECountry.Azerbaijan]: 'Azerbaijan',
			[ECountry.Bangladesh]: 'Bangladesh',
			[ECountry.Belgium]: 'Belgium',
			[ECountry.Belarus]: 'Belarus',
			[ECountry.BosniaAndHerzegovina]: 'Bosnia And Herzegovina',
			[ECountry.Brazil]: 'Brazil',
			[ECountry.Chile]: 'Chile',
			[ECountry.China]: 'China',
			[ECountry.Cyprus]: 'Cyprus',
			[ECountry.CzechRepublic]: 'Czech Republic',
			[ECountry.Egypt]: 'Egypt',
			[ECountry.Ecuador]: 'Ecuador',
			[ECountry.Estonia]: 'Estonia',
			[ECountry.Ethiopia]: 'Ethiopia',
			[ECountry.Finland]: 'Finland',
			[ECountry.France]: 'France',
			[ECountry.Greece]: 'Greece',
			[ECountry.Georgia]: 'Georgia',
			[ECountry.Spain]: 'Spain',
			[ECountry.India]: 'India',
			[ECountry.Indonesia]: 'Indonesia',
			[ECountry.Iraq]: 'Iraq',
			[ECountry.Iran]: 'Iran',
			[ECountry.Ireland]: 'Ireland',
			[ECountry.Iceland]: 'Iceland',
			[ECountry.Israel]: 'Israel',
			[ECountry.Yemen]: 'Yemen',
			[ECountry.Jordan]: 'Jordan',
			[ECountry.Cambodia]: 'Cambodia',
			[ECountry.Qatar]: 'Qatar',
			[ECountry.Kazakhstan]: 'Kazakhstan',
			[ECountry.Kenya]: 'Kenya',
			[ECountry.Kyrgyzstan]: 'Kyrgyzstan',
			[ECountry.Colombia]: 'Colombia',
			[ECountry.CongoDemocraticRepublicOfTheCongo]: 'Congo Democratic Republic Of The Congo',
			[ECountry.SouthKorea]: 'South Korea',
			[ECountry.NorthKorea]: 'North Korea',
			[ECountry.CostaRica]: 'Costa Rica',
			[ECountry.Cuba]: 'Cuba',
			[ECountry.Kuwait]: 'Kuwait',
			[ECountry.Laos]: 'Laos',
			[ECountry.Lebanon]: 'Lebanon',
			[ECountry.Libya]: 'Libya',
			[ECountry.Liechtenstein]: 'Liechtenstein',
			[ECountry.Lithuania]: 'Lithuania',
			[ECountry.Luxembourg]: 'Luxembourg',
			[ECountry.Latvia]: 'Latvia',
			[ECountry.Macedonia]: 'Macedonia',
			[ECountry.Malaysia]: 'Malaysia',
			[ECountry.Malta]: 'Malta',
			[ECountry.Morocco]: 'Morocco',
			[ECountry.Mexico]: 'Mexico',
			[ECountry.Moldova]: 'Moldova',
			[ECountry.Monaco]: 'Monaco',
			[ECountry.Mongolia]: 'Mongolia',
			[ECountry.Netherlands]: 'Netherlands',
			[ECountry.Germany]: 'Germany',
			[ECountry.Nigeria]: 'Nigeria',
			[ECountry.Norway]: 'Norway',
			[ECountry.NewZealand]: 'New Zealand',
			[ECountry.Oman]: 'Oman',
			[ECountry.Pakistan]: 'Pakistan',
			[ECountry.PalestinianNationalAuthority]: 'Palestinian National Authority',
			[ECountry.Panama]: 'Panama',
			[ECountry.Peru]: 'Peru',
			[ECountry.Portugal]: 'Portugal',
			[ECountry.RepublicOfSouthAfrica]: 'Republic Of South Africa',
			[ECountry.Russia]: 'Russia',
			[ECountry.SanMarino]: 'San Marino',
			[ECountry.Senegal]: 'Senegal',
			[ECountry.RepublicOfSerbiaAndRepublicOfMontenegro]: 'Republic Of Serbia And Republic Of Montenegro',
			[ECountry.Singapore]: 'Singapore',
			[ECountry.Slovakia]: 'Slovakia',
			[ECountry.Slovenia]: 'Slovenia',
			[ECountry.UnitedStatesOfAmericaIncludingNewYorkWashington]:
				'United States Of America Including New York Washington',
			[ECountry.Syria]: 'Syria',
			[ECountry.Tajikistan]: 'Tajikistan',
			[ECountry.Thailand]: 'Thailand',
			[ECountry.Tanzania]: 'Tanzania',
			[ECountry.Tunisia]: 'Tunisia',
			[ECountry.Turkey]: 'Turkey',
			[ECountry.Turkmenistan]: 'Turkmenistan',
			[ECountry.Ukraine]: 'Ukraine',
			[ECountry.Uruguay]: 'Uruguay',
			[ECountry.Uzbekistan]: 'Uzbekistan',
			[ECountry.Venezuela]: 'Venezuela',
			[ECountry.Vietnam]: 'Vietnam',
			[ECountry.Italy]: 'Italy',
			[ECountry.IvoryCoast]: 'IvoryCoast',
			[ECountry.Zimbabwe]: 'Zimbabwe',
			[ECountry.UnitedArabEmirates]: 'UnitedArabEmirates',
			[ECountry.OtherCountries]: 'OtherCountries',
		},
	},
};
