import { AsyncAction } from '@skillchill/redux-promisify';

import { ICurrency } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/currency';

import { IJson } from '@/store/ITypes';
import { Action } from '@/store/types';
import axios from '@/utils/axios';

import { ActionType } from '../types';

export const FETCH_ALL_CURRENCIES: ActionType = 'FETCH_ALL_CURRENCIES';

const basePath = 'Currency';

function _fetchCurrencies(currencies: ICurrency[]): Action {
	return {
		type: FETCH_ALL_CURRENCIES,
		currencies,
	};
}

export function fetch_all_currencies(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchCurrencies((json as IJson).data)));
		return axios().get(`${basePath}`);
	};
}
