export const Translation = {
	pl: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						popupAddNewSocialMedia: {
							title: 'Konfiguracja social media linków',
						},
						successSave: 'Pomyślnie skonfigurowano linki',
						errorSave: 'Błąd podczas zapisu. Zmiany w linkach nie zostały zapisane',
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				ProfileDetails: {
					AddSocialMediaCard: {
						popupAddNewSocialMedia: {
							popupAddNewSocialMedia: {
								title: 'Configuration of social media links',
							},
							successSave: 'Links successfully changed',
							errorSave: 'Error while saving. The changes to the links have not been saved',
						},
					},
				},
			},
		},
	},
};
