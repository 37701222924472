import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mainKey: 'UserList',
	password: 'password' as const,
	newPassword: 'new-password' as const,
	new: 'new' as const,
	usersPath: '/admin/organization/tabs/users' as const,
	avatarSize: { width: 100, height: 100 },
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				CreateUser: {
					pageTitle: 'Dodawanie użytkownika',
					pageTitleEdit: 'Edycja użytkownika',
					labels: {
						roleLabel: 'Rola',
						loginLabel: 'Login',
						passwordLabel: 'Hasło',
						emailLabel: 'Email',
						avatarLabel: 'Ścieżka awatara',
						name: 'Imie',
						surname: 'Nazwisko',
					},
					save: 'Zapisz',
					userCreated: 'Pomyślnie stworzono użytkownika',
					userUpdated: 'Pomyślnie zaktualizowano użytkownika',
					loginAlreadyInUse: 'Login lub email jest zajęty',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				CreateUser: {
					pageTitle: 'Add user',
					pageTitleEdit: 'Edit user',
					labels: {
						roleLabel: 'Role',
						loginLabel: 'Login',
						passwordLabel: 'Password',
						emailLabel: 'Email',
						avatarLabel: 'Avatar path',
						name: 'Name',
						surname: 'Surname',
					},
					save: 'Save',
					userCreated: 'User successfully created',
					userUpdated: 'User successfully updated',
					loginAlreadyInUse: 'Login or email is already used',
				},
			},
		},
	},
};
