import { makeStyles } from '@mui/styles';

import { isRtl } from '@skillandchill/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@skillandchill/tasker-widgets2/dist/utils/theme';

export const useStyles = makeStyles(
	(theme: CustomTheme) => ({
		...getGlobalStyles(),
		item: {
			display: 'block',
			paddingTop: 0,
			paddingBottom: 0,
		},
		itemLeaf: {
			paddingTop: 0,
			paddingBottom: 0,
		},
		button: {
			padding: '10px 8px',
			justifyContent: 'flex-start',
			textTransform: 'none',
			letterSpacing: 0,
			// color: theme.palette.white.contrastText,
		},
		buttonLeaf: {
			padding: '10px 8px',
			justifyContent: 'flex-start',
			textTransform: 'none',
			letterSpacing: 0,
			width: '100%',
			// fontWeight: 'regular',
			'&.depth-0': {
				fontWeight: theme.typography.fontWeightMedium,
			},
			display: 'flex',
			flexDirection: isRtl() ? 'row-reverse' : 'row',
			textAlign: isRtl() ? 'end' : 'unset',
		},
		icon: {
			color: theme.palette.icon,
			marginRight: theme.spacing(1),
		},
		expandIcon: {
			marginLeft: 'auto',
			height: 16,
			width: 16,
		},
		label: {
			marginLeft: 'auto',
		},
		active: {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium as 'normal',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
	}),
	{ name: `Dashboard_NavBarSection_NavItem` }
);
