import { Action, ActionType } from '@/store/types';

export const NAV_BAR_SET: ActionType = 'NAV_BAR_SET';

export function navBar_SetState(value: boolean): Action {
	return {
		type: NAV_BAR_SET,
		value,
	};
}
