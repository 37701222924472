import React from 'react';
import { components } from 'react-select';

import { useStyles } from './styles';

export const SingleValueCustom = (props: any) => {
	const classes = useStyles();

	return (
		<components.SingleValue {...props}>
			<div
				className={classes.tag}
				style={{
					backgroundColor: props?.data?.object?.TagColor,
				}}
			>
				{props.children}
			</div>
		</components.SingleValue>
	);
};
