import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Notification: {
								Edit: {
									channelId: 'Id kanału',
									message: 'Wiadomość',
									useUserAsHeader: 'Użyj użytkownika jako nagłówek',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Notification: {
								Edit: {
									channelId: 'Channel id',
									message: 'Message',
									useUserAsHeader: 'Use user as header',
								},
							},
						},
					},
				},
			},
		},
	},
};

export enum EActiveStep {
	Form = 0,
	Notification = 1,
	Users = 2,
}
