import { t } from 'i18next';
import uuid from 'uuid';

import { EWorklogIssues } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	NotificationList: {
		routerLinkRedirect: '#',
	},
	muiPaperElevation: 'MuiPaper-elevation2',
	droppable: 'droppable',
	virtual: 'virtual' as const,
	comment: 'comment',
	escapeKeyDown: 'escapeKeyDown' as const,
	backdropClick: 'backdropClick' as const,
	fullWidth: {
		style: { width: '100%' },
	},
	tileHeight: { height: '70px' },
	activeIssueKey: 'activeIssueKey_',
	activeKey: (id: number) => `ActiveTask_${id}_${uuid()}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dashboard: {
			WorklogStarter: {
				WorklogStarterPopover: {
					button: 'Zobacz wszystkie',
					comment: 'Komentarz',
					save: 'Zapisz',
					issueMore: 'Więcej o zgłoszeniu',
					sleepIssue: 'Nad niczym nie pracujesz...',
					issues: 'Zgłoszenia',
					searchIssues: 'Wyszukaj zgłoszenia...',
					due: 'Termin',
					yourIssues: 'Twoje zgłoszenia',
					estimatedTime: 'Szacowany czas',
					showLess: 'Pokaż więcej',
					showMore: 'Pokaż mniej',
					showAll: 'Pokaż wszystkie',
					reorder: 'Tryb zmiany kolejności',
					tabs: {
						allIssues: 'Wszystkie',
						lastLoggedIssues: 'Ostatnio logowane',
						favourites: 'Ulubione',
						active: 'Aktywne',
					},
				},
			},
		},
	},
	en: {
		Dashboard: {
			WorklogStarter: {
				WorklogStarterPopover: {
					button: 'See all',
					comment: 'Comment',
					save: 'Save',
					issueMore: 'More about the issue',
					sleepIssue: "You're not working...",
					issues: 'Issues',
					searchIssues: 'Search issues...',
					due: 'Due',
					yourIssues: 'Your ActivityIssues',
					estimatedTime: 'Estimated time',
					showLess: 'Show less',
					showMore: 'Show more',
					showAll: 'Show all',
					reorder: 'Reorder mode',
					tabs: {
						allIssues: 'All',
						lastLoggedIssues: 'Last logged',
						favourites: 'Favourites',
						active: 'Active',
					},
				},
			},
		},
	},
};

export const getTabs = () => {
	return [
		{
			value: EWorklogIssues.Favourites,
			label: t('Dashboard.WorklogStarter.WorklogStarterPopover.tabs.favourites'),
		},
		{
			value: EWorklogIssues.Last,
			label: t('Dashboard.WorklogStarter.WorklogStarterPopover.tabs.lastLoggedIssues'),
		},
		{
			value: EWorklogIssues.All,
			label: t('Dashboard.WorklogStarter.WorklogStarterPopover.tabs.allIssues'),
		},
		{
			value: EWorklogIssues.Active,
			label: t('Dashboard.WorklogStarter.WorklogStarterPopover.tabs.active'),
		},
	];
};
