import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	id: 'simple-popover',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					HeaderCell: {
						sortAsc: 'Posortuj rosnąco',
						sortDesc: 'Posortuj malejąco',
						deleteSort: 'Usuń sortowanie',
						deleteAllSorts: 'Usuń wszystkie sortowania',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					HeaderCell: {
						sortAsc: 'Sort ascending',
						sortDesc: 'Sort descending',
						deleteSort: 'Delete this sort',
						deleteAllSorts: 'Delete all applied sorts',
					},
				},
			},
		},
	},
};
