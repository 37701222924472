import { merge } from 'lodash';
// @ts-ignore
import storejs from 'store';

import { getFullOptions } from '@/store/middleware/localStorage/middleware';
import { Options, PathList } from '@/store/middleware/localStorage/types';
// @ts-ignore

// eslint-disable-next-line @typescript-eslint/ban-types
const initializeState = (paths: PathList, options?: Options): object => {
	const defaults = getFullOptions(options);
	let state = {};
	for (const path of paths) {
		const parts = path.path?.split('.').reverse() ?? [];
		const value = storejs.get(defaults.pathPrefix + path.path);
		const object = parts.reduce((curr, next) => ({ [next]: curr }), value);
		state = merge(object, state);
	}
	return state;
};
export default initializeState;
