import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	propertyField: {
		label: 'Label' as const,
		isImmutable: 'IsImmutable' as const,
		contextId: 'TagGroupContextId' as const,
	},
	menuPosition: 'menuPosition' as const,
	positionFixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		TagAdminPanel: {
			GroupPopup: {
				Content: {
					groupName: 'Nazwa grupy',
					isPortal: 'Grupa tagów portalowa',
					groupContext: 'Kontekst grupy',
				},
			},
		},
	},
	en: {
		TagAdminPanel: {
			GroupPopup: {
				Content: {
					groupName: 'Group name',
					isPortal: 'Portal tag group',
					groupContext: 'Group context',
				},
			},
		},
	},
};
