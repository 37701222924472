import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EKeys } from '@skillandchill/tasker-types/dist/types/common/keys';
import { EWorkLogOperation } from '@skillandchill/tasker-types/dist/types/endPoints/worklogShortcut';

import { keyDidChange, work_log_manage_clock } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { errorVariant, successVariant } from '@/utils/snackBar';

import { Resources } from './resources';

export interface KeyListenerProps {
	snackbarRef: React.RefObject<SnackbarProvider>;
}

export const KeyListener = ({ snackbarRef }: KeyListenerProps): JSX.Element => {
	const dispatch: Dispatch = useDispatch();
	const { t } = useTrans('KeyListener');
	const [excludes] = useState([
		'/errors/error-401',
		'/errors/error-404',
		'/errors/error-500',
		'/auth/loading',
		'/auth/login',
		'/auth/changepassword',
		'/auth/register',
		'/auth/recover',
	]);

	useEffect(() => {
		document.addEventListener(Resources.keydown, handleKeyDown, { passive: true });
		return () => {
			document.removeEventListener(Resources.keydown, handleKeyDown, true);
		};
	}, []);

	function isUrlForbidden() {
		const href = window.location.hash;

		for (let i = 0; i < excludes.length; i++) {
			const exclude = excludes[i];
			if (href.includes(exclude)) return true;
		}
		return false;
	}

	function handleKeyDown(event: KeyboardEvent) {
		const ctrAlt = event.ctrlKey && event.altKey;
		if (event.key === EKeys.Escape) if (!isUrlForbidden()) dispatch(keyDidChange(EKeys.Escape));
		if (event.key === EKeys.Enter) dispatch(keyDidChange(EKeys.Enter));
		if (event.ctrlKey && event.altKey && event.key == EKeys.Plus)
			if (!isUrlForbidden() && !window.location.hash.includes(Resources.issuePathPart))
				dispatch(keyDidChange(EKeys.I));
		if (ctrAlt && event.key == EKeys.ArrowLeft) dispatch(keyDidChange(EKeys.ArrowLeft));
		if (ctrAlt && event.key == EKeys.ArrowRight) dispatch(keyDidChange(EKeys.ArrowRight));
		if (event.ctrlKey && event.altKey && event.key == '1') {
			//ToDo Dawid use enum not string
			dispatch(work_log_manage_clock(EWorkLogOperation.StartWorkLog))
				.then(() => snackbarRef.current?.enqueueSnackbar(t('startIssue'), successVariant))
				.catch(() => snackbarRef.current?.enqueueSnackbar(t('shortcutError'), errorVariant));
		}

		if (event.ctrlKey && event.altKey && event.key == '2') {
			//ToDo Dawid use enum not string
			dispatch(work_log_manage_clock(EWorkLogOperation.PauseWorkLog))
				.then(() => snackbarRef.current?.enqueueSnackbar(t('pauseIssue'), successVariant))
				.catch(() => snackbarRef.current?.enqueueSnackbar(t('shortcutError'), errorVariant));
		}
		if (event.ctrlKey && event.altKey && event.key == '3') {
			//ToDo Dawid use enum not string
			dispatch(work_log_manage_clock(EWorkLogOperation.FinishWorkLog))
				.then(() => snackbarRef.current?.enqueueSnackbar(t('endIssue'), successVariant))
				.catch(() => snackbarRef.current?.enqueueSnackbar(t('shortcutError'), errorVariant));
		}
	}

	return <div />;
};
