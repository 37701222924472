import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	adviceTooltip: {
		patterns: {
			second: 'hh:mm',
			third: 'h:mm',
			forth: 'h:m',
		},
	},
	to: '-',
	hourMark: 'h',
	minuteMark: 'm',
	red: 'red' as const,
	black: 'black' as const,
	time: 'time' as const,
	trueString: 'true' as const,
	color: {
		one: '#d5d5d5',
		two: '#000',
	},
};

export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					Duration: {
						timeName: 'Czas',
						adviceTooltip: {
							firstLine: 'Wzorzec:',
							patterns: {
								first: 'Możliwe znaki to: ;,.:(spacja)',
								fifth: 'Jeżeli po liczbie wstawisz znak m lub M liczba zostanie uznana jako minuta',
								sixth: 'Jeżeli po liczbie wstawisz znak h lub H liczba zostanie uznana jako godzina',
								seventh: 'Dodatkowym wzorcem jest 00,00h gdzie liczby 0 oznaczają dowolne liczby 0-9',
							},
						},
						wrongFormat:
							'Wprowadzony format jest niewłaściwy proszę sprawdzić czy system poprawnie przetworzył dane.',
						dateFormat: 'Ustaw zakres czasu pracy',
						startTime: 'Czas rozpoczęcia',
						endTime: 'Czas zakończenia',
						minutesOver24: 'Podany czas wynosi powyżej 24h.',
					},
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					Duration: {
						timeName: 'Time',
						adviceTooltip: {
							firstLine: 'Pattern:',
							patterns: {
								first: 'Possible characters: ;,.:(space)',
								fifth: 'If put character m or M after number it will be assigned as minute',
								sixth: 'If put character h or H after number it will be assigned as hour',
								seventh: 'Additional pattern is 00,00h where number 0 is number between 0-9',
							},
						},
						wrongFormat: 'Invalid format. Please check that system correctly processed data.',
						dateFormat: 'Set up time frames',
						startTime: 'Start time',
						endTime: 'End time',
						minutesOver24: 'Provided time is more than 24h.',
					},
				},
			},
		},
	},
};
