import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed',
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					RightSideIssueData: {
						properties: 'Właściwości zgłoszenia',
					},
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					RightSideIssueData: {
						properties: 'Issue properties',
					},
				},
			},
		},
	},
};
