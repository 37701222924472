import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { isRtl } from '@skillandchill/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		mobileDrawer: {
			width: theme.spacing(32),
			right: isRtl() ? 0 : 'revert',
			left: 'revert',
		},
		desktopDrawer: {
			width: props => props.maxWidth,
			zIndex: 'auto',
			top: props => `${props.navBarHeight}!important`,
			height: props => `calc(100% - ${props.navBarHeight})`,
			right: isRtl() ? 0 : 'revert',
			left: 'revert',
		},
	}),
	{ name: `Dashboard_NavBar` }
);
