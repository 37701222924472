import { t } from 'i18next';

import { IInvoiceItem, IMeasureKind } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	invoiceItem: (measureKind: IMeasureKind, id: number) =>
		({
			Id: id,
			Vat: 23,
			Quantity: 0,
			MeasureKind: measureKind,
			MeasureKindId: measureKind?.Id,
			GrossPrice: 0,
			NetPrice: 0,
			Name: t('CustomerDetail.Content.Invoices.HeaderInvoice.InvoiceModal.Content.Items.new'),
		} as IInvoiceItem),
};
export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Content: {
								Items: {
									add: 'Dodaj nowy przedmiot faktury',
									new: 'Nowy',
									name: 'Nazwa',
									quantity: 'Ilość',
									netPrice: 'Netto',
									grossPrice: 'Brutto',
									measureKindId: 'Jednostki',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Content: {
								Items: {
									add: 'Add new invoice item',
									new: 'New',
									name: 'Name',
									quantity: 'Quantity',
									netPrice: 'Net price',
									grossPrice: 'Gross price',
									measureKind: 'Measure kind',
								},
							},
						},
					},
				},
			},
		},
	},
};
