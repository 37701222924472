import { Dispatch, SetStateAction } from 'react';

export interface Props {
	activeFilter: EDelegationAcceptanceListFilter;
	setActiveFilter: Dispatch<SetStateAction<EDelegationAcceptanceListFilter>>;
	setPage: Dispatch<SetStateAction<number>>;
}

export enum EDelegationAcceptanceListFilter {
	All = 1,
	ToAccept = 2,
	Done = 3,
}
