import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			TileComponents: {
				DelegationMenu: {
					menuTransfer: 'Sprawdź i przekaż delegację do akceptacji',
					menuRequestToDone: 'Sprawdź i zakończ delegację',
					menuCopy: 'Utwórz delegację na podstawie kopi',
					menuRemove: 'Usuń delegację',
					menuRaportPDF: 'Pobierz raport w formacie PDF',
					menuRaportXLSX: 'Pobierz raport w formacie XLSX',
				},
			},
		},
	},
	en: {
		DelegationList: {
			TileComponents: {
				DelegationMenu: {
					menuTransfer: 'Validate and transfer delegation for approval',
					menuRequestToDone: 'Validate and end delegation',
					menuCopy: 'Create a delegation based on a copy',
					menuRemove: 'Remove delegation',
					menuRaportPDF: 'Download report in PDF format',
					menuRaportXLSX: 'Download report in XLSX format',
				},
			},
		},
	},
};
