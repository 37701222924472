import React from 'react';
import { GoogleLoginResponse, GoogleLogout } from 'react-google-login';
import { useSelector } from 'react-redux';

import InputIcon from '@mui/icons-material/Input';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, IconButton, Tooltip } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';

import { LogOutButtonProps } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const LogOutButton = (props: LogOutButtonProps): JSX.Element => {
	const { onLogOut, logOutText } = props;
	const classes = useStyles();

	const sso: GoogleLoginResponse = useSelector((state: IReducerState) => state?.Session?.SSO);

	return (
		<VisibilityControl
			condition={!!sso && !!sso?.accessToken}
			alternative={
				<Tooltip title={logOutText}>
					<Button onClick={onLogOut} className={classes.button}>
						<LogoutIcon />
					</Button>
				</Tooltip>
			}
		>
			<GoogleLogout
				clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? Resources.GlobalResources.empty}
				onLogoutSuccess={onLogOut}
				render={renderProps => (
					<Tooltip title={logOutText}>
						<Button onClick={renderProps.onClick} className={classes.button}>
							<LogoutIcon />
						</Button>
					</Tooltip>
				)}
			/>
		</VisibilityControl>
	);
};
