import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					HistoryCard: {
						delete: 'Usuń',
						edit: 'Modyfikuj',
						workLogDelete: 'Usunięto wpis czasu pracy',
						errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
					},
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				HistoryTab: {
					HistoryCard: {
						delete: 'Delete',
						edit: 'Edit',
						workLogDelete: 'Worklog deleted',
						errorWorkLogDelete: 'Error while deleting worklog',
					},
				},
			},
		},
	},
};
