import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	nameFirstNameTextField: 'Name',
	nameLastNameTextField: 'Surname',
	nameEmailTextField: 'Email',
	namePhoneNumberTextField: 'phoneNumber',
	settingsUrl: '/settings/general',
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				GeneralSettings: {
					CardContentCustom: {
						fields: {
							Name: 'Imię',
							Surname: 'Nazwisko',
							Email: 'Adres email',
							phoneNumber: 'Numer telefonu',
							publicProfileHeader: 'Profil publiczny',
							publicProfileDetails:
								'Oznacza, że każdy przeglądający Twój profil zobaczy Twoje dane kontaktowe',
						},
					},
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				GeneralSettings: {
					CardContentCustom: {
						fields: {
							Name: 'Name',
							Surname: 'Surname',
							Email: 'Email',
							phoneNumber: 'Phone number',
							publicProfileHeader: 'Public profile',
							publicProfileDetails:
								'Means that anyone viewing your profile will be able to see your contacts details',
						},
					},
				},
			},
		},
	},
};
