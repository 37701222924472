import clsx from 'clsx';
import { Suspense } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { LinearProgress } from '@mui/material';

import { useStyles } from './styles';
import { TopBar } from './TopBar';

export const Auth = (props: RouteConfigComponentProps): JSX.Element => {
	const { route } = props;
	const classes = useStyles();

	return (
		<>
			<TopBar />
			<div
				className={clsx(
					classes.minvhOfHeight,
					classes.flexRow,
					classes.flexGrow,
					classes.maxOfWidth,
					classes.overflowXHidden,
					classes.container
				)}
			>
				<Suspense fallback={<LinearProgress />}>{renderRoutes(route?.routes)}</Suspense>
			</div>
		</>
	);
};

export default Auth;
