import { AsyncAction } from '@skillchill/redux-promisify';

import { IIssue } from '@skillandchill/tasker-types';
import { IIssueAcceptance } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueAcceptance';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

const basePath = 'IssueAcceptance';

export const ADD_ISSUE_ACCEPTANCE: ActionType = 'ADD_ISSUE_ACCEPTANCE';
export const REMOVE_ISSUE_ACCEPTANCE: ActionType = 'REMOVE_ISSUE_ACCEPTANCE';

function _addIssueAcceptance(issueId: number): Action {
	return {
		type: ADD_ISSUE_ACCEPTANCE,
		issueId,
	};
}

function _removeIssueAcceptance(issueId: number): Action {
	return {
		type: REMOVE_ISSUE_ACCEPTANCE,
		issueId,
	};
}

export function addIssueAcceptance(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_addIssueAcceptance((data as IJson).data)));
		return axios().post(`${basePath}/${issueId}`);
	};
}

export function removeIssueAcceptance(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_removeIssueAcceptance(issueId)));
		return axios().delete(`${basePath}/${issueId}`);
	};
}
