import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	ariaValue: 'primary checkbox' as const,
	hashIssue: '#',
	https: 'https://',
	http: 'http://',
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						SocialMediaLink: {
							delete: 'Usuń link',
							edit: 'Edytuj link',
							openInNewTab: 'Otwórz link w nowej karcie',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						SocialMediaLink: {
							delete: 'Delete link',
							edit: 'Edit link',
							openInNewTab: 'Open link in a new tab',
						},
					},
				},
			},
		},
	},
};
