import { Translation as FilterOptionsArchived } from './KanbanHeader/Filters/Options/Archived/resources';
import { Translation as FilterOptionsButtons } from './KanbanHeader/Filters/Options/Buttons/resources';
import { Translation as FilterOptionsComments } from './KanbanHeader/Filters/Options/Comments/resources';
import { Translation as QuickFilters } from './KanbanHeader/Filters/Options/QuickFilters/resources';
import { Translation as Filters } from './KanbanHeader/Filters/resources';

export const Translation = {
	pl: {
		Kanban: {
			noSuchKanban: 'Nie ma takiego kanbana!',
			AddColumn: {
				toLongLink: 'Link do zdjęcia jest za długi',
				labelName: 'Etykieta',
				emptyLabel: 'Etykieta nie może być pusta',
				imageLink: 'Link do zdjęcia',
				color: 'Kolor',
				wrongColorFormat: 'Niepoprawny format koloru',
				save: 'Zapisz',
				cancel: 'Anuluj',
				labelErrorMessage: '^Podaj nazwę kolumny!',
			},
			deleteColumn: 'Czy na pewno chcesz usunąć tą kolumnę?',
			KanbanHeader: {
				add: 'Dodaj Kanban',
				configure: 'Konfiguruj kanban',
				liteMode: 'Lite mode',
				kanbanLightMode:
					'Wyświetlaj tylko podstawowe informacje na kafelkach kanbana, polepszy to wydajność całego kanbana',
				KanbanPicker: { search: 'Szukaj', chooseKanban: 'Wybierz kanban' },
			},
			KanbanCreator: {
				kanbanName: 'Nazwa kanbana',
				addProjects: 'Dodaj projekty z których ma zostać stworzony kanban',
				configureYourSwimlines: 'Skkonfiguruj swoje swimliny',
				nameYourKanban: 'Wybierz nazwę swojego kanbana',
				configureTags: 'Skonfiguruj grupę tagów. Tagi będą kolumnami kanbana',
				nameStep: 'Nazwij kanban',
				projectsStep: 'Wybierz projekty',
				columnsStep: 'Skonfiguruj kolumny',
				swimlinesStep: 'Utwórz pierwszy swimline',
				popupLabel: 'Kreator kanbana',
				editStep: 'Edytuj krok',
				resetStep: 'Resetuj krok',
				resetStepTooltip: 'Naciśnięcie tego przycisku spowoduje cofnięcie wszystkich zmian w tym kroku.',
				sureReset: 'Are you sure you want to reset whole changes in this step?',
				close: 'Zamknij',
				name: 'Nazwa',
				projects: 'Projekty',
				tagGroup: 'Kanbanowa grupa tagów',
				tagGroupName: 'Nazwa grupy tagów dla kolumn',
				columns: 'Kolumny',
				finish: 'Zakończ',
				completeStep: 'Zakończ krok',
				createKanban: 'Stwórz Kanban',
				back: 'Wróć',
				summary: 'Podsumowanie',
				step: 'Krok',
				next: 'Dalej',
				goBackToCreator: 'Wróć do kreatora',
				alreadyCompleted: 'jest już zakończony',
				TagsCreation: {
					addNewTag: 'Dodaj nowy tag',
					or: 'Lub',
					chooseExistingTagGroup: 'Wybierz grupę tagów aby dodać tagi z istniejącej już grupy',
					clockFinish: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po zakończeniu zadania.',
					clockPause: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po wstrzymaniu zadania.',
					clockStart: 'Konfiguracja zegarka. Gdzie ma trafić zadanie po rozpoczęciu zadania.',
				},
				GroupContent: {
					groupName: 'Nazwa grupy',
					groupContext: 'Kontekst grupy',
					isPortal: 'Grupa tagów portalowa',
				},
				Actions: { save: 'Zapisz', cancel: 'Anuluj', reset: 'Resetuj kreator' },
				nameErrorMessage: '^Podaj nazwę kanbana!',
				nameSwimlineErrorMessage: '^Nazwa swimline powinna zawierać od 1 do 50 znaków!',
				projectsErrorMessage: '^Dodaj projekty do kanbana!',
				tagsErrorMessage: '^Dodaj kolumny do kanbana!',
				tagGroupLabelErrorMessage: '^Podaj nazwę grupy!',
				createTagGroup: 'Stwórz nową grupę',
				rest: 'Pozostałe zadania',
				createKanbanError: 'Wystąpił błąd podczas tworzenia kanbana. Spróbuj ponownie.',
				goBack: 'Idź do poprzedniego kroku',
				goForward: 'Idź do następnego kroku',
			},
			KanbanContent: {
				ConfigureKanban: {
					back: 'Wstecz',
					deleteKanban: 'Usuń kanban',
					deleteKanbanConfirmation: 'Czy na pewno chcesz usunąć ten kanban?',
					General: {
						nameLabel: 'Nazwa kanbana',
						kanban: 'Konfiguracja kanbana',
						save: 'Zapisz',
						projects: 'Projekty',
						columns: 'Kolumny',
						deleteColumnConfirmation: 'Czy na pewno chcesz usunąć kolumnę kanbana?',
						addColumn: 'Dodaj kolumnę',
						kanbanSubtext: 'Tutaj możesz edytować nazwę kanbana, projekty oraz kolumny kanbana',
						general: 'Ogólne',
						Errors: {
							Name: 'Nazwa musi zawierać od 1 do 100 znaków',
						},
					},
					Mappings: {
						saveChanges: 'Zapisz zmiany',
						kanbanName: 'Nazwa kanbana',
						projects: 'Projekty',
						deleteProject1:
							'Jeśli wybrany formularz należy do projektu, który chcesz usunąć dokonane zmiany i mapowania',
						deleteProject2: 'Mapowania',
						deleteProject3: 'dla tego projektu zostaną bezpowrotnie usunięte! Kontynuować?',
						form: 'Formularz',
						changesWontBeSave: 'Dokonane zmiany nie zostaną zapisane! Czy kontynuować?',
						mappingsTitle: 'Mapowania kanbana',
						save: 'Zapisz',
						subtext:
							'W tym miejscu możesz zarządzać mapowaniami kanbana, dodawać, usuwać lub generować automatycznie',
					},
					Swimlines: {
						saveChanges: 'Zapisz zmiany',
						addSwimline: 'Dodaj swimline',
						swimlines: 'Konfiguracja swimline',
						subtext: 'Tutaj możesz dodawać i konfigurować swimliny',
					},
					Tabs: {
						General: 'Główne',
						Mappings: 'Mapowania',
						Swimlines: 'Swimliny',
					},
				},
				ColumnsKanbanContent: {
					save: 'Zapisz',
					chooseTags: 'Wybierz tagi, które chcesz przypisać do pola statusowego',
					Swimline: {
						SwimlineChart: {
							issues: 'Zgłoszenia',
							issuesInColumns: 'Zadania w kolumnach',
							noIssues: 'Brak zgłoszeń',
						},
						addIssue: 'Dodaj zgłoszenie',
						swimLineBasic: 'Pozostałe',
						TaskList: {
							InnerItem: { noMappedTag: 'Brak zmapowanego taga do kolmny' },
							chooseStatusToAssign: 'Wybierz status, który chcesz ustawić w zgłoszeniu',
							KanbanTile: {
								CardActionsContent: {
									creator: 'Twórca zadania',
									reporters: 'Osoby zgłaszające zadanie',
									assignees: 'Osoby wykonujące zadanie',
									observers: 'Obserwatorzy zadania',
									attachments: 'Załączniki',
									comments: 'Komentarze',
									info:
										'To zadanie ma w statusie tagi z wielu kolumn kanbana, dlatego występuje w kilku miejscach',
								},
								CardHeader: {
									new: 'Nowy',
								},
							},
						},
					},
					SwimlineConfiguration: {
						SwimlineConfigurationDrawer: {
							SwimlineTile: {
								creationDate: 'Data stworzenia',
								createdBy: 'Stworzone przez',
								isDefaultSwimline: 'To jest defaultowy swimline. Do niego będą trafiać nowe zadania',
							},
							SwimlineCreationModal: {
								name: 'Nazwa',
								save: 'Zapisz',
								color: 'Kolor',
								title: 'Kreator swimline',
								altKanbanImage: 'Kanban image',
								nameInputError: 'Pole musie zawierać od 1 do 50 znaków',
								subtext: 'Swtórz oraz skonfigurj nowy swimline aby jeszcze lepiej zarządzać zadaniami',
							},
						},
						SwimlineCreator: {
							name: 'Nazwa',
							color: 'Kolor',
							save: 'Zapisz',
							cancel: 'Anuluj',
							create: 'Stwórz',
							validationMessageEmptyField: '^Pole nazwy nie może być puste',
							validationMessageToLong: '^Nazwa jest za długa!',
							validationMessageNoColor: 'Ustaw kolor!',
							validationMessageNoSuchKanban: '^Nie można znaleźć tego kanbana!',
						},
						SwimlineButtons: {
							deleteConfirmationMessage: 'Czy na pewno chcesz usunąć ten swimline?',
							defaultSwimline: 'Tego swimline nie możesz usunąć, jest to domyślny swimline',
							createdBy: 'Stworzone przez',
							creationDate: 'Data stworzenia',
							name: 'Nazwa',
							color: 'Kolor',
							delete: 'Usuń swimline',
							generalSwimline:
								'Ustaw swimline jako główny. Będą trafiały do niego wszystkie nowe zgłoszenia',
						},
						swimlineMenu: 'Menu swimeline',
						save: 'Zapisz',
						swimlineConfiguration: 'Konfiguracja swimeline',
						ifYouWantToConfigure: 'Jeśli chcesz konfigurować swimliny, ',
						useYourOwnConfiguration: 'użyj swojej konfiguracji',
						youAreUsingConfiguration: 'Używasz konfiguracji użytkownika:',
						swimlineBarCollapseIn: 'Zwiń pasek swimline',
						swimlineCollapseIn: 'Zwiń wszystkie swimeliny',
						swimlineCollapseOut: 'Rozwiń wszystkie swimeliny',
						swimlineBarCollapseOut: 'Rozwiń pasek swimline',
						scrollToSwimeline: 'Przewiń do swimelina',
						swimLineBasic: 'Pozostałe',
						NoSwimlines: 'Stwórz przynajmniej jeden swimline',
						NoDefaultSwimline: 'Wybierz swimline główny, do którego będą trafiały nowe zgłoszenia',
						addSwimline: 'Dodaj Swimline',
					},
					TaskListHeader: { issueCount: 'Liczba zgłoszeń', addIssue: 'Dodaj zgłoszenie' },
					heading: 'Lista swimline kanbanów jest pusta',
					headingTags: 'Brak kolumn kanbana',
					description: 'Stwórz swimliny, oraz skonfiguruj mapowania dla kolumn kanbana w zakładce edycji.',
					descriptionTags: 'Stwórz kolumny dla kanbana w zakładce edycji.',
				},
				EditKanbanContent: {
					InnerEditList: {
						KanbanFormSection: {
							KanbanIssueForm: {
								IssueFormSectionWithCollapse: {
									FieldDefinition: {
										MultiTag: {
											noMappedTagToColumn: 'Brak mapowania tagu statusowego do kolumny kanbana ',
											gotToTagGroup: 'Przejdź do grupy tagów przypisanej do tego pola',
										},
									},
								},
							},
						},
						TaskLiseEdit: {
							TaskListHeader: {
								InEditHeader: { editColumn: 'Edytuj kolumnę', deleteColumn: 'Usuń kolumnę' },
							},
						},
					},
					LeftSideButtons: {
						generateMappings: 'Generuj mapowania',
						addColumn: 'Dodaj kolumnę',
						generateForAll: 'Generuj dla wszystkich formularzy',
						missingMappings: 'brakujące mapowania',
						allMappings: 'wszystkie mapowania',
						generateForThisForm: 'Generuj dla tego formularza',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			noSuchKanban: 'There is no such kanban!',
			AddColumn: {
				toLongLink: 'Image link is to long',
				labelName: 'Label',
				emptyLabel: 'Label cannot be empty',
				imageLink: 'Image link',
				color: 'Color',
				wrongColorFormat: 'Invalid color format',
				save: 'Save',
				cancel: 'Cancel',
				labelErrorMessage: '^Enter name of the column!',
			},
			deleteColumn: 'Are you sure, you want delete this column?',
			KanbanHeader: {
				add: 'Add Kanban',
				configure: 'Configure kanban',
				liteMode: 'Lite mode',
				kanbanLightMode:
					'Display only basic information on kanban tiles, it will improve the efficiency of whole kanban',
				KanbanPicker: { search: 'Search', chooseKanban: 'Choose kanban' },
			},
			KanbanCreator: {
				kanbanName: 'Kanban name',
				addProjects: 'Add projects which kanban will be based on',
				configureYourSwimlines: 'Configure your swimlines',
				nameYourKanban: 'Name your kanban',
				configureTags: 'Configure tag group. Tags will be the columns of a kanban',
				nameStep: 'Name your kanban',
				projectsStep: 'Choose projects',
				columnsStep: 'Configure columns',
				swimlinesStep: 'Create first swimline',
				popupLabel: 'Kanban creator',
				editStep: 'Edit step',
				resetStep: 'Reset step',
				resetStepTooltip: 'Pressing this button will cause reset whole changes in this step.',
				sureReset: 'Jesteś pewien, że chcesz cofnąć wszystkie zmiany w tym kroku?',
				close: 'Close',
				name: 'Name',
				projects: 'Projects',
				tagGroup: 'Kanban tag group',
				tagGroupName: 'Name of the tag group for columns',
				columns: 'Columns',
				summary: 'Summary',
				finish: 'Finish',
				completeStep: 'Complete Step',
				createKanban: 'Create Kanban',
				step: 'Step',
				next: 'Next',
				goBackToCreator: 'Go back to creator',
				alreadyCompleted: 'already completed',
				back: 'Back',
				TagsCreation: {
					addNewTag: 'Add new tag',
					or: 'or',
					chooseExistingTagGroup: 'Choose a tag group to add tags from already existing tag group',
					clockFinish: 'Clock configuration. Place where should be task after end.',
					clockPause: 'Clock configuration.  Place where should be task after pause.',
					clockStart: 'Clock configuration. Place where should be task after Start.',
				},
				GroupContent: {
					groupName: 'Group name',
					groupContext: 'Group context',
					isPortal: 'Portal tag group',
				},
				Actions: { save: 'Save', cancel: 'Cancel', reset: 'Reset creator' },
				nameErrorMessage: '^Enter name of the kanban!',
				nameSwimlineErrorMessage: '^The name of the swimline should be between 1 and 50 characters!',
				projectsErrorMessage: '^Add projects to kanban!',
				tagsErrorMessage: '^Add columns to kanban!',
				tagGroupLabelErrorMessage: '^Enter name of the tag group!',
				createTagGroup: 'Create new group',
				rest: 'Other tasks',
				createKanbanError: 'An error occurred while creating kanban. Please try again.',
				addKanban: 'Create new kanban in kanban creator.',
				goBack: 'Go to previous step',
				goForward: 'Go to next step',
			},
			KanbanContent: {
				ConfigureKanban: {
					back: 'Back',
					deleteKanban: 'Delete kanban',
					deleteKanbanConfirmation: 'Czy na pewno chcesz usunąć ten kanban?',
					General: {
						nameLabel: 'Kanban name',
						kanban: 'Kanban configuration',
						save: 'Save',
						projects: 'Projects',
						columns: 'Columns',
						deleteColumnConfirmation: 'Are you sure you want to delete this kanban column?',
						addColumn: 'Add column',
						kanbanSubtext: 'Here You can edit kanban name, projects and columns of the kanban',
						general: 'General',
						Errors: {
							Name: 'Name should be between 1 to 100 characters',
						},
					},
					Mappings: {
						saveChanges: 'Save changes',
						kanbanName: 'Kanban name',
						form: 'Form',
						changesWontBeSave: `Changes you've made won't be saved! Do You want to continue?`,
						mappingsTitle: 'Kanban mappings',
						save: 'Save',
						subtext: 'Here You can manage mappings for kanban, add, delete or generate automaticly',
					},
					Swimlines: {
						saveChanges: 'Save changes',
						addSwimline: 'Add swimline',
						swimlines: 'Swimlines',
						subtext: 'Here You can add and manage your swimlines',
					},
					Tabs: {
						General: 'General',
						Mappings: 'Mappings',
						Swimlines: 'Swimlines',
					},
				},
				ColumnsKanbanContent: {
					save: 'Save',
					chooseTags: 'Choose tags, which you want to assign to status field',
					Swimline: {
						SwimlineChart: {
							issues: 'Issues',
							issuesInColumns: 'Issues in columns',
							noIssues: 'No issues',
						},
						addIssue: 'Add issue',
						swimLineBasic: 'Rest',
						TaskList: {
							InnerItem: { noMappedTag: 'There is no mapped tag to this column' },
							chooseStatusToAssign: 'Choose which status you want to assign',
							KanbanTile: {
								CardActionsContent: {
									creator: 'Issue creator',
									reporters: 'Issue reporters',
									assignees: 'Issue assignees',
									observers: 'Issue observers',
									attachments: 'Attachments',
									comments: 'Comments',
									info: `This issue has in status field more than one column tag, so it occurs in many places`,
								},
								CardHeader: {
									new: 'New',
								},
							},
						},
					},
					SwimlineConfiguration: {
						SwimlineConfigurationDrawer: {
							SwimlineTile: {
								creationDate: 'Creation date',
								createdBy: 'Created by',
								isDefaultSwimline: 'This is default swimline. New issues will appear in here',
							},
							SwimlineCreationModal: {
								name: 'Name',
								save: 'Save',
								color: 'Color',
								title: 'Swimline creator',
								altKanbanImage: 'Kanban image',
								nameInputError: 'This field must contain from 1 to 50 characters',
								subtext: 'Create and configure new swimlne to manage your issues even better',
							},
						},
						SwimlineCreator: {
							name: 'Name',
							color: 'Color',
							save: 'Save',
							cancel: 'Cancel',
							create: 'Create',
							validationMessageEmptyField: '^Name field cant be empty',
							validationMessageToLong: '^Name is to long!',
							validationMessageNoColor: 'Set the color!',
							validationMessageNoSuchKanban: '^There is no such kanban!',
						},
						SwimlineButtons: {
							deleteConfirmationMessage: 'Are you sure, you want delete this swimeline?',
							defaultSwimline: `You can't delete this swimeline, it's default swimline`,
							createdBy: 'Created by',
							creationDate: 'Creation date',
							name: 'Name',
							color: 'Color',
							delete: 'Delete swimline',
							generalSwimline: 'Set swiline as general. All new issues will apera here',
						},
						swimlineMenu: 'Swimeline menu ',
						save: 'Save',
						swimlineConfiguration: 'Swimeline configuration',
						ifYouWantToConfigure: 'If you want to configure the swimlines, ',
						useYourOwnConfiguration: 'use your own configuration',
						youAreUsingConfiguration: 'You are using a configuration of user:',
						swimlineBarCollapseIn: 'Shrink swimline bar',
						swimlineBarCollapseOut: 'Expand swimline bar',
						swimlineCollapseIn: 'Collapse all swimelines',
						swimlineCollapseOut: 'Expand all swimelines',
						scrollToSwimeline: 'Scroll to swimeline',
						swimLineBasic: 'Rest',
						NoSwimlines: 'Create atleast one swimline',
						addSwimline: 'Add a swimline',
						NoDefaultSwimline: 'Choose general swimline, where new issues will appear',
					},
					TaskListHeader: { issueCount: 'Number of issues', addIssue: 'Add issue' },
					heading: 'The list of kanban is empty',
					headingTags: 'There is no columns in kanban',
					description: 'Create swimlines and configure mapping for kanban columns in edit tab.',
					descriptionTags: 'Create columns for kanban in edit tab.',
				},
				EditKanbanContent: {
					LeftSideButtons: {
						generateMappings: 'Generate mappings',
						addColumn: 'Add column',
						generateForAll: 'Generate mappings for all forms',
						missingMappings: 'missing mappings',
						allMappings: 'all mappings',
						generateForThisForm: 'Generate for this from',
					},
					InnerEditList: {
						KanbanFormSection: {
							KanbanIssueForm: {
								IssueFormSectionWithCollapse: {
									FieldDefinition: {
										MultiTag: {
											noMappedTagToColumn: 'No mapped status tag to kanban column  ',
											gotToTagGroup: 'Go to tag group assigned to this field ',
										},
									},
								},
							},
						},
						TaskLiseEdit: {
							TaskListHeader: {
								InEditHeader: { editColumn: 'Edit column', deleteColumn: 'Delete column' },
							},
						},
					},
				},
			},
		},
	},
};

export const KanbanTranslationPL = [
	Filters.pl,
	FilterOptionsButtons.pl,
	FilterOptionsComments.pl,
	QuickFilters.pl,
	FilterOptionsArchived.pl,
	Translation.pl,
];

export const KanbanTranslationEN = [
	Filters.en,
	FilterOptionsButtons.en,
	FilterOptionsComments.en,
	QuickFilters.en,
	FilterOptionsArchived.en,
	Translation.en,
];
