import { IAttachment, IDictionaryItem, IFieldValue, IIssue, ITag, ITaskerUser } from '@skillandchill/tasker-types';

export enum EIssueModalDetailsPageFields {
	title = 'Title',
	projectId = 'ProjectId',
	projectIssueTypeId = 'ProjectIssueTypeId',
	description = 'Description',
	descriptionHtml = 'DescriptionHtml',
	assigned = 'Assigned',
	observer = 'Observer',
	reporter = 'Reporter',
	auditor = 'Auditor',
	properties = 'Properties',
	attachment = 'Attachment',
	subTasks = 'SubTasks',
	parentTasks = 'ParentTasks',
	equivalentTasks = 'EquivalentTasks',
}

export interface IFieldValuesChange {
	fieldsValueToCreate?: Partial<IFieldValue>[];
	fieldsValueToUpdate?: Partial<IFieldValue>[];
	fieldsValueToDelete?: number[];
}

export enum EInputTypes {
	text = 'text',
	number = 'number',
}

export interface IIssueModalData {
	Id?: number;
	Title?: string;
	ProjectId?: number;
	DescriptionHtml?: string;
	Description?: string;
	Assigned?: Partial<ITaskerUser>[];
	Observer?: Partial<ITaskerUser>[];
	Reporter?: Partial<ITaskerUser>[];
	Auditor?: Partial<ITaskerUser>[];
	ProjectIssueTypeId?: number | null;
	Attachment?: IAttachmentOnCreate[];
	// SubTasks?: Partial<IIssueToIssueRelationship> & { uId: string };
	SubTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	ParentTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	EquivalentTasks?: (IIssue & { RelationId: number } & { uId: string })[];
	Properties?: {
		Tags?: Record<number, ITag[]>;
		Text?: Record<number, string>;
		TextArea?: Record<number, string>;
		Integer?: Record<number, number>;
		Decimal?: Record<number, number>;
		Boolean?: Record<number, boolean>;
		Date?: Record<number, Date>;
		DateWithTime?: Record<number, Date>;
		DictionaryItems?: Record<number, IDictionaryItem[]>;
	};
}

export interface IAttachmentOnCreate extends Partial<IAttachment> {
	File?: File;
	uId: string;
}

// export enum EIssueRoleString {
// 	assigned = 'Assigned',
// }
