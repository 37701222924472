import { IRole, ITaskerObject, ITaskerUserProjectsAndRoles, ITaskerUserRole } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { getFirst } from '@/utils/utils';

export const State = {
	taskerUsers: [] as Partial<ITaskerUserProjectsAndRoles>[],
	roles: [] as IRole[],
	permissionObjects: [] as ITaskerObject[],
};
export type IRoleEditorState = typeof State;

const roleReducer = (state = State, action: ObjectAction): IRoleEditorState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_ROLE_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		case actionTypes.FETCH_ALL_ROLES_NEW:
		case actionTypes.SET_PROJECT_USERS: {
			return {
				...state,
				roles: action.roles,
			};
		}
		case actionTypes.UPDATE_PERMISSIONS:
		case actionTypes.FETCH_ROLE_PERMISSIONS:
		case actionTypes.FETCH_ALL_PERMISSIONS_NEW: {
			return {
				...state,
				permissionObjects: action.permissions,
			};
		}
		case actionTypes.ROLE_FETCH_BY_PROFILE: {
			const role: ITaskerUserRole[] = action?.roles;
			const taskerUserId = getFirst(role)?.TaskerUserId;
			if (!taskerUserId) return state;
			return {
				...state,
				taskerUsers: state?.taskerUsers?.map(x =>
					x?.Id != taskerUserId ? x : { ...x, TaskerUserRole: role }
				) as Partial<ITaskerUserProjectsAndRoles>[],
			};
		}
		default: {
			return state;
		}
	}
};

export default roleReducer;
