import clsx from 'clsx';
import { Suspense } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';

import { LinearProgress } from '@mui/material';

import { useStyles } from './styles';

export const Error = (props: RouteConfigComponentProps): JSX.Element => {
	const classes = useStyles();

	return (
		<>
			<div className={clsx(classes.minvhOfHeight, classes.flexRow, classes.root)}>
				<div className={clsx(classes.flexGrow, classes.maxOfWidth, classes.overflowXHidden)}>
					<Suspense fallback={<LinearProgress />}>{renderRoutes(props?.route?.routes)}</Suspense>
				</div>
			</div>
		</>
	);
};

export default Error;
