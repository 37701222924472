import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			GeneralTab: {
				CardActions: {
					title: 'Dane organizacji',
					description: 'Edytuj dane organizacji takie jak nazwa firmy czy dane fakturowe.',
					cancel: 'Anuluj zmiany',
					save: 'Zapisz zmiany',
					edit: 'Edytuj dane organizacji',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			GeneralTab: {
				CardActions: {
					title: 'Organization data',
					description: 'Edit organization data such as company name or billing data.',
					cancel: 'Cancel changes',
					save: 'Save changes',
					edit: 'Edit organization data',
				},
			},
		},
	},
};
