import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: '/projects/create/',
	customerAdd: (customerId: number) => `/customers/${customerId}/projectsAdd/`,
};

export const Translation = {
	pl: {
		ProjectList: {
			HeaderSuffix: {
				add: 'Dodaj',
				create: 'Stwórz',
				createTooltip: 'Stwórz nowy rekord',
				addTooltip: 'Dodaj nowy rekord',
			},
		},
	},
	en: {
		ProjectList: {
			HeaderSuffix: {
				add: 'Add',
				create: 'Create',
				createTooltip: 'Create a new record',
				addTooltip: 'Add new record',
			},
		},
	},
};
