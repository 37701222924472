import { AsyncAction } from '@skillchill/redux-promisify';

import { IQuickFiltersCreateOrUpdate } from '@skillandchill/tasker-types';
import { IQuickFilter } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/quickfilter';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'quickFilter';

export const DELETE_QUICK_FILTERS: ActionType = 'DELETE_QUICK_FILTERS';
export const UPDATE_QUICK_FILTERS: ActionType = 'UPDATE_QUICK_FILTERS';
export const CREATE_QUICK_FILTERS: ActionType = 'CREATE_QUICK_FILTERS';
export const GET_QUICK_FILTERS_ISSUE_LIST: ActionType = 'GET_QUICK_FILTERS_ISSUE_LIST';

function _updateQuickFilters(name: string, id: number): Action {
	return {
		type: UPDATE_QUICK_FILTERS,
		name,
		id,
	};
}

function _deleteQuickFilters(id: number): Action {
	return {
		type: DELETE_QUICK_FILTERS,
		id,
	};
}

function _createQuickFilters(data: IQuickFilter): Action {
	return {
		type: CREATE_QUICK_FILTERS,
		data,
	};
}

function _getQuickFilters(data: IQuickFilter[]): Action {
	return {
		type: GET_QUICK_FILTERS_ISSUE_LIST,
		data,
	};
}

export function update_quickFilters(name: string, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_updateQuickFilters(name, id)));
		return axios().patch(`${basePath}/${id}`, { name });
	};
}

export function delete_quickFilters(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteQuickFilters(id)));
		return axios().delete(`${basePath}/${id}`);
	};
}

export function create_quickFilters(data: IQuickFiltersCreateOrUpdate): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createQuickFilters((json as IJson).data)));
		return axios().post(basePath, data);
	};
}

export function get_quickFiltersIssueList(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getQuickFilters((json as IJson).data)));
		return axios().get(`${basePath}/IssueList`);
	};
}
