import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	tagPath: '/admin/tag/',
	dictionaryPath: '/dictionaries/',
	warning: 'warning' as const,
};

export const Translation = {
	pl: {
		DictionaryContent: {
			DictionaryContentTile: {
				DictionaryItemValue: {
					dictionaryUnactive: 'Słownik jest nieaktywny',
					tagGroupUnactive: 'Grupa tagów jest nieaktywna',
				},
			},
		},
	},
	en: {
		DictionaryContent: {
			DictionaryContentTile: {
				DictionaryItemValue: {
					dictionaryUnactive: 'Dictionary is unactive',
					tagGroupUnactive: 'Tag group is unactive',
				},
			},
		},
	},
};
