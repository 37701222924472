import { FullFilters, IHoliday, ILeaveRequest, ITaskerUser, ITimeReport } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

import { IFilters } from 'view/SearchBar/model';
import { getInitialFilter, getInitialFilterWorkTimeNew } from 'view/SearchBar/resources';

export const State = {
	timeReport: {} as ITimeReport,
	filterTimeReportNew: getInitialFilterWorkTimeNew() as FullFilters,
	filterTimeReport: getInitialFilter() as IFilters,
	taskerUsers: [] as Partial<ITaskerUser>[], //Options
	holidays: [] as IHoliday[],
	selectedUser: (undefined as unknown) as Partial<ITaskerUser>,
	leaveRequest: [] as ILeaveRequest[],
};
export type ITimeReportState = typeof State;

const timeReportReducer = (state = State, action: ObjectAction): ITimeReportState => {
	switch (action?.type) {
		case actionTypes.REPORT_TIME_FETCH: {
			return {
				...state,
				timeReport: {
					Summary: action.timeReport.Summary,
					Columns: action.timeReport.Columns,
				},
				leaveRequest: action?.timeReport?.Vacations,
				holidays: action.timeReport.Holidays,
			};
		}
		case actionTypes.TIME_REPORT_SET_FILTERS: {
			return {
				...state,
				filterTimeReport: action.filter,
			};
		}
		case actionTypes.TIME_REPORT_SET_FILTERS_NEW: {
			return {
				...state,
				filterTimeReportNew: action.filter,
			};
		}
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		case actionTypes.TIME_REPORT_SET_LOCAL_USERS: {
			return {
				...state,
				filterTimeReportNew: {
					...state.filterTimeReportNew,
					WorkLogs: {
						...state?.filterTimeReportNew?.WorkLogs,
						ITaskerUserId: action?.data,
					},
				},
				selectedUser: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default timeReportReducer;
