import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			commentsContainer: {
				marginTop: theme.spacing(2),
				marginBottom: theme.spacing(2),
				backgroundColor: '#f1f1f1',
				// height: '200px',
				overflowY: 'scroll',
				borderRadius: '10px',
				padding: props => (props.extraPadding ? theme.spacing(2) : theme.spacing(0)),
				wordBreak: 'break-all',
				width: '100%',
				// paddingBottom: theme.spacing(2),
				maxHeight: '400px',
				overflowX: 'hidden',
			},
		};
	},
	{ name: `IssueModalEditor` }
);
