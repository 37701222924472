export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							validationError:
								'Brak wypełnionych wszystkich wymaganych pól, proszę uzupełnić je przed zapisaniem faktury',
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							validationError:
								'Not all required fields are filled, please fill them before saving the invoice',
						},
					},
				},
			},
		},
	},
};
