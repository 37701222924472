import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	url: 'https://discord.com/api/oauth2/authorize?client_id=1113401032819626024&permissions=139586948160&scope=bot',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						Discord: {
							title: 'Discord',
							integrate: 'Rozpocznij integracje',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						Discord: {
							title: 'Discord',
							integrate: 'Begin integration',
						},
					},
				},
			},
		},
	},
};
