import { TextAlign } from 'chartjs-plugin-datalabels/types/options';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	format: 'yyyy/MM/dd  HH:mm',
	right: 'right' as TextAlign,
	textFieldInsideMargin: { width: '1px', height: '1px', marginLeft: '4px' },
};
export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			Content: {
				PartDayLeave: {
					fromDate: 'Data: ',
					leaveType: 'Typ urlopu: ',
					time: 'Czas: ',
				},
			},
		},
	},
	en: {
		LeaveRequestCalendar: {
			Content: {
				PartDayLeave: {
					leaveType: 'Leave type: ',
					fromDate: 'Date: ',
					time: 'Time: ',
				},
			},
		},
	},
};
