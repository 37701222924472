import { Theme, tooltipClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles<Theme>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		lightText: {
			color: theme.palette.secondary.contrastText,
		},
		border: {
			backgroundColor: theme.palette.background.default,
			borderRadius: '3px',
			border: '1px solid #b4b4b4',
			boxShadow: '0 1px 1px rgba(0, 0, 0, .2), 0 2px 0 0 rgba(255, 255, 255, .7) inset',
			color: theme.palette.darkGray.dark,
			display: 'inline-block',
			fontWeight: 700,
			lineHeight: 1,
			padding: '2px 4px',
			margin: '3px',
			whiteSpace: 'nowrap',
		},
		padding: {
			marginTop: theme.spacing(2),
		},
		tooltipWidth: {
			[`& .${tooltipClasses.tooltip}`]: {
				maxWidth: 500,
			},
		},
	}),
	{ name: `Dashboard_TopBar` }
);
