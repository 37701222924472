import { AsyncAction } from '@skillchill/redux-promisify';

import { IIssueKanbanDetails, IPortalAnswer } from '@skillandchill/tasker-types';
import { IPortalFilterOptions } from '@skillandchill/tasker-types/dist/types/endPoints/portalFilter';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const filterPortalDefault = 'Filter/Portal';
const portalAnswerDefault = 'PortalAnswer';

export const PORTAL_FILTER_STATUS_OPTIONS: ActionType = 'PORTAL_FILTER_STATUS_OPTIONS';
export const PORTAL_FILTER_TAGS_OPTIONS: ActionType = 'PORTAL_FILTER_TAGS_OPTIONS';
export const PORTAL_ANSWER_POST: ActionType = 'PORTAL_ANSWER_POST';
export const PORTAL_ANSWER_PATCH: ActionType = 'PORTAL_ANSWER_PATCH';
export const PASS_PORTAL_DATA: ActionType = 'PASS_PORTAL_DATA';
export const PORTAL_VALIDATE: ActionType = 'PORTAL_VALIDATE';
export const DELETE_PORTAL_ANSWER: ActionType = 'DELETE_PORTAL_ANSWER';

function _createPortalAnswer(data: IPortalAnswer[]): Action {
	return {
		type: PORTAL_ANSWER_POST,
		data,
	};
}

function _updatePortalAnswer(data: IPortalAnswer[]): Action {
	return {
		type: PORTAL_ANSWER_PATCH,
		data,
	};
}

function _deletePortalAnswer(id: number): Action {
	return {
		type: DELETE_PORTAL_ANSWER,
		id,
	};
}

function _fetchFilterTagsOptions(data: IPortalFilterOptions): Action {
	return {
		type: PORTAL_FILTER_TAGS_OPTIONS,
		data,
	};
}

function _fetchFilterStatusOptions(data: IPortalFilterOptions): Action {
	return {
		type: PORTAL_FILTER_STATUS_OPTIONS,
		data,
	};
}

export function setValidate(validate: boolean): Action {
	return {
		type: PORTAL_VALIDATE,
		data: validate,
	};
}

export function _passKanbanPortalData(data: Partial<IIssueKanbanDetails>): Action {
	return {
		type: PASS_PORTAL_DATA,
		data,
	};
}

export function create_PortalAnswer(data: Partial<IPortalAnswer>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createPortalAnswer((json as IJson).data ?? {})));
		return axios().post(`${portalAnswerDefault}`, data);
	};
}

export function update_PortalAnswer(data: Partial<IPortalAnswer>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updatePortalAnswer((json as IJson).data ?? {})));
		return axios().patch(`${portalAnswerDefault}/${data?.Id}`, data);
	};
}

export function delete_PortalAnswer(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deletePortalAnswer(id)));
		return axios().delete(`${portalAnswerDefault}/${id}`);
	};
}

export function get_Portal_Filter_Status_Options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFilterStatusOptions((json as IJson).data ?? {})));
		return axios().post(`${filterPortalDefault}/Status/All`);
	};
}

export function get_Portal_Filter_Tags_Options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFilterTagsOptions((json as IJson).data ?? {})));
		return axios().post(`${filterPortalDefault}/Tags/All`);
	};
}
