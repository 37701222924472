import { AsyncAction } from '@skillchill/redux-promisify';

import {
	DeepPartial,
	IEmployeeAgreementsFilters,
	IIEmployeeAgreementsChosenSort,
	IPage,
	IAgreementPaginationData,
} from '@skillandchill/tasker-types';
import { IEmployeeAgreement } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/employeeAgreement';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const SET_EMPLOYEE_AGREEMENTS: ActionType = 'SET_EMPLOYEE_AGREEMENTS';
export const SET_EMPLOYEE_AGREEMENT_FORM_PAGE: ActionType = 'SET_EMPLOYEE_AGREEMENT_FORM_PAGE';
export const SET_EMPLOYEE_AGREEMENT_DETAILS_PAGE: ActionType = 'SET_EMPLOYEE_AGREEMENT_DETAILS_PAGE';
export const CLEAR_EMPLOYEE_AGREEMEN_FORM_STATE: ActionType = 'SET_EMPLOYEE_AGREEMENT';

const BASE_PATH = 'EmployeeAgreement';

export function _set_employee_agreements(employeeAgreements: IPage<IEmployeeAgreement[]>): Action {
	return {
		type: SET_EMPLOYEE_AGREEMENTS,
		data: employeeAgreements.Data,
		count: employeeAgreements.Count,
	};
}

export function _set_employee_agreement_form_page(employeeAgreement: IEmployeeAgreement): Action {
	return {
		type: SET_EMPLOYEE_AGREEMENT_FORM_PAGE,
		employeeAgreement,
	};
}

export function _set_employee_agreement_details_page(employeeAgreement: IEmployeeAgreement): Action {
	return {
		type: SET_EMPLOYEE_AGREEMENT_DETAILS_PAGE,
		employeeAgreement,
	};
}

export function clear_employeeAgreement_form_state(): Action {
	return {
		type: CLEAR_EMPLOYEE_AGREEMEN_FORM_STATE,
	};
}

export function fetch_employee_agreements(
	from: number,
	count: number,
	agreementFilters: IEmployeeAgreementsFilters,
	searchValue: string,
	appliedSort: IIEmployeeAgreementsChosenSort[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_employee_agreements((data as IJson).data)));
		return axios().post(`${BASE_PATH}/from/${from}/count/${count}`, {
			agreementFilters,
			searchValue,
			appliedSort,
		});
	};
}

export function fetch_employee_agreement_form_page(employeeAgreementId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_employee_agreement_form_page((data as IJson).data)));
		return axios().get(`${BASE_PATH}/${employeeAgreementId}`);
	};
}

export function fetch_employee_agreement_details_page(employeeAgreementId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_employee_agreement_details_page((data as IJson).data)));
		return axios().get(`${BASE_PATH}/${employeeAgreementId}`);
	};
}

export function create_employee_agreement(data: Partial<IEmployeeAgreement>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => {});
		return axios().post(`${BASE_PATH}`, data);
	};
}

export function edit_employee_agreement(data: DeepPartial<IEmployeeAgreement>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => {});
		return axios().patch(`${BASE_PATH}/${data.Id}`, data);
	};
}

export function delete_employee_agreement(
	employeeAgreementId: number,
	paginationData?: IAgreementPaginationData
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_employee_agreements((data as IJson).data)));
		return axios().delete(`${BASE_PATH}/${employeeAgreementId}`, {
			data: paginationData,
		});
	};
}

export function delete_many_employee_agreements(
	deletedAgreementsIds: number[],
	from: number,
	count: number,
	agreementFilters: IEmployeeAgreementsFilters,
	searchValue: string,
	appliedSort: IIEmployeeAgreementsChosenSort[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_set_employee_agreements((data as IJson).data)));
		return axios().delete(`${BASE_PATH}/deleteMany`, {
			data: { from, count, agreementFilters, searchValue, appliedSort, deletedAgreementsIds },
		});
	};
}
