import { Translation as ModalActions } from './Modal/Actions/resources';
import { Translation as ModalContentDescription } from './Modal/Content/General/Description/resources';
import { Translation as ModalContentIsActive } from './Modal/Content/General/IsActive/resources';
import { Translation as ModalContentName } from './Modal/Content/General/Name/resources';
import { Translation as ModalItemForm } from './Modal/Content/Items/Form/resources';
import { Translation as ModalItemFormValue } from './Modal/Content/Items/Form/Value/resources';
import { Translation as ModalItems } from './Modal/Content/Items/resources';
import { Translation as ModalContent } from './Modal/Content/resources';
import { Translation as Dictionaries } from './resources';

export const DictionariesTranslationEN = [
	Dictionaries.en,
	ModalActions.en,
	ModalContent.en,
	ModalContentName.en,
	ModalContentIsActive.en,
	ModalContentDescription.en,
	ModalItems.en,
	ModalItemForm.en,
	ModalItemFormValue.en,
];

export const DictionariesTranslationPL = [
	Dictionaries.pl,
	ModalActions.pl,
	ModalContent.pl,
	ModalContentName.pl,
	ModalContentIsActive.pl,
	ModalContentDescription.pl,
	ModalItems.pl,
	ModalItemForm.pl,
	ModalItemFormValue.pl,
];
