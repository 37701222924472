import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	close: 'close' as const,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					CustomConfirmationMessage: {
						yes: 'Tak',
						no: 'Nie',
						back: 'Wróć',
						title: 'Masz niezapisane zmiany, czy chcesz je zapisać?',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					CustomConfirmationMessage: {
						yes: 'Yes',
						no: 'No',
						back: 'Back',
						title: 'You have unsaved changes, do you want to save them?',
					},
				},
			},
		},
	},
};
