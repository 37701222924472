import { useContext } from 'react';
import { __RouterContext } from 'react-router';

export default (): any => useContext(__RouterContext);

export const getKanbanDetails = () => {
	const kanbanId = (window.location.href?.split('/kanban/') ?? [])[1];
	return {
		IsKanban: window.location.href.includes('/kanban/'),
		KanbanId: kanbanId ? Number.parseInt(kanbanId) : undefined,
	};
};
