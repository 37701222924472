import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DictionaryContent: {
			DictionaryContentTile: {
				DictionaryItemLabelDuringEdit: {
					tagGroupListPlaceholder: 'Wybierz grupę tagów ...',
					dictionaryListPlaceholder: 'Wybierz słownik ...',
				},
			},
		},
	},
	en: {
		DictionaryContent: {
			DictionaryContentTile: {
				DictionaryItemLabelDuringEdit: {
					tagGroupListPlaceholder: 'Select group tag ...',
					dictionaryListPlaceholder: 'Select dictionary ...',
				},
			},
		},
	},
};
