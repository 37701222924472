import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	...GlobalResources,
	delegation: (id: number) => `/${id}`,
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/DelegationSettlement/${id}`,
};

export const Translation = {
	pl: {
		DelegationsList: {
			pageTitle: 'Lista delegacji',
			name: 'Nazwa',
			fetchError: 'Wystąpił błąd podczas pobierania danych.',
			requestToAcceptanceError:
				'Wybrana delegacja nie spełnia wszystkich wymagań, potrzebnych do wysłania do akcpetacji. Proszę sprawdzić czy wszystkie wymagane pola są wypełnione.',
			requestToAcceptanceSuccess: 'Delegacja została wysłana do akceptacji.',
			finalSettlementError: 'Wystąpił błąd podczas eksportu pliku',
			finalSettlementSuccess: 'Pomyślnie wyeksportowano plik',
			requestToDoneSuccess: 'Delegacja została zakończona.',
			requestToDoneError: 'Wystąpił błąd podczas zakończania delegacji.',
			useTableData: {
				name: 'Nazwa delegacji',
				taskerUser: 'Użytkownik',
				status: 'Status',
				dateRange: 'Zakres dat delegacji',
				menu: 'Akcje',
			},
		},
	},
	en: {
		DelegationsList: {
			pageTitle: 'Delegations list',
			name: 'Name',
			fetchError: 'An error occurred while fetching data.',
			requestToAcceptanceError:
				'The selected delegation does not meet all the requirements needed to be sent for acceptance. Please check if all required fields are filled in.',
			requestToAcceptanceSuccess: 'Delegation has been sent for acceptance.',
			finalSettlementError: 'An error occurred while exporting the file',
			finalSettlementSuccess: 'File successfully exported',
			requestToDoneSuccess: 'Delegation has been completed.',
			requestToDoneError: 'An error occurred while completing the delegation.',
			useTableData: {
				name: 'Delegation name',
				taskerUser: 'User',
				status: 'State',
				dateRange: 'Delegation date range',
				menu: 'Actions',
			},
		},
	},
};
