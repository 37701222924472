import clsx from 'clsx';
import React, { useState } from 'react';

import { Card, Typography } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { FieldDefinition } from '../FieldDefinition';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueFormSection = (props: Props): JSX.Element => {
	const { issueFormSection, level, zIndex, issueFieldDetails, subKey } = props;
	const classes = useStyles();

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	return (
		<Card>
			<div className={classes.flexColumn} onClick={() => setIsCollapsed(!isCollapsed)}>
				<Typography
					style={{ fontSize: 32 - level * 2 }}
					className={clsx(classes.flexOne, classes.marginBottom)}
				>
					{issueFormSection?.Name}
				</Typography>
				<VisibilityControl condition={(issueFormSection?.IssueFormSectionToIssueField?.length ?? 0) > 0}>
					{issueFormSection?.IssueFormSectionToIssueField?.map((x, index) => {
						return (
							<FieldDefinition
								issueFormSectionToIssueField={x}
								key={`${subKey}${Resources.fieldDefinition}${x?.Id}`}
								subKey={`${subKey}${Resources.fieldDefinition}${x?.Id}`}
								zIndex={zIndex - index}
							/>
						);
					})}
				</VisibilityControl>
				<VisibilityControl condition={(issueFormSection?.IssueFormSection?.length ?? 0) > 0}>
					{issueFormSection?.IssueFormSection?.map(x => {
						return (
							<IssueFormSection
								issueFieldDetails={issueFieldDetails}
								issueFormSection={x}
								subKey={`${subKey}${Resources.key(x?.Id ?? -4)}`}
								key={`${subKey}${Resources.key(x?.Id ?? -4)}`}
								level={level + 1}
								zIndex={zIndex - (issueFormSection?.IssueFormSectionToIssueField?.length ?? 0) - 1}
							/>
						);
					})}
				</VisibilityControl>
			</div>
		</Card>
	);
};
