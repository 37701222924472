import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	xAxis: 'x-axis' as const,
	greenBright: `rgba(75, 196, 12, 0.68)`,
	greenBrighter: `rgba(79, 208, 15, 0.85)`,
	greenDark: `rgba(88, 243, 9, 0.3)`,
	grayDark: `rgba(78, 227, 57, 0)`,
	singleStep: 60,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				ProjectCharts: {
					paperTitle: 'Zalogowany czas',
					paperSubtitle: 'Czas zalogowany na zadania w tym projekcie',
					thisWeek: 'Ten tydzień',
					lastWeek: 'Ostatni tydzień',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				ProjectCharts: {
					paperTitle: 'Logged time',
					paperSubtitle: 'Time logged on issues in this project',
					thisWeek: 'This week',
					lastWeek: 'Last week',
				},
			},
		},
	},
};
