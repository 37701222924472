import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	keydown: 'keydown' as const,
	issuePathPart: 'issueId=',
};

export const Translation = {
	pl: {
		KeyListener: {
			startIssue: 'Skrót rozpoczęcia zadania aktywowany',
			pauseIssue: 'Skrót pauzy zadania aktywowany',
			endIssue: 'Skrót zakończenia zadania aktywowany',
			shortcutError: 'Skrót nie może zostać aktywowany',
		},
	},
	en: {
		KeyListener: {
			startIssue: 'Shortcut start issue activated',
			pauseIssue: 'Shortcut pause issue activated',
			endIssue: 'Shortcut finish issue activated',
			shortcutError: 'Shortcut cannot be activated',
		},
	},
};
