import { IEmployeeSalary, ITaskerUser } from '@skillandchill/tasker-types';
import { ICurrency } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/currency';
import { IEmployeeAgreement } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/employeeAgreement';

import {
	CLEAR_EMPLOYEE_AGREEMEN_FORM_STATE,
	FETCH_ALL_USERS,
	SET_EMPLOYEE_AGREEMENT_FORM_PAGE,
	SET_UPDATED_SALARY,
} from '@/store/actions';
import { FETCH_ALL_CURRENCIES } from '@/store/actions/currencyActions';
import { ObjectAction } from '@/store/types';

const State = {
	employeeAgreement: {} as IEmployeeAgreement,
	taskerUsers: [] as Partial<ITaskerUser>[],
	currencies: [] as ICurrency[],
	salaryAndIndexToReplace: {
		salary: {} as Partial<IEmployeeSalary>,
		indexToReplace: -1 as number,
	},
};
export type IEmployeeAgreementFormState = typeof State;

const employeeAgreementFormReducer = (state = State, action: ObjectAction): IEmployeeAgreementFormState => {
	switch (action.type) {
		case SET_EMPLOYEE_AGREEMENT_FORM_PAGE: {
			return {
				...state,
				employeeAgreement: action.employeeAgreement,
			};
		}

		case FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}

		case FETCH_ALL_CURRENCIES: {
			return {
				...state,
				currencies: action.currencies,
			};
		}

		case CLEAR_EMPLOYEE_AGREEMEN_FORM_STATE: {
			return {
				employeeAgreement: {} as IEmployeeAgreement,
				taskerUsers: [],
				currencies: [],
				salaryAndIndexToReplace: {
					salary: {} as Partial<IEmployeeSalary>,
					indexToReplace: -1 as number,
				},
			};
		}

		case SET_UPDATED_SALARY: {
			const newEmployeeSalary = (state.employeeAgreement?.EmployeeSalary ?? []).map((salary, i) => {
				if (i !== action.indexToReplace) return salary;
				return action.salary;
			});

			return {
				...state,
				employeeAgreement: { ...state.employeeAgreement, EmployeeSalary: newEmployeeSalary },
				salaryAndIndexToReplace: {
					salary: action.salary as Partial<IEmployeeSalary>,
					indexToReplace: action.indexToReplace as number,
				},
			};
		}

		default: {
			return state;
		}
	}
};

export default employeeAgreementFormReducer;
