import { useState } from 'react';

export const useToggle = (defaultValue = false) => {
	const [value, setValue] = useState(defaultValue);

	const toggleOn = () => setValue(true);
	const toggleOff = () => setValue(false);

	return [value, toggleOn, toggleOff, setValue] as const;
};
