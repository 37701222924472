import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	overline: 'overline' as const,
};

export const Translation = {
	pl: {
		Budgets: {
			TreeSingleBudgetLine: {
				Icons: {
					edit: 'Edytuj element',
					add: 'Dodaj element',
					move: 'Przenieś element',
					delete: 'Usuń element',
					clean: 'Wyczyść elementy',
				},
			},
		},
	},
	en: {
		Budgets: {
			TreeSingleBudgetLine: {
				Icons: {
					edit: 'Edit element',
					add: 'Add element',
					move: 'Move element',
					delete: 'Delete element',
					clean: 'Clean elements',
				},
			},
		},
	},
};
