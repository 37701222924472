import React, { useEffect, useState } from 'react';

import { ITag } from '@skillandchill/tasker-types';
import { SelectTextField } from '@skillandchill/tasker-widgets2';

import { useCurrentTheme } from '@/utils/hooks/useCurrentTheme';

import { IssueModalSelectTextFieldFontSize, IssueModalSelectTextFieldHeight } from 'view/IssueModalEditor/resources';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Tag = (props: Props): JSX.Element => {
	const { value: valueInput, onChange: onChangeInput, items } = props;
	const classes = useStyles();
	const { currentTheme } = useCurrentTheme();

	const [value, setValue] = useState<Partial<ITag> | undefined>(valueInput as Partial<ITag>);

	const onChange = (object: Partial<ITag>) => {
		setValue(object);
		onChangeInput(object);
	};

	useEffect(() => {
		setValue(valueInput as Partial<ITag>);
	}, [valueInput]);

	return (
		<div className={classes.root}>
			<div className={classes.selectTextFieldContainer}>
				<SelectTextField<Partial<ITag>>
					muiTheme={currentTheme}
					menuPortalTarget
					getName={c => c?.Label ?? Resources.GlobalResources.empty}
					getKey={c => c?.Id ?? -1}
					items={items}
					value={value}
					args={Resources.selectArgs}
					onChange={onChange}
					height={IssueModalSelectTextFieldHeight}
					fontSize={IssueModalSelectTextFieldFontSize}
				/>
			</div>
		</div>
	);
};
