import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	rowHeight: 55,
	keyPart: 'tagGroup',
	defaultColor: '#ffffff',
	kanbanUpdateErrorInfo: 'TagGroup exists in Kanban',
	caption: 'caption' as const,
	tagUrl: '/admin/tag/',
	error: 'error',
	fields: {
		id: 'id',
		isImmutable: 'isImmutable',
		context: 'context',
		label: 'label',
		key: 'TagGroupList',
		tags: 'tags',
	},
};

export const Translation = {
	pl: {
		TagAdminPanel: {
			add: 'Dodaj',
			context: 'Konteksty',
			pageTitle: 'Administracja tagów',
			headerTitle: 'Zarządzanie tagami',
			createTagGroup: 'Stwórz grupę tagów',
			showFilters: 'Pokaż filtry',
			filters: 'Filters',
			tagGroupUpdated: 'Zaktualizowano grupę tagów',
			tagGroupUpdateFailed: {
				kanbanExists:
					'Aktualizacja grupy tagów zakończona niepowodzeniem z powodu zmiany ekskluzywności grupy tagów, która została wykorzystana w kanbanie',
			},
			saveSuccess: 'Pomyślnie zapisano',
			searchHelperText: 'Wyszukaj po nazwie grupy, kontekstu lub nazwie tagu',
			saveErrorNoLabel: 'Nie podano nazwy grupy tagów',
			saveErrorNoContext: 'Nie podano kontekstu grupy tagów',
			saveErrorHeader: 'Błąd podczas zapisu:',
			found: 'Znaleziono',
			tagGroups: 'grup tagów',
			deleteTagGroupSuccess: 'Usunięto grupę tagów',
			createTagGroupSuccess: 'Stworzono grupę tagów',
			copyTagGroupSuccess: 'Skopiowano grupę tagów',
			deleteError: 'Błąd poczas usuwania grupy tagów',

			label: 'Nazwa grupy tagów',
			multipleAllowed: 'Zezwól na wiele',
			isImmutable: 'Niezmienny',
			tags: 'Tagi',
			actions: 'Akcje',
			copyGroup: 'Kopiuj grupę',
			editGroup: 'Edytuj grupę',
			deleteGroup: 'Usuń grupę',
			noTags: '(0) Brak tagów w grupie',
			chooseContext: 'Wybierz filtr...',
			all: 'Wszystkie',
			Delete: {
				title: 'Czy jesteś pewien?',
				subTitle: 'Grupa tagów może być usunięta tylko gdy nic tego nie używa.',
			},
		},
	},
	en: {
		TagAdminPanel: {
			add: 'Add',
			context: 'Contexts',
			pageTitle: 'Tag administration',
			headerTitle: 'Tag administration',
			createTagGroup: 'Create tag group',
			showFilters: 'Show filters',
			filters: 'Filters',
			tagGroupUpdated: 'Group tag updated',
			tagGroupUpdateFailed: {
				kanbanExists: 'TagGroup exists in Kanban',
			},
			saveSuccess: 'Save success',
			searchHelperText: 'Search by group name, context or tag name',
			saveErrorNoLabel: 'The name of the tag group is missing',
			saveErrorNoContext: 'The context of the tag group is missing',
			saveErrorHeader: 'Error while saving:',
			found: 'Found',
			tagGroups: 'tag groups',
			deleteTagGroupSuccess: 'Tag group has been deleted',
			createTagGroupSuccess: 'Tag group has been created',
			copyTagGroupSuccess: 'Tag group has been copied',
			deleteError: 'Error while deleting tag group',

			label: 'Tag group name',
			multipleAllowed: 'Allow multiple',
			isImmutable: 'Immutable',
			tags: 'Tags',
			actions: 'Actions',
			copyGroup: 'Copy group',
			editGroup: 'Edit group',
			deleteGroup: 'Delete group',
			noTags: '(0) No tags in the group',
			chooseContext: 'Choose filter...',
			all: 'All',
			Delete: {
				title: 'Are you sure?',
				subTitle: 'Tag group can be deleted if nothing using it.',
			},
		},
	},
};
