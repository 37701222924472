import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				CardActions: {
					title: 'Użytkownicy',
					description: 'Zarządzaj użytkownikami w swojej organizacji lub dodaj nowego.',
					add: 'Dodaj nowego użytkownika',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				CardActions: {
					title: 'Users',
					description: 'Manage users in your organization or add a new one.',
					add: 'Add new user',
				},
			},
		},
	},
};
