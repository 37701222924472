import React from 'react';
import { components } from 'react-select';

import { Typography } from '@mui/material';

export const SingleValueProjectCustom = (props: any) => {
	return (
		<components.SingleValue {...props}>
			<Typography>{props.children}</Typography>
		</components.SingleValue>
	);
};
