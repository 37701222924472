import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		WorkDayEndSummary: {
			Filters: { filterButton: { button: 'Filtry', tooltip: 'Filtruj' } },
		},
	},
	en: {
		WorkDayEndSummary: {
			Filters: { filterButton: { button: 'Filters', tooltip: 'Filter' } },
		},
	},
};
