import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	h: 'h',
	style: {
		fontSize: 'initial !important',
		height: 32.5,
	},
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				General: {
					IssueForm: {
						IssueFormSectionWithCollapse: {
							FieldDefinition: {
								DateField: {
									today: 'Dzisiaj',
								},
							},
						},
					},
				},
			},
		},
	},

	en: {
		IssueModalEditor: {
			Tabs: {
				General: {
					IssueForm: {
						IssueFormSectionWithCollapse: {
							FieldDefinition: {
								DateField: {
									today: 'Today',
								},
							},
						},
					},
				},
			},
		},
	},
};
