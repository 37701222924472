import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						add: 'Dodaj fakturę do klienta',
						addTooltip: 'Dodaj nowy rekord',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						add: 'Add invoice to customer',
						addTooltip: 'Add new record',
					},
				},
			},
		},
	},
};
