import { AsyncAction } from '@skillchill/redux-promisify';

import { FullFilters, IFormFilter, IProject } from '@skillandchill/tasker-types';
import { IQuickFilter } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/quickfilter';
import { IFilterCustomer, IFilterSimplifiedCustomer } from '@skillandchill/tasker-types/dist/types/endPoints/filter';
import {
	IPortalFilterFieldDefinition,
	IPortalFilterForms,
} from '@skillandchill/tasker-types/dist/types/endPoints/portalFilter';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { EPortalFilter } from 'view/IssueList/IssueListPagination/resources';
import { IFilters } from 'view/SearchBar/model';

const filterDefault = 'Filter';

export const FILTER_STATUS_OPTIONS: ActionType = 'FILTER_STATUS_OPTIONS';
export const FILTER_TAGS_OPTIONS: ActionType = 'FILTER_TAGS_OPTIONS';
export const FILTER_CUSTOMER_FILTERS: ActionType = 'FILTER_CUSTOMER_FILTERS';
export const FILTER_CUSTOMER_FILTERS_SIMPLIFIED: ActionType = 'FILTER_CUSTOMER_FILTERS_SIMPLIFIED';

export const PROJECT_SET_FILTERS: ActionType = 'PROJECT_FILTERS_SET';
export const PROJECT_SET_FILTERS_NEW: ActionType = 'PROJECT_SET_FILTERS_NEW';

export const ISSUE_SET_FILTERS: ActionType = 'ISSUE_FILTERS_SET';
export const ISSUE_SET_FILTERS_NEW: ActionType = 'ISSUE_SET_FILTERS_NEW';
export const ISSUE_SET_FILTERS_NEW_V2: ActionType = 'ISSUE_SET_FILTERS_NEW_V2';

export const KANBAN_SET_FILTERS_NEW: ActionType = 'KANBAN_SET_FILTERS_NEW';

export const TIME_REPORT_SET_FILTERS: ActionType = 'TIME_REPORT_SET_FILTERS';
export const TIME_REPORT_SET_FILTERS_NEW: ActionType = 'TIME_REPORT_SET_FILTERS_NEW';

export const PORTAL_SET_FILTERS: ActionType = 'PORTAL_SET_FILTERS';
export function filter_setPortal(filter: FullFilters): Action {
	return {
		type: PORTAL_SET_FILTERS,
		filter: filter,
	};
}
export const FILTER_SET_LISTS: ActionType = 'FILTER_SET_LISTS';
export const PORTAL_SET_EXCLUDED_TAGS: ActionType = 'PORTAL_SET_EXCLUDED_TAGS';
export const PORTAL_SET_STATUS: ActionType = 'PORTAL_SET_STATUS';
export const KANBAN_SET_STATUS: ActionType = 'KANBAN_SET_STATUS';
export const KANBAN_SET_QUICK_FILTERS: ActionType = 'KANBAN_SET_QUICK_FILTERS';
export const ISSUE_SET_STATUS: ActionType = 'ISSUE_SET_STATUS';
export const FILTER_PROJECT_SET: ActionType = 'FILTER_PROJECT_SET';
export const FILTER_PROJECT_DELETE: ActionType = 'FILTER_PROJECT_DELETE';
export const FILTER_KANBAN_PROJECT_DELETE: ActionType = 'FILTER_KANBAN_PROJECT_DELETE';
export const FILTER_ISSUE_PROJECT_DELETE: ActionType = 'FILTER_ISSUE_PROJECT_DELETE';
export const PORTAL_SET_TAGS: ActionType = 'PORTAL_SET_TAGS';
export const PORTAL_TAGS_NOT_IN: ActionType = 'PORTAL_TAGS_NOT_IN';
export const KANBAN_TAGS_NOT_IN: ActionType = 'PORTAL_TAGS_NOT_IN';

export const KANBAN_DELETE_FILTERS_NEW: ActionType = 'KANBAN_DELETE_FILTERS_NEW';

export const ISSUE_FORM_SET_FILTERS: ActionType = 'ISSUE_FORM_SET_FILTERS';
export const CHECK_LIST_VALUE_SET_FILTERS: ActionType = 'CHECK_LIST_VALUE_SET_FILTERS';
export const TILE_CONTEXT_SET_FILTERS: ActionType = 'TILE_CONTEXT_SET_FILTERS';
export const FILTER_APPLY_KANBAN: ActionType = 'FILTER_APPLY_KANBAN';
export const KANBAN_SET_FILTERS_NEW_TMP: ActionType = 'KANBAN_SET_FILTERS_NEW_TMP';
export const ISSUE_SET_QUICK_FILTERS: ActionType = 'ISSUE_SET_QUICK_FILTERS';
export const FILTER_SET_KIND: ActionType = 'FILTER_SET_KIND';

function _fetchFilterCustomerOptions(data: IFilterCustomer[]): Action {
	return {
		type: FILTER_CUSTOMER_FILTERS,
		data,
	};
}

export function filter_SetKind(data: EPortalFilter): Action {
	//ToDo Poprawić robione nq szybko
	return {
		type: FILTER_SET_KIND,
		data,
	};
}

function _fetchFilterCustomerSimplifiedOptions(data: IFilterSimplifiedCustomer[]): Action {
	return {
		type: FILTER_CUSTOMER_FILTERS_SIMPLIFIED,
		data,
	};
}

export function filter_setProjectNew(filters: { filter: IFilters; filterNew: FullFilters }): Action {
	return {
		type: PROJECT_SET_FILTERS_NEW,
		filter: filters,
	};
}

export function filter_setTimeReport(filter: IFilters): Action {
	return {
		type: TIME_REPORT_SET_FILTERS,
		filter: filter,
	};
}

export function filter_setTimeReportNew(filter: FullFilters): Action {
	return {
		type: TIME_REPORT_SET_FILTERS_NEW,
		filter: filter,
	};
}

export function filter_setList(filter: FullFilters): Action {
	return {
		type: FILTER_SET_LISTS,
		filter: filter,
	};
}

export function filter_setExcludedTags(filter: FullFilters): Action {
	return {
		type: PORTAL_SET_EXCLUDED_TAGS,
		filter: filter,
	};
}

export function filter_setProject(data: { choosenProjects: IProject[] }): Action {
	return {
		type: FILTER_PROJECT_SET,
		data,
	};
}

export function filter_deleteProject(data: number): Action {
	return {
		type: FILTER_PROJECT_DELETE,
		data,
	};
}

export function filter_setPortalStatus(data: {
	choosenForms: IPortalFilterForms[];
	choosenFieldDefinitions: IPortalFilterFieldDefinition[];
	choosenProjects: IProject[];
}): Action {
	return {
		type: PORTAL_SET_STATUS,
		data,
	};
}

export function filter_setKanbanStatus(data: {
	choosenForms: IPortalFilterForms[];
	choosenFieldDefinitions: IPortalFilterFieldDefinition[];
	choosenProjects: IProject[];
}): Action {
	return {
		type: KANBAN_SET_STATUS,
		data,
	};
}

export function filter_setPortalTags(data: {
	choosenForms: IPortalFilterForms[];
	choosenFieldDefinitions: IPortalFilterFieldDefinition[];
	choosenProjects: IProject[];
}): Action {
	return {
		type: PORTAL_SET_TAGS,
		data,
	};
}

export function filter_setKanbanTmp(filter: FullFilters): Action {
	return {
		type: KANBAN_SET_FILTERS_NEW_TMP,
		data: filter,
	};
}

export function filter_setIssueForm(filter: IFormFilter): Action {
	return {
		type: ISSUE_FORM_SET_FILTERS,
		filter: filter,
	};
}

export function filter_setKanbanQuickFilters(filter: IQuickFilter): Action {
	return {
		type: KANBAN_SET_QUICK_FILTERS,
		filter: JSON.parse(filter.CalculatedQueryContentPrisma),
	};
}

export function filter_setIssueQuickFilters(filters: IQuickFilter): Action {
	return {
		type: ISSUE_SET_QUICK_FILTERS,
		filters: JSON.parse(filters?.CalculatedQueryContentPrisma),
	};
}

export function filter_checkListValue(filter: { text: string }): Action {
	return {
		type: CHECK_LIST_VALUE_SET_FILTERS,
		filter: filter,
	};
}

export function filter_v2_checkListValue(filter: { userId?: number | null; date?: Date | null }): Action {
	return {
		type: CHECK_LIST_VALUE_SET_FILTERS,
		filter: filter,
	};
}

export function get_Customer_Filter_Options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFilterCustomerOptions((json as IJson).data ?? {})));
		return axios().get(`${filterDefault}/Tag`);
	};
}

export function get_Customer_Filter_Simplified_Options(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFilterCustomerSimplifiedOptions((json as IJson).data ?? {})));
		return axios().get(`${filterDefault}/Tag/Simplified`);
	};
}

export function filter_tileContext(filter: { text: string }): Action {
	return {
		type: TILE_CONTEXT_SET_FILTERS,
		filter: filter,
	};
}

export function filter_applyKanban(filter: FullFilters): Action {
	return {
		type: FILTER_APPLY_KANBAN,
		filter: filter,
	};
}
