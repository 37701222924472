import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	sideBarOpen: false as boolean,
};
export type ICommonState = typeof State;

const commonReducer = (state = State, action: ObjectAction): ICommonState => {
	switch (action.type) {
		case actionTypes.NAV_BAR_SET: {
			return {
				...state,
				sideBarOpen: action.value,
			};
		}
		default: {
			return state;
		}
	}
};

export default commonReducer;
