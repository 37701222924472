import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemHeader: {
									tooltipSelectAllRow: 'Zaznacz/odznacz wszystkie wartości w dniu',
									tooltipReverseRow: 'Odwróć wartości w dniu',
									legendDay: 'Dzień',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemRow: {
								PerDiemHeader: {
									tooltipSelectAllRow: 'Check/Uncheck all values in day',
									tooltipReverseRow: 'Reverse values in day',
									legendDay: 'Day',
								},
							},
						},
					},
				},
			},
		},
	},
};
