import { AsyncAction } from '@skillchill/redux-promisify';

import { CommentBotConfiguration } from '@skillandchill/tasker-widgets2/dist/utils/types/CommentBotConfiguration';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const COMMENT_BOT_CONFIGURATION_FETCH: ActionType = 'COMMENT_BOT_CONFIGURATION_FETCH';

function _fetchConfig(configuration: CommentBotConfiguration): Action {
	return {
		type: COMMENT_BOT_CONFIGURATION_FETCH,
		configuration,
	};
}

export function configuration_bot_comment_fetch(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchConfig((json as IJson).data ?? {})));
		context.catch(() => dispatch(_fetchConfig({} as CommentBotConfiguration)));
		return axios().get(`configuration/comments/project/${projectId}`);
	};
}

export function configuration_bot_comment_put(configuration: CommentBotConfiguration): AsyncAction {
	if (configuration?.project) delete configuration?.project;
	return function(context, dispatch) {
		context.then(() => dispatch(configuration_bot_comment_fetch(configuration?.projectId)));
		return axios().post(`/configuration/comments`, configuration);
	};
}
