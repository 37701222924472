import { IHolidayType, IPage } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	data: {} as IPage<IHolidayType[]>,
};
export type IHolidayTypeListState = typeof State;

const holidayTypeListReducer = (state = State, action: ObjectAction): IHolidayTypeListState => {
	switch (action.type) {
		case actionTypes.HOLIDAY_TYPES_FETCH: {
			return {
				...state,
				data: action.holidays,
			};
		}
		default: {
			return state;
		}
	}
};

export default holidayTypeListReducer;
