import React from 'react';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { IconButton, Tooltip } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { EIssueModalDetailsPageFields } from '../../../model';

import { Props } from './model';
import { useStyles } from './styles';

export const AssignMe = (props: Props) => {
	const { isCurrentTaskerUserSelected, handleAssignPerson, field } = props;
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<VisibilityControl
				condition={!isCurrentTaskerUserSelected}
				alternative={
					<Tooltip title="Usuń mnie">
						<IconButton onClick={() => handleAssignPerson(field)}>
							<PersonRemoveIcon />
						</IconButton>
					</Tooltip>
				}
			>
				<Tooltip title="Dodaj mnie">
					<IconButton onClick={() => handleAssignPerson(field)}>
						<PersonAddAlt1Icon />
					</IconButton>
				</Tooltip>
			</VisibilityControl>
		</div>
	);
};
