import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							createItemTitle: 'Tworzenie wartości',
							createItemDescription: 'Uzupełnij formularz, aby utworzyć nową wartość.',

							dictionaryListTitle: 'Lista własnych słowników',
							dictionaryListDescription:
								'W tym miejscu znajduje się lista słowników, które można utworzyć w celu wyspecyfikowania własnych wartości dla środków transportu i typów załączników.',
							addDictionary: 'Dodaj słownik',
							dictionaryCreateTitle: 'Tworzenie własnego słownika',
							dictionaryCreateDescription: 'Uzupełnij formularz, aby utworzyć nowy słownik.',
							dictionaryEditTitle: 'Edycja słownika',
							dictionaryEditDescription: 'Uzupełnij formularz, aby edytować słownik.',
							removeDictionarySuccess: 'Słownik został usunięty.',
							removeDictionaryError: 'Wystąpił błąd podczas usuwania słownika. Spróbuj ponownie później.',
							returnToDictionaryList: 'Powrót do listy',
							addDictionaryItem: 'Dodaj wartość',
							removeItemSuccess: 'Wartość została usunięta.',
							removeItemError: 'Wystąpił błąd podczas usuwania wartości. Spróbuj ponownie później.',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							createItemTitle: 'Creating a value',
							createItemDescription: 'Complete the form to create a new value.',

							dictionaryListTitle: 'List of custom dictionaries',
							dictionaryListDescription:
								'This is the place where you can create a list of dictionaries to specify your own values for means of transport and types of attachments.',
							addDictionary: 'Add dictionary',
							dictionaryCreateTitle: 'Creating a custom dictionary',
							dictionaryCreateDescription: 'Complete the form to create a new dictionary.',
							dictionaryEditTitle: 'Dictionary editing',
							dictionaryEditDescription: 'Complete the form to edit the dictionary.',
							removeDictionarySuccess: 'The dictionary has been removed.',
							removeDictionaryError: 'An error occurred while removing the dictionary. Try again later.',
							returnToDictionaryList: 'Return to the list',
							addDictionaryItem: 'Add value',
							removeItemSuccess: 'The value has been removed.',
							removeItemError: 'An error occurred while removing the value. Try again later.',
						},
					},
				},
			},
		},
	},
};
