import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export type IMandatory = 100003 | 100004 | 100005 | 100006 | 100007;

export const Resources = {
	...GlobalResources,
	googleDriveKey: (x: number) => `GoogleDrive_${x}_${uuid()}`,
	isMandatory: {
		100003: true,
		100004: true,
		100005: false,
		100006: false,
		100007: true,
	},
	mainSite: '/admin/organization/tabs/general',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						GoogleDrive: {
							100003: 'Identyfikator klienta',
							100004: 'Tajny klucz klienta',
							100005: 'Url przekierowywania',
							100006: 'Identyfikator folderu',
							100007: 'Token odświeżający',
							100008: 'Aktywacja dysku google',
							saveToolTip: 'Zapisz',
							successSave: 'Zapisano dane integracji z google drive',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Integration: {
					TabsContent: {
						GoogleDrive: {
							100003: 'Client key',
							100004: 'Secret client key',
							100005: 'Redirect url',
							100006: 'Folder identifier',
							100007: 'Refresh token',
							100008: 'Activation of google drive',
							saveToolTip: 'Save',
							successSave: 'Saved integration data from google drive',
						},
					},
				},
			},
		},
	},
};
