import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	agreementsTab: 'agreementsTab',
	createAgreementPath: '/agreements/agreement/create',
	dash: '-',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				title: 'Umowy',
				placeholder: 'Wyszukaj numer umowy, użytkownika ...',
				agreementNo: 'Numer umowy',
				user: 'Zakontraktowany(-a)',
				agreementType: 'Typ umowy',
				signedDate: 'Data zawarcia',
				startDate: 'Data rozpoczęcia',
				endDate: 'Data zakończenia',
				contractTime: 'Okres umowy',
				createdBy: 'Umowę dodał(a)',
				status: 'Status umowy',
				agreementsDeleted: 'Zaznaczone umowy zostały usunięte',
				errorOccured: 'Wystąpił błąd',
				deleteManyConfirmationData: {
					title: 'Czy na pewno chcesz usunąć zaznaczone umowy?',
					subtitle: 'Usunięcie zaznaczonych umów usunie wszystkie powiązane wynagrodzenia.',
					submitText: 'Tak',
					cancelText: 'Nie',
				},
				deleteConfirmationData: {
					title: 'Czy na pewno chcesz usunąć umowę?',
					subtitle: 'Usunięcie umowy spowoduje usunięcie wszystkich powiązanych wynagrodzeń.',
					submitText: 'Tak',
					cancelText: 'Nie',
				},

				sortAlphaAsc: 'Posortuj alfabetycznie od  A- Z',
				sortAlphaDesc: 'Posortuj alfabetycznie od Z - A',
				sort: 'Posortuj',
				sortAscByAgreementExpDate: 'Posortuj malejąco względem ważności umowy"',
				sortDescByAgreementExpDate: 'Posortuj rosnąco względem ważności umowy',
				agreementDeleted: 'Usunięto umowę',
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				title: 'Agreements',
				placeholder: 'Search for agreement number, user ...',
				agreementNo: 'Agreement No.',
				user: 'Contracted user',
				agreementType: 'Agreement type',
				signedDate: 'Signing date',
				startDate: 'Start date',
				endDate: 'End date',
				contractTime: 'Contract time',
				createdBy: 'Agreement created by',
				status: 'Agreement status',
				agreementsDeleted: 'Selected agreements have been deleted',
				errorOccured: 'Error has occured',
				deleteManyConfirmationData: {
					title: 'Are you sure, you want to delete selected agreements?',
					subtitle: 'Deleting selected agreements will also delete all associated salaries.',
					submitText: 'Yes',
					cancelText: 'No',
				},
				deleteConfirmationData: {
					title: 'Are you sure, you want to delete the agreement?',
					subtitle: 'Deleting the agreement will also delete all associated salaries.',
					submitText: 'Yes',
					cancelText: 'No',
				},
				sortAlphaAsc: 'Sort alphabetically from A - Z',
				sortAlphaDesc: 'Sort alphabetically from Z - A',
				sort: 'Sort',
				sortByAgreementExpDate: 'Sort descending by contract validity',
				sortDescByAgreementExpDate: 'Sort descending by contract validity',
				sortAscByAgreementExpDate: 'Sort ascending by contract validity',
				agreementDeleted: 'Agreement has been deleted',
			},
		},
	},
};

export const defaultConfirmationMessageData = {
	title: '',
};
