import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		starIcon: { fontSize: '30px' },
		closeIcon: { fontSize: '30px' },
		favouriteIssue: { color: '#ffc31f' },

		title: {
			maxWidth: '1000px',
			marginRight: theme.spacing(1),
		},
		starIconButton: {
			marginRight: theme.spacing(1),

			'&:hover': {
				color: '#ffc31f',
			},
		},
	}),
	{ name: `IssueModalEditor` }
);
