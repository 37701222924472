import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	agreementsListPath: '/agreements/agreements',
	editAgreementPath: '/agreements/agreement/edit/',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					CardHeader: {
						agreementDeleted: 'Usunięto umowę',
						errorOccured: 'Wystąpił błąd',
						confirmationTitle: 'Czy chcesz usunąć umowę?',
						confirmationSubTitle: 'Usunięcie umowy spowoduje usunięcie wszystkich powiązanych wynagrodzeń',
						confirmationSubmitText: 'Tak',
						confirmationCancelText: 'Nie',
						agreementDetails: 'Szczegóły umowy',
						editAgreement: 'Edytuj umowę',
						deleteAgreement: 'Usuń umowę',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					CardHeader: {
						agreementDeleted: 'Agreement has been deleted',
						errorOccured: 'Error. Something went wrong',
						confirmationTitle: 'Are you sure you want to delete this agreement',
						confirmationSubTitle: 'Deleting agreement will also delete all associated salaries',
						confirmationSubmitText: 'Yes',
						confirmationCancelText: 'No',
						editAgreement: 'Edit agreement',
						agreementDetails: 'Agreement details',
						deleteAgreement: 'Delete agreement',
					},
				},
			},
		},
	},
};

export const defaultConfirmationMessageData = {
	title: Resources.GlobalResources.empty,
};
