import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	defaultPLN: ' PLN',
};
export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerCharts: {
						customerTitlePercent: 'Podział budżetu na projekty w procentach',
						customerTitleTotal: 'Podział budżetu na projekty',
						customerDesc:
							'Przedstawienie graficzne podziału budżetów na poszczególne projekty należące do wybranego klienta.',
						totalBudget: 'Całkowity budżet klienta',
						customerLineDesc:
							'Przedstawienie graficzne podziału budżetów na poszczególne projekty w formie wykresu liniowego.',
						heading: 'Twój wykres się ładuje',
						description: 'Proszę, bądź cierpliwy, twoje dane są obliczane.',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerCharts: {
						customerTitlePercent: 'Percentage breakdown of the budget',
						customerTitleTotal: 'Breakdown of the budget',
						customerDesc: `Chart shows allocation of the client's budget.`,
						totalBudget: 'Total client budget',
						customerLineDesc: "Line chart shows allocation of the client's budget.",
						heading: 'Your chart is loading',
						description: 'Please be patient while your data is being calculated.',
					},
				},
			},
		},
	},
};
