import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		logo: {
			height: '2rem',
			margin: 'auto',
			justifyContent: 'center',
			display: 'flex',
		},
		logoWrapper: { margin: 'auto' },
	}),
	{ name: `Dashboard_TopBar_LinkToStartPage` }
);
