import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { isRtl } from '@skillandchill/tasker-widgets2/dist/utils';
import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			paddingLeft: '0px',
			paddingRight: '0px',
		},
		flexActiveTask: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.paper,
			borderRadius: 15,
			paddingLeft: 10,
			height: '40px',
			background: 'yellow',
		},
		flexActiveTaskWidth: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.default,
			borderRadius: 15,
			paddingLeft: 10,
			height: '40px',
			background: 'yellow',
		},
		flexTask: {
			border: '1px solid #bdbdbd',
			backgroundColor: theme.palette.background.default,
			borderRadius: 15,
			paddingLeft: 10,
			paddingRight: 10,
			height: '40px',
			background: 'yellow',
		},
		clock: {
			padding: theme.spacing(1, 2, 1, 1),
		},
		titleWorkingOn: {
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
		badge: {
			color: theme.palette.green.light,
			backgroundColor: theme.palette.background.paper,
			border: `1px solid`,
		},
		divider: {
			width: '2px',
			backgroundColor: theme.palette.lightGray.light,
			height: '80%',
			marginTop: '4px',
			marginBottom: '2px',
		},
		widthXL: {
			width: '500px',
		},
		widthL: {
			width: '465px',
		},
		hotelIcon: {
			color: theme.palette.lightGray.light,
			paddingTop: '3px',
		},
		titleContainer: {
			maxWidth: '320px',
			flex: 1,
			flexDirection: isRtl() ? 'row-reverse' : 'row',
		},
		titleContainerEstimatedTime: {
			flex: 1,
			maxWidth: '280px',
		},
		activeIssue: {
			WebkitBoxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			MozBoxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			boxShadow: 'inset 0px 0px 20px -9px rgb(0 213 36)',
			border: '2px solid rgb(0 213 36 / 22%)',
		},
		justifyContentArabic: {
			justifyContent: isRtl() ? 'flex-end' : 'unset',
			display: 'flex',
		},
		mobileText: {
			width: '40%',
		},
	}),
	{ name: `Dashboard_WorklogStarter` }
);
