export enum ModalType {
	Issue = 'Issue',
	KanbanChooseTagsToField = 'KanbanChooseTagsToField',
	DateRangePicker = 'DateRangePicker',
	Time = 'Time',
	Holiday = 'Holiday',
	IssueUrl = 'IssueUrl',
}

export enum ModalsEvent {
	Close = 'close',
	Open = 'open',
	DidSave = 'DidSave',
	DidSaveWithAnother = 'DidSaveWithAnother',
	DidUpdate = 'DidUpdate',
	SaveFailed = 'SaveFailed',
	Delete = 'Delete',
	Reset = 'Reset',
	ResetV2 = 'reset',
	CloseCreate = 'closeCreate',
	CloseEdit = 'closeEdit',
	CloseUpdate = 'closeUpdate',
	CancelCreate = 'cancelCreate',
	CancelEdit = 'cancelEdit',
	CloseCancelCreate = 'closeCancelCreate',
	CloseCancelUpdate = 'closeCancelUpdate',
	DidDeleted = 'didDeleted',
}
