import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			margin: theme.spacing(2, 0),
		},
	}),
	{ name: `IssueModalEditor_Tabs_General_IssueForm_IssueFormSectionWithCollapse_FieldDefinition` }
);
