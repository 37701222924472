import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		PortalList: {
			Filters: {
				Tags: {
					Tag: {},
				},
			},
		},
	},
	en: {
		PortalList: {
			Filters: { Tags: { Tag: {} } },
		},
	},
};
