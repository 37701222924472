import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	plus: '+',
	column: 'column' as const,
	alt: 'Alt',
	ctrl: 'Ctrl',
	arrowLeft: '<',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dashboard: {
			TopBar: {
				HotkeysTooltip: {
					or: 'lub',
					hello: 'Witamy w Time Booster, skróty klawiszowe dostępne na tej stronie to:',
					more: 'Kliknij, żeby zobaczyć wszystkie skróty.',
					noHotkeys:
						'Witamy w Time Booster, niestety na tej stronie nie posiadasz dostępnych skrótów klawiszowych.',
				},
			},
		},
	},
	en: {
		Dashboard: {
			TopBar: {
				HotkeysTooltip: {
					or: 'or',
					hello: 'Welcome to Time Booster, your possible keyboard shortcuts on this page are:',
					more: 'Click to show all shortcuts.',
					noHotkeys: 'Welcome to Time Booster, you have no keyboard shortcuts on this page.',
				},
			},
		},
	},
};
