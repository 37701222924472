import clsx from 'clsx';

import { Hidden } from '@mui/material';

import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { useTrans } from '@/utils/hooks/useTrans';

import { NavBarSection } from '../../NavBarSection';

import { LogOutButton } from './LogOutButton';
import { Props } from './model';
import { useStyles } from './styles';

export const DrawerContent = (props: Props): JSX.Element => {
	const { className, sections, onLogOut, ...rest } = props;
	const { t } = useTrans('Dashboard.NavBar.DrawerContent');
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(className, classes.fullHeight, classes.flexColumn)}>
			<nav className={clsx(classes.navigation, classes.flexGrow)}>
				{sections.map(item => (
					<NavBarSection section={item} key={item.name} />
				))}
			</nav>
			<Hidden mdUp>
				<LogOutButton onLogOut={onLogOut} logOutText={t('signOut')} />
			</Hidden>
		</div>
	);
};
