import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	emptySpecialText: 'emptySpecialText' as const,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				detailsTab: 'Szczegóły',
				subTasksTab: 'Podzadania',
				higherTasksTab: 'Zadania nadrzędne',
				equivalentTab: 'Zadania równorzędne',
				worklogsTab: 'Dziennik pracy',
				issueName: 'Nazwa zgłoszenia',
				project: 'Projekt',
				issueType: 'Typ zgłoszenia',
				issueProperties: 'Właściwości zgłoszenia',
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				detailsTab: 'Details',
				subTasksTab: 'Subtasks',
				higherTasksTab: 'Overarching tasks',
				equivalentTab: 'Equivalent tasks',
				worklogsTab: 'Worklogs',
				issueName: 'Issue name',
				project: 'Project',
				issueType: 'Issue type',
				issueProperties: 'Issue Properties',
			},
		},
	},
};
