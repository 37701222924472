import { IErrorValidationV2 } from '@skillandchill/tasker-types';

export enum ESocialMediaLinkInputName {
	Label = 'Label',
	Url = 'Url',
	UrlTypeId = 'UrlTypeId',
}

export interface ISocialMediaLink {
	Label: string;
	Url: string;
	UrlTypeId: number;
	id?: number;
}

export interface Props {
	handleSave: (formState: Partial<ISocialMediaLink>) => void;
	initialFormState?: Partial<ISocialMediaLink>;
	handleCancel: () => void;
	formErrors: IErrorValidationV2<ESocialMediaLinkInputName>[];
	setFormErrors: React.Dispatch<React.SetStateAction<IErrorValidationV2<ESocialMediaLinkInputName>[]>>;
}

export interface IEnumAsObject {
	Key: number;
	Label: string;
}
