import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	loggedTimeBackground: 'rgba(13,227,47,0.45)',
	loggedTimeBorder: 'rgba(69,192,69,0.72)',
	activeWorklogBackground: 'rgba(22,205,61,0.76)',
	activeWorklogBorder: 'rgba(25,161,54,0.73)',
	summaryTimeBackground: 'rgba(201,201,201,0.71)',
	summaryTimeBorder: 'rgba(255,255,255,0.13)',
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				summary: 'Łączny tygodniowy czas:',
				summaryTime: 'Suma z dnia',
				loggedTime: 'Zalogowany czas',
				activeWorklog: 'Aktywne zadania',
				issues: 'Twoje zgłoszenia',
				today: 'Dziś',
				lastWeek: 'Ostatni tydzień',
				move: 'Przejdź do issue ',
				comments: 'Komentarze w twoich zgłoszeniach',
				title: 'Tytuł:',
				comment: 'Komentarz:',
				lastComments: 'Ostatnie komentarze',
				noComments: 'Nic nie dodano...',
				worklogs: 'Zalogowany czas w tym tygodniu',
				loggedMonthTime: 'Miesięczny zalogowany czas: ',
				loading: 'Ładowanie',
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				summary: 'Summary week time:',
				summaryTime: 'Summary day time',
				loggedTime: 'Logged time',
				activeWorklog: 'Active issues',
				issues: 'Your Issues',
				today: 'Today',
				lastWeek: 'Last week',
				move: 'Open issue ',
				comments: 'Comments in your issues',
				title: 'Title:',
				comment: 'Comment:',
				lastComments: 'Last comments',
				noComments: 'Nothing added...',
				worklogs: 'Logged time this week',
				loggedMonthTime: 'Month logged time: ',
				loading: 'Loading',
			},
		},
	},
};
