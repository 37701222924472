import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	customers: '#/customers/',
};

export const Translation = {
	pl: {
		WorkDayEndSummary: {
			workDaySummary: 'Podsumowanie dnia pracy',
			creationDate: 'Data rozpoczęcia',
			dateOfCompletion: 'Data wypełnienia',
		},
	},
	en: {
		WorkDayEndSummary: {
			workDaySummary: 'Workday summary',
			creationDate: 'Start date',
			dateOfCompletion: 'Completion date',
		},
	},
};
