import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	primaryCheckBox: 'primary checkbox' as const,
	ariaLabel: 'aria-label' as const,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Comments: {
				post: 'Wyślij',
				commentEditFailed: 'Dodanie komentarza zakończona niepowodzeniem, ponieważ: ',
				displayAllComments: 'Pokaż usunięte komentarze',
				advancedComment: 'Zaawansowany komentarz',
				portalPost: 'Wyślij na portal',
				comment: 'Komentarz',
				textIsToLong: 'Zbyt długi komentarz',
				commentAddSuccess: 'Pomyślnie dodano komentarz',
				commentDeleteSuccess: 'Usunięto komentarz',
				commentDeleteError: 'Błąd podczas usuwania',
				cancel: 'Anuluj',
				editTitle: 'Edytuj komentarz',
				edit: 'Edytuj',
				commentEditSuccess: 'Pomyślnie edytowano komentarz',
				noComments: 'Brak komentarzy',
			},
		},
	},
	en: {
		IssueModalEditor: {
			Comments: {
				post: 'Post',
				commentEditFailed: 'Add of comment failure cause: ',
				displayAllComments: 'Show deleted comments',
				advancedComment: 'Advanced comment',
				portalPost: 'Post on portal',
				comment: 'Comment',
				textIsToLong: 'Comment is to long',
				commentAddSuccess: 'Comment successfully added',
				commentDeleteSuccess: 'Comment has been deleted',
				commentDeleteError: 'Error while deleting comment',
				cancel: 'Cancel',
				editTitle: 'Edit a comment',
				edit: 'Edit',
				commentEditSuccess: 'Comment succesfully edited',
				noComments: 'No comments available',
			},
		},
	},
};
