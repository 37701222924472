import { ITag } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	defaultSelectedTagGroup: { Id: -1, Label: '-' } as Partial<ITag>,
	args: {
		fullWidth: true,
		variant: 'outlined' as const,
	},
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						SubHeader: {
							type: 'Typ zgłoszenia',
							projects: 'Typ projektu',
							formLabel: 'Nazwa formularza',
							isPortalLabel: 'Użyj jako formularz portalowy',
							typeProject: 'Wybierz projekt',
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						SubHeader: {
							type: 'Issue type',
							projects: 'Project type',
							formLabel: 'Form name',
							isPortalLabel: 'Use as portal form',
							typeProject: 'Type project',
						},
					},
				},
			},
		},
	},
};
