import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			Preferences: {
				Tile: {
					100002: 'Tryb ciemny',
					100003: 'Obsługuj zegarek za pomocą kanbana',
					100004: 'Zezwól tylko na jedno aktywne zadanie',
					100005: 'Otwórz meet wraz z zadaniem',
					100006: 'Zezwól na edycje tile',
					100008: 'Włącz light mode w kanbanie',
					100009: 'Uproszczone filtry dla list zgłoszeń i kanbana',
					100010: 'Spersonalizowany raport czasu',
					Description: {
						100002: 'Czy włączyć tryb ciemny?',
						100003: 'Czy przesunięcie zadania na kanbanie do odpowiedniej kolumny powinno odpalać zegarek?',
						100004: 'Czy chcesz aby odpalenie zadania X zatrzymywało zadanie Y jeżeli jest odpalone?',
						100005: 'Jeżeli zadanie, które odpalamy za pomocą zegarka czy ma się otworzyć wraz z zadaniem meet?',
						100006: 'Czy chcesz uruchomić możliwość edycji tile?',
						100008: 'Włącz light mode w kanbanie, który polega na ograniczeniu ilości wyświetlanych danych na kafelku zgłoszeniowym w celu zwiększenia wydajności',
						100009: 'Włącz uproszczenie filtrów dla listy zgłoszeń i kanbana',
						100010: 'Spersonalizowany raport czasu',
					},
				},
			},
		},
	},
	en: {
		Settings: {
			Preferences: {
				Tile: {
					100002: 'Dark mode',
					100003: 'Use Kanban with clock',
					100004: 'Allow only for one active task',
					100005: 'Open meet when start task',
					100006: 'Allow tile editing',
					100008: 'Improve performance by reducing displaying staff',
					100009: 'Simplify filters for kanban and issue list',
					100010: 'Personalized time report',
					Description: {
						100002: 'Turn on dark mode?',
						100003: 'Move task to correct column should start measuring time?',
						100004: 'Start measuring time at task X should stop it in another if its started?',
						100005: 'Open task when is configured meet should open this url in new tab?',
						100006: 'Do you want to enable tile editing?',
						100008: 'Improve performance by reducing displaying staff',
						100009: 'Simplify filters for kanban and issue list',
						100010: 'Personalized time report',
					},
				},
			},
		},
	},
};
