import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			starIcon: {
				fontSize: '30px',
			},

			starIconButton: {
				marginRight: theme.spacing(1),

				'&:hover': {
					color: '#ffc31f',
				},
			},

			favouriteIssue: {
				color: '#ffc31f',
			},

			issueSetAsFavourited: {
				color: '#ffc31f',
			},
		};
	},
	{ name: `IssueModalEditor` }
);
