export const Translation = {
	pl: {
		Dashboard: {
			NavBar: {
				DrawerContent: {
					signOut: 'Wyloguj',
				},
			},
		},
	},
	en: {
		Dashboard: {
			NavBar: {
				DrawerContent: {
					signOut: 'Sign out',
				},
			},
		},
	},
};
