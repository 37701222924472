import * as global_axios_package from 'axios';
import { format, subDays } from 'date-fns';
import Cookie from 'universal-cookie';

import { AsyncAction } from '@skillchill/redux-promisify';

import { EDelegationStep, IDelegation, IPage } from '@skillandchill/tasker-types';
import { IDelegationExchange } from '@skillandchill/tasker-types/dist/types/endPoints/delegationDetail';
import { EDateFNS } from '@skillandchill/tasker-widgets2/dist/utils';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';
import { EDelegationAcceptanceListFilter } from '@/views/DelegationsAcceptanceList/DelegationAcceptanceListFilters/model';
import { EDelegationListFilter } from '@/views/DelegationsList/DelegationListFilters/model';

import { IJson } from '../ITypes';

const axios_global = global_axios_package.default;
export const DELEGATION_FETCH_MANY: ActionType = 'DELEGATION_FETCH_MANY';
export const DELEGATION_FETCH_ONE: ActionType = 'DELEGATION_FETCH_ONE';
export const DELEGATION_FETCH_ACCEPTANCE_MANY: ActionType = 'DELEGATION_FETCH_ACCEPTANCE_MANY';
export const DELEGATION_CREATE: ActionType = 'DELEGATION_CREATE';
export const DELEGATION_UPDATE: ActionType = 'DELEGATION_UPDATE';
export const DELEGATION_REMOVE: ActionType = 'DELEGATION_REMOVE';
export const DELEGATION_REQUEST_TO_FETCH_MANY: ActionType = 'DELEGATION_REQUEST_TO_FETCH_MANY';
export const DELEGATION_REQUEST_TO_WITH_ID_FETCH_MANY: ActionType = 'DELEGATION_REQUEST_TO_WITH_ID_FETCH_MANY';

const basePath = 'Delegation';

function _fetchManyDelegations(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_FETCH_MANY,
		data,
	};
}

function _fetchManyAcceptanceDelegations(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_FETCH_ACCEPTANCE_MANY,
		data,
	};
}

function _fetchOneDelegation(data: IDelegationExchange, id: number): Action {
	return {
		type: DELEGATION_FETCH_ONE,
		id: id,
		data,
	};
}

function _createDelegation(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_CREATE,
		data,
	};
}

function _updateDelegation(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_UPDATE,
		data,
	};
}

function _removeDelegation(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_REMOVE,
		data,
	};
}

function _fetchDelegationsRequestTo(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_REQUEST_TO_FETCH_MANY,
		data,
	};
}

function _fetchDelegationsRequestToWithId(data: IPage<IDelegation[]>): Action {
	return {
		type: DELEGATION_REQUEST_TO_WITH_ID_FETCH_MANY,
		data,
	};
}

export function delegation_fetchMany(from: number, to: number, filter = EDelegationListFilter.All): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchManyDelegations((json as IJson).data)));
		return axios().get(`${basePath}/from/${from}/to/${to}/filter/${filter}`);
	};
}

export function delegation_fetchManyAcceptance(
	from: number,
	to: number,
	filter = EDelegationAcceptanceListFilter.All
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchManyAcceptanceDelegations((json as IJson).data)));
		return axios().get(`${basePath}/acceptance/from/${from}/to/${to}/filter/${filter}`);
	};
}

export function delegation_fetchOne(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneDelegation((json as IJson).data, id)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function delegation_create(
	data: IDelegationExchange,
	files?: File[],
	filter = EDelegationListFilter.All
): AsyncAction {
	const fd = new FormData();
	fd.append('data', JSON.stringify(data));
	fd.append('filter', JSON.stringify(filter));
	if (files) {
		for (let i = 0; i < files?.length; i++) {
			const file = files[i];
			fd.append('files', file);
		}
	}
	return function(context, dispatch) {
		context.then(json => dispatch(_createDelegation((json as IJson).data)));
		return axios().post(`${basePath}`, fd);
	};
}

export function delegation_update(
	data: IDelegationExchange,
	id: number,
	filters: {
		delegationListFilter?: EDelegationListFilter;
		delegationAcceptanceListFilter?: EDelegationAcceptanceListFilter;
	}
): AsyncAction {
	const json = JSON.stringify(data);
	return function(context, dispatch) {
		context.then(json => dispatch(_updateDelegation((json as IJson).data)));
		return axios().post(`${basePath}/${id}`, {
			data: json,
			filters,
		});
	};
}

export function delegation_remove(id: number, filter = EDelegationListFilter.All): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_removeDelegation((json as IJson).data)));
		return axios().delete(`${basePath}/${id}`, {
			data: { filter },
		});
	};
}

export function delegation_requestTo(
	step: EDelegationStep,
	data: IDelegationExchange,
	files?: File[],
	filters?: {
		delegationListFilter?: EDelegationListFilter;
		delegationAcceptanceListFilter?: EDelegationAcceptanceListFilter;
	}
): AsyncAction {
	const fd = new FormData();
	fd.append('data', JSON.stringify(data));
	fd.append('filters', JSON.stringify(filters));
	if (files) files.forEach(file => fd.append('files', file));
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationsRequestTo((json as IJson).data)));
		return axios().post(`${basePath}/requestTo/${step}`, fd);
	};
}

export function delegation_requestToWithId(
	step: EDelegationStep,
	id: number,
	filters?: {
		delegationListFilter?: EDelegationListFilter;
		delegationAcceptanceListFilter?: EDelegationAcceptanceListFilter;
	}
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchDelegationsRequestToWithId((json as IJson).data)));
		return axios().post(`${basePath}/${id}/requestTo/${step}`, {
			data: { filters },
		});
	};
}

export async function delegation_getCurrencyRate(currency: string, date: Date) {
	let currentDate = date;
	let data = null;

	while (!data) {
		if (currency.toLowerCase() === 'pln') {
			return {
				response: { rates: [{ mid: 1 }] },
				date: currentDate,
			};
		} else {
			try {
				const responseA = await axios_global.get(
					`http://api.nbp.pl/api/exchangerates/rates/a/${currency}/${format(
						currentDate,
						EDateFNS.FormatDash
					)}/?format=json`
				);
				if (responseA.data) {
					data = responseA.data;
				}
			} catch (errorA) {
				try {
					const responseB = await axios_global.get(
						`http://api.nbp.pl/api/exchangerates/rates/b/${currency}/${format(
							currentDate,
							EDateFNS.FormatDash
						)}/?format=json`
					);
					if (responseB.data) {
						data = responseB.data;
					}
				} catch (errorB) {
					currentDate = subDays(currentDate, 1);
				}
			}
		}
	}

	return {
		response: data,
		date: currentDate,
	};
}
