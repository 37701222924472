import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	selectProps: {
		menuPosition: 'fixed',
		isSearchable: false,
		closeMenuOnSelect: false,
		valuesLimit: 10,
	},
};

export const Translation = {
	pl: {
		Calendar: {
			Toolbar: {
				monthLabel: 'Miesiąc',
				weekLabel: 'Tydzień',
				dayLabel: 'Dzień',
				agendaLabel: 'Agenda',
				dayGridLabel: 'Niestandardowy',
				DefaultSearchValue: 'Wpisz wyszukiwanego użytkownika',
				delete: 'Usuń',
			},
		},
	},
	en: {
		Calendar: {
			Toolbar: {
				monthLabel: 'Month',
				weekLabel: 'Week',
				dayLabel: 'Day',
				agendaLabel: 'Agenda',
				dayGridLabel: 'Custom',
				DefaultSearchValue: 'Type user',
				delete: 'Delete',
			},
		},
	},
};
