import React, { useState } from 'react';

import { ITag } from '@skillandchill/tasker-types';
import { MultiSelectTextField } from '@skillandchill/tasker-widgets2';

import { useCurrentTheme } from '@/utils/hooks/useCurrentTheme';

import { IssueModalSelectTextFieldFontSize, IssueModalSelectTextFieldHeight } from 'view/IssueModalEditor/resources';

import { Props } from './model';
import { Resources } from './resources';

export const MultiTag = (props: Props): JSX.Element => {
	const { value: valueInput, onChange: onChangeInput, items } = props;
	const { currentTheme } = useCurrentTheme();

	const [value, setValue] = useState<Partial<ITag>[]>(valueInput as Partial<ITag>[]);

	const onChange = (object: Partial<ITag>[]) => {
		setValue(object);
		onChangeInput(object);
	};

	return (
		<MultiSelectTextField<Partial<ITag>>
			muiTheme={currentTheme}
			getName={c => c?.Label ?? ''}
			getKey={c => c?.Id ?? -1}
			items={items}
			values={value}
			args={Resources.selectArgs}
			onChange={onChange}
			height={value?.length > 1 ? undefined : IssueModalSelectTextFieldHeight}
			fontSize={IssueModalSelectTextFieldFontSize}
		/>
	);
};
