import React from 'react';
import { components } from 'react-select';

import { Checkbox, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { DictionaryItemComponent } from '../DictionaryItemComponent';

import { useStyles } from './styles';

export const CustomOption = (props: any) => {
	const { children, hasValue, ...rest } = props;
	const classes = useStyles();

	// const { dictionaryItem } = rest;

	const id = rest.value;
	const dictionaryItem = rest.options.find((option: any) => option.value === id).object;

	return (
		<components.Option {...props}>
			<DictionaryItemComponent dictionaryItem={dictionaryItem} classNameText={classes.text} />
		</components.Option>
	);
};
