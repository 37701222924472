export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						add: 'Dodaj nowy link',
					},
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						add: 'Add new link',
					},
				},
			},
		},
	},
};
