import { LangResources } from '@skillandchill/tasker-widgets2/dist/utils/language/languageResources';
import translationReduced from '@skillandchill/translate/dist/ar/default.json';

import { multipleMerge } from '@/locales/utils';

const translation = multipleMerge([translationReduced.FRONTEND, LangResources.ar]);
const widgets = multipleMerge([translationReduced.WIDGETS, LangResources.ar]);

export default {
	ar_default: {
		FRONTEND: translation,
		WIDGETS: widgets,
		BACKEND: translationReduced.BACKEND,
	},
};
