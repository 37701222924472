import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	warning: 'warning' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					SettlementAcceptanceEditActions: {
						rejectAndSaveAcceptance: 'Sprawdzam i odrzucam',
						returnAndSaveAcceptance: 'Sprawdzam i zwracam do akceptacji',
						submitAndSaveAcceptance: 'Sprawdzam i akceptuję',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					SettlementAcceptanceEditActions: {
						rejectAndSaveAcceptance: 'Validate and reject',
						returnAndSaveAcceptance: 'Validate and return to acceptance',
						submitAndSaveAcceptance: 'Validate and accept',
					},
				},
			},
		},
	},
};
