import { AsyncAction } from '@skillchill/redux-promisify';

import { Modifiable } from '@skillandchill/tasker-widgets2/dist/utils/types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const ALERT_FETCH: ActionType = 'ALERT_FETCH';
export const ALERT_RESET: ActionType = 'ALERT_RESET';

export interface Alert extends Modifiable {
	title: string;
	subtitle: string;
	description: string;
	isRead: boolean;
	link: string;
}

function _fetchAlert(alerts: Alert[]): Action {
	return {
		type: ALERT_RESET,
		alerts,
	};
}

export function alert_fetchAlerts(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAlert((json as IJson).data)));
		return axios().get(`/alert`);
	};
}

export function alert_readAlerts(ids: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(alert_fetchAlerts()));
		return axios().post(`/alert`, ids);
	};
}

export function alert_readAlertById(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(alert_fetchAlerts()));
		return axios().post(`/alert/${id}`);
	};
}
