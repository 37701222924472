import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};
export const Translation = {
	pl: {
		LeaveRequestCalendar: {
			Content: {
				FullDayLeave: {
					dateRange: 'Zakres dat: ',
					leaveType: 'Typ urlopu: ',
				},
			},
		},
	},
	en: {
		LeaveRequestCalendar: {
			Content: {
				FullDayLeave: {
					dateRange: 'Range of date: ',
					leaveType: 'Leave type: ',
				},
			},
		},
	},
};
