import { ProviderContext, SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { HashRouter } from 'react-router-dom';

import { Button } from '@mui/material';

import { isMobile } from '@skillandchill/tasker-widgets2/dist/utils';
import { CookiesNotification } from '@skillandchill/tasker-widgets2/dist/widgets/CookieNotification';
import { ErrorBoundary } from '@skillandchill/tasker-widgets2/dist/widgets/ErrorBoundry';
import { GoogleAnalytics } from '@skillandchill/tasker-widgets2/dist/widgets/GoogleAnalytics';
import { ScrollReset } from '@skillandchill/tasker-widgets2/dist/widgets/ScrollReset';
import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { SecureRouter } from '@/layouts/SecureRouter';
import { Routes } from '@/routes';
import { session_setIsSmallDevice } from '@/store/actions';
import { GlobalStyles } from '@/utils/assets/CommonMakeStyles/Global';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const SnackBarProviderWrapper = (props: Props): JSX.Element => {
	const { snackbarRef } = props;
	const classes = useStyles();
	GlobalStyles();
	const dispatch = useDispatch();
	const { t } = useTrans('SnackBarProviderWrapper');

	useEffect(() => {
		dispatch(session_setIsSmallDevice(isMobile()));
	}, []);

	return (
		<SnackbarProvider
			hideIconVariant
			classes={useStyles()}
			ref={snackbarRef}
			maxSnack={1}
			anchorOrigin={{
				vertical: Resources.GlobalResources.top,
				horizontal: Resources.GlobalResources.center,
			}}
			autoHideDuration={3000}
			action={key => (
				<Button
					onClick={() => (snackbarRef?.current as ProviderContext)?.closeSnackbar(key)}
					className={classes.closeButton}
				>
					{t('dismiss')}
				</Button>
			)}
		>
			<HashRouter>
				<ErrorBoundary redirectTo={Resources?.errorLink}>
					<ScrollReset />
					<GoogleAnalytics />
					<CookiesNotification />
					<SecureRouter>{renderRoutes(Routes)}</SecureRouter>
				</ErrorBoundary>
			</HashRouter>
		</SnackbarProvider>
	);
};
