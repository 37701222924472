import uuid from 'uuid';

import { IDictionary } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	Keys: {
		budget: (x: IDictionary) => `Budgets_${x?.Id}_${uuid()}`,
		project: (x: IDictionary) => `Project_${x?.Id}_${uuid()}`,
	},
	Names: {
		budget: (x: IDictionary) => x?.Name,
		project: (x: IDictionary) => x?.Name,
	},
	red: 'red',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						DictionaryConfiguration: {
							budget: 'Słownik dla budżetów',
							project: 'Słownik dla projektów',
							attachmentTypes: 'Rodzaje załączników',
							transportTypes: 'Rodzaje środków transportu',
							title: 'Konfiguracja słowników wykorzystywanych w delegacjach',
							subtitle: 'Słowniki wykorzystywane w delegacjach do zdefiniowania budżetów i projektów',
							error:
								'Zarówno projekt jak i budget musi zostać wypełniony aby zadziałał automatyczny zapis',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						DictionaryConfiguration: {
							budget: 'Dictionary for budgets',
							project: 'Dictionary for projects',
							attachmentTypes: 'Attachment types',
							transportTypes: 'Means of transport types',
							title: 'Configuration of dictionaries used in delegates',
							subtitle: 'Dictionary used in delegates for defining budgets and projects',
							error: 'Both project and budget must be defined to be saved automatically',
						},
					},
				},
			},
		},
	},
};
