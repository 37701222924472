import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';

import { ITaskerUserPwdSetting } from '@skillandchill/tasker-types';

import { Routes, SecureRouteConfig } from '@/routes';
import { session_loginWithToken } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { CheckRoles } from '@/utils/permissions';

import { Props } from './model';

export const SecureRouter = (props: Props): JSX.Element => {
	const history = useHistory();
	const session = useSelector((state: IReducerState) => state.Session);
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();
	const [login, setLogin] = useState<boolean>(false);
	let firstLoad = false;

	useEffect(() => {
		if (session?.access_token && session?.access_token != '') {
			firstLoad = true;
			setLogin(true);
			const startLocation = location;
			const changePasswordLocation = '/auth/changepassword';
			history.replace('/auth/loading');
			dispatch(session_loginWithToken(session.access_token))
				.then(() => {
					const pwdSetting = session?.taskerUser?.TaskerUserPwdSettingTaskerUser?.find(
						(setting: ITaskerUserPwdSetting) => setting.PasswordMustChange
					);
					if (pwdSetting?.PasswordMustChange) history.push(changePasswordLocation);
					else history.push(startLocation);
				})
				.catch(() => {
					const routes = matchRoutes(Routes, startLocation.pathname);
					if (routes.reduce((x, y) => (y.route.secured ?? false) || x, false) && !session.isLoggedIn) {
						history.replace('/auth/login');
					} else history.push(startLocation);
				})
				.finally(() => {
					setLogin(false);
				});
		}
	}, []);

	useEffect(() => {
		// If user is logged in or it's the first load, return
		if (login || firstLoad) return;

		// If user appears to be on the error page, return because we don't want to trigger infinite loop
		const errorPages = ['/errors/error-401', '/errors/error-403', '/errors/error-404', '/errors/error-500'];
		if (errorPages.includes(location.pathname)) return;

		// Route protection, redirects user to login page if doesn't have necessary permissions
		const routes = matchRoutes(Routes, location.pathname);
		if (routes.reduce((x, y) => (y.route.secured ?? false) || x, false) && !session.isLoggedIn) {
			history.replace('/auth/login');
			return;
		}

		// Redirect user to change password page if password must be changed
		const changePasswordLocation = '/auth/changepassword';
		const pwdSetting = session?.taskerUser?.TaskerUserPwdSettingTaskerUser?.find(
			(setting: ITaskerUserPwdSetting) => setting.PasswordMustChange
		);
		if (pwdSetting?.PasswordMustChange && location.pathname !== changePasswordLocation)
			history.push(changePasswordLocation);
		else if (!pwdSetting?.PasswordMustChange && location.pathname === changePasswordLocation)
			history.push('/activity');

		// Check if user has necessary permission
		const route: SecureRouteConfig = routes[routes.length - 1].route;
		if (!CheckRoles(session.permissions, route.permission)) {
			history.push('/errors/error-401');
		}
	}, [location, session.isLoggedIn]);
	return <div>{props.children}</div>;
};
