import { ICustomer, IProject, IWorkLog } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { applyUpdateInList } from '@/utils/reducer';

const State = {
	projects: [] as IProject[],
	worklogs: [] as IWorkLog[],
	customers: [] as ICustomer[],
};
export type IGantState = typeof State;

const gantReducer = (state = State, action: ObjectAction): IGantState => {
	switch (action.type) {
		case actionTypes.PROJECT_UPDATE: {
			return {
				...state,
				projects: applyUpdateInList<IProject>(state.projects, action.project),
			};
		}
		case actionTypes.GANT_WORKLOG_FETCH: {
			return {
				...state,
				worklogs: action.worklogs,
			};
		}
		case actionTypes.GANT_CUSTOMER_FETCH: {
			return {
				...state,
				customers: action.customers,
			};
		}
		default: {
			return state;
		}
	}
};

export default gantReducer;
