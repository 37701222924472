export const Resources = {
	Activity: {
		href: '/activity',
		title: 'Activities',
	},
	Projects: {
		href: '/projects',
		title: 'Projects',
	},
	Issues: {
		href: '/issues',
		title: 'Issues',
	},
	Kanban: {
		href: '/kanban',
		title: 'Kanban',
	},
	Customers: {
		href: '/customers',
		title: 'Customers',
	},
	Forms: {
		href: '/forms/',
		title: 'Forms',
	},
	Reports: {
		href: '/reports',
		title: 'Reports',
	},
	Agreement: {
		href: '/agreements',
		title: 'Agreement',
	},
	Time: {
		href: '/report/time',
		title: 'Time',
	},
	TimeCalendar: {
		href: '/calendar',
		title: 'TimeCalendar',
	},
	LeaveRequestCalendar: {
		href: '/leaveRequestCalendar',
		title: 'LeaveRequestCalendar',
	},
	WorkdaySummary: {
		href: '/workday-summary',
		title: 'WorkdaySummary',
	},
	GantCalendar: {
		href: '/gant',
		title: 'Gant',
	},
	Organization: {
		href: '/admin/organization',
		title: 'Organization',
	},
	Users: {
		href: '/admin/user',
		title: 'Users',
	},
	Tag: {
		href: '/admin/tag',
		title: 'Tag',
	},
	Settings: {
		href: '/settings',
		title: 'Settings',
	},
	Section: {
		user: 'user',
		admin: 'admin',
		config: 'config',
		reports: 'reports',
		dashboards: 'dashboards',
		portal: 'portal',
	},
	Portal: {
		href: '/portal',
		title: 'Portal',
	},
	Dictionaries: {
		href: '/dictionaries',
		title: 'Dictionaries',
	},
	HolidayType: {
		href: '/admin/holidayType',
		title: 'HolidayType',
	},
	Holidays: {
		href: '/admin/holiday',
		title: 'Holidays',
	},
	LeaveType: {
		href: '/leaveType',
		title: 'LeaveType',
	},
	Budget: {
		href: '/budget',
		title: 'Budget',
	},
	Delegations: {
		href: '/delegations',
		title: 'Delegations',
	},
	DelegationsAcceptance: {
		href: '/delegationsAcceptance',
		title: 'DelegationsAcceptance',
	},
	RoleConfiguration: {
		href: '/admin/role/configuration',
		title: 'RoleConfiguration',
	},
};

export const Translation = {
	pl: {
		Nav: {
			Reports: 'Raporty',
			Activities: 'Aktywności',
			Projects: 'Projekty',
			Issues: 'Zgłoszenia',
			Kanban: 'Kanban',
			Customers: 'Klienci',
			Forms: 'Formularze',
			Agreement: 'Umowy',
			Time: 'Czas',
			TimeCalendar: 'Kalendarz',
			WorkdaySummary: 'Podsumowanie dnia',
			LeaveRequestCalendar: 'Urlopy',
			Gant: 'Gant',
			Users: 'Użytkownicy',
			Organization: 'Organizacja',
			Tag: 'Tag',
			Settings: 'Ustawienia',
			Section: {
				user: 'Użytkownik',
				admin: 'Administrator',
				reports: 'Raporty',
				dashboards: 'Główne',
				portal: 'Portal',
				config: 'Konfiguracja',
			},
			Portal: 'Portal',
			Dictionaries: 'Słowniki',
			HolidayType: 'Rodzaje dni wolnych',
			Holidays: 'Dni wolne',
			LeaveType: 'Rodzaje urlopów',
			Budget: 'Budżet',
			Delegations: 'Delegacje',
			DelegationsAcceptance: 'Akceptacja delegacji',
			RoleConfiguration: 'Role',
			RoleConfigurationNew: 'Nowe role',
		},
	},
	en: {
		Nav: {
			Reports: 'Reports',
			Activities: 'Activities',
			Projects: 'Projects',
			Issues: 'Issues',
			Kanban: 'Kanban',
			Customers: 'Customers',
			Forms: 'Forms',
			Agreement: 'Agreements',
			Time: 'Time',
			TimeCalendar: 'Calendar',
			LeaveRequestCalendar: 'Leave requests',
			WorkdaySummary: 'Workday summary',
			Gant: 'Gant',
			Organization: 'Organization',
			Users: 'Users',
			Tag: 'Tag',
			Settings: 'Settings',
			Section: {
				user: 'User',
				admin: 'Admin',
				reports: 'Reports',
				dashboards: 'Dashboards',
				portal: 'Portal',
				config: 'Config',
			},
			Portal: 'Portal',
			Dictionaries: 'Dictionaries',
			HolidayType: 'Holiday types',
			Holidays: 'Holidays',
			LeaveType: 'Leave type',
			Budget: 'Budget',
			Delegations: 'Delegations',
			DelegationsAcceptance: 'Delegations acceptance',
			RoleConfiguration: 'Roles',
			RoleConfigurationNew: 'New roles',
		},
	},
};
