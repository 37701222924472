import { IPage, ITaskerUser } from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IWorkDayEndSummaryFilter } from '@skillandchill/tasker-types/dist/types/endPoints/workDayEndSummaryFilter';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	taskerUsers: [] as Partial<ITaskerUser>[],
	filterCheckListValue: {
		text: '' as string,
		userId: null as number | null,
		date: null as Date | null,
	} as IWorkDayEndSummaryFilter,
	checkListValue: {
		Count: 0,
		Data: [],
	} as IPage<ICheckListValue[]>,
	selectedUser: null as Partial<ITaskerUser> | null,
};
export type IworkDayEndSummaryState = typeof State;

const workDayEndSummaryReducer = (state = State, action: ObjectAction): IworkDayEndSummaryState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		case actionTypes.CHECK_LIST_VALUE_SET_FILTERS: {
			return {
				...state,
				filterCheckListValue: action?.filter,
			};
		}
		case actionTypes.FETCH_ALL_CHECKLIST: {
			return {
				...state,
				checkListValue: action.checkListValue,
			};
		}
		case actionTypes.WORK_DAY_END_SUMMARY_SET_LOCAL_USERS: {
			return {
				...state,
				selectedUser: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default workDayEndSummaryReducer;
