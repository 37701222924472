import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Overview: {
				UsersInProject: {
					CardHeaderTitle: {
						cardHeaderTitle: 'Użytkownicy w projekcie',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Overview: {
				UsersInProject: {
					CardHeaderTitle: {
						cardHeaderTitle: 'Users in project',
					},
				},
			},
		},
	},
};
