import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	handleChangeFieldCustomer: 'CustomerId' as const,
	handleChangeFieldOwner: 'OwnerId',
	Customer: {
		field: 'CustomerId',
	},
	styles: 'styles',
	errorColor: '#e53935',
};

export const Translation = {
	pl: {
		ProjectCreate: {
			AboutCustomer: {
				Customer: {
					label: 'Klient',
				},
				defaultSearchValue: 'Wpisz wyszukiwane klienta',
			},
		},
	},
	en: {
		ProjectCreate: {
			AboutCustomer: {
				Customer: {
					label: 'Customer',
				},
				defaultSearchValue: 'Type searching customer',
			},
		},
	},
};
