import { IssueListFiltersTranslationEN, IssueListFiltersTranslationPL } from './Filters/index.translation';
import { Translation as IssueListPagination } from './IssueListPagination/resources';
import { Translation as IssueList } from './resources';
import { Translation as AttachmentCount } from './TileComponents/Attachment/resources';
import { Translation as CreationDate } from './TileComponents/CreationDate/resources';
import { Translation as Project } from './TileComponents/Project/resources';
import { Translation as TileComponents } from './TileComponents/resources';
import { Translation as StartPause } from './TileComponents/StartPause/resources';

export const IssueListTranslationPL = [
	IssueListPagination.pl,
	IssueList.pl,
	AttachmentCount.pl,
	CreationDate.pl,
	TileComponents.pl,
	StartPause.pl,
	Project.pl,
	...IssueListFiltersTranslationPL,
];

export const IssueListTranslationEN = [
	IssueListPagination.en,
	IssueList.en,
	AttachmentCount.en,
	CreationDate.en,
	TileComponents.en,
	StartPause.en,
	Project.en,
	...IssueListFiltersTranslationEN,
];
