import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Actions: {
					save: 'Zapisz',
					close: 'Zamknij',
					edit: 'Edytuj',
					finishEdit: 'Zakończ edycje bez zapisu',
					add: 'Dodaj przedmiot słownika',
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Dictionaries: {
				Modal: {
					Actions: {
						save: 'Save',
						close: 'Close',
						edit: 'Edit',
						finishEdit: 'Finish edit without save',
						add: 'Add dictionary item',
					},
				},
			},
		},
	},
};
