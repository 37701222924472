import clsx from 'clsx';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Collapse, ListItem, Tooltip } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { NavBarTabConfig } from '@/layouts/Dashboard/NavBar/DrawerContentResized/NavBarSection/model';
import { NavItemWrapper } from '@/layouts/Dashboard/NavBar/DrawerContentResized/NavBarSection/NavItem';
import { useTrans } from '@/utils/hooks/useTrans';
import { collapseEndListener } from '@/utils/utils';

import { ButtonWrapper } from './ButtonWraper';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const NavItemBase = (props: Props): JSX.Element => {
	const { open: startOpen, item, depth, className } = props;
	const { icon: Icon, title, href, items: children } = item;
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(startOpen);
	const { tString } = useTrans('Nav');

	const handleToggle = () => setOpen(prevOpen => !prevOpen);
	const paddingLeft = depth > 0 ? 32 + 8 * depth : 8;
	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem className={clsx(classes.item, className)} disableGutters>
				<Button className={clsx(classes.button, classes.fullWidth)} onClick={handleToggle} style={style}>
					<VisibilityControl condition={!!Icon}>
						{Icon && <Icon className={classes.icon} />}
					</VisibilityControl>
					<VisibilityControl
						condition={open}
						alternative={
							<ExpandMoreIcon className={classes.expandIcon} color={Resources.GlobalResources.inherit} />
						}
					>
						<ExpandLessIcon className={classes.expandIcon} color={Resources.GlobalResources.inherit} />
					</VisibilityControl>
				</Button>
				<Collapse in={open} addEndListener={collapseEndListener}>
					<ul>
						{children.map((child: NavBarTabConfig) => (
							<NavItemWrapper
								key={`${Resources.children}${child.title}`}
								item={child}
								depth={depth + 1}
							/>
						))}
					</ul>
				</Collapse>
			</ListItem>
		);
	}

	return (
		<Tooltip
			title={title ? tString(title) : Resources.GlobalResources.empty}
			placement={Resources.GlobalResources.right}
		>
			<ListItem
				disableGutters
				key={`${title}${href}`}
				className={clsx(classes.itemLeaf, className, classes.flexRow)}
			>
				<ButtonWrapper
					className={clsx(classes.buttonLeaf, `depth-${depth}`, classes.fullWidth)}
					component={RouterLink}
					style={style}
					activeClassName={classes.active}
					to={href ?? Resources.GlobalResources.empty}
				>
					<VisibilityControl condition={!!Icon}>
						{Icon && <Icon className={clsx(classes.icon, classes.flexYCenter)} />}
					</VisibilityControl>
				</ButtonWrapper>
			</ListItem>
		</Tooltip>
	);
};
