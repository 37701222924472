import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton, Typography } from '@mui/material';

import { IErrorValidationV2, IIssueUrl } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { createIssueUrl, updateIssueUrl } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Form } from './Form';
import { ESocialMediaLinkInputName, ISocialMediaLink } from './Form/model';
import { SocialMediaLink } from './SocialMediaLink';
import { useStyles } from './styles';

export const SocialMediaLinks = () => {
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const { t } = useTrans('IssueModalNew.IssueModalTitle.MoreOptions.SocialMediaLinks');

	const [isNewLinkFormVisible, setIsNewLinkFormVisible] = useState(false);
	const [issueUrlInEdit, setIssueUrlInEdit] = useState<Partial<IIssueUrl>>({});
	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const [formErrors, setFormErrors] = useState<IErrorValidationV2<ESocialMediaLinkInputName>[]>([]);

	useEffect(() => {
		setFormErrors([]);
	}, [issueUrlInEdit, isNewLinkFormVisible]);

	const handleCreate = (formState: Partial<ISocialMediaLink>) => {
		dispatch(
			createIssueUrl({
				Url: formState.Url,
				Label: formState.Label,
				UrlTypeId: formState.UrlTypeId,
				IssueId: issue.Id,
			})
		)
			.then(handleCancel)
			.catch(error => {
				setFormErrors(error?.response?.data?.message);
			});
	};

	const handleEditIconClick = (issueUrl: IIssueUrl) => {
		setIssueUrlInEdit(issueUrl);
	};

	const handleUpdate = (formState: Partial<ISocialMediaLink>) => {
		dispatch(
			updateIssueUrl({
				Url: formState.Url,
				Label: formState.Label,
				UrlTypeId: formState.UrlTypeId,
				IssueId: issue.Id,
				Id: formState?.id,
			})
		)
			.then(handleCancel)
			.catch(error => {
				setFormErrors(error?.response?.data?.message);
			});
	};

	const handleCancel = () => {
		setIssueUrlInEdit({});
		setIsNewLinkFormVisible(false);
		setFormErrors([]);
	};

	return (
		<div>
			<VisibilityControl
				condition={!isNewLinkFormVisible && !Object.keys(issueUrlInEdit)?.length}
				alternative={
					<div>
						<Form
							handleSave={Object.keys(issueUrlInEdit)?.length ? handleUpdate : handleCreate}
							initialFormState={
								Object.keys(issueUrlInEdit)?.length
									? {
											Url: issueUrlInEdit.Url,
											Label: issueUrlInEdit.Label,
											UrlTypeId: issueUrlInEdit.UrlTypeId,
											id: issueUrlInEdit?.Id,
									  }
									: undefined
							}
							handleCancel={handleCancel}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
						/>
					</div>
				}
			>
				<div>
					{issue?.IssueUrl?.map(issueUrl => (
						<SocialMediaLink
							key={`issueUrl=${issueUrl?.Id}`}
							issueUrl={issueUrl}
							handleEditIconClick={handleEditIconClick}
						/>
					))}
					<div className={clsx(classes.flexYCenter, classes.addRelationContainer)}>
						<IconButton onClick={() => setIsNewLinkFormVisible(true)}>
							<AddCircleIcon color="primary" />
						</IconButton>
						<Typography variant="h6" className={classes.addRelationText}>
							{t('add')}
						</Typography>
					</div>
				</div>
			</VisibilityControl>
		</div>
	);
};
