import { combineReducers } from 'redux';

import budgetReducer from '@/store/reducers/New/budgetReducer';
import delegationListReducer from '@/store/reducers/New/delegationListReducer';
import workDayEndSummaryReducer from '@/store/reducers/New/workDayEndSummaryReducer';

import activitiesReducer from './New/activitiesReducer';
import calendarWorkLogReducer from './New/calendarReducer';
import commonReducer from './New/coomonReducer';
import customerDetailsReducer from './New/customerDetailsReducer';
import customerListReducer from './New/customerListReducer';
import dashBoardReducer from './New/dashBoardReducer';
import delegationAcceptanceListReducer from './New/delegationAcceptanceListReducer';
import delegationModalReducer from './New/delegationModalReducer';
import dictionariesContentReducer from './New/dictionariesContentReducer';
import dictionariesReducer from './New/dictionariesReducer';
import employeeAgreementDetailsReducer from './New/employeeAgreementDetailsReducer';
import employeeAgreementFormReducer from './New/employeeAgreementFormReducer';
import employeeAgreementsReducer from './New/employeeAgreementsReducer';
import gantReducer from './New/gantReducer';
import holidayEventDetailsReducer from './New/holidayEventDetailsReducer';
import holidayEventListReducer from './New/holidayEventListReducer';
import holidayTypeDetailsReducer from './New/holidayTypeDetailsReducer';
import holidayTypeListReducer from './New/holidayTypeListReducer';
import issueListReducer from './New/issueListReducer';
import issueModalEditorReducer from './New/issueModalEditorReducer';
import kanbanReducer from './New/kanbanReducer';
import keyReducer from './New/keyReducer';
import leaveRequestCalendarReducer from './New/leaveRequestCalendarReducer';
import leaveRequestReducer from './New/leaveRequestReducer';
import leaveTypeReducer from './New/leaveTypeReducer';
import notificationsListReducer from './New/notificationListReducer';
import organizationPanelReducer from './New/organizationPanelReducer';
import permissionPanelReducer from './New/permissionPanelReducer';
import portalIssueReducer from './New/portalIssueReducer';
import portalListReducer from './New/portalListReducer';
import projectCreateReducer from './New/projectCreateReducer';
import projectDetailReducer from './New/projectDetailReducer';
import projectListReducer from './New/projectListReducer';
import reportsReducer from './New/reportsReducer';
import rolePanelReducer from './New/rolePanelReducer';
import roleReducer from './New/roleReducer';
import searchBarReducer from './New/searchBarReducer';
import sessionReducer from './New/sessionReducer';
import settingsReducer from './New/settingsReducer';
import tagAdminPanelReducer from './New/tagAdminPanelReducer';
import tagGroupInfoReducer from './New/tagGroupInfoReducer';
import timeAssignReducer from './New/timeAssignReducer';
import timeReportReducer from './New/timeReportReducer';
import versionReducer from './New/versionReducer';
import workingUsersListReducer from './New/workingUsersReducer';
import { IReducer } from './types';

export const reducers: IReducer = {
	Activities: activitiesReducer,
	Calendar: calendarWorkLogReducer,
	CustomerDetails: customerDetailsReducer,
	CustomerList: customerListReducer,
	Dictionaries: dictionariesReducer,
	DictionariesContent: dictionariesContentReducer,
	Gant: gantReducer,
	HolidayEventDetail: holidayEventDetailsReducer,
	HolidayEventList: holidayEventListReducer,
	HolidayTypeDetail: holidayTypeDetailsReducer,
	HolidayTypeList: holidayTypeListReducer,
	IssueList: issueListReducer,
	IssueModalEditor: issueModalEditorReducer,
	NotificationList: notificationsListReducer,
	OrganizationPanel: organizationPanelReducer,
	PermissionPanel: permissionPanelReducer,
	ProjectCreate: projectCreateReducer,
	ProjectDetail: projectDetailReducer,
	ProjectList: projectListReducer,
	RolePanel: rolePanelReducer,
	SearchBar: searchBarReducer,
	Settings: settingsReducer,
	TagAdminPanel: tagAdminPanelReducer,
	TagGroupInfo: tagGroupInfoReducer,
	TimeAssign: timeAssignReducer,
	TimeReport: timeReportReducer,
	WorkingUsers: workingUsersListReducer,
	WorkDayEndSummary: workDayEndSummaryReducer,
	Session: sessionReducer,
	Common: commonReducer,
	DashBoard: dashBoardReducer,
	Kanban: kanbanReducer,
	Key: keyReducer,
	Portal: portalListReducer,
	EmployeeAgreements: employeeAgreementsReducer,
	EmployeeAgreementDetails: employeeAgreementDetailsReducer,
	EmployeeAgreementForm: employeeAgreementFormReducer,
	PortalIssue: portalIssueReducer,
	Report: reportsReducer,
	Version: versionReducer,
	LeaveType: leaveTypeReducer,
	LeaveRequest: leaveRequestReducer,
	LeaveRequestCalendar: leaveRequestCalendarReducer,
	Budgets: budgetReducer,
	DelegationList: delegationListReducer,
	DelegationModal: delegationModalReducer,
	DelegationAcceptanceList: delegationAcceptanceListReducer,
	Role: roleReducer,
} as IReducer;

const rootReducer = combineReducers({
	...reducers,
});

export default rootReducer;
