import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TagGroupInfo: {
			TagsCard: {
				TagPopup: {
					Title: {
						tagCreate: 'Stwórz tag',
						tagUpdate: 'Aktualizacja tagów',
					},
				},
			},
		},
	},
	en: {
		TagGroupInfo: {
			TagsCard: {
				TagPopup: {
					Title: {
						tagCreate: 'Create tag',
						tagUpdate: 'Update tag',
					},
				},
			},
		},
	},
};
