import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			margin: theme.spacing(1.25, 0),
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(1),
			borderRadius: '10px',
		},
		pinned: {
			paddingLeft: theme.spacing(0.625),
			textWeight: 'bold',
		},
		bottomLine: {},
	}),
	{ name: `IssueModalEditor_CommendCard` }
);
