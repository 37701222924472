import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultValues: {
		stringValue: undefined,
		numberValue: undefined,
		booleanValue: undefined,
	},
	noValue: '',
	invalidDate: 'Invalid date',
	...GlobalResources,
	bottomEnd: 'bottom-end' as const,
};

export const Translation = {
	pl: {
		DictionaryContent: {
			DictionaryContentTile: {
				isActive: 'Czy aktywny',
				today: 'Dzisiaj',
				active: 'Aktywny',
				inactive: 'Nieaktywny',
				dictionaryItemType: {
					1: 'Wartość logiczna',
					2: 'Liczba całkowita',
					3: 'Liczba dziesiętna',
					4: 'Wartość tekstowa',
					5: 'Słownik',
					6: 'Grupa tagów',
					7: 'Data',
					8: 'Data wraz z czasem',
				},
				abandonChanges: 'Porzuć zmiany',
				edit: 'Edytuj',
				delete: 'Usuń',
				save: 'Zapisz',
			},
		},
	},
	en: {
		DictionaryContent: {
			DictionaryContentTile: {
				isActive: 'Is active',
				today: 'Today',
				active: 'Active',
				inactive: 'Inactive',
				dictionaryItemType: {
					1: 'Boolean',
					2: 'Integer',
					3: 'Decimal',
					4: 'Text',
					5: 'Dictionary',
					6: 'TagGroup',
					7: 'Date',
					8: 'DateTime',
				},
				edit: 'Edit',
				delete: 'Delete',
				save: 'Save',
				abandonChanges: 'Abandon the changes',
			},
		},
	},
};
