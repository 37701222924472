import { Translation as TagGroupInfo } from './resources';
import { Translation as DraggableTagsWidget } from './TagsCard/DraggableTagsWidget/resources';
import { Translation as TagsCard } from './TagsCard/resources';
import { Translation as Actions } from './TagsCard/TagPopup/Actions/resources';
import { Translation as Content } from './TagsCard/TagPopup/Content/resources';
import { Translation as Title } from './TagsCard/TagPopup/Title/resources';

export const TagGroupInfoTranslationEN = [
	TagGroupInfo.en,
	TagsCard.en,
	Actions.en,
	Content.en,
	Title.en,
	DraggableTagsWidget.en,
];

export const TagGroupInfoTranslationPL = [
	TagGroupInfo.pl,
	TagsCard.pl,
	Actions.pl,
	Content.pl,
	Title.pl,
	DraggableTagsWidget.pl,
];
