import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryList: {
								CustomDictionaryListMenu: {
									menuEdit: 'Edytuj słownik',
									menuRemove: 'Usuń słownik',
									cannotRemove:
										'Nie można usunąć słownika, ponieważ jest używany w grupie (zakładka "Konfiguracja używanych słowników").',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryList: {
								CustomDictionaryListMenu: {
									menuEdit: 'Edit słownik',
									menuRemove: 'Remove słownik',
									cannotRemove:
										'Cannot remove dictionary, because it is used in group (tab "Used dictionaries configuration").',
								},
							},
						},
					},
				},
			},
		},
	},
};
