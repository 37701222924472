import { enUS, pl } from 'date-fns/locale';

import { theme } from '@skillandchill/tasker-widgets2/dist/utils/theme';

import { store } from '@/App';
import { IReducerState } from '@/store/reducers/types';

export const TextAwaitUntilReFetch = 500;
export const TextAwaitUntilAutoSave = 750;
export const FormAwaitUntilAutoSave = 3000;
export const MaxLengthText = 5000;
export const MaxPrefixLength = 5;
export const MaxLengthComment = 500;
export const NavBarWidthOnlyIcon = theme.spacing(9);
export const NavBarWidth = theme.spacing(20.25);
export const KanbanTileInColumn = 5;
export const IssueTilesInActivity = 10;
export const headerHeight = 64;
export const headerHeightInString = `${headerHeight}px`;
export const kanbanRender = 4;
export const defaultOrganization = 2;
export const rowsPerPage = 6;
export const canvasId = 'invoiceCanvas';
export const SwitchSavingStateDelay = 1000;
export const UserRoleSavingStateDelay = 250;
export const RoleAcceptanceSavingStateDelay = 250;
export const TooltipSecondDelay = 1000;
export const errorColor = 'red';
export const rowsPerTablePage = 10;
export const rowsPerTableLowPage = 6;

export const getLanguage = () => {
	const reducer: IReducerState = store?.getState() as IReducerState;
	const languageCode = reducer?.Session?.language;
	return languageCode == 'pl' ? pl : enUS;
};
