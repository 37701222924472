import { AsyncAction } from '@skillchill/redux-promisify';

import {
	IParameter,
	ITaskerUser,
	ITaskerUserSetting,
	ITaskerUserToTeam,
	ITeam,
	IPage,
	ITeamCreationData,
	ITeamUpdateData,
	IUserTeamsData,
} from '@skillandchill/tasker-types';
import { ITileContext } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tileContext';
import {
	ITileConfiguration,
	IUpdatedTileSettings,
} from '@skillandchill/tasker-types/dist/types/dataBaseTypes/tileRecordConfiguration';
import { ITileContextFilter } from '@skillandchill/tasker-types/dist/types/endPoints/tileContextFilter';

import { IJson } from '@/store/ITypes';
import { Action } from '@/store/types';
import axios from '@/utils/axios';

import { ActionType } from '../types';

const basePath = 'TaskerUserSettings';
const taskerUserTeamPath = 'taskerUserTeam';
const tileContextPath = 'tileContext';

export const TASKERUSERSETTIGNS_FETCH_PREFERENCES: ActionType = 'TASKERUSERSETTIGNS_FETCH_PREFERENCES';
export const TASKERUSERSETTIGNS_UPDATE_PREFERENCES: ActionType = 'TASKERUSERSETTIGNS_UPDATE_PREFERENCES';
export const SET_TASKER_USER_TEAMS: ActionType = 'SET_TASKER_USER_TEAMS';
export const SET_TASKER_USER_TEAMS_DATA: ActionType = 'SET_TASKER_USER_TEAMS_DATA';
export const SET_USERS_FROM_ACTIVE_USER_TEAM: ActionType = 'SET_USERS_FROM_ACTIVE_USER_TEAM';
export const TILE_CONTEXT_FETCH: ActionType = 'TILE_CONTEXT_FETCH';
export const TILE_CONFIGURATE_FETCH: ActionType = 'TILE_CONFIGURATE_FETCH';
export const TILE_CONFIGURATE_UPDATE: ActionType = 'TILE_CONFIGURATE_UPDATE';

function _fetchPreferences(data: ITaskerUserSetting[]): Action {
	return {
		type: TASKERUSERSETTIGNS_FETCH_PREFERENCES,
		data,
	};
}

function _setTaskerUserTeams(data: ITaskerUserToTeam[]): Action {
	return {
		type: SET_TASKER_USER_TEAMS,
		data,
	};
}

function _setTaskerUserTeamsData(data: IUserTeamsData): Action {
	return {
		type: SET_TASKER_USER_TEAMS_DATA,
		teams: data.userTeams,
		usersFromActiveUserTeam: data.usersFromActiveUserTeam,
	};
}

function _setUsersFromActiveUserTeam(data: Partial<ITaskerUser>[]): Action {
	return {
		type: SET_USERS_FROM_ACTIVE_USER_TEAM,
		data,
	};
}

function _updatePreferences(data: Partial<IParameter>[]) {
	return {
		type: TASKERUSERSETTIGNS_UPDATE_PREFERENCES,
		data,
	};
}

function _fetchTileContext(contexts: IPage<ITileContext>): Action {
	return {
		type: TILE_CONTEXT_FETCH,
		contexts: contexts,
	};
}
function _fetchTileConfiguration(data: ITileConfiguration[]): Action {
	return {
		type: TILE_CONFIGURATE_FETCH,
		data,
	};
}
function _updateUserTileSettings(updatedTile: IUpdatedTileSettings): Action {
	return {
		type: TILE_CONFIGURATE_UPDATE,
		updatedTile,
	};
}

export function fetch_Preferences(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchPreferences((json as IJson).data)));
		return axios().get(`${basePath}/preferences`);
	};
}

export function update_Preferences(data: Partial<ITaskerUserSetting>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updatePreferences((json as IJson).data)));
		return axios().post(`${basePath}/preferences`, { data });
	};
}

export function fetch_TaskerUser_Teams(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeams((json as IJson).data)));
		return axios().get(`${taskerUserTeamPath}/userTeams`);
	};
}

export function setUserTeamAsActive(taskerUserToTeam: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeamsData((json as IJson).data)));
		return axios().patch(`${taskerUserTeamPath}/activate/${taskerUserToTeam}`);
	};
}

export function setUserTeamAsInactive(taskerUserToTeam: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeamsData((json as IJson).data)));
		return axios().patch(`${taskerUserTeamPath}/deactivate/${taskerUserToTeam}`);
	};
}

export function deleteTeamAssignment(taskerUserToTeam: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeamsData((json as IJson).data)));
		return axios().delete(`${taskerUserTeamPath}/taskerUserToTeam/${taskerUserToTeam}`);
	};
}

export function createTeam(teamData: ITeamCreationData): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeams((json as IJson).data)));
		return axios().post(`${taskerUserTeamPath}`, teamData);
	};
}

export function updateTeam(teamData: ITeamUpdateData, teamId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setTaskerUserTeamsData((json as IJson).data)));
		return axios().patch(`${taskerUserTeamPath}/team/${teamId}`, teamData);
	};
}

export function getUsersFromActiveTeam(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setUsersFromActiveUserTeam((json as IJson).data)));
		return axios().get(`${taskerUserTeamPath}/activeTeamUsers`);
	};
}

export function getTileContextPage(from: number, count: number, data: ITileContextFilter): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchTileContext((json as IJson)?.data)));
		return axios().post(`${tileContextPath}/from/${from}/count/${count}`, data);
	};
}

export function getTileRecordConfiguration(tileId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchTileConfiguration((json as IJson).data)));
		return axios().get(`${tileContextPath}/tileRecordConfiguration/${tileId}`);
	};
}
export function updateUserTileSettings(tileId: number, isActive: boolean): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateUserTileSettings((json as IJson).data)));
		return axios().patch(`${tileContextPath}/tileRecordConfiguration/update/${tileId}`, { isActive });
	};
}
