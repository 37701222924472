import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	divider: 'divider',
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						Tabs: {
							description: 'Opis delegacji',
							advances: 'Zaliczki',
							settlementAttachments: 'Rozliczenie wydatków',
							attachments: 'Załączniki',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						Tabs: {
							description: 'Delegation description',
							advances: 'Advances',
							settlementAttachments: 'Expense settlement',
							attachments: 'Attachments',
						},
					},
				},
			},
		},
	},
};
