export const Translation = {
	pl: {
		Calendar: {
			Toolbar: {
				ButtonGroupContent: {
					prev: {
						day: 'Poprzedni dzień',
						week: 'Poprzedni tydzień',
						month: 'Poprzedni miesiąc',
					},
					today: 'Dzisiaj',
					next: {
						day: 'Następny dzień',
						week: 'Następny tydzień',
						month: 'Następny miesiąc',
					},
				},
			},
		},
	},
	en: {
		Calendar: {
			Toolbar: {
				ButtonGroupContent: {
					prev: {
						day: 'Previous day',
						week: 'Previous week',
						month: 'Previous month',
					},
					today: 'Today',
					next: {
						day: 'Next day',
						week: 'Next week',
						month: 'Next month',
					},
				},
			},
		},
	},
};
