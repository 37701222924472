import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	portalPath: '/portal/',
	userPath: '/user/',
	textPrimary: 'textPrimary' as const,
	blank: '_blank',
	taskerIcon: process.env.PUBLIC_URL + '/favicon.png',
	textSecondary: 'textSecondary' as const,
	hashIcon: '#',
	column: 'column' as const,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectUsersActiveTasksList: {
				ProjectUserActiveTask: {
					assignedTo: 'Wykonawca',
					creationDate: 'Data zgłoszenia ',
					viewProfile: 'Wyświetl profil',
					details: 'Szczegóły',
					attachmentCount: 'Liczba załączników',
					formName: 'Nazwa formularza',
					workingFor: 'Przepracowano:',
					idAndNameOfTask: 'Id i nazwa zadania:',
					taskCreatedBy: 'Zadanie stworzone przez:',
					taskSubmissionDate: 'Data zgłoszenia zadania:',
					issueMore: 'Więcej o zgłoszeniu',
					worklogStartedAt: 'Rozpoczęto o godzinie:',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectUsersActiveTasksList: {
				ProjectUserActiveTask: {
					assignedTo: 'Contractor',
					creationDate: 'Creation date ',
					viewProfile: 'View profile',
					details: 'Details',
					attachmentCount: 'Number of attachments',
					formName: 'Form name',
					workingFor: 'Working for:',
					idAndNameOfTask: 'Id and name of the task:',
					taskCreatedBy: 'Task created by:',
					taskSubmissionDate: 'Date of task submission:',
					issueMore: 'More about the issue',
					worklogStartedAt: 'Started at:',
				},
			},
		},
	},
};
