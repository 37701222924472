import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	infoVariant: GlobalResources.GlobalResources.h5,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Info: {
								optional: 'Krok opcjonalny',
								form: 'Skonfiguruj pytania na które osoba będzie musiała odpowiedzieć.',
								notification: `Skonfiguruj zapytanie, które powiadomi serwer zewnętrzny.`,
								users: 'Wybierz użytkowników, którzy będą musieli wypełniać formularz.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Info: {
								optional: 'Optional step',
								form: 'Configure question which users will have to answer.',
								notification: 'Configure request to outsource server.',
								users: 'Choose users who will have fill the form.',
							},
						},
					},
				},
			},
		},
	},
};
