import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					PopupActions: {
						saveButton: 'Zapisz',
						saveButtonTooltip: 'Zapisz wszystkie zmiany.',
						cancelButton: 'Anuluj',
						cancelButtonTooltip:
							'Spowoduje to wyjście z popupu nie dodając żadnego użytkownika wszystkie zmiany zostaną utracone.',
						back: 'Wróć',
						backTooltip: 'Wróć do poprzedniego kroku zachowując wszystkie dotychczas wprowadzone zmiany.',
						next: 'Dalej',
						nextTooltip: 'Przejdź do następnego kroku',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					PopupActions: {
						saveButton: 'Save',
						saveButtonTooltip: 'Save all changes',
						cancelButton: 'Cancel',
						cancelButtonTooltip: 'All changes will be forgotten.',
						back: 'Back',
						backTooltip: 'Go back to previous step but keep data.',
						next: 'Next',
						nextTooltip: 'Go to next step.',
					},
				},
			},
		},
	},
};
