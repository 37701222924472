import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	number: 'number' as const,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Series: {
								name: 'Nazwa',
								value: 'Przedimek',
								valueSeperator: 'Znak rozdzielający',
								startNumber: 'Liczba porządkowa dla pierwszej faktury',
								save: 'Zapisz',
								cancel: 'Anuluj',
								validationError:
									'Brak wypełnionych wszystkich wymaganych pól, proszę uzupełnić je przed zapisaniem serii',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							Series: {
								name: 'Name',
								value: 'Value',
								valueSeperator: 'Separator',
								startNumber: 'Start number for first invoice',
								save: 'Save',
								cancel: 'Cancel',
								validationError:
									'Not all required fields are filled, please fill them before saving the series',
							},
						},
					},
				},
			},
		},
	},
};
