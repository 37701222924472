import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	max: 'max',
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								DropzoneInfo: {
									callToAction1: 'Kliknij, aby przesłać',
									callToAction2: 'lub przeciągnij i upuść pliki',
									allowedExtensions: 'Zezwolone rozszerzenia:',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								DropzoneInfo: {
									callToAction1: 'Click to upload',
									callToAction2: 'or drag and drop files',
									allowedExtensions: 'Allowed extensions:',
								},
							},
						},
					},
				},
			},
		},
	},
};
