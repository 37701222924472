import clsx from 'clsx';
import React, { useState } from 'react';

import { MainIssueData } from './MainIssueData';
import { RightSideIssueData } from './RightSideIssueData';
import { useStyles } from './styles';
import { IssueDetailsTabs } from './Tabs';
import { EIssueModalDetailsPageTabs } from './Tabs/model';
import { TabsContent } from './TabsContent';

export const IssueDetialsPage = () => {
	const classes = useStyles();

	const [selectedTab, setSelectedTab] = useState<EIssueModalDetailsPageTabs>(EIssueModalDetailsPageTabs.Description);

	return (
		<div className={clsx(classes.flexRow, classes.flexGrow, classes.container)}>
			<div className={clsx(classes.leftSideContainer, classes.fullHeight, classes.flexColumn)}>
				<MainIssueData />
				<div className={clsx(classes.flexGrow, classes.flexColumn)}>
					<IssueDetailsTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
					<TabsContent selectedTab={selectedTab} />
				</div>
			</div>
			<RightSideIssueData />
		</div>
	);
};
