import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			mainContainer: {
				marginRight: theme.spacing(5),
			},

			secondRow: {
				display: 'grid',
				gridTemplateColumns: '3fr 3fr 6fr',
				gridGap: theme.spacing(2),
				marginTop: theme.spacing(3),
				alignItems: 'start',
				minHeight: '200px',
			},

			profileSelector: {
				paddingLeft: '10px',

				'& img, & svg': {
					width: '26px',
					height: '26px',
					borderRadius: '50%',
				},

				'& .MuiAvatar-root': {
					width: '30px',
					height: '30px',
				},
			},

			avatarSelect: {
				'& .select__multi-value': {
					margin: '0px 4px',
					borderRadius: '5px',
				},

				'& .select__value-container ': {
					padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
					display: 'flex',
				},

				'& .select__option': {
					cursor: 'pointer',

					'&--is-selected': {
						backgroundColor: 'white',
						color: 'black',

						'&:hover': {
							backgroundColor: '#9beab5',
						},
					},
				},

				'& .select__control': {
					minHeight: '34px',
					backgroundColor: 'white',
					border: '2px solid #e3e3e3',
					borderRadius: '5px',
					boxShadow: 'none',

					'&--is-focused': {
						border: '2px solid #33b35c',
					},

					'& .select__single-value ': {
						fontSize: '15px',
					},

					'& .select__indicator': {
						padding: '4px',
					},
				},

				'& .basic-multi-select': {
					margin: '0',
				},
			},

			tooltipTextBaseText: {
				fontSize: '14px',
			},

			select: {
				'& .select__value-container ': {
					padding: `0 ${theme.spacing(1)}`,
				},

				'& .select__control': {
					minHeight: '34px',
					// backgroundColor: 'white',
					border: '2px solid #e3e3e3',
					borderRadius: '5px',
					boxShadow: 'none',

					'&--is-focused': {
						border: '2px solid #33b35c',
					},

					'& .select__single-value ': {
						fontSize: '15px',
					},

					'& .select__indicator': {
						padding: '4px',
					},
				},

				'& .basic-multi-select': {
					margin: '0',
				},
			},

			selectError: {
				'& .select__control': {
					border: '2px solid red',

					'&--is-focused': {
						border: '2px solid red',
					},
				},
			},

			tag: {
				borderRadius: '20px',
				padding: '3px 10px',
				fontSize: '12px',
				marginLeft: theme.spacing(0.25),
				width: 'max-content',
			},

			tagText: {
				filter: 'invert(100%)',
			},
		};
	},
	{ name: `IssueModalNew_IssueModalContent_IssueDetailsPage_BasicIssueData_LeftSide` }
);
