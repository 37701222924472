import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { VisibilityControl, RichEditor2 } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { MaxLengthText } from '@/utils/configuration';
import { issueModalDataContext } from '@/views/IssueModelNew';

import { EIssueModalDetailsPageFields } from '../../model';
import { Resources } from '../../resources';

import { useStyles } from './styles';

export const IssueDescription = () => {
	const classes = useStyles();

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const darkMode = useSelector((state: IReducerState) => state.Session.darkMode);

	const { handleInputChange, issueState } = useContext(issueModalDataContext);

	const handleChangeDescription = (descriptionHtml: string, description: string) => {
		handleInputChange(EIssueModalDetailsPageFields.description, description);
		handleInputChange(EIssueModalDetailsPageFields.descriptionHtml, descriptionHtml);
	};

	return (
		<div className={classes.container}>
			<div className={clsx(classes.richEditor, classes.fullHeight)}>
				<VisibilityControl condition={!!issue?.Id}>
					<RichEditor2
						key={`${Resources.issue}${issueState?.Id}`}
						isDarkMode={darkMode}
						defaultValue={
							issueState?.DescriptionHtml ?? issue?.Description ?? Resources.GlobalResources.empty
						}
						onChange={handleChangeDescription}
						maxLength={MaxLengthText}
					/>
				</VisibilityControl>
				<VisibilityControl condition={!issue?.Id}>
					<RichEditor2
						key={`${Resources.issue}${issueState?.Id}`}
						isDarkMode={darkMode}
						defaultValue={issueState?.DescriptionHtml ?? Resources.GlobalResources.empty}
						onChange={handleChangeDescription}
						maxLength={MaxLengthText}
					/>
				</VisibilityControl>
			</div>
		</div>
	);
};
