import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	colorDisk: {
		green: 'green',
		red: 'red',
	},
};

export const Translation = {
	pl: {
		RolePanel: {
			Errors: {
				Fetch: 'Błąd podczas pobierania danych',
				RoleAssign: 'Błąd podczas przypisywania roli',
			},
			PageName: 'Panel zarzadania rolami uzytkownikow',
			Header: {
				title: 'Przypisywanie roli',
			},
			Popup: {
				Title: 'Istnieja niezapisane zmiany',
				Subtitle: 'Czy jesteś pewien, że chcesz zmienic edytowanego uzytkownika?',
			},
		},
	},
	en: {
		RolePanel: {
			Errors: {
				Fetch: 'Error while fetching data',
				RoleAssign: 'Error while assigning roles',
			},
			PageName: 'Role management panel',
			Header: {
				title: 'Role assignment',
			},
			Popup: {
				Title: 'There are some unsaved changes',
				Subtitle: 'Are you sure you want to switch edited user?',
			},
		},
	},
};

export enum Action {
	SAVE = 'SAVE',
	CHANGE = 'CHANGE',
}
