import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	path: '/auth/login',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Error404: {
			pageTitle: 'Błąd 404',
			notFound: '404: Podana ścieżka jest nieprawidłowa',
			info: 'Podana ścieżka jest nieprawidłowa.',
			goHome: 'Wróć do strony głównej',
		},
	},
	en: {
		Error404: {
			pageTitle: 'Error 404',
			notFound: '404: The page you are looking for isn’t here',
			info:
				'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
			goHome: 'Back to home',
		},
	},
};
