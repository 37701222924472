import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryTopButtons: {
						tooltipText: 'Przywróć początkowych stan wynagrodzeń',
						addFiltersText: 'Dodaj filtry do wynagrodzeń',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryTopButtons: {
						tooltipText: 'Restore the initial state of salaries',
						addFiltersText: 'Add filters to salaries',
					},
				},
			},
		},
	},
};
