import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		tab: {
			fontSize: '13px',
			padding: '12px 4px',
			textTransform: 'none',
			minHeight: '0px',
		},

		tabSelected: {
			color: theme.palette.darkGray.light,
		},

		tabNotSelected: {
			color: theme.palette.lightGray.main,
		},

		tabList: {
			minHeight: '0px',

			'& .MuiTabs-indicator': {
				backgroundColor: theme.palette.darkGray.light,
				height: '3px',
			},
		},

		notReadCount: {
			marginLeft: theme.spacing(0.5),
			color: theme.palette.background.paper,
			padding: '0px 4px',
			borderRadius: '5px',
		},

		notReadCountTabSelected: {
			backgroundColor: theme.palette.darkGray.light,
		},

		notReadCountTabNotSelected: {
			backgroundColor: 'rgba(156,152,149,0.3)',
			color: theme.palette.lightGray.main,
		},
	}),
	{ name: `Tabs` }
);
