import { TFunction, i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ETaskerTranslationLanguage } from '@skillandchill/tasker-types/dist/types/custom/language';

import { availableLanguages } from '@/i18n';
import { IReducerState } from '@/store/reducers/types';

export function useTrans(
	keyPrefix?: string
): { t: TFunction; tString: (key: string) => string; i18n: i18n; setLng: (lng: string) => void } {
	const isCustom: boolean = useSelector((state: IReducerState) => state?.Session?.hasOrgCustomTranslation);
	const organization = useSelector((state: IReducerState) => state?.Session?.organization);
	const customFileName = useSelector((state: IReducerState) => state?.Session?.customTransaltionKey) ?? '';
	const { t, i18n } = useTranslation('FRONTEND', {
		keyPrefix: keyPrefix ? `${keyPrefix}` : '',
	});

	const setLng = (lng: string): void => {
		if (availableLanguages) {
			if (
				isCustom &&
				organization?.length > 0 &&
				ETaskerTranslationLanguage[lng as keyof typeof ETaskerTranslationLanguage] &&
				customFileName?.length > 0 &&
				availableLanguages?.indexOf(`${lng}_${customFileName}`) > -1
			) {
				i18n.changeLanguage(`${lng}_${customFileName}`);
			} else if (ETaskerTranslationLanguage[lng as keyof typeof ETaskerTranslationLanguage]) {
				i18n.changeLanguage(`${lng}_default`);
			} else {
				i18n.changeLanguage('en_default');
			}
		}
	};

	const tString = (key: string): string => {
		return `${t(key)}`;
	};

	return { t, tString, i18n, setLng };
}
