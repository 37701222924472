import { FullFilters, ICustomer } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { applyUpdateInList } from '@/utils/reducer';

import { getInitialFilterNew } from 'view/SearchBar/resources';

const State = {
	page: {
		Data: [] as ICustomer[],
		Count: 0,
	},
	filtersCustomerNew: getInitialFilterNew() as FullFilters,
};
export type ICustomerListState = typeof State;

const customerListReducer = (state = State, action: ObjectAction): ICustomerListState => {
	switch (action.type) {
		case actionTypes.CUSTOMER_CREATE:
		case actionTypes.CUSTOMER_FETCH_PAGE: {
			return {
				...state,
				page: {
					Count: action.customersCount,
					Data: action.customers,
				},
			};
		}
		case actionTypes.CUSTOMER_UPDATE: {
			return {
				...state,
				page: {
					...state.page,
					Data: applyUpdateInList<ICustomer>(state.page?.Data, action?.customer),
				},
			};
		}
		case actionTypes.CUSTOMER_DELETE: {
			delete state.page?.Data[<number>action?.customerId];
			if (state?.page?.Count) state.page.Count = state?.page?.Count - 1;
			return state;
		}
		case actionTypes.PROJECT_SET_FILTERS_NEW: {
			return {
				...state,
				filtersCustomerNew: action?.filter?.filterNew,
			};
		}
		default: {
			return state;
		}
	}
};

export default customerListReducer;
