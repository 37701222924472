import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	listWeek: 'listWeek',
	dayGridMonth: 'dayGridMonth',
	prev: 'prev',
	next: 'next',
	today: 'today',
	date: 'date',
	digit2: '2-digit' as const,
	dayGrid: 'dayGrid' as const,
	basic: 'basic' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		Gant: {
			calendar: 'Kalendarz',
			timeIsToShort: 'Czas zakończenia zdarzenia musi zachodzić po rozpoczęciu.',
			idNotExists:
				'Brak możliwości edycji tego rekordu z powodu braku wpisu w bazie danych lub błędu połączenia.',
		},
	},
	en: {
		Gant: {
			calendar: 'Calendar',
			timeIsToShort: 'End date have to be after start date.',
			idNotExists: 'No possibility edit record because no data in database or connection error.',
		},
	},
};

export enum CalendarView {
	listWeek = 'listWeek',
	timeGridDay = 'timeGridDay',
	timeGridWeek = 'timeGridWeek',
	dayGridMonth = 'dayGridMonth',
	dayGridCustom = 'dayGridCustom',
}

export const calendarDefaultFormatObject = {
	hour: Resources.digit2,
	minute: Resources.digit2,
	hour12: false,
};

export enum DateRange {
	start = 'start',
	end = 'end',
}

export const DayToMonthThreshold = 31;
