export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				pageTitle: 'Członkowie projektu',
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				pageTitle: 'Project users',
			},
		},
	},
};
