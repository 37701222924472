import { NotificationSkeletonCard } from './NotificationSkeletonCard';

export const NotificationSkeleton = () => {
	return (
		<>
			<NotificationSkeletonCard />
			<NotificationSkeletonCard />
			<NotificationSkeletonCard />
			<NotificationSkeletonCard />
			<NotificationSkeletonCard />
			<NotificationSkeletonCard />
		</>
	);
};
