import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	redirect: '/projects/create/',
	customers: '#/customers/',
	issuePath: '?issueId=',
	projectsPath: '/projects/',
};

export const Translation = {
	pl: {
		ProjectList: {
			projectName: 'Nazwa projektu',
			creationDate: 'Data stworzenia',
			startDate: 'Data rozpoczęcia',
			endDate: 'Data zakończenia',
			managers: 'Menadżerowie',
			owner: 'Właściciel',
			customer: 'Klient',
			createdBy: 'Utworzony przez',
			pageTitle: 'Zarządzanie projektami',
			addProjectToCustomer: 'Dodaj projekty do klienta',
			add: 'Dodaj projekt',
			addShort: 'Dodaj',
			clientName: 'Klient',
			openProject: 'Otwórz projekt',
			projectDeadline: 'Deadline',
			assignedTo: 'Przypisano do',
			Header: {
				type: 'Projekty',
			},
			members: 'Członkowie',
		},
	},
	en: {
		ProjectList: {
			projectName: 'Project name',
			creationDate: 'Creation date',
			startDate: 'Start date',
			endDate: 'End date',
			managers: 'Managers',
			owner: 'Owner',
			customer: 'Customer',
			createdBy: 'Created by',
			pageTitle: 'Project Management List',
			addProjectToCustomer: 'Add project to the customer',
			add: 'Add project',
			addShort: 'Add',
			openProject: 'Open project',
			clientName: 'Client',
			projectStarted: 'Project started',
			details: 'Details',
			projectDeadline: 'Deadline',
			Header: {
				type: 'Projects',
			},
			members: 'Members',
		},
	},
};
