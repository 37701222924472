import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		container: {
			width: '100%',
			maxWidth: '500px',
			margin: 'auto',
			display: 'grid',
			marginTop: theme.spacing(3),
			gridGap: theme.spacing(3),
			gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
			// gridTemplateAreas: `
			// 'title title'
			// 'name name'
			// 'start end'
			// 'desc desc'
			// `,
		},

		dateGrid: {},

		issueNameArea: {
			// gridArea: 'name',
			gridColumn: '1 / 3',
		},

		startDateArea: {
			// gridArea: 'start',
		},

		endDateArea: {
			// gridArea: 'end',
		},

		titleArea: {
			// gridArea: 'title',
			gridColumn: '1 / 3',
		},

		descArea: {
			// gridArea: 'desc',
			gridColumn: '1 / 3',
		},

		errorButton: {
			backgroundColor: theme.palette.red.light,
			color: theme.palette.white.light,

			'&:hover': {
				backgroundColor: theme.palette.red.light,
			},
		},

		root: {
			marginTop: theme.spacing(2),
		},

		checkbox: {
			// padding: theme.spacing(1),
			marginTop: theme.spacing(1),
		},

		buttonsContainer: {
			display: 'grid',
			gridTemplateColumns: '100px 100px 100px',
			gridGap: theme.spacing(1),
		},
	}),
	{ name: 'IssueModalNew_IssueModalContent_Worklogs_Form' }
);
