import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	textFieldStyles: {
		'.MuiInputBase-input': {
			fontSize: '0.5rem',
			paddingTop: '0.3rem',
			paddingBottom: '0.3rem',
		},
	},
};

export const Translation = {
	pl: {
		Budgets: {
			search: 'Wyszukaj budżet',
			hideAll: 'Zwiń wszystkie',
			pageTitle: 'Budżety',
			dataError: 'Błąd wysyłania zapytania',
			Confirmation: {
				outcome: `Czy chcesz usunąć wydatek o nazwie: {{name}}?`,
				outcomeList: `Czy chcesz usunąć wszystkie wydatki budżetu: {{name}}?`,
				income: `Czy chcesz usunąć przychód o nazwie: {{name}}?`,
				incomeList: `Czy chcesz usunąć wszystkie przychody budżetu: {{name}}?`,
				budget: `Czy chcesz usunąć budżet o nazwie: {{name}} wraz z całą zawartością?`,
				linePeriodical: `Czy chcesz usunąć zlecanie stałe {{nameLP}} budżetu:{{nameB}}?`,
				linePeriodicalList: `Czy chcesz usunąć zlecania stałe budget {{name}}?`,
			},
		},
	},
	en: {
		Budgets: {
			search: 'Search budget',
			hideAll: 'Hide all',
			pageTitle: 'Budgets',
			dataError: 'Error sending request',
		},
	},
};
