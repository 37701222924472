import { AsyncAction } from '@skillchill/redux-promisify';

import { ICreateIssueForm, IFormFilter, IIssueForm, IIssueFormUpdate, IPage } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IIssueFormInEdit } from 'view/ProjectDetails/Form/IssueForm/Edit/model';

const basePath = 'IssueForm';

export const GET_ISSUE_FORM_LIST: ActionType = 'GET_ISSUE_FORM_LIST';
export const CREATE_ISSUE_FORM: ActionType = 'CREATE_ISSUE_FORM';
export const SET_ISSUE_FORM_CREATE: ActionType = 'SET_ISSUE_FORM_CREATE';
export const SET_ISSUE_FORM_UPDATE: ActionType = 'SET_ISSUE_FORM_UPDATE';
export const GET_ISSUE_FORM: ActionType = 'GET_ISSUE_FORM';
export const GET_KANBAN_FORMS: ActionType = 'GET_KANBAN_FORMS';

export function setIssueForm(data: IIssueForm): Action {
	return {
		type: GET_ISSUE_FORM,
		data,
	};
}

function _getIssueFormList(data: IPage<IIssueForm[]>): Action {
	return {
		type: GET_ISSUE_FORM_LIST,
		data,
	};
}

function _getKanbanForms(data: IIssueForm[]): Action {
	return {
		type: GET_KANBAN_FORMS,
		data,
	};
}

export function setCreateIssueForm(data: ICreateIssueForm): Action {
	return { type: SET_ISSUE_FORM_CREATE, data };
}

export function setUpdateIssueForm(data: IIssueFormInEdit): Action {
	return { type: SET_ISSUE_FORM_UPDATE, data };
}

function _createIssueForm(): Action {
	return { type: CREATE_ISSUE_FORM };
}

export function get_issueFormListByProject(
	from: number,
	count: number,
	filter: IFormFilter,
	projectId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getIssueFormList((json as IJson)?.data)));
		return axios().post(`${basePath}/project/${projectId}/from/${from}/count/${count}`, filter);
	};
}

export function get_issueFormListByUser(from: number, count: number, filter: IFormFilter): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getIssueFormList((json as IJson)?.data)));
		return axios().post(`${basePath}/from/${from}/count/${count}`, filter);
	};
}

export function create_IssueForm(data: ICreateIssueForm): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_createIssueForm()));
		return axios().post(basePath, data);
	};
}

export function get_KanbanForms(projectIds: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getKanbanForms((json as IJson)?.data)));
		return axios().post(`${basePath}/formsByProjects`, projectIds);
	};
}

export function update_IssueForm(data: IIssueFormUpdate, issueFormId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_createIssueForm()));
		return axios().patch(`${basePath}/${issueFormId}`, data);
	};
}

export function get_IssueForm(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueForm((json as IJson)?.data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function copy_IssueForm(formId: number, projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueForm((json as IJson)?.data)));
		return axios().post(`${basePath}/copy/${formId}/project/${projectId}`);
	};
}

export function share_IssueForm(formId: number, projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(setIssueForm((json as IJson)?.data)));
		return axios().post(`${basePath}/share/${formId}/project/${projectId}`);
	};
}
