import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	lowDash: '_',
	outcomeKey: 'O_',
	incomeKey: 'I_',
	periodicalKey: 'P_B_',
};

export const Translation = {
	pl: {
		Budgets: {
			TreeSingleBudgetLine: {
				outcome: 'Wydatki',
				income: 'Przychody',
				periodical: 'Stałe linie budżetowe',
			},
		},
	},
	en: {
		Budgets: {
			TreeSingleBudgetLine: {
				outcome: 'Outcome',
				income: 'Income',
				periodical: 'Periodical lines',
			},
		},
	},
};
