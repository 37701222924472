import { useLocation } from 'react-router';

export function useQuery(): { [x: string]: string } {
	const queryData: { [x: string]: string } = {};
	const p = new URLSearchParams(useLocation().search).entries();
	for (const [key, value] of p) {
		queryData[key] = value;
	}
	return queryData;
}
