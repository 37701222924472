import {
	IFieldDefinition,
	IFieldValue,
	IIssueKanbanDetails,
	IProjectIssueType,
	ITag,
} from '@skillandchill/tasker-types';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

export class FieldValue implements Partial<IFieldValue> {
	// ValueTag: ITag;
	ValueTagId: number;
	AttributeDefinitionId: number;
	AttributeDefinition?: IFieldDefinition = {} as IFieldDefinition;

	constructor(tag: ITag, issueFormSectionToIssueField: IIssueFormSectionToIssueField) {
		// this.ValueTag = tag;
		this.ValueTagId = tag.Id;
		this.AttributeDefinitionId = issueFormSectionToIssueField.AttributeDefinitionId as number;
		this.AttributeDefinition = {
			...(this.AttributeDefinition as IFieldDefinition),
			IsStatus: issueFormSectionToIssueField?.AttributeDefinition?.IsStatus ?? false,
		};
	}
}

export function setColumnTag(
	kanbanDetails: IIssueKanbanDetails,
	projectIssueType: IProjectIssueType,
	isEdittingIssue: boolean
) {
	let tag = undefined;
	for (const projectIssueTypeToIssueForm of projectIssueType?.ProjectIssueTypeToIssueForm ?? []) {
		const issueFormSections = projectIssueTypeToIssueForm?.IssueForm?.IssueFormSection ?? [];
		for (const issueFormSection of issueFormSections) {
			const issueFormSectionToIssueFields = issueFormSection?.IssueFormSectionToIssueField ?? [];
			for (const issueFormSectionToIssueField of issueFormSectionToIssueFields) {
				if (kanbanDetails?.isKanban && !isEdittingIssue) {
					const newTag: ITag | undefined =
						kanbanDetails?.mappedTags?.find(find => {
							return (
								find.FieldDefinitionId == issueFormSectionToIssueField.AttributeDefinitionId &&
								find.TagId == kanbanDetails.columnTagId
							);
						})?.FieldDefinitionTag ?? undefined;

					if (newTag) tag = newTag;
				}
			}
		}
	}
	return tag;
}
