import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			position: 'inherit',
			display: 'flex',
			flexWrap: 'wrap',
			overflow: props => (props.isMouseOver ? 'inherit' : 'hidden'),
			paddingLeft: props => getSize(props.size),
			maxHeight: '36.5px',
		},

		more: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium as 'normal',
		},

		inheritPosition: {
			position: 'inherit',
		},
		tooltip: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(97, 97, 97, 0.92)',
			padding: `${theme.spacing(1)}`,
		},
		tooltipFlex: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(97, 97, 97, 0.92)',
			padding: `${theme.spacing(1)}`,
			'& > :first-child': {
				marginRight: theme.spacing(1),
			},
		},
	}),
	{ name: 'SearchBar_ProfileSelector' }
);

const getSize = (size: 'small' | 'medium' | 'large' | undefined) => {
	switch (size) {
		case 'small': {
			return 10;
		}
		case 'medium': {
			return 20;
		}
		case 'large': {
			return 30;
		}
		default: {
			return 20;
		}
	}
};
