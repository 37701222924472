import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	salary: 'salary',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					agreementDetails: 'Szczegóły umowy',
					personContracted: 'Zakontraktowany(-a)',
					resetAgreementDetails: 'Przywróć początkowych stan szczegółów umowy',
					IsForIndefinitePeriod: 'Na czas nieokreślony',
					salary: 'Wynagrodzenie',
					salary100PercentLabelHourlySalaryType:
						'Poniższa wartość nie może być bezpośrednio modyfikowana. Została wyliczona na podstawie wielkości wynagrodzenia za godzinę oraz okresu wynagrodzenia. ( Przyjęto 100% etatu czyli 160h w miesiącu. )',
					salary100PercentLabelFixedSalaryType: 'Wynagrodzenie',
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					agreementDetails: 'Agreement details',
					personContracted: 'Person contracted',
					resetAgreementDetails: 'Restore the contract details to their original state',
					IsForIndefinitePeriod: 'For indefinite period',
					salary: 'Salary',
					salary100PercentLabelHourlySalaryType:
						'The following value cannot be directly modified. It was calculated based on the amount of remuneration per hour and the remuneration period. (100% full-time employment was assumed, so 160 hours per month.)',
					salary100PercentLabelFixedSalaryType: 'Salary',
				},
			},
		},
	},
};
