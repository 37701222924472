import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		url: {
			display: 'flex',
			width: '26px',
			height: '26px',
		},

		label: {
			marginLeft: theme.spacing(1),
			maxWidth: '150px',
		},

		icon: {
			fontSize: theme.spacing(2.5),
		},

		container: {
			border: `1px solid ${theme.palette.grey[300]}`,
			backgroundColor: theme.palette.background.default,
			padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
			borderRadius: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
	{ name: `IssueModalEditor_ModalTitle_CreateUrl` }
);
