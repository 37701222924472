export const w1Width = '40%';
export const w2Width = '17%';
export const w3Width = '10%';
export const w4Width = '15%';
export const w5Width = '5%';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fullWidth: 'fullWidth' as const,
	history: 'history' as const,
	minutes: 'm',
	noMinutes: '0m',
	options: {
		variant: 'h6' as const,
	},
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				Worklogs: {
					onlyMine: 'Pokaż tylko mój czas pracy',
					date: 'Data',
					from: 'Czas rozpoczęcia',
					to: 'Czas zakończenia',
					user: 'Użytkownik',
					issue: 'Zgłoszenie',
					description: 'Opis',
					logged: 'Zalogowano',
					addNewWorklog: 'Dodaj nowy czas pracy',
					delete: 'Usuń',
					edit: 'Modyfikuj',
					workLogDelete: 'Usunięto wpis czasu pracy',
					errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
					worklogStillActive: 'Aktywne',
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				Worklogs: {
					onlyMine: 'Show only my time work',
					date: 'Date',
					from: 'From',
					to: 'To',
					user: 'User',
					issue: 'Issue',
					description: 'Description',
					logged: 'Logged',
					addNewWorklog: 'Add new worklog',
					delete: 'Delete',
					edit: 'Edit',
					workLogDelete: 'Worklog deleted',
					errorWorkLogDelete: 'Error while deleting worklog',
					worklogStillActive: 'Active',
				},
			},
		},
	},
};
