import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	issuePath: (id: number) => `?issueId=${id}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			ModalActions: {
				ActionButtons: {
					addButton: 'Dodaj',
					updateButton: 'Aktualizuj',
					deleteButton: 'Usuń',
					cancel: 'Zamknij',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			ModalActions: {
				ActionButtons: {
					addButton: 'Add',
					updateButton: 'Update',
					deleteButton: 'Delete',
					cancel: 'Close',
				},
			},
		},
	},
};
