export const Translation = {
	pl: {
		LeaveType: {
			Details: {
				name: 'Nazwa',
				description: 'Opis',
				save: 'Zapisz',
				cancel: 'Anuluj',
			},
		},
	},
	en: {
		LeaveType: {
			Details: {
				name: 'Name',
				description: 'Description',
				save: 'Save',
				cancel: 'Cancel',
			},
		},
	},
};
