import { ICustomer } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	path: { customers: '/customers' },
	constrains: { Name: { presence: { allowEmpty: false } } },
	...GlobalResources,
};

export const initialCustomerState: Partial<ICustomer> = {
	Name: '',
	IsPublic: true,
};

export const Translation = {
	pl: {
		CustomerCreate: {
			Header: {
				management: 'Nowy klient',
				type: 'Rejestrowanie klienta',
			},
			Index: {
				create: 'Utwórz',
				pageTitle: 'Tworzenie klienta',
			},
			SaveCustomerFailed: 'Coś poszło nie tak. Spróbuj ponownie później',
			SaveCustomerSuccess: 'Pomyślnie utworzono klienta',
		},
	},
	en: {
		CustomerCreate: {
			Header: {
				management: 'New customer',
				type: 'Registering customer',
			},
			Index: {
				create: 'Create',
				pageTitle: 'Customer Create',
			},
			SaveCustomerFailed: 'Something went wrong. Try again later',
			SaveCustomerSuccess: 'Customer successfully created ',
		},
	},
};
