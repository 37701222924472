// @flow

import { List } from '@mui/material';

import { useStyles } from '../styles';

import { NavBarSectionProps, NavBarTabConfig } from './model';
import { NavItemWrapper } from './NavItem';

export const NavBarSection = (props: NavBarSectionProps): JSX.Element => {
	const { name, items } = props.section;
	const classes = useStyles({ maxWidth: 'none' });
	return (
		<List key={name}>
			{items.map((item: NavBarTabConfig) => (
				<NavItemWrapper item={item} key={`${name}${item.title}`} />
			))}
			<div className={classes.navbarLine} />
		</List>
	);
};
