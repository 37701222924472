import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					SingleAgreement: {
						ContractTimeCell: {
							forIndefinitePeriod: 'Na czas nieokreślony',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					SingleAgreement: {
						ContractTimeCell: {
							forIndefinitePeriod: 'For indefinite period',
						},
					},
				},
			},
		},
	},
};
