import uuid from 'uuid';

import { EDelegationDictionaryItemType, EDictionaryItemType } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	getTypeKey: (item: EDictionaryItemType) => `Type_${item}_${uuid()}`,
	getItemTypeKey: (item: EDelegationDictionaryItemType) => `ItemType_${item}_${uuid()}`,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemForm: {
								typeLabel: 'Typ wartości',
								type1: 'Wartość logiczna',
								type2: 'Liczba całkowita',
								type3: 'Liczba dziesiętna',
								type4: 'Wartość tekstowa',
								type7: 'Data',
								type8: 'Data i czas',
								itemTypeLabel: 'Rodzaj wartości',
								itemType1: 'Załącznik zakwaterowania',
								itemType2: 'Inny',
								itemType3: 'Samochód prywatny do 900 cm3',
								itemType4: 'Samochód prywatny powyżej 900 cm3',
								itemType5: 'Samochód wypożyczony do 900 cm3',
								itemType6: 'Samochód wypożyczony powyżej 900 cm3',
								itemType8: 'Samolot',
								itemType7: 'Inny',
								cancel: 'Anuluj',
								save: 'Zapisz',
								fieldsRequired: 'Pola wymagane',
								createSuccess: 'Wartość w słowniku została utworzona pomyślnie',
								createError: 'Wystąpił błąd podczas tworzenia wartości w słowniku.',
								updateSuccess: 'Wartość w słowniku została zaktualizowana pomyślnie',
								updateError: 'Wystąpił błąd podczas aktualizowania wartości w słowniku.',
								missing:
									'Nie podano wszystkich wymaganych pól w formularzu. Proszę uzupełnić wszystkie wymagane pola.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Delegation: {
					TabsContent: {
						CustomDictionariesConfiguration: {
							CustomDictionaryItemForm: {
								typeLabel: 'Value type',
								type1: 'Logical value',
								type2: 'Integer',
								type3: 'Decimal',
								type4: 'Text value',
								type7: 'Date',
								type8: 'Date and time',
								itemTypeLabel: 'Value kind',
								itemType1: 'Accommodation attachment',
								itemType2: 'Other',
								itemType3: 'Private car up to 900 cm3',
								itemType4: 'Private car above 900 cm3',
								itemType5: 'Rented car up to 900 cm3',
								itemType6: 'Rented car above 900 cm3',
								itemType8: 'Aircraft',
								itemType7: 'Other',
								cancel: 'Cancel',
								save: 'Save',
								fieldsRequired: 'Fields required',
								createSuccess: 'The value in the dictionary has been created successfully',
								createError: 'An error occurred while creating a value in the dictionary.',
								updateSuccess: 'The value in the dictionary has been updated successfully',
								updateError: 'An error occurred while updating a value in the dictionary.',
								missing:
									'Not all required fields have been provided in the form. Please fill in all required fields.',
							},
						},
					},
				},
			},
		},
	},
};
