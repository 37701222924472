import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						StatusFilter: {
							label: 'Status umowy - czas do wygaśnięcia umowy',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Filters: {
						StatusFilter: {
							label: 'Agreement status',
						},
					},
				},
			},
		},
	},
};
