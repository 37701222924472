import { Translation as FullDayLeave } from './Content/FullDayLeave/resources';
import { Translation as PartDayLeave } from './Content/PartDayLeave/resources';
import { Translation as PopupContent } from './Content/resources';
import { Translation as Calendar } from './resources';
import { Translation as ButtonGroupContent } from './Toolbar/ButtonGroupContent/resources';
import { Translation as DisplayDate } from './Toolbar/Header/DisplayDate/resources';
import { Translation as Header } from './Toolbar/Header/resources';
import { Translation as Toolbar } from './Toolbar/resources';

export const LeaveRequestCalendarEN = [
	Calendar.en,
	Toolbar.en,
	Header.en,
	DisplayDate.en,
	ButtonGroupContent.en,
	PopupContent.en,
	FullDayLeave.en,
	PartDayLeave.en,
];

export const LeaveRequestCalendarPL = [
	Calendar.pl,
	Toolbar.pl,
	Header.pl,
	DisplayDate.pl,
	ButtonGroupContent.pl,
	PopupContent.pl,
	FullDayLeave.pl,
	PartDayLeave.pl,
];
