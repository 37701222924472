import { t } from 'i18next';

import { EReckonTabs } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	agreementsTab: 'agreementsTab',
	agreementsPath: '/agreements/agreements',
};

export const Translation = {
	pl: {
		Reckon: {
			agreementsTab: 'Umowy',
			title: 'Rentowność',
		},
	},
	en: {
		Reckon: {
			agreementsTab: 'Agreements',
			title: 'Reckon',
		},
	},
};

export const getTabs = (): { visible: boolean; href: string; label: string; value: string }[] => {
	return [
		{
			value: EReckonTabs.agreements,
			label: t('Reckon.agreementsTab'),
			visible: true,
			href: '/agreements',
		},
	];
};
