import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';
import { IMapTagToFieldDefinition } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/mapTagToTag';

import { fetch_formByUser, resetEndOfWork } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';

import { IssueFormSectionWithCollapse } from './IssueFormSectionWithCollapse';
import { Props } from './model';
import { Resources } from './resources';

export const Form = (props: Props): JSX.Element => {
	const { issueFieldDetails } = props;
	const dispatch = useDispatch();

	const formState: IForm = useSelector((state: IReducerState) => state?.DashBoard?.EndOfWork.form);
	const mappings: IMapTagToFieldDefinition[] = useSelector(
		(state: IReducerState) => state?.Kanban?.kanbanFormMappings
	);

	useEffect(() => {
		dispatch(fetch_formByUser());

		return () => {
			dispatch(resetEndOfWork());
		};
	}, []);

	return (
		<div>
			{formState?.IssueFormSection?.map((x, index) => {
				return (
					<IssueFormSectionWithCollapse
						issueFieldDetails={{ ...issueFieldDetails, mappedTags: mappings }}
						issueFormSection={x}
						key={Resources.key(x?.Id ?? -1, x?.IssueFormId ?? -2)}
						subKey={Resources.key(x?.Id ?? -1, x?.IssueFormId ?? -2)}
						zIndex={(formState?.IssueFormSection?.length ?? 0 - index) + 2}
					/>
				);
			})}
		</div>
	);
};
