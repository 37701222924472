export default {
	styleOverrides: {
		cursor: 'default',
		gutterBottom: {
			marginBottom: 8,
		},
		root: {
			justifyContent: 'flex-end',
			display: 'flex',
		},
	},
};
