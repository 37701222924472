import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			container: {
				position: 'relative',
				minWidth: `calc(${theme.spacing(21)} + 15rem)`,
			},
			inputContainer: {
				minWidth: `15rem`,
			},
			selectError: {
				'& .select__control': {
					border: '2px solid red',

					'&--is-focused': {
						border: '2px solid red',
					},
				},
			},

			valueContainer: {
				marginLeft: theme.spacing(0.5),
				padding: '3px 10px',
			},
		};
	},
	{
		name: `IssueModalNew.IssueModalContent.IssueDetailsPage.MainIssueData.DependentInputs.IssueFormSection.DecimalIssueProperty`,
	}
);
