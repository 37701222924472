import { FullFilters, IIssue, IPage, IProject, ITaskerUser } from '@skillandchill/tasker-types';
import {
	IPortalFilterFieldDefinition,
	IPortalFilterForms,
	IPortalFilterTags,
} from '@skillandchill/tasker-types/dist/types/endPoints/portalFilter';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

import { getInitialFilterNew } from 'view/SearchBar/resources';

export const State = {
	issuePage: {} as IPage<IIssue[]>,
	filters: {
		projects: [] as IProject[],
		carouselUsers: [] as Partial<ITaskerUser>[],
		filter: getInitialFilterNew() as FullFilters,
		Status: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		Tags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
		ExcludedTags: {
			personalizedForms: [] as IPortalFilterForms[],
			personalizedFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			personalizedTags: [] as IPortalFilterTags[],
			personalizedProjects: [] as IProject[],
			choosenForms: [] as IPortalFilterForms[],
			choosenFieldDefinitions: [] as IPortalFilterFieldDefinition[],
			choosenProjects: [] as IProject[],
		},
	},
};

export type IPortalListState = typeof State;

const portalListReducer = (state = State, action: ObjectAction): IPortalListState => {
	switch (action?.type) {
		case actionTypes.ISSUE_FETCH_PORTAL: {
			return {
				...state,
				issuePage: action.data,
			};
		}
		case actionTypes.FILTER_PROJECT_DELETE: {
			const projects = state?.filters?.projects?.filter(x => x?.Id != action.data);
			const tagsChoosenProjects = state?.filters?.Tags?.choosenProjects?.filter(x => x?.Id != action.data);
			const statusChoosenProjects = state?.filters?.Status?.choosenProjects?.filter(x => x?.Id != action.data);
			return {
				...state,
				filters: {
					...state?.filters,
					projects: projects,
					Tags: {
						...state?.filters?.Tags,
						choosenProjects: tagsChoosenProjects,
					},
					Status: {
						...state?.filters?.Status,
						choosenProjects: statusChoosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_STATUS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.FILTER_SET_LISTS: {
			return {
				...state,
				filters: {
					...state?.filters,
					filter: action?.filter,
				},
			};
		}
		case actionTypes.PORTAL_SET_STATUS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Status: {
						...state?.filters?.Status,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		case actionTypes.PORTAL_FILTER_TAGS_OPTIONS:
		case actionTypes.FILTER_TAGS_OPTIONS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
					ExcludedTags: {
						...state?.filters?.Tags,
						personalizedForms: action?.data?.IssueForms,
						personalizedFieldDefinitions: action?.data?.FieldDefinitions,
						personalizedTags: action?.data?.Tags,
						personalizedProjects: action?.data?.Projects,
					},
				},
			};
		}
		case actionTypes.PORTAL_SET_TAGS: {
			return {
				...state,
				filters: {
					...state?.filters,
					Tags: {
						...state?.filters?.Tags,
						choosenForms: action?.data?.choosenForms,
						choosenFieldDefinitions: action?.data?.choosenFieldDefinitions,
						choosenProjects: action?.data?.choosenProjects,
					},
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default portalListReducer;
