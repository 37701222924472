import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Avatar,
	Button,
	Card,
	CardActions,
	Checkbox,
	FormControlLabel,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import { IComment, ITaskerUser, Permission } from '@skillandchill/tasker-types';
import { SnackbarErrorList } from '@skillandchill/tasker-widgets2/dist/widgets';
import { RichEditor2 } from '@skillandchill/tasker-widgets2/dist/widgets/RichEditor2';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import {
	comment_delete,
	comment_edit,
	comment_post,
	fetch_IssueModalComments,
	setComment as setCommentRedux,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { MaxLengthText } from '@/utils/configuration';
import { useTrans } from '@/utils/hooks/useTrans';
import { CheckRoles } from '@/utils/permissions';
import { errorVariant, successVariant } from '@/utils/snackBar';
import { getFullNameNew } from '@/utils/user';

import { CommentCard } from '../CommendCard';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Comments = (props: Props): JSX.Element => {
	const { issueId, issueIsPortal } = props;
	const classes = useStyles();
	const snackbar = useSnackbar();
	const dispatch: Dispatch = useDispatch();
	const { t, tString } = useTrans('IssueModelNew.Comments');

	const loggedUser: Partial<ITaskerUser> | undefined = useSelector((state: IReducerState) => state.Session?.profile);
	const isPortalUser = useSelector((state: IReducerState) => state?.IssueModalEditor?.isPortalUser);
	const currentUserPermissions = useSelector((state: IReducerState) => state?.Session?.permissions);
	const comments: IComment[] = useSelector((state: IReducerState) => state?.IssueModalEditor?.comments) ?? [];
	const darkMode = useSelector((state: IReducerState) => state.Session.darkMode);
	const commentReducer: string =
		useSelector((state: IReducerState) => state?.IssueModalEditor?.comment) ?? Resources.GlobalResources.empty;
	const commentHtmlReducer: string =
		useSelector((state: IReducerState) => state?.IssueModalEditor?.commentHTML) ?? Resources.GlobalResources.empty;

	const [commentHtml, setCommentHtml] = useState<string>(commentHtmlReducer);
	const [commentText, setCommentText] = useState<string>(commentReducer);
	const [commentId, setCommentId] = useState<number>(0);
	const [isInEdit, setIsInEdit] = useState<boolean>(false);
	const [fetchDeleted, setFetchDeleted] = useState<boolean>(false);
	const [isInProgress, setIsInProgress] = useState<boolean>(false);

	const [isAdmin] = useState<boolean>(CheckRoles(currentUserPermissions, Permission.SuperAdmin));

	const [advancedComment, setAdvancedComment] = useState<boolean>(false);

	useEffect(() => {
		dispatch(fetch_IssueModalComments(issueId, fetchDeleted && isAdmin));
	}, [fetchDeleted, isAdmin]);

	useEffect(() => {
		setComment(commentHtml, commentText);
	}, [advancedComment]);

	const setDone = () => {
		setIsInProgress(false);
	};

	const handlePostComment = (issueId: number, commentHtml: string, isPortal = false): void => {
		setIsInProgress(true);
		//ToDo No nested requests, use regular ifs, question marks, strings
		//ToDo explain Zapytania nie mogą być piętrowo jak coś chcesz wsiąść coś z serwera możesz zwrócić w zapytaniu tworzącym w przypadku tak długich then i catch używamy zwykłych ifów i należy przestrzegać podstawowych założeń
		//ToDo for KW from AD
		isInEdit
			? dispatch(comment_edit(commentId, commentText, commentHtml, isPortal))
					.then(() => {
						setComment('', '');
						setIsInEdit(false);

						setAdvancedComment(false);
						snackbar.enqueueSnackbar(t('commentEditSuccess'), successVariant);
						dispatch(fetch_IssueModalComments(issueId, fetchDeleted && isAdmin));
						dispatch(setCommentRedux('', ''));
					})
					.catch(error =>
						snackbar.enqueueSnackbar(
							<SnackbarErrorList errors={error.response.data.message} />,
							errorVariant
						)
					)
					.finally(setDone)
			: dispatch(comment_post(issueId, commentText, commentHtml, isPortal))
					.then(() => {
						setComment('', '');
						setAdvancedComment(false);
						snackbar.enqueueSnackbar(t('commentAddSuccess'), successVariant);
						dispatch(setCommentRedux('', ''));
					})
					.catch(error =>
						snackbar.enqueueSnackbar(
							<SnackbarErrorList errors={error.response.data.message} />,
							errorVariant
						)
					)
					.finally(setDone);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === Resources.GlobalResources.enterEvent && !isInProgress) {
			event.preventDefault();
			handlePostComment(issueId, commentHtml);
		}
	};

	const setComment = (htmlString: string, string: string, length = 0) => {
		if (length >= MaxLengthText) return snackbar.enqueueSnackbar(t('textIsToLong'), errorVariant);
		dispatch(setCommentRedux(string, htmlString));
		setCommentHtml(htmlString);
		setCommentText(string);
	};

	const handleDeleteComment = (commentId: number) => {
		if (!commentId) return;

		dispatch(comment_delete(commentId))
			.then(() => {
				snackbar.enqueueSnackbar(t('commentDeleteSuccess'), successVariant);
			})
			.catch(() => {
				snackbar.enqueueSnackbar(t('commentDeleteError'), errorVariant);
			});
	};

	const onEditComment = (comment: Partial<IComment>) => {
		if (comment?.CommentHtml && comment?.Comment && comment?.Id) {
			comment?.Comment != comment?.CommentHtml ? setAdvancedComment(true) : setAdvancedComment(false);
			setIsInEdit(true);
			setCommentHtml(comment?.CommentHtml);
			setCommentText(comment?.Comment);
			setCommentId(comment?.Id);
		}
	};

	return (
		<div className={clsx(classes.fullWidth, classes.flexColumn, classes.root)}>
			<VisibilityControl
				condition={!!comments?.length}
				alternative={<Typography className={classes.noComments}>{tString('noComments')}</Typography>}
			>
				<div className={classes.commentsContainer}>
					{comments?.map((comment, i) => (
						<CommentCard
							key={i}
							onDelete={handleDeleteComment}
							comment={comment}
							onEditComment={onEditComment}
						/>
					))}
				</div>
			</VisibilityControl>
			<div className={classes.flexRow}>
				<VisibilityControl condition={isAdmin}>
					<div className={classes.checkBox}>
						<FormControlLabel
							control={
								<Checkbox
									color={Resources.GlobalResources.primary}
									checked={fetchDeleted}
									inputProps={{ [`${Resources.ariaLabel}`]: Resources.primaryCheckBox }}
									onChange={event => setFetchDeleted(event.target.checked)}
								/>
							}
							label={
								<Typography variant={Resources.GlobalResources.h6}>
									{t('displayAllComments')}
								</Typography>
							}
						/>
					</div>
				</VisibilityControl>
				<div className={classes.checkBox}>
					<FormControlLabel
						control={
							<Checkbox
								color={Resources.GlobalResources.primary}
								checked={advancedComment}
								inputProps={{ [`${Resources.ariaLabel}`]: Resources.primaryCheckBox }}
								onChange={event => setAdvancedComment(event.target.checked)}
							/>
						}
						label={<Typography variant={Resources.GlobalResources.h6}>{t('advancedComment')}</Typography>}
					/>
				</div>
			</div>
			<Card>
				<VisibilityControl condition={isInEdit}>
					<Typography className={classes.editTitle} variant={Resources.GlobalResources.h4}>
						{t('editTitle')}
					</Typography>
				</VisibilityControl>
				<CardActions>
					<VisibilityControl condition={!advancedComment}>
						<Tooltip title={getFullNameNew(loggedUser)}>
							<Avatar src={loggedUser?.AvatarPath ?? ''} alt={getFullNameNew(loggedUser)} />
						</Tooltip>
					</VisibilityControl>
					<VisibilityControl
						condition={advancedComment}
						alternative={
							<TextField
								inputProps={{ maxLength: MaxLengthText }}
								className={classes.addCommentField}
								value={commentText}
								onChange={e => setComment(e.target.value, e.target.value, e.target.value.length)}
								onKeyPress={handleKeyPress}
							/>
						}
					>
						<div className={clsx(classes.fullWidth, classes.richEditor)}>
							<RichEditor2
								isDarkMode={darkMode}
								defaultValue={commentHtml}
								onChange={setComment}
								maxLength={MaxLengthText}
							/>
						</div>
					</VisibilityControl>
					<div className={advancedComment ? classes.flexColumn : classes.flexRow}>
						<VisibilityControl
							condition={isInEdit}
							alternative={
								<>
									<VisibilityControl condition={!isPortalUser}>
										<Button
											disabled={isInProgress}
											className={classes.button}
											variant={Resources.GlobalResources.contained}
											onClick={() => handlePostComment(issueId, commentHtml)}
										>
											{t('post')}
										</Button>
									</VisibilityControl>
									<Button
										disabled={isInProgress}
										className={classes.button}
										variant={Resources.GlobalResources.contained}
										onClick={() => handlePostComment(issueId, commentHtml, true)}
									>
										{isPortalUser ? t('post') : t('portalPost')}
									</Button>
								</>
							}
						>
							<Button
								className={classes.button}
								variant={Resources.GlobalResources.contained}
								onClick={() => handlePostComment(issueId, commentHtml)}
							>
								{t('edit')}
							</Button>
							<Button
								className={classes.button}
								variant={Resources.GlobalResources.contained}
								onClick={() => {
									setIsInEdit(false);
									setAdvancedComment(false);
									setCommentHtml(Resources.GlobalResources.empty);
									setCommentText(Resources.GlobalResources.empty);
								}}
							>
								{t('cancel')}
							</Button>
						</VisibilityControl>
					</div>
				</CardActions>
			</Card>
		</div>
	);
};

export default Comments;
