import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationList: {
			RadioGroupRow: {
				all: 'Wszystkie',
				read: 'Odczytane',
				notRead: 'Nieprzeczytane',
			},
		},
	},
	en: {
		NotificationList: {
			RadioGroupRow: {
				all: 'All',
				read: 'Archived',
				notRead: 'New',
			},
		},
	},
};
