import { FullFilters, IPage, IProject } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';
import { applyUpdateInList } from '@/utils/reducer';

import { IFilters } from 'view/SearchBar/model';
import { getInitialFilter, getInitialFilterNew } from 'view/SearchBar/resources';

export const State = {
	projectPage: {} as IPage<IProject[]>,
	filtersProject: getInitialFilter() as IFilters,
	filtersProjectNew: getInitialFilterNew() as FullFilters,
};
export type IProjectListEditorState = typeof State;

const projectListReducer = (state = State, action: ObjectAction): IProjectListEditorState => {
	switch (action?.type) {
		case actionTypes.PROJECT_FETCH_TYPE_PAGE_NEW: {
			return {
				...state,
				projectPage: action?.projects,
			};
		}
		case actionTypes.PROJECT_SET_FILTERS: {
			return {
				...state,
				filtersProject: action.filter,
			};
		}
		case actionTypes.PROJECT_SET_FILTERS_NEW: {
			return {
				...state,
				filtersProject: action?.filter?.filter,
				filtersProjectNew: action?.filter?.filterNew,
			};
		}

		case actionTypes.PROJECT_FETCH_ONE: {
			const newObj = {
				Data: state?.projectPage?.Data?.filter(project => project.Id !== action?.project?.Id),
				Count: state?.projectPage?.Count,
			};

			return {
				...state,
				projectPage: newObj,
			};
		}

		default: {
			return state;
		}
	}
};

export default projectListReducer;
