import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	rounded: 'rounded' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							NewCommentEditor: {
								send: 'Wyślij komentarz',
								sendInternal: 'Wyślij komentarz wewnętrzny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAcceptance: {
						DelegationAcceptanceComments: {
							NewCommentEditor: {
								send: 'Send comment',
								sendInternal: 'Send internal comment',
							},
						},
					},
				},
			},
		},
	},
};
