import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						IssueAttch: {
							add: 'Dodaj',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					TabsContent: {
						IssueAttch: {
							add: 'Add',
						},
					},
				},
			},
		},
	},
};
