import Mark from 'mark.js';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueTitle: (displayId?: string, title?: string) => `[${displayId ?? ''}] ${title}`,

	zero: '0' as const,
	portalPath: '/portal',
	markOptions: {
		separateWordSearch: true,
		caseSensitive: false,
		accuracy: 'exactly',
	} as Mark.MarkOptions,
};

export const Translation = {
	pl: {
		PortalIssue: {
			completed: 'Zapisano',
			form: 'Formularz',
			name: 'Nazwa',
			created: 'Stworzony ',
			createdBy: ' przez ',
			error: 'Wystąpił błąd',
			cancel: 'Wróć do listy zgłoszeń',
			ErrorMessage: {
				noIssue: 'ERROR',
				create: 'Tworzenie zgłoszenia zakończone niepowodzeniem z powodu błędu: ',
				update: 'Aktualizacja zgłoszenia zakończone niepowodzeniem z powodu błędu: ',
			},
			formListHeader: 'Wybierz formularz, za pomocą którego dokonasz zgłoszenia',
			highlightsWords: 'Podświetl znalezione słowa',
			noTitle: 'Brak tytułu zgłoszenia.',
			noDescription: 'Brak opisu zgłoszenia.',
			noForm: 'Istnieją braki w wypełnionym formularzu(Zaznaczone pola są wymagane).',
			creationDate: 'Data stworzenia',
		},
	},
	en: {
		PortalIssue: {
			completed: 'Saved',
			form: 'Form',
			name: 'Name',
			created: 'Created ',
			createdBy: ' by ',
			error: 'Serwer Error',
			cancel: 'Back to issue list',
			ErrorMessage: {
				noIssue: 'ERROR',
				create: 'Created failed cause  error: ',
				update: 'Update failed cause  error: ',
			},
			formListHeader: 'Select form with which you will create your issue',
			highlightsWords: 'Highlight search words',
			noTitle: 'There is lack of tittle.',
			noDescription: 'There is lack of description.',
			noForm: 'There are fields which inside form are required but not completed.',
			creationDate: 'Creation date',
		},
	},
};

export const headerHeight = '24px';
export enum EPortalSearch {
	Title = `TitlePortalSearch_1`,
	Comments = `CommentsPortalSearch_2`,
}
