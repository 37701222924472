import { useContext } from 'react';

import { IErrorValidationV2 } from '@skillandchill/tasker-types';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { issueModalDataContext } from '@/views/IssueModelNew';

import { EIssueModalDetailsPageFields } from '../../../../model';

export const useHandleResetError = (issueFormSectionToIssueField: IIssueFormSectionToIssueField) => {
	const { setIssueErrors } = useContext(issueModalDataContext);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;

	const handleResetInputError = () => {
		setIssueErrors(prevState => {
			const propertiesError = prevState?.find(
				error => error?.inputName === EIssueModalDetailsPageFields?.properties
			);

			return [
				...prevState?.filter(error => error?.inputName !== EIssueModalDetailsPageFields?.properties),
				// (propertiesError?.additionalData as number[])?.filter(
				// 	attributeDefinitionErrorId => attributeDefinitionErrorId !== AttributeDefinitionId
				// ),
				{
					...propertiesError,
					additionalData: (propertiesError?.additionalData as number[])?.filter(
						attributeDefinitionErrorId => attributeDefinitionErrorId !== AttributeDefinitionId
					),
				},
			] as IErrorValidationV2<EIssueModalDetailsPageFields>[];
		});
	};

	return { handleResetInputError };
};
