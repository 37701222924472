import { IInvoicePayment, IInvoiceSeries } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { getInvoicePaymentPLLabel } from 'view/CustomerDetail/Content/Invoices/utils';

export const Resources = {
	...GlobalResources,
	max: 1000000000000000000,
	invoicePaymentKey: (x: IInvoicePayment) => `InvoicePayment_${x?.Label}_${x?.Id}`,
	invoiceSeriesKey: (x: IInvoiceSeries) => `InvoiceSeries${x?.Name}_${x?.Id}`,
	number: 'number' as const,
	InvoicePayment: (id: number): string => getInvoicePaymentPLLabel(id),
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							General: {
								invoiceName: 'Nazwa faktury ',
								description: 'Opis ',
								invoicePayment: 'Rodzaj płatności',
								dateOfCompletion: 'Data wypełnienia',
								dateOfSell: 'Data sprzedaży',
								dueDate: 'Termin płatności',
								invoiceSeries: 'Seria faktur',
								payed: 'Zapłacono',
								addSeries: 'Dodaj nową serię',
								editSeries: 'Edytuj serię',
								deleteSeries: 'Usuń serię',
							},
						},
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Invoices: {
					HeaderInvoice: {
						InvoiceModal: {
							General: {
								invoiceName: 'Invoice name ',
								description: 'Description ',
								invoicePayment: 'Invoice payment',
								dateOfCompletion: 'Date of completion',
								dateOfSell: 'Date of sell',
								dueDate: 'Due date',
								invoiceSeries: 'Invoice series',
								payed: 'Payed',
								addSeries: 'Add new series',
								editSeries: 'Edit series',
								deleteSeries: 'Delete series',
							},
						},
					},
				},
			},
		},
	},
};
