import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				General: {
					Members: {
						assignedTo: 'Osoby przypisane do zadania',
						observer: 'Obserwatorzy',
						creator: 'Twórca',
						reporter: 'Raportujący',
						labelsModal: {
							assignee: 'Osoba wykonująca',
							reporter: 'Zgłaszający',
							observer: 'Obserwatorzy',
							creator: 'Twórca',
							auditor: 'Weryfikator',
						},
						assignMyself: 'Przypisz mnie',
						auditor: 'Weryfikator',
					},
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			Tabs: {
				General: {
					Members: {
						assignedTo: 'Assigned to',
						observer: 'Observer',
						creator: 'Creator',
						reporter: 'Reporter',
						labelsModal: {
							assignee: 'Assignee',
							reporter: 'Reporter',
							observer: 'Observers',
							creator: 'Creator',
							auditor: 'Auditor',
						},
						assignMyself: 'Assign myself',
						auditor: 'Auditor',
					},
				},
			},
		},
	},
};
