import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	...GlobalResources,
	questionMark: '?',
	percent: '%',
	dot: '.',
	fullWidth: '100%',
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/streamable/${id}`,
	urlPreview: (id: number, token: string) =>
		`${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/public_streamable/${id}/${token}`,
	loadingBarProgressTransition: 'width 0.5s ease-in-out',
	imageExtensions: ['png', 'jpg', 'jpeg'],
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								DropzoneAttachmentRecord: {
									removeFileMsg: 'Czy na pewno chcesz usunąć plik',
									fileFormPending: 'Oczekiwanie na wypełnienie formularza',
									fileSaved: 'Zapisano plik',
									fileUploaded: 'Przesłano plik',
									fileDownloaded: 'Pobrano plik',
									save: 'Zapisz',
									formValidateError:
										'Zatwierdzenie formularza nie powiodło się. Upewnij się, że wszystkie wymagane pola zostały uzupełnione.',
									menuDownload: 'Pobierz załącznik',
									menuEdit: 'Edytuj formularz załącznika',
									menuRemove: 'Usuń załącznik',
									preview: 'Podgląd załącznika',
								},
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationAttachments: {
							Dropzone: {
								DropzoneAttachmentRecord: {
									removeFileMsg: 'Are you sure you want to remove the file',
									fileFormPending: 'Waiting for the form to be filled',
									fileSaved: 'File saved',
									fileUploaded: 'File uploaded',
									fileDownloaded: 'File downloaded',
									save: 'Save',
									formValidateError:
										'Form validation failed. Make sure all required fields have been filled in.',
									menuDownload: 'Download attachment',
									menuEdit: 'Edit attachment form',
									menuRemove: 'Remove attachment',
									preview: 'Attachment preview',
								},
							},
						},
					},
				},
			},
		},
	},
};
