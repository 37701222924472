import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			textAlign: 'center',
			padding: theme.spacing(3),
			minWidth: theme.spacing(62.5),
		},
		image: {
			height: theme.spacing(43.75),
			width: theme.spacing(56.25),
			backgroundImage: 'url("/images/undraw_empty_xct9.svg")',
			backgroundPositionX: 'right',
			backgroundPositionY: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
		},
	}),
	{ name: `Dashboard_AlertPopover_PlaceHolder` }
);
