import uuid from 'uuid';

import { ECurrency, EDirection, IDelegationDictionaryItem } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	getCurrencyKey: (item: ECurrency) => `Currency_${item}_${uuid()}`,
	getAttachmentTypeKey: (item: IDelegationDictionaryItem) => `AttachmentType_${item?.Id}_${uuid()}`,
	getAttachmentDirectionKey: (item: EDirection) => `AttachmentDirection_${item}_${uuid()}`,
	fixed: 'fixed' as const,
	number: 'number' as const,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							DelegationSettlementAttachmentsForm: {
								descriptionLabel: 'Opis załącznika',
								descriptionTooltip: 'Opis powinien dokładne informacje o treści załącznika',
								dateLabel: 'Data wystawienia faktury',
								typeLabel: 'Typ załącznika',
								attachmentType1: 'Zakwaterowanie',
								attachmentType2: 'Inny',
								valueLabel: 'Kwota butto',
								taxLabel: '% VAT',
								netValueLabel: 'Kwota netto',
								currencyLabel: 'Waluta',
								attachmentDirection: 'Status wydatku',
								direction1: 'Podlega zwrotowi',
								direction2: 'Nie podlega zwrotowi',
								direction3: 'Inny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationDetails: {
						DelegationSettlementAttachments: {
							DelegationSettlementAttachmentsForm: {
								descriptionLabel: 'Attachment description',
								descriptionTooltip:
									'The description should contain detailed information about the attachment content',
								dateLabel: 'Invoice date',
								typeLabel: 'Attachment type',
								attachmentType1: 'Accommodation',
								attachmentType2: 'Other',
								valueLabel: 'Gross amount',
								taxLabel: '% VAT',
								netValueLabel: 'Net amount',
								currencyLabel: 'Currency',
								attachmentDirection: 'Expense status',
								direction1: 'Reimbursable',
								direction2: 'Non-reimbursable',
								direction3: 'Other',
							},
						},
					},
				},
			},
		},
	},
};
