import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalActions: {
				issueCreated: 'Zgłoszenie utworzone przez {{userName}} dnia {{createdAt}}',
				accruedTime: 'Naliczony czas w minutach: {{time}}',
				save: 'Zapisz',
				close: 'Zamknij',
				delete: 'Usuń',
				saveAndClose: 'Zapisz i zamknij',
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalActions: {
				issueCreated: 'Issue created by {{userName}} at {{createdAt}}',
				accruedTime: 'Total time in minutes: {{time}}',
				save: 'Save',
				close: 'Close',
				delete: 'Delete',
				saveAndClose: 'Save and close',
			},
		},
	},
};
