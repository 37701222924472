import { Time } from './model';

export const calculateMinutesToTime = (minutes = 0): Time => {
	if (minutes === undefined) return { hour: 0, minutes: 0 };
	const hour = Math.floor(minutes / 60);
	const minutesLeft = minutes - hour * 60;
	return { hour: hour, minutes: minutesLeft };
};

export const getTimeInDefaultFormat = (minutes = 0): string => {
	if (minutes <= 0) return '';
	const time = calculateMinutesToTime(minutes);
	const hour: string = time.hour != 0 ? `${time.hour}h` : '';
	const minutesLeft: string = time.minutes > 0 ? `${time.minutes}m` : time.hour > 0 ? '' : `${time.minutes}m`;
	return `${hour} ${minutesLeft}`;
};
