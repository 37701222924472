import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
};

export const Translation = {
	pl: {
		Kanban: {
			Filters: {
				Options: { QuickFilters: { quickFilter: 'Szybkie filtry', filterSet: 'Filtry zostały ustawione' } },
			},
		},
	},
	en: {
		Kanban: {
			Filters: { Options: { QuickFilters: { quickFilter: 'Quick filter', filterSet: 'Filter has been set' } } },
		},
	},
};
