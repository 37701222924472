export const Translation = {
	pl: {
		Dictionaries: {
			Modal: {
				Content: {
					IsActive: {
						status: 'Status',
						isActive: 'Czy jest aktywny?',
						active: 'Aktywne',
						inActive: 'Nie aktywne',
					},
				},
			},
		},
	},
	en: {
		Dictionaries: {
			Modal: {
				Content: {
					IsActive: {
						status: 'Status',
						isActive: 'Is active?',
						active: 'Active',
						inActive: 'Inactive',
					},
				},
			},
		},
	},
};
