import { GoogleLoginResponse } from 'react-google-login';
import { AnyAction } from 'redux';

import { AsyncAction } from '@skillchill/redux-promisify/dist';

import { IAuthData, ITaskerUserSetting } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const SESSION_START_LOGIN: ActionType = 'SESSION_START_LOGIN_RECEIVE';
export const SESSION_LOGOUT: ActionType = 'SESSION_LOGOUT';
export const SESSION_RESET: ActionType = 'SESSION_RESET';
export const SESSION_FINISH_LOGIN: ActionType = 'SESSION_FINISH_LOGIN_RECEIVE';
export const SESSION_REMEMBER_ME_SET: ActionType = 'SESSION_REMEMBER_ME_SET';
export const SESSION_UPDATE_AUTH_INFO: ActionType = 'SESSION_UPDATE_AUTH_INFO';
export const SESSION_CHECK_PASSWORD_RECOVERY: ActionType = 'SESSION_CHECK_PASSWORD_RECOVERY';
export const SESSION_RESET_PASSWORD_RECEIVE: ActionType = 'SESSION_RESET_PASSWORD_RECEIVE';
export const SESSION_SET_LOCAL_AVATAR: ActionType = 'SESSION_SET_LOCAL_AVATAR';
export const SESSION_SET_IS_LOADING: ActionType = 'SESSION_SET_IS_LOADING';
export const SESSION_SET_IS_LOADING_V2: ActionType = 'SESSION_SET_IS_LOADING_V2';

export const SESSION_SET_IS_SMALL_DEVICE: ActionType = 'SESSION_SET_IS_SMALL_DEVICE';
export const SESSION_SET_LANGUAGE: ActionType = 'SESSION_SET_LANGUAGE';
export const SESSION_RECALCULATE: ActionType = 'SESSION_RECALCULATE';
export const SESSION_SSO_SAVE: ActionType = 'SESSION_SSO_SAVE';
export const SESSION_IS_BUILDING: ActionType = 'SESSION_IS_BUILDING';
export const SESSION_IS_NOT_BUILDING: ActionType = 'SESSION_IS_NOT_BUILDING';

const basePath = 'auth';
const basePathLanguage = '/TaskerUserSettings/language';

function _startLogin(profiles: { name: string; tenantName: string; access_token: string }[]): Action {
	return {
		type: SESSION_START_LOGIN,
		profiles,
	};
}

function _finishLogin(args: IAuthData): Action {
	return {
		...args,
		type: SESSION_FINISH_LOGIN,
	};
}

function _setLocalAvatar(avatar: string): Action {
	return {
		type: SESSION_SET_LOCAL_AVATAR,
		avatar,
	};
}

function _checkRecover(token: string): Action {
	return {
		type: SESSION_CHECK_PASSWORD_RECOVERY,
		recoverToken: token,
	};
}

function _resetPassword(): Action {
	return {
		type: SESSION_RESET_PASSWORD_RECEIVE,
	};
}

export function session_logout(): Action {
	return {
		type: SESSION_LOGOUT,
	};
}

export function session_logIn_SSO(data: GoogleLoginResponse): Action {
	return {
		type: SESSION_SSO_SAVE,
		data,
	};
}

export function session_reset(): Action {
	return {
		type: SESSION_RESET,
	};
}
export function sessionRecalculate(): Action {
	return {
		type: SESSION_RECALCULATE,
	};
}
export function session_setIsSmallDevice(isMobile: boolean): Action {
	return {
		type: SESSION_SET_IS_SMALL_DEVICE,
		isMobile,
	};
}

export function session_setLanguage(language: string, data?: ITaskerUserSetting): Action {
	return {
		type: SESSION_SET_LANGUAGE,
		language,
		languageId: data?.Id,
	};
}

// export function session_logout(): AsyncAction {
// 	return function(context, dispatch, getState) {
// 		context.then(() => dispatch(_logout()));
// 		return axios()
// 			.post(`auth/logout`, { access_token: (getState() as SessionState).session.access_token })
// 			.catch(e => {
// 				switch (e.response.status) {
// 					case 404: {
// 						window.location.href = '#/errors/error-404';
// 						break;
// 					}
// 					case 500: {
// 						window.location.href = '#/errors/error-500';
// 						break;
// 					}
// 					case 401: {
// 						window.location.href = '#/errors/error-401';
// 						break;
// 					}
// 					default: {
// 						window.location.href = '#/errors/error-500';
// 						break;
// 					}
// 				}
// 			});
// 	};
// }

export function session_setAvatar(avatar: string): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_setLocalAvatar(avatar)));
		return Promise.resolve();
	};
}

export function session_setRememberMe(value: boolean): Action {
	return {
		type: SESSION_REMEMBER_ME_SET,
		value,
	};
}

export function session_updateAuthInfo(args: IAuthData): Action {
	return {
		...args,
		type: SESSION_UPDATE_AUTH_INFO,
	};
}

export function sesion_setIsLoading(isAdd: boolean, value: string): AnyAction {
	return {
		isAdd,
		value,
		type: SESSION_SET_IS_LOADING,
	};
}

export function sesion_setIsLoading_v2(isLoading: boolean): AnyAction {
	return {
		data: isLoading,
		type: SESSION_SET_IS_LOADING_V2,
	};
}

export function session_login(login: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_startLogin((json as IJson).data.profiles)));
		return axios().post(`${basePath}/login`, { email: login, password });
	};
}

export function session_superLogin(userId: number, orgId: number): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/superLogin/user/${userId}/org/${orgId}`);
	};
}

export function session_loginV2(login: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/login`, {
			login,
			password,
			keepLogin: true,
		});
	};
}

export function session_loginSSO(data: GoogleLoginResponse): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/loginSSO`, data);
	};
}

export function changeOrganization(organizationId: number, access_token: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/refresh`, { organizationId, access_token });
	};
}

export function session_loginWithToken(access_token: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));
		return axios().post(`${basePath}/loginWithToken`, { access_token });
	};
}

export function session_finishLogin(token: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_finishLogin((json as IJson).data)));

		return axios().post(`${basePath}/profile`, { token });
	};
}

export function login(login: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_startLogin((json as IJson).data.profiles)));
		return axios().post(`${basePath}/login`, { login, password, organizationId: 2 });
	};
}

export function session_checkPasswordChange(token: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(_checkRecover((json as IJson).data.auth)));
		return axios().post(`${basePath}/recover/check`, { token });
	};
}

export function session_resetPassword(token: string, password: string): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_resetPassword()));
		return axios().post(`${basePath}/recover/complete`, { token, password });
	};
}

export function session_changeLanguageForUser(language: string, id?: number): AsyncAction {
	return id ? session_updateLanguageForUser(language, id) : session_createLanguageForUser(language);
}

function session_createLanguageForUser(language: string): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(session_setLanguage(language, (json as IJson).data)));
		return axios().post(basePathLanguage, { language });
	};
}

function session_updateLanguageForUser(language: string, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then((json: unknown) => dispatch(session_setLanguage(language, (json as IJson).data)));
		return axios().patch(`${basePathLanguage}/${id}`, { language });
	};
}
