import { useState } from 'react';

import { IDictionaryItem } from '@skillandchill/tasker-types';
import { MultiSelectTextField } from '@skillandchill/tasker-widgets2';

import { useCurrentTheme } from '@/utils/hooks/useCurrentTheme';

import { IssueModalSelectTextFieldFontSize, IssueModalSelectTextFieldHeight } from 'view/IssueModalEditor/resources';

import { getNameDictionary as getName } from '../utils';

import { Props } from './model';
import { Resources } from './resources';

export const DictionaryItems = (props: Props): JSX.Element => {
	const { value: valueInput, onChange: onChangeInput, items, validate, requiredClassName, isRequired } = props;
	const { currentTheme } = useCurrentTheme();

	const [value, setValue] = useState<Partial<IDictionaryItem>[]>(valueInput as Partial<IDictionaryItem>[]);

	const onChange = (object: Partial<IDictionaryItem>[]) => {
		setValue(object);
		onChangeInput(object);
	};

	return (
		<div
			className={
				isRequired && validate && !((value?.length ?? 0) > 0)
					? requiredClassName
					: Resources.GlobalResources.empty
			}
		>
			<MultiSelectTextField<Partial<IDictionaryItem>>
				muiTheme={currentTheme}
				getName={getName}
				getKey={c => c?.Id ?? -1}
				items={items}
				values={value}
				args={Resources.selectArgs}
				onChange={onChange}
				height={value?.length > 1 ? undefined : IssueModalSelectTextFieldHeight}
				fontSize={IssueModalSelectTextFieldFontSize}
			/>
		</div>
	);
};
