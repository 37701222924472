import { useState } from 'react';

import { IDictionaryItem } from '@skillandchill/tasker-types';
import { SelectTextField } from '@skillandchill/tasker-widgets2';

import { useCurrentTheme } from '@/utils/hooks/useCurrentTheme';

import { IssueModalSelectTextFieldFontSize, IssueModalSelectTextFieldHeight } from 'view/IssueModalEditor/resources';

import { getNameDictionary as getName } from '../utils';

import { Props } from './model';
import { Resources } from './resources';

export const DictionaryItem = (props: Props): JSX.Element => {
	const { value: valueInput, onChange: onChangeInput, items, isRequired, validate, requiredClassName } = props;
	const { currentTheme } = useCurrentTheme();

	const [value, setValue] = useState<Partial<IDictionaryItem>>(valueInput as Partial<IDictionaryItem>);

	const onChange = (object: Partial<IDictionaryItem>) => {
		setValue(object);
		onChangeInput(object);
	};

	return (
		<div className={isRequired && validate && !value?.Id ? requiredClassName : Resources.GlobalResources.empty}>
			<SelectTextField<Partial<IDictionaryItem>>
				muiTheme={currentTheme}
				getName={getName}
				getKey={c => c?.Id ?? -1}
				items={items}
				value={value}
				args={Resources.selectArgs}
				onChange={onChange}
				height={IssueModalSelectTextFieldHeight}
				fontSize={IssueModalSelectTextFieldFontSize}
			/>
		</div>
	);
};
