import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					popupTitle: 'Konfiguracja członków projektu',
					usersAddedToProject: 'Dodano użytkowników do projektu',
					rolesNotChosenForAllUsers:
						'Nie każdemu z wybranych użytkowników wybrano role. Czy chcesz kontynuować ?',
					showsUsersWithoutChosenRoles: 'Wyświetl osoby bez ról',
					constraintErrorOnAddedManyUsers: 'Następujący użytkownicy są już w projekcie: {{users}}',
					cancelSure: 'Czy jesteś pewien?',
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					popupTitle: 'Project users configuration',
					usersAddedToProject: 'Users have been added to the project',
					rolesNotChosenForAllUsers:
						'Not all of the selected users had roles selected. Do you want to continue ?',
					showsUsersWithoutChosenRoles: 'Display people with no roles selected',
					constraintErrorOnAddedManyUsers: 'Following users are already in project: {{users}}',
					cancelSure: 'Are you sure?',
				},
			},
		},
	},
};

export const defaultConfirmationMessageData = {
	title: Resources.GlobalResources.empty,
};
