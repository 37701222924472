import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					FieldsRequiredIndicator: {
						fieldsRequired: 'Pola wymagane do akcpetacji',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					FieldsRequiredIndicator: {
						fieldsRequired: 'Fields required for acceptance',
					},
				},
			},
		},
	},
};
