import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	ariaValue: 'primary checkbox' as const,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			ModalActions: {
				createAnother: 'Zgłoś kolejne',
			},
		},
	},
	en: {
		IssueModalEditor: {
			ModalActions: {
				createAnother: 'Create another',
			},
		},
	},
};
