import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerInfo: {
						indexInfo: 'Informacje',
						name: 'Nazwa',
						editCustomer: 'Edytuj klienta',
					},
				},
			},
		},
	},
	en: {
		CustomerDetail: {
			Content: {
				Summary: {
					CustomerInfo: {
						indexInfo: 'Index info',
						name: 'Name',
						editCustomer: 'Edit customer',
					},
				},
			},
		},
	},
};
