import clsx from 'clsx';
import { SyntheticEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { ENotificationReadStatusFilter } from '@skillandchill/tasker-types';
import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Tabs = (props: Props) => {
	const { selectedTab, setSelectedTab } = props;
	const { t } = useTrans('NotificationPopover.Tabs');
	const classes = useStyles();

	const countOfNotReadUserNotifications = useSelector(
		(state: IReducerState) => state.DashBoard.countOfNotReadUserNotifications
	);

	useEffect(() => {
		setSelectedTab(ENotificationReadStatusFilter.NotRead);
	}, []);

	const handleChange = (event: SyntheticEvent, newTab: ENotificationReadStatusFilter) => {
		setSelectedTab(newTab);
	};

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: Resources.divider }}>
				<TabList
					onChange={handleChange}
					className={classes.tabList}
					textColor={Resources.GlobalResources.inherit}
				>
					<Tab
						className={clsx(
							classes.tab,
							selectedTab === ENotificationReadStatusFilter.NotRead
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={
							<span>
								{t('notRead')}
								<span
									className={clsx(
										classes.notReadCount,
										selectedTab === ENotificationReadStatusFilter.NotRead
											? classes.notReadCountTabSelected
											: classes.notReadCountTabNotSelected
									)}
								>
									{countOfNotReadUserNotifications}
								</span>
							</span>
						}
						value={ENotificationReadStatusFilter.NotRead}
					/>
					<Tab
						className={clsx(
							classes.tab,
							selectedTab === ENotificationReadStatusFilter.All
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={t('all')}
						value={ENotificationReadStatusFilter.All}
					/>
					<Tab
						className={clsx(
							classes.tab,
							selectedTab === ENotificationReadStatusFilter.Read
								? classes.tabSelected
								: classes.tabNotSelected
						)}
						label={t('read')}
						value={ENotificationReadStatusFilter.Read}
					/>
				</TabList>
			</Box>
		</TabContext>
	);
};
