import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			UsersTab: {
				CardActions: {
					addTitle: 'Dodawanie użytkownika',
					addDescription: 'Wypełnij poniższe pola, aby dodać nowego użytkownika do organizacji.',
					editTitle: 'Edycja użytkownika',
					editDescription: 'Edytuj dane użytkownika w organizacji poprzez wypełnienie poniższych pól.',
					save: 'Zapisz użytkownika',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			UsersTab: {
				CardActions: {
					addTitle: 'Add user',
					addDescription: 'Fill in the fields below to add a new user to the organization.',
					editTitle: 'Edit user',
					editDescription: 'Edit user data in the organization by filling in the fields below.',
					save: 'Save user',
				},
			},
		},
	},
};
