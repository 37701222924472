import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemAlternative: {
								heading: 'Lista elementów diety jest pusta',
								description:
									'Uzupełnij formularz delegacji, po uzupełnieniu daty początkowej i końcowej elementy diety zostaną wygenerowane dla każdego dnia delegacji zgodnie z ustawieniami.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							PerDiemAlternative: {
								heading: 'Per diem list is empty',
								description:
									'Fill in the delegation form, after filling in the start and end date, per diem elements will be generated for each day of the delegation according to the settings.',
							},
						},
					},
				},
			},
		},
	},
};
