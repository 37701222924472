import {
	EDictionaryItemType,
	EFieldDefinitionType,
	IDictionaryItem,
	IFieldValue,
	ITag,
} from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { IIssueFieldCreate } from '../../../../../../../../temporaryInterfaces';

import { Resources } from './resources';

export const getDefaultValue = (
	issueFormSectionToIssueField: IIssueFormSectionToIssueField
): {
	field: IFieldValue | undefined;
	value: string | number | undefined;
	type: string;
} => {
	const parameterNames = getParameterName(
		issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId as EFieldDefinitionType
	);
	const values = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const valuesCheckList = issueFormSectionToIssueField?.AttributeDefinition?.CheckListValue;
	let valuesCheckListSingleValue: ICheckListValue;
	let firstFieldValue: IFieldValue | undefined = undefined;
	let value;

	if (values && (values?.length ?? 0) > 0) {
		firstFieldValue = values[0];
		const subValue = values[0];
		if (subValue) value = subValue[parameterNames?.value as keyof IFieldValue];
	} else if (valuesCheckList && (valuesCheckList?.length ?? 0) > 0) {
		valuesCheckListSingleValue = valuesCheckList[0];
		const subValue = valuesCheckList[0];
		if (subValue) value = subValue[parameterNames?.valueSub as keyof ICheckListValue];
	} else if (
		issueFormSectionToIssueField &&
		issueFormSectionToIssueField[parameterNames?.defaultValue as keyof IIssueFormSectionToIssueField]
	)
		value = issueFormSectionToIssueField?.[parameterNames?.defaultValue as keyof IIssueFormSectionToIssueField];
	else value = undefined;

	return {
		field: firstFieldValue,
		value: value as string | number | undefined,
		type: parameterNames?.type as string,
	};
};

const getParameterName = (
	type: EFieldDefinitionType
): {
	value?: keyof IFieldValue;
	defaultValue?: keyof IIssueFormSectionToIssueField;
	type?: string;
	valueSub?: keyof ICheckListValue;
} => {
	switch (type) {
		case EFieldDefinitionType.Integer: {
			return {
				defaultValue: Resources.defaultValue.integer,
				value: Resources.value.integer,
				type: Resources.number,
				valueSub: Resources.checkListValue.integer,
			};
		}
		case EFieldDefinitionType.Text: {
			return {
				defaultValue: Resources.defaultValue.text,
				value: Resources.value.text,
				type: Resources.string,
				valueSub: Resources.checkListValue.text,
			};
		}
		case EFieldDefinitionType.Decimal: {
			return {
				defaultValue: Resources.defaultValue.decimal,
				value: Resources.value.decimal,
				type: Resources.number,
				valueSub: Resources.checkListValue.decimal,
			};
		}
	}
	return {};
};

export const changeDateInField = (fieldValue: IFieldValue, isDateTime: boolean, date: Date): IFieldValue => {
	return {
		...fieldValue,
		ValueDate: isDateTime ? fieldValue?.ValueDateTime : date,
		ValueDateTime: isDateTime ? date : fieldValue?.ValueDateTime,
	};
};

export const changeTagInField = (fieldValue: IFieldValue, tag: Partial<ITag>): IFieldValue => {
	return {
		...fieldValue,
		ValueTagId: tag?.Id,
	};
};

export const addTagInField = (
	attributeDefinitionId: number,
	tag: Partial<ITag>,
	fieldValue: Partial<IFieldValue>
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinitionId,
		ValueTagId: tag?.Id,
	};
};

export const addTagsInField = (
	attributeDefinitionId: number,
	tags: Partial<ITag>[],
	fieldValue: Partial<IFieldValue>
): Partial<IFieldValue>[] => {
	return tags?.map(x => addTagInField(attributeDefinitionId, x, fieldValue));
};

export const addDictionaryInField = (
	attributeDefinitionId: number,
	dictionary: Partial<IDictionaryItem>,
	fieldValue?: Partial<IFieldValue>
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinitionId,
		ValueDictionaryItemId: dictionary?.Id,
	};
};

export const addDictionariesInField = (
	attributeDefinitionId: number,
	tags: Partial<IDictionaryItem>[]
): Partial<IFieldValue>[] => {
	return tags?.map(x => addDictionaryInField(attributeDefinitionId, x));
};

export const addDateInField = (
	attributeDefinitionId: number,
	isDateTime: boolean,
	date: Date,
	fieldValue?: Partial<IFieldValue>
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinitionId,
		ValueDate: isDateTime ? undefined : date,
		ValueDateTime: isDateTime ? date : undefined,
	};
};

export const getNameDictionary = (item: Partial<IDictionaryItem>): string => {
	switch (item?.DictionaryItemTypeId) {
		case EDictionaryItemType.Text: {
			return item?.ValueText ?? '';
		}
		case EDictionaryItemType.Boolean: {
			return item?.ValueBoolean?.toString() ?? '';
		}
		case EDictionaryItemType.Date: {
			return item?.ValueDate?.toString() ?? '';
		}
		case EDictionaryItemType.DateTime: {
			return item?.ValueDateTime?.toString() ?? '';
		}
		case EDictionaryItemType.Decimal: {
			return item?.ValueDecimal?.toString() ?? '';
		}
		case EDictionaryItemType.Dictionary: {
			return item?.ValueDictionary?.Name ?? '';
		}
		case EDictionaryItemType.Integer: {
			return item?.ValueInteger?.toString() ?? '';
		}
		case EDictionaryItemType.TagGroup: {
			return item?.ValueTagGroup?.Label ?? '';
		}
		default: {
			return '--';
		}
	}
};

export const getFieldValues = (
	issueFormSectionToIssueField: IIssueFormSectionToIssueField,
	issueFieldDetails?: IIssueFieldCreate
): IFieldValue[] | undefined => {
	return (issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? [])?.length > 0
		? issueFormSectionToIssueField?.AttributeDefinition?.FieldValue
		: ([
				{
					ValueTag: issueFieldDetails?.mappedTags?.find(
						mappedTag =>
							mappedTag?.FieldDefinitionId == issueFormSectionToIssueField?.AttributeDefinition?.Id &&
							(((issueFieldDetails?.kanbanColumnId ?? 0) > 0 &&
								issueFieldDetails?.kanbanColumnId == mappedTag?.TagId) ||
								(issueFieldDetails?.kanbanColumnId == 0 &&
									issueFieldDetails?.kanbanColumnOrder == mappedTag?.TagId))
					)?.FieldDefinitionTag,
				},
		  ] as IFieldValue[]) ?? [];
};

export const getCheckListValue = (
	values: { [x: number]: ICheckListValue[] },
	fieldDefinitionId: number
): ICheckListValue => {
	const valuesOfFieldDefinition = values[fieldDefinitionId];
	let value: ICheckListValue = {} as ICheckListValue;
	if (valuesOfFieldDefinition) value = valuesOfFieldDefinition[0];
	return value;
};
