import { Translation as DimensionProfileSelector } from './DimensionProfileSelector/resources';
import { Translation as CustomerTabFilter } from './Filter/CustomerTabFilter/resources';
import { Translation as IssueTabFilter } from './Filter/IssueTabFilter/resources';
import { Translation as IssueTag } from './Filter/IssueTabFilter/TagsFilter/resources';
import { Translation as PortalTabFilter } from './Filter/PortalTabFilter/resources';
import { Translation as PortalTag } from './Filter/PortalTabFilter/TagsFilter/resources';
import { Translation as ProjectTabFilter } from './Filter/ProjectTabFilter/resources';
import { Translation as Filter } from './Filter/resources';
import { Translation as ProfileSelector } from './ProfileSelector/resources';
import { Translation as SearchBar } from './resources';
import { Translation as Search } from './Search/resources';
import { Translation as SearchBy } from './Search/SearchBy/resources';
import { Translation as Tags } from './Tags/resources';

export const SearchBarTranslationPL = [
	CustomerTabFilter.pl,
	IssueTabFilter.pl,
	IssueTag.pl,
	PortalTabFilter.pl,
	PortalTag.pl,
	Filter.pl,
	ProfileSelector.pl,
	SearchBar.pl,
	Search.pl,
	Tags.pl,
	ProjectTabFilter.pl,
	DimensionProfileSelector.pl,
	SearchBy.pl,
];
export const SearchBarTranslationEN = [
	CustomerTabFilter.en,
	IssueTabFilter.en,
	IssueTag.en,
	PortalTabFilter.en,
	PortalTag.en,
	Filter.en,
	ProfileSelector.en,
	SearchBar.en,
	Search.en,
	Tags.en,
	ProjectTabFilter.en,
	DimensionProfileSelector.en,
	SearchBy.en,
];
