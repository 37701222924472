import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	emptyCell: '----------',
	settlementNestedTableElementKey: (key: string) => `SettlementNestedTableElement_${uuid()}_${key}`,
	settlementNestedTableFieldKey: (key: string) => `SettlementNestedTableField_${uuid()}_${key}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementNestedTable: {
								headingElement: 'Element diety',
								headingCharge: 'Należność',
								headingFullDayValue: 'Pełna kwota dobowa',
								headingCalculatedValue: 'Kwota wyliczona dla tego dnia',
								headingTotalValue: 'Pełna należność w dniu',
								1: 'Śniadanie',
								2: 'Obiad',
								3: 'Kolacja',
								4: 'Pobyt w szpitalu',
								5: 'Zakwaterowanie',
								6: 'Transport publiczny',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationSettlement: {
						SettlementTable: {
							SettlementNestedTable: {
								headingElement: 'Per diem element',
								headingCharge: 'Charge',
								headingFullDayValue: 'Full day value',
								headingCalculatedValue: 'Calculated value for this day',
								headingTotalValue: 'Full charge in day',
								1: 'Breakfast',
								2: 'Dinner',
								3: 'Supper',
								4: 'Hospital stay',
								5: 'Accommodation',
								6: 'Public transport',
							},
						},
					},
				},
			},
		},
	},
};
