import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	userPath: '/user/',
	taskerIcon: process.env.PUBLIC_URL + '/favicon.png',
	issueIdPath: '?issueId=',
	hash: '#',
	textFieldInputProps: {
		style: {
			background: 'white',
		},
	},
	column: 'column' as const,
};

export const Translation = {
	pl: {
		Activities: {
			ActivityList: {
				WorkingUsers: {
					WorkingUserCard: {
						pageTitle: { activeUsersList: 'Lista pracujących użytkowników', loading: 'Ładowanie' },
						nameDet: 'Imię',
						issueDet: 'Zgłoszenie',
						timeDet: 'Czas pracy',
						timeDesc: 'Pracuje od: ',
						viewIssue: 'Pokaż więcej',
						searchDet: 'Wyszukaj',
						sortDet: 'Sortowanie',
						projectDet: 'Projekt',
						errorUsers: 'Wystąpił błąd pobierania użytkowników',
						errorIssue: 'Bład pobierania zlecenia',
						sortingLabels: {
							sortName: 'Imie',
							sortIssueId: 'Numer zlecenia',
							sortIssue: 'Nazwa zlecenia',
						},
					},
				},
			},
		},
	},
	en: {
		Activities: {
			ActivityList: {
				WorkingUsers: {
					WorkingUserCard: {
						pageTitle: { activeUsersList: 'Working Users List', loading: 'Loading' },
						nameDet: 'Tasker User',
						issueDet: 'Issue',
						timeDet: 'Time',
						timeDesc: 'Working for: ',
						viewIssue: 'Show more',
						searchDet: 'Search',
						sortDet: 'Sort',
						projectDet: 'Project',
						errorUsers: 'There was an error with getting users',
						errorIssue: 'No issue downloaded',
						sortingLabels: {
							sortName: 'Name',
							sortIssueId: 'Issue Id',
							sortIssue: 'Issue Name',
						},
					},
				},
			},
		},
	},
};
