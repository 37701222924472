import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

const address = process?.env?.REACT_APP_BACKEND_ADDRESS ?? '';

export const Resources = {
	urlDownload: (id: number) => `${process.env.REACT_APP_PROTOCOL}://${address}/Attachment/streamable/${id}`,
	downloadButtonId: 'DownloadButtonList',
	menuId: 'menuId',
	ariaLabelledby: 'aria-labelledby',
	basicButton: 'basic-button',
	key: (x: number) => `Tag_${uuid()}_${x}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueList: {
			TileComponents: {
				AttachmentCount: {
					attachmentCount: 'Liczba załączników',
					errorDownloading:
						'Wystąpił błąd podczas pobieranie plików proszę skontaktować się z administracją.',
				},
			},
		},
	},
	en: {
		IssueList: {
			TileComponents: {
				AttachmentCount: {
					attachmentCount: 'Number of attachments',
					errorDownloading:
						'Error occurs while downloading file. Please contact administration to solve that problem.',
				},
			},
		},
	},
};
