import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							BorderCrossing: {
								legend: 'Przekroczenie granicy',
								dateTimePickerLabel: 'Data przekroczenia granicy',
								borderCrossingTooltip:
									'Data przekroczenia granicy dla środków innych niż samochód oznacza datę wejścia na pokład.',
							},
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationRoute: {
							BorderCrossing: {
								legend: 'Border crossing',
								dateTimePickerLabel: 'Border crossing date',
								borderCrossingTooltip:
									'Border crossing date for means of transport other than car means the date of boarding.',
							},
						},
					},
				},
			},
		},
	},
};
