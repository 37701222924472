import React, { ChangeEvent, useState } from 'react';

import { TextField as TextFieldMui } from '@mui/material';

import { Props } from './model';
import { Resources } from './resources';

export const TextField = (props: Props): JSX.Element => {
	const { type, defaultValue, onChange: onChangeInput, isInt = false } = props;

	const [value, setValue] = useState<string | number>(defaultValue as string | number);

	const onBlur = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setValue(e.target.value);
		onChangeInput(e);
	};

	const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (isInt && type === Resources.number) {
			setValue(Number.parseInt(e.target.value));
		} else if (!isInt && type === Resources.number) {
			setValue(Number.parseFloat(e.target.value));
		} else setValue(e.target.value);
	};

	return (
		<>
			<TextFieldMui
				fullWidth
				type={type}
				variant={Resources.GlobalResources.outlined}
				value={value}
				onChange={onChange}
				size={Resources.GlobalResources.small}
				onBlur={onBlur}
			/>
		</>
	);
};
