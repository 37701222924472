import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const GlobalStyles = makeStyles(
	(theme: Theme) => ({
		'@global': {
			'*::-webkit-scrollbar': {
				width: '10px !important',
				height: '10px !important',
			},
			'*::-webkit-scrollbar-track': {
				background: `transparent !important`,
			},
			'*::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(190,190,190,0.9)',
				borderRadius: theme.spacing(1.25),
				border: '3px none #ffffff',
			},
			'*::-webkit-scrollbar-thumb:hover': {
				backgroundColor: 'rgba(175,175,175,0.90)',
			},
			'*::-webkit-scrollbar-thumb:active': {
				backgroundColor: 'rgba(160,160,160,0.90)',
			},
			'*::-webkit-scrollbar-corner': {
				backgroundColor: 'transparent !important',
			},
			'*': {
				fontFamily: 'Jost, sans-serif !important',
			},
		},
	}),
	{ name: `CommonMakeStyles` }
);
