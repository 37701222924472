import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryDescriptionStep: {
							mainTitle: 'Opis wynagrodzenia',
							mainSubtitle: 'Dodanie opisu do wynagrodzenia pozwoli na jego szybsze wyszukanie',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					SalaryForm: {
						SalaryDescriptionStep: {
							mainTitle: 'Salary description',
							mainSubtitle: 'Adding a description to your salary will allow you to find it faster',
						},
					},
				},
			},
		},
	},
};
