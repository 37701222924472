import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as sub } from '../resources';

export const Resources = {
	...sub,
	...GlobalResources,
	getPerDiemKey: (index: number) => `PerDiem_${index}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							tooltipBreakfast: 'Zaznacz/Odznacz wartości w kolumnie Śniadanie',
							breakfast: 'Śniadanie',
							tooltipDinner: 'Zaznacz/Odznacz wartości w kolumnie Obiad',
							dinner: 'Obiad',
							tooltipSupper: 'Zaznacz/Odznacz wartości w kolumnie Kolacja',
							supper: 'Kolacja',
							tooltipHospital: 'Zaznacz/Odznacz wartości w kolumnie Szpital',
							hospital: 'Szpital',
							tooltipAcommodation: 'Zaznacz/Odznacz wartości w kolumnie Kwater',
							acommodation: 'Kwatery',
							tooltipMeansOfTransport: 'Zaznacz/Odznacz wartości w kolumnie Transport',
							meansOfTransport: 'Transport',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationElements: {
						DelegationPerDiem: {
							tooltipBreakfast: 'Check/Uncheck values in Breakfast column',
							breakfast: 'Breakfast',
							tooltipDinner: 'Check/Uncheck values in Dinner column',
							dinner: 'Dinner',
							tooltipSupper: 'Check/Uncheck values in Supper column',
							supper: 'Supper',
							tooltipHospital: 'Check/Uncheck values in Hospital column',
							hospital: 'Hospital',
							tooltipAcommodation: 'Check/Uncheck values in Housing column',
							acommodation: 'Housing',
							tooltipMeansOfTransport: 'Check/Uncheck values in Transport column',
							meansOfTransport: 'Transport',
						},
					},
				},
			},
		},
	},
};
