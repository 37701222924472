import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	TableRowKeys: {
		9: 'TableRow9',
		8: 'TableRow8',
		7: 'TableRow7',
		6: 'TableRow6',
	},
	PortalFilter: {
		AssignedTo: {
			field: 'portal',
			subField: 'assignedTo',
		},
	},
};

export const Translation = {
	pl: {
		SearchBar: {
			Filter: {
				PortalTabFilter: {
					PortalFilter: {
						AssignedTo: {
							label: 'Przypisano do',
						},
					},
				},
			},
		},
	},
	en: {
		SearchBar: {
			Filter: {
				PortalTabFilter: {
					PortalFilter: {
						AssignedTo: {
							label: 'Assigned to',
						},
					},
				},
			},
		},
	},
};
