import { Translation as HotkeysPopover } from '@/layouts/Dashboard/HotkeysPopover/resources';
import { Translation as DrawerContent } from '@/layouts/Dashboard/NavBar/DrawerContent/resources';
import { Translation as DrawerContentResized } from '@/layouts/Dashboard/NavBar/DrawerContentResized/resources';
import { Translation as Nav } from '@/layouts/Dashboard/NavConfig/resources';
import { Translation as Notification } from '@/layouts/Dashboard/NotificationsPopover/Notification/resources';
import { Translation as DashboardPlaceHolder } from '@/layouts/Dashboard/NotificationsPopover/PlaceHolder/resources';
import { Translation as PopoverHeader } from '@/layouts/Dashboard/NotificationsPopover/PopoverHeader/resources';
import { Translation as NotificationPopover } from '@/layouts/Dashboard/NotificationsPopover/resources';
import { Translation as Tabs } from '@/layouts/Dashboard/NotificationsPopover/Tabs/resources';
//import { Translation as Dashboard } from '@/layouts/Dashboard/resources';
import { Translation as HotkeysTooltip } from '@/layouts/Dashboard/TopBar/HotkeysTooltip/resources';
import { Translation as Flag } from '@/layouts/Dashboard/TopBar/LanguageButton/resources';
import { Translation as TopBar } from '@/layouts/Dashboard/TopBar/resources';
import { Translation as WorklogStarter } from '@/layouts/Dashboard/WorklogStarter/resources';
import { Translation as WorklogStarterPopover } from '@/layouts/Dashboard/WorklogStarter/WorklogStarterPopover/resources';

export const DashboardTranslationPL = [
	Nav.pl,
	HotkeysTooltip.pl,
	HotkeysPopover.pl,
	DrawerContentResized.pl,
	DrawerContent.pl,
	Notification.pl,
	DashboardPlaceHolder.pl,
	//Dashboard.pl,
	PopoverHeader.pl,
	Tabs.pl,
	NotificationPopover.pl,
	WorklogStarterPopover.pl,
	WorklogStarter.pl,
	Flag.pl,
	TopBar.pl,
];
export const DashboardTranslationEN = [
	Nav.en,
	HotkeysTooltip.en,
	HotkeysPopover.en,
	DrawerContentResized.en,
	DrawerContent.en,
	Notification.en,
	DashboardPlaceHolder.en,
	//Dashboard.en,
	PopoverHeader.en,
	Tabs.en,
	NotificationPopover.en,
	WorklogStarterPopover.en,
	WorklogStarter.en,
	Flag.en,
	TopBar.en,
];
