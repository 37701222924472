import clsx from 'clsx';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Typography } from '@mui/material';

import { IErrorValidationV2 } from '@skillandchill/tasker-types';
import { InputLabelNew, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { issueModalDataContext } from '@/views/IssueModelNew';

import { EIssueModalDetailsPageFields } from '../../model';

import { IssueFormSection } from './IssueFormSection';
import { useStyles } from './styles';

export const DependentInputs = () => {
	const classes = useStyles();

	const { t } = useTrans('IssueModelNew.IssueMainData');

	const formState = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueForm);

	const { issueState, inputsInEdit, handleEditStateChange, issueErrors, isEdittingIssue, handleReset } = useContext(
		issueModalDataContext
	);

	const propertiesError = (issueErrors.find(error => error.inputName === EIssueModalDetailsPageFields.properties) ??
		{}) as IErrorValidationV2<EIssueModalDetailsPageFields> | undefined;

	const anyRequiredFieldMissing = Boolean(propertiesError?.additionalData ?? false);

	return (
		<div className={classes.container}>
			<InputLabelNew
				inEdit={isEdittingIssue ? !!inputsInEdit[EIssueModalDetailsPageFields.properties] : true}
				canSetInputInNonEdit={isEdittingIssue}
				className={classes.issuePropertiesContainer}
				label={t('issueProperties')}
				handleInEditStateChange={() => handleEditStateChange(EIssueModalDetailsPageFields.properties, true)}
				handleReset={() => handleReset(EIssueModalDetailsPageFields.properties)}
			/>
			<VisibilityControl
				condition={!issueState?.ProjectIssueTypeId}
				alternative={
					<div className={classes.issuePropertiesInnerContainer}>
						{formState?.IssueFormSection?.map(issueFormSection => (
							<div key={`issueFormSection-${issueFormSection.Id}`} className={classes.innerWrapper}>
								<IssueFormSection
									issueFormSection={issueFormSection}
									shouldHideLines
									inEdit={
										isEdittingIssue ? !!inputsInEdit[EIssueModalDetailsPageFields.properties] : true
									}
								/>
							</div>
						))}
						<VisibilityControl condition={anyRequiredFieldMissing}>
							<Typography className={classes.errorText}>Brak wartości w wymaganych polach</Typography>
						</VisibilityControl>
					</div>
				}
			>
				<div className={clsx(classes.flexColumn, classes.noIssueType)}>
					<QuestionMarkIcon className={classes.noIssueTypeIcon} />
					<Typography className={classes.noIssueTypeText}>Brak typu zgłoszenia</Typography>
				</div>
			</VisibilityControl>
		</div>
	);
};
