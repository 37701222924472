import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),

		container: {
			width: '300px',
		},

		addRelationText: {
			fontWeight: '400',
		},

		addRelationContainer: {
			marginTop: theme.spacing(1),
		},
	}),
	{ name: `IssueModalEditor_Tabs_Relation` }
);
