import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	divider: 'divider',
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					Tabs: {
						description: 'Opis zgłoszenia',
						comments: 'Komentarze',
						attachments: 'Załączniki',
					},
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					Tabs: {
						description: 'Description',
						comments: 'Comments',
						attachments: 'Attachments',
					},
				},
			},
		},
	},
};
