import { AsyncAction } from '@skillchill/redux-promisify';

import { IInvoiceDetails } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

const basePath = 'InvoiceDetails';

export const UPDATE_INVOICE_DETAIL: ActionType = 'UPDATE_INVOICE_DETAIL';

function _updateAddresses(data: IInvoiceDetails): Action {
	return {
		type: UPDATE_INVOICE_DETAIL,
		data,
	};
}

export function invoiceDetailUpdate(id: number, address: Partial<IInvoiceDetails>): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_updateAddresses((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, { ...address });
	};
}
