import { Translation as Buttons } from 'view/WorkDayEndSummary/Filters/Options/Buttons/resources';
import { Translation as UserFilter } from 'view/WorkDayEndSummary/Filters/Options/UserFilter/resources';
import { Translation as SignedDateFilter } from 'view/WorkDayEndSummary/Filters/Options/WorkdaySignedDateFilter/resources';
import { Translation as Filters } from 'view/WorkDayEndSummary/Filters/resources';
import { Translation as WorkDayEndSummary } from 'view/WorkDayEndSummary/resources';

export const WorkDayEndSummaryTranslationEN = [
	WorkDayEndSummary.en,
	Filters.en,
	UserFilter.en,
	Buttons.en,
	SignedDateFilter.en,
];
export const WorkDayEndSummaryTranslationPL = [
	WorkDayEndSummary.pl,
	Filters.pl,
	UserFilter.pl,
	Buttons.pl,
	SignedDateFilter.pl,
];
