import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	dash: '_',
	standard: 'standard' as const,
	colon: ' : ',
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Body: {
								keyLabel: 'Klucz',
								keyValue: 'Wartość',
							},
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							Body: {
								keyLabel: 'Key',
								keyValue: 'Value',
							},
						},
					},
				},
			},
		},
	},
};
