import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	agreementsListPath: '/agreements/agreements',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					HeaderSection: {
						cancelChangesConfirmationData: {
							title: 'Czy na pewno chcesz anulować edycję umowy?',
							subtitle: 'Posiadasz niezapisane zmiany. Twoje zmiany zostaną utracone.',
							submitText: 'Tak',
							cancelText: 'Nie',
						},
						cancelCreateConfirmationData: {
							title: 'Czy na pewno chcesz anulować tworzenie umowy?',
							subtitle:
								'Posiadasz niezapisane zmiany. Wprowadzone dane zostaną utracone, a umowa nie zostanie utworzona.',
							submitText: 'Tak',
							cancelText: 'Nie',
						},
						agreementCreatedSnackbar: 'Umowa została stworzona',
						wrongDataSnackbar: 'Podano nieprawidłowe dane',
						errorOccured: 'Wystąpił błąd',
						agreementHasBeenEdited: 'Umowa została zedytowana',
						agreementHasBeenDeleted: 'Umowa została usunięta',
						deleteAgreementConfirmationData: {
							title: 'Czy na pewno chcesz usunąć umowę?',
							subtitle: 'Usunięcie umowy usunie wszystkie powiązane wynagrodzenia.',
							submitText: 'Tak',
							cancelText: 'Nie',
						},
						editingAgreement: 'Edycja umowy',
						creatingAgreement: 'Nowa umowa',
						cancel: 'Anuluj',
						submitChanges: 'Zatwierdź zmiany',
						createNewAgreement: 'Stwórz umowę',
						deleteAgreement: 'Usuń umowę',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementEdit: {
					HeaderSection: {
						cancelChangesConfirmationData: {
							title: 'Are you sure, you want to cancel editing the agreement?',
							subtitle: 'You have unsaved changes. The changes will be lost.',
							submitText: 'Yes',
							cancelText: 'No',
						},
						cancelCreateConfirmationData: {
							title: 'Are you sure, you want to cancel creating the agreement?',
							subtitle:
								'You have unsaved changes. The data you have entered will be lost, and the agreement will not be created.',
							submitText: 'Yes',
							cancelText: 'No',
						},
						agreementCreatedSnackbar: 'Agreement has been created',
						wrongDataSnackbar: 'Incorrect data',
						errorOccured: 'Error has occured',
						agreementHasBeenEdited: 'Agreement has been edited',
						agreementHasBeenDeleted: 'Agreement has been deleted',
						deleteAgreementConfirmationData: {
							title: 'Are you sure, you want to delete the agreement?',
							subtitle: 'Deleting the agreement will also delete all associated salaries.',
							submitText: 'Yes',
							cancelText: 'No',
						},
						editingAgreement: 'Editing the agreement',
						creatingAgreement: 'New agreement',
						cancel: 'Cancel',
						submitChanges: 'Submit changes',
						createNewAgreement: 'Create agreement',
						deleteAgreement: 'Delete agreement',
					},
				},
			},
		},
	},
};

export const defaultConfirmationMessageData = {
	title: Resources.GlobalResources.empty,
};
