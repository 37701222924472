import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { Button, Typography } from '@mui/material';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './modal';
import { useStyles } from './styles';

export const DeleteIssue = (props: Props) => {
	const { handleDeleteIssueWithConfirmation } = props;
	const classes = useStyles();

	const { t, tString } = useTrans('IssueModalNew.IssueModalTitle');

	return (
		<div className={clsx(classes.flexColumn, classes.container)}>
			<Button
				onClick={handleDeleteIssueWithConfirmation}
				variant="contained"
				className={clsx(classes.deleteArchiv)}
			>
				Usuń zgłoszenie
			</Button>
			<Typography className={classes.subText} variant="body2">
				Wraz z usunieciem zgłoszenia zostaną usunięte wszystkie powiązane z nim dane.
			</Typography>
		</div>
	);
};
