import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import PublishIcon from '@mui/icons-material/Publish';
import { Button } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { issueModalDataContext } from '@/views/IssueModelNew';

import { AttachmentOnCreate } from './AttachmentOnCreate';
import { AttachmentOnUpdate } from './AttachmentOnUpdate';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueAttch = () => {
	const classes = useStyles();

	const { t } = useTrans('IssueModelNew.IssueModalContent.IssueDetailsPage.TabsContent.IssueAttch');
	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const { isEdittingIssue } = useContext(issueModalDataContext);

	return (
		<div className={clsx(classes.flexColumn, classes.fullWidth)}>
			<Button className={classes.button} variant={Resources.GlobalResources.contained}>
				<label htmlFor="file-upload" className={clsx(classes.flexYCenter, classes.clickable)}>
					{<PublishIcon />}
					{t('add')}
				</label>
			</Button>
			<VisibilityControl condition={isEdittingIssue} alternative={<AttachmentOnCreate />}>
				<AttachmentOnUpdate
					issueId={issue?.Id && issue?.Id != 0 ? issue?.Id : -1}
					didAddFile={undefined}
					saveOnlyLocal={!issue?.Id}
					projectId={issue?.ProjectId}
				/>
			</VisibilityControl>
		</div>
	);
};
