import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,

	textFieldPasswordName: 'password',
	textFieldConfirmName: 'confirm',
	textFieldOldPasswordName: 'oldPassword',
	password: 'password' as const,
};

export const Translation = {
	pl: {
		Login: {
			ChangePassword: {
				CardContentCustom: {
					passwordLabel: {
						password: 'Nowe hasło',
						confirm: 'Powtórz nowe hasło',
						oldPassword: 'Stare hasło',
					},
					requirements: {
						description: 'Wytyczne dotyczące hasła',
						PassPolitic_PwdMinLength: 'Minimalna długość hasła: ',
						PassPolitic_PwdMinDigit: 'Minimalna ilość cyfr: ',
						PassPolitic_PwdMinLowercase: 'Minimalna ilość małych liter: ',
						PassPolitic_PwdMinSpecialChar: 'Minimalna ilość znaków specjalnych: ',
						PassPolitic_PwdMinUppercase: 'Minimalna ilość wielkich liter: ',
					},
					oldMatchError: 'Podane hasło jest nieprawidłowe',
					newOldMatchError: 'Hasła muszą być różne',
					requirementsError: 'Podane hasło nie spełnia wymagań',
					requirementsValid: 'Podane hasło spełnia wymagania',
				},
			},
		},
	},
	en: {
		Login: {
			ChangePassword: {
				CardContentCustom: {
					passwordLabel: {
						password: 'New password',
						confirm: 'Repeat new password',
						oldPassword: 'Old password',
					},
					requirements: {
						description: 'Password guidelines',
						PassPolitic_PwdMinLength: 'Minimal password length: ',
						PassPolitic_PwdMinDigit: 'Minimal digit number: ',
						PassPolitic_PwdMinLowercase: 'Minimal lowercase character number: ',
						PassPolitic_PwdMinSpecialChar: 'Minimal special character number: ',
						PassPolitic_PwdMinUppercase: 'Minimal uppercase character number: ',
					},
					oldMatchError: 'Podane hasło jest nieprawidłowe',
					newOldMatchError: 'Passwords must be different',
					requirementsError: `Password doesn't meet requirements`,
					requirementsValid: 'Password meets requirements',
				},
			},
		},
	},
};
