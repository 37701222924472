export const Translation = {
	pl: {
		Gant: {
			Toolbar: {
				Header: {
					ButtonGroupContent: {
						prev: {
							day: 'Poprzedni dzień',
							week: 'Poprzedni tydzień',
							month: 'Poprzedni miesiąc',
						},
						today: 'Dzisiaj',
						next: {
							day: 'Następny dzień',
							week: 'Następny tydzień',
							month: 'Następny miesiąc',
						},
					},
				},
			},
		},
	},
	en: {
		Gant: {
			Toolbar: {
				Header: {
					ButtonGroupContent: {
						prev: {
							day: 'Previous day',
							week: 'Previous week',
							month: 'Previous month',
						},
						today: 'Today',
						next: {
							day: 'Next day',
							week: 'Next week',
							month: 'Next month',
						},
					},
				},
			},
		},
	},
};
