import { PopoverOrigin } from '@mui/material';

import { IWorkLog } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as SubResources } from '../../resources';

export const Resources = {
	generalTab: SubResources.generalTab,
	propertyField: {
		title: 'Title' as const,
		projectId: 'ProjectId' as const,
	},
	gridJustify: 'space-between' as const,
	anchorOriginLocal: {
		vertical: 'bottom',
		horizontal: 'center',
	} as PopoverOrigin,
	transformOrigin: {
		vertical: 'top',
		horizontal: 'center',
	} as PopoverOrigin,
	htmlFor: 'file-upload',
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				General: {
					basicProperties: 'Podstawowe pola zgłoszenia',
					general: 'Ogólne',
					comments: 'Komentarze',
					attachments: 'Załączniki',
					labelsModal: {
						title: 'Tytuł',
						project: 'Projekt',
						issueType: 'Typ zgłoszenia',
						description: 'Opis',
						assignee: 'Osoba wykonująca',
						reporter: 'Zgłaszający',
						tag: 'Tag',
						portalTag: 'Tagi portalowe',
						creation: 'Data stworzenia zgłoszenia',
						due: 'Koniec',
						hint: 'Nazwa zgłoszenia',
						today: 'Dziś',
						time: 'Czas',
					},
					timeIsToShort: 'Ustawiony czas jest mniejszy niż 1 minuta',
					timeAdded: 'Zapis czasu pracy został dodany',
					saveTimeError: 'Wystąpił błąd serwera.',
					commentLabel: 'Komentarz',
					assignMe: 'Przypisz mnie',
					searchPlaceHolder: 'Szukaj',
					addAttachment: 'Dodaj załącznik',
					issueForm: 'Formularz zgłoszeniowy',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			Tabs: {
				General: {
					basicProperties: 'Basic issue properties',
					general: 'General',
					comments: 'Comments',
					attachments: 'Attachments',
					labelsModal: {
						title: 'Title',
						project: 'Project',
						issueType: 'IssueType',
						description: 'Description',
						assignee: 'Assignee',
						reporter: 'Reporter',
						tag: 'Tag',
						portalTag: 'Tag info portal',
						creation: 'Creation date',
						due: 'Due',
						hint: 'Issue name',
						today: 'Today',
						time: 'Time',
					},
					timeIsToShort: 'Time is lower than 1 minute',
					saveTimeError: 'Serwer error.',
					timeAdded: 'Work log has been added',
					commentLabel: 'Comment',
					assignMe: 'Assign Myself',
					searchPlaceHolder: 'Search',
					addAttachment: 'Add Attachment',
					issueForm: 'Issue form',
				},
			},
		},
	},
};

export const initialWorklog: Partial<IWorkLog> = {
	Description: '',
	Minutes: 0,
	DateTimeFrom: new Date(),
};
