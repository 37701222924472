import _ from 'lodash';
import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EFieldDefinitionType, IDictionaryItem, ITag } from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { VisibilityControl } from '@skillandchill/tasker-widgets2';

import { setLocalValues } from '@/store/actions/checkListValueActions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';

import { ModalRecord } from 'view/IssueModalEditor/ModalRecord';
import { DateField } from 'view/IssueModalEditor/Tabs/General/IssueForm/IssueFormSectionWithCollapse/FieldDefinition/DateField';
import { DictionaryItem } from 'view/IssueModalEditor/Tabs/General/IssueForm/IssueFormSectionWithCollapse/FieldDefinition/DictionaryItem';
import { DictionaryItems } from 'view/IssueModalEditor/Tabs/General/IssueForm/IssueFormSectionWithCollapse/FieldDefinition/DictionaryItems';

import { BooleanValueField } from './BooleanValueField';
import { Props } from './model';
import { MultiTag } from './MultiTag';
import { Resources } from './resources';
import { useStyles } from './styles';
import { Tag } from './Tag';
import { TextField } from './TextField';
import { getCheckListValue, getDefaultValue } from './utils';

export const FieldDefinition = (props: Props): JSX.Element => {
	const { issueFormSectionToIssueField, zIndex, subKey } = props;
	const dispatch: Dispatch = useDispatch();
	const classes = useStyles();

	const values: { [x: number]: ICheckListValue[] } = useSelector(
		(state: IReducerState) => state?.DashBoard?.EndOfWork.values
	);

	const onChangeTextField = (x: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			ValueText: x?.target?.value,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onChangeIntegerField = (
		x: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		fieldDefinitionId: number
	) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		let valueInteger: Partial<ICheckListValue> = {};

		const mappedValue = Number.parseInt(x?.target?.value);
		if (!Number.isNaN(mappedValue)) valueInteger = { ValueInteger: mappedValue };

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			...valueInteger,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onChangeDecimalField = (
		x: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		fieldDefinitionId: number
	) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		let valueDecimal: Partial<ICheckListValue> = {};

		const mappedValue = Number.parseFloat(x?.target?.value);
		if (!Number.isNaN(mappedValue)) valueDecimal = { ValueDecimal: mappedValue };

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			...valueDecimal,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onChangeBoolean = (x: boolean, fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			ValueBoolean: x ?? false,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onChangeDate = (x: Date, fieldDefinitionId: number, isDateTime = false) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			ValueDate: x ?? false,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onDictionaryChange = (x: Partial<IDictionaryItem>, fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			ValueDictionaryItemId: x?.Id,
			ValueDictionaryItem: x as IDictionaryItem,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onTagsChange = (x: Partial<ITag>[], fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue[] = x?.map(y => {
			return {
				...value,
				FieldDefinitionId: fieldDefinitionId,
				ValueTag: y,
				ValueTagId: y?.Id,
			} as ICheckListValue;
		});

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: checkListValue,
			})
		);
	};

	const onTagChange = (x: Partial<ITag>, fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue = {
			...value,
			FieldDefinitionId: fieldDefinitionId,
			ValueTag: x as ITag,
			ValueTagId: x?.Id,
		};

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: [checkListValue],
			})
		);
	};

	const onDictionariesChange = (x: Partial<IDictionaryItem>[], fieldDefinitionId: number) => {
		const value = getCheckListValue(values, fieldDefinitionId);

		const checkListValue: ICheckListValue[] = x?.map(y => {
			return {
				...y,
				...value,
				FieldDefinitionId: fieldDefinitionId,
				ValueDictionaryItem: y,
				ValueDictionaryItemId: y?.Id,
			} as ICheckListValue;
		});

		dispatch(
			setLocalValues({
				...values,
				[fieldDefinitionId]: checkListValue,
			})
		);
	};

	const getComponent = () => {
		const attributeDefinitionId: number = issueFormSectionToIssueField?.AttributeDefinitionId as number;
		const fieldValues = values[attributeDefinitionId];
		const fieldValue = _.first(fieldValues);

		const label = issueFormSectionToIssueField?.AttributeDefinition?.Name ?? Resources.GlobalResources.empty;
		const fieldDefinition = issueFormSectionToIssueField?.AttributeDefinition;

		switch (issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId) {
			case EFieldDefinitionType.Text:
			case EFieldDefinitionType.Decimal:
			case EFieldDefinitionType.Integer: {
				const values = getDefaultValue(issueFormSectionToIssueField);
				let onChange = onChangeTextField;
				const typeId = issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId;
				if (typeId == EFieldDefinitionType.Text) onChange = onChangeTextField;
				else if (typeId == EFieldDefinitionType.Integer) onChange = onChangeIntegerField;
				else if (typeId == EFieldDefinitionType.Decimal) onChange = onChangeDecimalField;

				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.textField)}`}
					>
						<TextField
							type={values?.type}
							defaultValue={values?.value}
							onChange={e => onChange(e, attributeDefinitionId)}
							isInt={typeId === EFieldDefinitionType.Integer}
						/>
					</ModalRecord>
				);
			}
			case EFieldDefinitionType.Boolean: {
				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.boolField)}`}
					>
						<BooleanValueField
							onChange={e => onChangeBoolean(e, attributeDefinitionId)}
							defaultValue={
								fieldValue?.ValueBoolean ?? issueFormSectionToIssueField?.DefaultValueBoolean ?? false
							}
						/>
					</ModalRecord>
				);
			}
			case EFieldDefinitionType.Dictionary: {
				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.dictionaryField)}`}
					>
						<VisibilityControl
							condition={!fieldDefinition?.IsMultiValue}
							alternative={
								<DictionaryItems
									items={fieldDefinition?.Dictionary?.DictionaryItem as IDictionaryItem[]}
									value={fieldValues?.map(
										(x: ICheckListValue) => x?.ValueDictionaryItem as IDictionaryItem
									)}
									onChange={x => onDictionariesChange(x, attributeDefinitionId)}
								/>
							}
						>
							<DictionaryItem
								onChange={x => onDictionaryChange(x, attributeDefinitionId)}
								items={fieldDefinition?.Dictionary?.DictionaryItem as IDictionaryItem[]}
								value={fieldValue?.ValueDictionaryItem as IDictionaryItem}
							/>
						</VisibilityControl>
					</ModalRecord>
				);
			}
			case EFieldDefinitionType.TagGroup: {
				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.tagField)}`}
					>
						<VisibilityControl
							condition={!fieldDefinition?.IsMultiValue}
							alternative={
								<MultiTag
									items={fieldDefinition?.TagGroup?.Tag as Partial<ITag>[]}
									value={fieldValues?.map((x: ICheckListValue) => x?.ValueTag as ITag)}
									onChange={x => onTagsChange(x, attributeDefinitionId)}
								/>
							}
						>
							<Tag
								onChange={x => onTagChange(x, attributeDefinitionId)}
								items={fieldDefinition?.TagGroup?.Tag as Partial<ITag>[]}
								value={fieldValue?.ValueTag}
							/>
						</VisibilityControl>
					</ModalRecord>
				);
			}
			case EFieldDefinitionType.DateTime: {
				const isDateTime = true;
				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.dateTimeField)}`}
					>
						<DateField
							isDateTime={isDateTime}
							onChange={e => onChangeDate(e as Date, attributeDefinitionId, isDateTime)}
							value={
								(fieldValue?.ValueDateTime ??
									issueFormSectionToIssueField?.DefaultValueDateTime ??
									new Date()) as Date
							}
						/>
					</ModalRecord>
				);
			}
			case EFieldDefinitionType.Date: {
				const isDateTime = false;
				return (
					<ModalRecord
						label={label}
						zIndex={zIndex}
						key={`${subKey}${Resources.key(fieldDefinition?.Id ?? -5, Resources.dateField)}`}
					>
						<DateField
							isDateTime={isDateTime}
							onChange={e => onChangeDate(e as Date, attributeDefinitionId, isDateTime)}
							value={
								(fieldValue?.ValueDate ??
									issueFormSectionToIssueField?.DefaultValueDate ??
									new Date()) as Date
							}
						/>
					</ModalRecord>
				);
			}
		}
	};

	return <div className={classes.root}>{getComponent()}</div>;
};

export default FieldDefinition;
