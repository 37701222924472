import { Translation as Buttons } from './Filters/Options/Buttons/resources';
import { Translation as Options } from './Filters/Options/resources';
import { Translation as Status } from './Filters/Options/Stataus/resources';
import { Translation as Filters } from './Filters/resources';
import { Translation as Tags } from './Filters/Tags/resources';
import { Translation as Tag } from './Filters/Tags/Tag/resources';
import { Translation as PortalList } from './resources';

export const PortalListTranslationEN = [PortalList.en, Filters.en, Options.en, Status.en, Buttons.en, Tags.en, Tag.en];
export const PortalListTranslationPL = [PortalList.pl, Filters.pl, Options.pl, Status.pl, Buttons.pl, Tags.pl, Tags.pl];
