import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Gant: {
			Toolbar: {
				Header: {
					today: 'Dziś',
					date: 'Data',
					addEvent: 'Dodaj nowy czas pracy',
					selectDate: 'Wybierz datę',
				},
			},
		},
	},
	en: {
		Gant: {
			Toolbar: {
				Header: {
					today: 'Today',
					date: 'Data',
					addEvent: 'Add work log',
					selectDate: 'Select date',
				},
			},
		},
	},
};
