import clsx from 'clsx';

import { Avatar, ListItemText } from '@mui/material';

import { IIssue } from '@skillandchill/tasker-types';
import { TooltipTextBase } from '@skillandchill/tasker-widgets2';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const MultiIssueInfo = (props: Props): JSX.Element => {
	const { issues } = props;
	const classes = useStyles();

	return (
		<>
			{issues?.map((issue: IIssue) => (
				<>
					<div className={classes.avatarContainer}>
						<Avatar className={classes.avatar} src={issue?.Title}>
							{issue?.Title}
						</Avatar>
					</div>
					<ListItemText
						primary={
							<TooltipTextBase
								className={clsx(classes?.title, classes.overflowHidden)}
								text={`#${issue?.DisplayId} ${issue?.Title}`}
							/>
						}
						primaryTypographyProps={{ variant: Resources.GlobalResources.body1 }}
					/>
				</>
			))}
		</>
	);
};
