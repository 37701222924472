import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed',
};

export const Translation = {
	pl: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					RightSideIssueData: {
						ExtraIssueInfo: {
							creator: 'Twórca',
							time: 'Twój czas pracy',
							creationDate: 'Data stworzenia',
							assigned: 'Osoby wykonujące',
							observer: 'Obserwatorzy',
							reporter: 'Osoby zgłaszające',
							auditor: 'Osoby weryfikujące',
							projectChangeTooltip:
								'Zmiana projektu spowoduje zrestowanie wartośći w: typie formularza zgłoszeniowego oraz we właściowościach zgłoszenia',
							issueTypeChangeTooltip:
								'Zmiana typu zgłoszenia spowoduje zrestowanie wartośći we właściowościach zgłoszenia',
							issueType: 'Typ zgłoszenia',
							project: 'Projekt',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModelNew: {
			IssueModalContent: {
				IssueDetailsPage: {
					RightSideIssueData: {
						ExtraIssueInfo: {
							creator: 'Creator',
							time: 'Your working time',
							creationDate: 'Creation Date',
							assigned: 'Assigned',
							observer: 'Observer',
							reporter: 'Reporter',
							auditor: 'Auditor',
							projectChangeTooltip:
								'Changing project will reset the values in: issue type and in the properties of the issue',
							issueTypeChangeTooltip:
								'Changing issue type will reset the values in properties of the issue',
							issueType: 'Issue type',
							project: 'Project',
						},
					},
				},
			},
		},
	},
};
