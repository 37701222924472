import { colors, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		addFile: {
			display: 'none',
		},
		w1: {
			width: '20%',
			color: theme.palette.text.primary,
		},
		w2: {
			width: '10%',
			color: theme.palette.text.primary,
		},
		noAttachments: {
			padding: theme.spacing(1),
			justifyContent: 'center',
		},
		attachmentsTile: {
			margin: theme.spacing(2, 0),
		},
		button: {
			padding: theme.spacing(2),
			placeContent: 'left',
			'&:hover': {
				transitionDuration: '0.15s',
				webkitBoxShadow: '0px 0px 10px 0px rgba(179,179,179,0.30)',
				boxShadow: '0px 0px 10px 0px rgba(179,179,179,0.30)',
			},
		},
		transformBadge: {
			margin: theme.spacing(0, 0, 0, 1),
			transform: 'rotate(30deg)',
		},
		badge: {
			backgroundColor: 'white',
			color: colors.grey[600],
			border: `1px solid ${colors.grey['900']}`,
		},
		noBold: {
			fontWeight: 'normal',
		},

		tableHead: {
			backgroundColor: theme.palette.background.paper,
		},

		headButton: {
			padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
			minWidth: '30px',
			justifyContent: 'center',
		},
	}),
	{ name: `IssueModalEditor_Attachment` }
);
