import { EBudgetObject, IBudget, ICurrency } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import {
	extendList,
	removeBudget,
	removeIncomeLine,
	removeIncomeLineForBudget,
	removeLinePeriodical,
	removeLinePeriodicalByBudget,
	removeOutcomeLine,
	removeOutcomeLineForBudget,
	updateBudgetById,
	updateOrAddBudgetById,
} from '@/store/reducers/utils/budgetUtils';
import { ObjectAction } from '@/store/types';

const State = {
	didChange: 0,
	budgetTree: [] as IBudget[],
	budget: {} as IBudget,
	options: {
		currency: [] as ICurrency[],
	},
};
export type IBudgetState = typeof State;

const budgetReducer = (state = State, action: ObjectAction): IBudgetState => {
	switch (action.type) {
		case actionTypes.BUDGET_GET_BY_CUSTOMER_ID_TREE:
		case actionTypes.BUDGET_GET_BY_PROJECT_ID_TREE:
		case actionTypes.BUDGET_GET_BY_ISSUE_ID_TREE:
		case actionTypes.BUDGET_GET_TENANT: {
			return {
				...state,
				budgetTree: action?.data,
				didChange: state?.didChange + 1,
			};
		}
		case actionTypes.BUDGET_GET_BY_ID_TREE: {
			const temp = extendList(state?.budgetTree, action?.data?.Id, action?.data);
			return {
				...state,
				budgetTree: temp,
				didChange: state?.didChange + 1,
			};
		}
		case actionTypes.MOVE_BUDGET: {
			return {
				...state,
				budgetTree: updateBudgetById(state?.budgetTree, [
					action?.data?.Additional,
					action?.data?.Prev,
					action?.data?.Curr,
				]),
				didChange: state?.didChange + 1,
			};
		}
		case actionTypes.DID_CHANGE_BUDGET: {
			return {
				...state,
				didChange: state?.didChange + 1,
			};
		}
		case actionTypes.DELETE_BUDGET_OPTION: {
			let tree = state?.budgetTree;
			switch (action?.data?.option) {
				case EBudgetObject.Budget: {
					tree = removeBudget(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.Income: {
					tree = removeIncomeLine(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.Outcome: {
					tree = removeOutcomeLine(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.IncomeList: {
					tree = removeIncomeLineForBudget(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.OutcomeList: {
					tree = removeOutcomeLineForBudget(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.LinePeriodical: {
					tree = removeLinePeriodical(tree, action?.data?.id);
					break;
				}
				case EBudgetObject.LinePeriodicalList: {
					tree = removeLinePeriodicalByBudget(tree, action?.data?.id);
					break;
				}
			}
			return {
				...state,
				budgetTree: tree,
				didChange: state?.didChange + 1,
			};
		}
		case actionTypes.FETCH_ALL_CURRENCIES: {
			return {
				...state,
				options: {
					...state?.options,
					currency: action.currencies,
				},
			};
		}
		case actionTypes.BUDGET_SAVE: {
			const tree = updateOrAddBudgetById(state?.budgetTree, action?.data, action?.data?.Id);

			return {
				...state,
				budgetTree: tree,
			};
		}
		case actionTypes.OUTCOME_LINE_ADD:
		case actionTypes.INCOME_LINE_ADD: {
			return {
				...state,
				budgetTree: extendList(state?.budgetTree, action?.data?.Id, action?.data),
			};
		}
		case actionTypes.OUTCOME_LINE_UPDATE:
		case actionTypes.INCOME_LINE_UPDATE: {
			return {
				...state,
				budgetTree: updateBudgetById(state?.budgetTree, [action?.data]),
			};
		}
		default: {
			return state;
		}
	}
};

export default budgetReducer;
