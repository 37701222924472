import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { Resources as subResources } from '../../resources';

export const Resources = {
	...GlobalResources,

	generalTab: subResources.generalTab,
	reckonTab: subResources.reckonTab,
	formTab: subResources.formTab,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			ModalActions: {
				TabButtons: {
					general: 'Ogólne',
					reckon: 'Rozliczenia',
					formName: 'Formularz zgłoszeniowy',
					children: 'Dzieci',
					parent: 'Rodzice',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			ModalActions: {
				TabButtons: {
					general: 'General',
					reckon: 'Reckon',
					formName: 'Issue form',
					children: 'Children',
					parent: 'Parent',
				},
			},
		},
	},
};
