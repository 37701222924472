import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			starIcon: {
				fontSize: '30px',
			},

			starIconButton: {
				marginRight: theme.spacing(1),

				'&:hover': {
					color: '#ffc31f',
				},
			},

			favouriteIssue: {
				color: '#ffc31f',
			},

			icon: {
				padding: theme.spacing(1),
				fontSize: theme.spacing(5),
				color: theme.palette.text.primary,
			},

			container: {
				height: '400px',
			},

			content: {
				width: '400px',
				maxHeight: '100%',
				padding: theme.spacing(1),
				overflowY: 'scroll',
			},

			arrowIcon: {
				marginLeft: theme.spacing(0),
			},

			button: {
				// padding: theme.spacing(0),
				padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)}`,
				display: 'flex',
				justifyContent: 'flex-start',
				textDecoration: 'none',
				textTransform: 'none',
				// padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
			},

			menu: {
				borderRight: `1px solid ${theme.palette.grey[300]}`,
				marginRight: theme.spacing(2),
				alignItems: 'stretch',
				// paddingRight: theme.spacing(3),
				minHeight: '400px',
				width: '200px',
			},

			boldMenuItem: {
				fontWeight: 600,
				fontSize: '15px',
			},

			archivMenuItem: {
				alignItems: 'flex-start',
			},

			subText: {
				textAlign: 'left',
			},
		};
	},
	{ name: `IssueModalEditor` }
);
