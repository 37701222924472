import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	mousedown: 'mousedown' as const,
	onBlur: 'onBlur',
	menuPortalTarget: 'menuPortalTarget',
	menuIsOpen: 'menuIsOpen',
	openMenuOnClick: 'openMenuOnClick',
	body: 'body',
	ref: 'ref',
	uuid: (id: number) => `ProjectRoles_${id}_${uuid()}`,
};

export const multiValueStyles = {
	fontWeight: '600',
	borderRadius: '7px',
};

export const multiValueRemove = {
	color: 'white',
	cursor: 'pointer',
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						1: 'Menadżer projektu',
						2: 'Członek projektu',
						3: 'Właściciel projektu',
						4: 'Obserwator',
						5: 'Audytor',
						6: 'Użytkownik portalu',
						7: 'Menadżer portalu',
					},
				},
				pageTitle: 'Członkowie projektu',
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				CommonCells: {
					UserProjectRoles: {
						1: 'Project manager',
						2: 'Project member',
						3: 'Project owner',
						4: 'Observer',
						5: 'Auditor',
						6: 'Portal user',
						7: 'Portal manager',
					},
				},
				pageTitle: 'Project users',
			},
		},
	},
};
