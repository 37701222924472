import { AsyncAction } from '@skillchill/redux-promisify';

import { Issue, Modifiable } from '@skillandchill/tasker-widgets2/dist/utils/types';
import { Tag, TagGroup } from '@skillandchill/tasker-widgets2/dist/utils/types/tag';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export interface IIssueAdditionalProperties extends Modifiable {
	groupContextLinkId: number;
	issueId: number;
	tagId: number;
	groupContextLink?: TagGroup;
	issue?: Issue;
	tag?: Tag;
}

export const ISSUE_ADDITIONAL_PROPERTIES_GET: ActionType = 'ISSUE_ADDITIONAL_PROPERTIES_GET';

function _getIssueAdditionalProperties(issueAdditionalProperties: IIssueAdditionalProperties[]): Action {
	return {
		type: ISSUE_ADDITIONAL_PROPERTIES_GET,
		issueAdditionalProperties,
	};
}

export function putIssueAdditionalProperties(
	issueAdditionalProperties: Partial<IIssueAdditionalProperties>[],
	issueId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(getIssueAdditionalProperties(issueId)));
		return axios().post(`/issue/properties/${issueId}`, issueAdditionalProperties);
	};
}

export function getIssueAdditionalProperties(issueId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(response => dispatch(_getIssueAdditionalProperties(mapProperties((response as IJson).data))));
		return axios().get(`issue/properties/${issueId}`);
	};
}

interface IData {
	IssueAdditionalProperties: IIssueAdditionalProperties;
	tag: Tag;
}

export function mapProperties(data: IData[]): IIssueAdditionalProperties[] {
	return data.map(mapProperty);
}

export const mapProperty = (data: IData): IIssueAdditionalProperties => {
	return data
		? {
				...data.IssueAdditionalProperties,
				tag: data.tag,
		  }
		: ({} as IIssueAdditionalProperties);
};
