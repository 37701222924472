import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						SingleUser: {
							ActionsCell: {
								delete: 'Usuń z listy',
								addToAddList: 'Dodaj do listy do dodania',
							},
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			ProjectMembersConfiguration: {
				AddUsersToProjectPopupContent: {
					ProjectRolesSelection: {
						SingleUser: {
							ActionsCell: {
								delete: 'Delete',
								addToAddList: 'Add to add list',
							},
						},
					},
				},
			},
		},
	},
};
