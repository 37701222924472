import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		selectTextFieldContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		createMapButtonWrapper: {
			display: 'flex',
			marginLeft: '5px',
			color: 'red',
		},
		createMapButton: {
			cursor: 'pointer',
			color: '#33b35c',
		},
	}),
	{ name: `IssueModalEditor_Tabs_General_IssueForm_IssueFormSectionWithCollapse_FieldDefinition_Tag` }
);
