import { IVersion } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/version';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	versions: [] as IVersion[],
};

export type IVersionState = typeof State;

const versionReducer = (state = State, action: ObjectAction): IVersionState => {
	switch (action.type) {
		case actionTypes.GET_VERSIONS: {
			return {
				...state,
				versions: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default versionReducer;
