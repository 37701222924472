import { Translation as DateField } from 'views/IssueModalEditor/Tabs/General/IssueForm/IssueFormSectionWithCollapse/FieldDefinition/DateField/resources';
import { Translation as Members } from 'views/IssueModalEditor/Tabs/General/Members/resources';
import { Translation as General } from 'views/IssueModalEditor/Tabs/General/resources';
import { Translation as Reckon } from 'views/IssueModalEditor/Tabs/Reckon/resources';
import { Translation as Relation } from 'views/IssueModalEditor/Tabs/Relation/resources';

import { Translation as Attachment } from './Attachment/resources';
import { Translation as Footer } from './CommendCard/Footer/resources';
import { Translation as Header } from './CommendCard/Header/resources';
import { Translation as CommentCard } from './CommendCard/resources';
import { Translation as Comments } from './Comments/resources';
import { Translation as ActionButtons } from './ModalActions/ActionButtons/resources';
import { Translation as ModalActions } from './ModalActions/resources';
import { Translation as TabButtons } from './ModalActions/TabButtons/resources';
import { Translation as CreateUrlResources } from './ModalTitle/CreateUrl/resources';
import { Translation as ModalTitle } from './ModalTitle/resources';
import { Translation as IssueModalEditor } from './resources';
import { Translation as IssueModalEditorTabsGeneralIssueFormIssueFormSectionWithCollapse } from './Tabs/General/IssueForm/IssueFormSectionWithCollapse/resources';

export const IssueModalEditorTranslationEN = [
	Attachment.en,
	IssueModalEditor.en,
	Footer.en,
	Header.en,
	Comments.en,
	CommentCard.en,
	ActionButtons.en,
	ModalActions.en,
	TabButtons.en,
	ModalTitle.en,
	Members.en,
	DateField.en,
	General.en,
	Relation.en,
	Reckon.en,
	IssueModalEditorTabsGeneralIssueFormIssueFormSectionWithCollapse.en,
	CreateUrlResources.en,
];

export const IssueModalEditorTranslationPL = [
	Attachment.pl,
	IssueModalEditor.pl,
	Footer.pl,
	Header.pl,
	Comments.pl,
	CommentCard.pl,
	ActionButtons.pl,
	ModalActions.pl,
	TabButtons.pl,
	ModalTitle.pl,
	Members.pl,
	DateField.pl,
	General.pl,
	Relation.pl,
	Reckon.pl,
	IssueModalEditorTabsGeneralIssueFormIssueFormSectionWithCollapse.pl,
	CreateUrlResources.pl,
];
