import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	postRequestReset: '/auth/recover',
};

export const Translation = {
	pl: {
		RecoverPassword: {
			onCompletePostRequestReset: 'Email z zrestartowaniem hasła został wysłany',
			onCatchPostRequestReset: 'Wysąpił błąd proszę spróbować później',
			pageTitle: 'Zapomniałem hasła',
			resetPasswordTitle: 'Zresetuj hasło',
			resetRequest: 'Zresetuj zapytanie',
			textFieldLabel: 'Mail',
		},
	},
	en: {
		RecoverPassword: {
			onCompletePostRequestReset: 'An email with a reset link was sent.',
			onCatchPostRequestReset: 'An error occured. Try again later.',
			pageTitle: 'Forgot password',
			resetPasswordTitle: 'Reset password',
			resetRequest: 'Request reset',
			textFieldLabel: 'Email',
		},
	},
};
