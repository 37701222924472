import React from 'react';

import { Switch } from '@mui/material';

import { Props } from './model';
import { Resources } from './resources';

export const BooleanValueField = (props: Props): JSX.Element => {
	const { defaultValue, onChange } = props;

	return (
		<Switch
			defaultChecked={defaultValue}
			size={Resources.GlobalResources.small}
			color={Resources.GlobalResources.primary}
			onChange={(event: React.ChangeEvent<HTMLInputElement>, value: boolean) => onChange(value)}
		/>
	);
};
