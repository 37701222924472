import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					links: 'Linki',
					archive: 'Archiwizacja',
					archiveDescription: 'Zakładka dostępna z poziomu ekranu kanbanów.',
					deleteIssue: 'Usuń zgłoszenie',
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					links: 'Links',
					archive: 'Archive',
					archiveDescription: 'Tab available from the kanban screen.',
					deleteIssue: 'Delete issue',
				},
			},
		},
	},
};
