import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

import { Props } from './model';
import { Resources } from './resources';

export const Delete = (props: Props): JSX.Element => {
	const { relationId, onDelete, issueId } = props;

	return (
		<IconButton
			size={Resources.GlobalResources.small}
			color={Resources.GlobalResources.default}
			onClick={() => onDelete(issueId, relationId)}
		>
			<DeleteIcon fontSize={Resources.GlobalResources.small} />
		</IconButton>
	);
};
