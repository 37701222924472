import { Skeleton } from '@mui/material';

import { Resources } from './resources';
import { useStyles } from './styles';

export const NotificationSkeletonCard = () => {
	const classes = useStyles();

	return (
		<div className={classes.card}>
			<Skeleton className={classes.avatarSkeleton} variant={Resources.rectangular} width={36} height={36} />
			<div className={classes.textContainer}>
				<Skeleton variant={Resources.text} width={170} height={20} />
				<Skeleton variant={Resources.text} width={320} height={23} />
				<Skeleton className={classes.detailsSkeleton} variant={Resources.text} width={250} height={23} />
			</div>
		</div>
	);
};
