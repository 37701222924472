export const Translation = {
	pl: {
		Activities: {
			pageTitle: 'Aktywności',
		},
	},
	en: {
		Activities: {
			pageTitle: 'Activities',
		},
	},
};
