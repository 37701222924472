import { colors, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { getTextColor } from '@/utils/color';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),
			addFile: {
				display: 'none',
			},

			attachmentName: {
				width: 'auto',
			},

			attachmentOperations: {
				width: '10%',
			},

			w1: {
				width: 'auto',
			},
			w2: {
				width: '10%',
			},
			noAttachments: {
				padding: theme.spacing(1),
				justifyContent: 'center',
			},
			attachmentsTile: {
				margin: theme.spacing(2, 0),
			},
			button: {
				padding: theme.spacing(2),
				placeContent: 'left',
				'&:hover': {
					transitionDuration: '0.15s',
					webkitBoxShadow: '0px 0px 10px 0px rgba(179,179,179,0.30)',
					boxShadow: '0px 0px 10px 0px rgba(179,179,179,0.30)',
				},
			},
			transformBadge: {
				margin: theme.spacing(0, 0, 0, 1),
				transform: 'rotate(30deg)',
			},
			badge: {
				backgroundColor: 'white',
				color: colors.grey[600],
				border: `1px solid ${colors.grey['900']}`,
			},
			noBold: {
				fontWeight: 'normal',
			},
			text: {
				color: getTextColor(theme),
			},
		};
	},
	{ name: `IssueModalEditor_Attachment` }
);
