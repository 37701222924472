import clsx from 'clsx';
import React, { useState } from 'react';

import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { Card, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import { collapseEndListener } from '@/utils/utils';

import { FieldDefinition } from './FieldDefinition';
import { IssueFormSection } from './IssueFormSection';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueFormSectionWithCollapse = (props: Props): JSX.Element => {
	const { issueFormSection, issueFieldDetails, zIndex, subKey } = props;
	const classes = useStyles();

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	const headerRendererLastName = (): JSX.Element => {
		if (!isCollapsed) return <ArrowDropDownRoundedIcon fontSize={Resources.GlobalResources.large} />;
		else if (isCollapsed) return <ArrowDropUpRoundedIcon fontSize={Resources.GlobalResources.large} />;
		else return <></>;
	};

	return (
		<Card className={classes.card}>
			<div
				className={clsx(classes.flexRow, classes.alignCenter, classes.marginLeft)}
				onClick={() => setIsCollapsed(!isCollapsed)}
			>
				{headerRendererLastName()}
				<Typography className={classes.flexOne} style={{ fontSize: 32 }}>
					{issueFormSection?.Name}
				</Typography>
			</div>

			<Collapse in={!isCollapsed} className={classes.marginLeft} addEndListener={collapseEndListener}>
				{issueFormSection?.IssueFormSectionToIssueField?.map((x, index) => {
					return (
						<FieldDefinition
							issueFormSectionToIssueField={x}
							key={`${subKey}${Resources.fieldDefinition}${x?.Id}`}
							subKey={`${subKey}${Resources.fieldDefinition}${x?.Id}`}
							zIndex={zIndex * 100 - index}
						/>
					);
				})}
				{issueFormSection?.IssueFormSection?.map(x => {
					return (
						<IssueFormSection
							issueFormSection={x}
							issueFieldDetails={issueFieldDetails}
							key={`${subKey}${Resources.key(x?.Id ?? -3)}`}
							subKey={`${subKey}${Resources.key(x?.Id ?? -3)}`}
							zIndex={zIndex - (issueFormSection?.IssueFormSectionToIssueField?.length ?? 0) - 1}
							level={1}
						/>
					);
				})}
			</Collapse>
		</Card>
	);
};
