import {
	EDictionaryItemType,
	EFieldDefinitionType,
	IDictionaryItem,
	IFieldDefinition,
	IFieldValue,
	IIssueKanbanDetails,
	ITag,
} from '@skillandchill/tasker-types';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';

import { Resources } from './resources';

export const getDefaultValue = (
	issueFormSectionToIssueField: IIssueFormSectionToIssueField
): { field: IFieldValue | undefined; value: string | number | undefined; type: string } => {
	const parameterNames = getParameterName(
		issueFormSectionToIssueField?.AttributeDefinition?.FieldDefinitionTypeId as EFieldDefinitionType
	);
	const values = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	let firstFieldValue: IFieldValue | undefined = undefined;
	let value;

	if (values && (values?.length ?? 0) > 0) {
		firstFieldValue = values[0];
		const subValue = values[0];
		if (subValue) value = subValue[parameterNames?.value as keyof IFieldValue];
	} else if (
		issueFormSectionToIssueField &&
		issueFormSectionToIssueField[parameterNames?.defaultValue as keyof IIssueFormSectionToIssueField]
	)
		value = issueFormSectionToIssueField?.[parameterNames?.defaultValue as keyof IIssueFormSectionToIssueField];
	else value = undefined;

	return {
		field: firstFieldValue,
		value: value as string | number | undefined,
		type: parameterNames?.type as string,
	};
};

const getParameterName = (
	type: EFieldDefinitionType
): { value?: keyof IFieldValue; defaultValue?: keyof IIssueFormSectionToIssueField; type?: string } => {
	switch (type) {
		case EFieldDefinitionType.Integer: {
			return {
				defaultValue: Resources.defaultValue.integer,
				value: Resources.value.integer,
				type: Resources.number,
			};
		}
		case EFieldDefinitionType.Text: {
			return {
				defaultValue: Resources.defaultValue.text,
				value: Resources.value.text,
				type: Resources.string,
			};
		}
		case EFieldDefinitionType.Decimal: {
			return {
				defaultValue: Resources.defaultValue.decimal,
				value: Resources.value.decimal,
				type: Resources.number,
			};
		}
	}
	return {};
};

export const changeDateInField = (fieldValue: IFieldValue, isDateTime: boolean, date: Date): IFieldValue => {
	return {
		...fieldValue,
		ValueDate: isDateTime ? fieldValue?.ValueDateTime : date,
		ValueDateTime: isDateTime ? date : fieldValue?.ValueDateTime,
	};
};

export const changeTagInField = (
	fieldValue: IFieldValue,
	tag: Partial<ITag>,
	fieldDefinition?: IFieldDefinition
): IFieldValue => {
	return {
		...fieldValue,
		ValueTagId: tag?.Id,
		AttributeDefinition: fieldDefinition,
	};
};

export const addTagInField = (
	attributeDefinitionId: number,
	tag: Partial<ITag>,
	fieldValue: Partial<IFieldValue>,
	fieldDefinition?: IFieldDefinition
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinitionId,
		AttributeDefinition: fieldDefinition,
		ValueTagId: tag?.Id,
		Id: fieldValue?.Id,
	};
};

export const addTagsInField = (
	attributeDefinitionId: number,
	tags: Partial<ITag>[],
	fieldValue: Partial<IFieldValue>
): Partial<IFieldValue>[] => {
	return tags?.map(x => addTagInField(attributeDefinitionId, x, fieldValue));
};

export const addDictionaryInField = (
	attributeDefinition: IFieldDefinition,
	dictionary: Partial<IDictionaryItem>,
	fieldValue?: Partial<IFieldValue>
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinition?.Id,
		AttributeDefinition: attributeDefinition,
		ValueDictionaryItemId: dictionary?.Id,
	};
};

export const addDictionariesInField = (
	attributeDefinition: IFieldDefinition,
	tags: Partial<IDictionaryItem>[]
): Partial<IFieldValue>[] => {
	return tags?.map(x => addDictionaryInField(attributeDefinition, x));
};

export const addDateInField = (
	attributeDefinitionId: number,
	isDateTime: boolean,
	date: Date,
	fieldValue?: Partial<IFieldValue>
): Partial<IFieldValue> => {
	return {
		...fieldValue,
		AttributeDefinitionId: attributeDefinitionId,
		ValueDate: isDateTime ? undefined : date,
		ValueDateTime: isDateTime ? date : undefined,
	};
};

export const getNameDictionary = (item: Partial<IDictionaryItem>): string => {
	switch (item?.DictionaryItemTypeId) {
		case EDictionaryItemType.Text: {
			return item?.ValueText ?? '';
		}
		case EDictionaryItemType.Boolean: {
			return item?.ValueBoolean?.toString() ?? '';
		}
		case EDictionaryItemType.Date: {
			return item?.ValueDate?.toString() ?? '';
		}
		case EDictionaryItemType.DateTime: {
			return item?.ValueDateTime?.toString() ?? '';
		}
		case EDictionaryItemType.Decimal: {
			return item?.ValueDecimal?.toString() ?? '';
		}
		case EDictionaryItemType.Dictionary: {
			return item?.ValueDictionary?.Name ?? '';
		}
		case EDictionaryItemType.Integer: {
			return item?.ValueInteger?.toString() ?? '';
		}
		case EDictionaryItemType.TagGroup: {
			return item?.ValueTagGroup?.Label ?? '';
		}
		default: {
			return '--';
		}
	}
};

export const getFieldValues = (
	issueFormSectionToIssueField: IIssueFormSectionToIssueField,
	issueFieldDetails?: IIssueKanbanDetails
): IFieldValue[] | undefined => {
	return (issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? [])?.length > 0
		? issueFormSectionToIssueField?.AttributeDefinition?.FieldValue
		: ([
				{
					ValueTag: issueFieldDetails?.mappedTags?.find(
						mappedTag =>
							mappedTag?.FieldDefinitionId == issueFormSectionToIssueField?.AttributeDefinition?.Id &&
							(issueFieldDetails?.columnTagId ?? 0) > 0 &&
							issueFieldDetails?.columnTagId == mappedTag?.TagId
					)?.FieldDefinitionTag,
				},
		  ] as IFieldValue[]) ?? [];
};
