import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	oneWeek: 7,
	oneMonth: 30,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Shared: {
						active: 'Ważna więcej niż 30 dni',
						expired: 'Wygasła',
						expiresIn: 'Wygaśnie {{daysLeft}}',
						forIndefinitePeriod: 'Na czas nieokreślony',
						startsIn: 'Zacznie obowiązywać {{daysLeft}}',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					Shared: {
						active: 'Expires in more than 30 days',
						expired: 'Expired',
						expiresIn: 'Expires {{daysLeft}}',
						forIndefinitePeriod: 'For indefinite period',
						startsIn: 'Starts {{daysLeft}}',
					},
				},
			},
		},
	},
};
