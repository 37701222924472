import { EUrlType } from '@skillandchill/tasker-types';

import { IEnumAsObject } from './model';

const StringIsNumber = (value: any) => isNaN(Number(value)) === false;

const toArray = (enumme: any) => {
	return Object.keys(enumme)
		.filter(StringIsNumber)
		.map(key => enumme[key]);
};

export const getArrayFromUrlType = (): IEnumAsObject[] => {
	const returnData: IEnumAsObject[] = [];
	const data = toArray(EUrlType);
	for (let i = 0; i < data.length; i++) {
		returnData.push({ Key: (EUrlType[data[i]] as unknown) as number, Label: data[i] });
	}
	return returnData;
};
