import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueId: '?issueId=',
	projectId: 'projects/',
	overview: 'overview',
	customers: 'customers/',
	summary: 'summary',
	issuePath: '?issueId=0',
	projectPath: '&projectId=',
	unassignedAvatar: '/favicon.png',
	user: '/user/',
	plus: '+',
	minus: '-',
	csv: {
		headers: () => {
			return [
				{ label: t('IssueList.csv.id'), key: 'id' },
				{ label: t('IssueList.csv.issueName'), key: 'issueName' },
				{ label: t('IssueList.csv.displayId'), key: 'displayId' },
				{ label: t('IssueList.csv.url'), key: 'url' },
				{ label: t('IssueList.csv.type'), key: 'type' },
				{ label: t('IssueList.csv.status'), key: 'status' },
				{ label: t('IssueList.csv.time'), key: 'time' },
			];
		},
		filename: () => t('IssueReport.csv'),
	},
	comma: ';',
	csvStyles: { visibility: 'hidden' } as { visibility: 'hidden' },
};

export const Translation = {
	pl: {
		IssueList: {
			headerType: 'Zgłoszenia',
			addIssue: 'Dodaj zgłoszenie',
			pageTitle: { issueList: 'Lista zgłoszeń', loading: 'Ładowanie' },
			AssigneeAvatars: {
				unassigned: 'Nieprzypisany',
				others: 'Pozostali',
				assignees: 'Osoby wykonujące:',
				observers: 'Obserwatorzy:',
				reporters: 'Osoby zgłaszające:',
				audtiors: 'Osoby zgłaszające:',
				creator: 'Twórca zgłoszenia:',
			},
			AddToFavourites: {
				add: 'Dodaj do ulubionych',
				remove: 'Usuń z ulubionych',
			},
			TileProject: {
				projectName: 'Projekt',
				customerName: 'Klient',
				creationDate: 'Data stworzenia',
			},
			csv: {
				id: 'Id zgłoszenia',
				issueName: 'Nazwa zgłoszenia',
				displayId: 'Klucz zgłoszenia',
				url: 'Url',
				type: 'Typ zgłoszenia',
				status: 'Status',
				time: 'Przepracowany czas',
			},
			reports: 'Pobierz raport',
			accepted: 'Osoba akceptująca',
			waitingForAcceptance: 'Oczekuje na akceptację',
			acceptanceStatus: 'Status akceptacji',
			useTableData: {
				assignees: 'Osoby przypisane',
				name: 'Nazwa zgłoszenia',
				status: 'Status zgłoszenia',
				project: 'Projekt',
				customer: 'Klient',
				creationDate: 'Data utworzenia',
				issueAttachments: 'Załączniki',
			},
		},
	},
	en: {
		IssueList: {
			headerType: 'Issues',
			addIssue: 'Add issue',
			pageTitle: { issueList: 'Issue List', loading: 'Loading' },
			AssigneeAvatars: {
				unassigned: 'Unassigned',
				others: 'Others',
				assignees: 'Assignees:',
				observers: 'Observers:',
				reporters: 'Reporters:',
				audtiors: 'Auditors:',
				creator: 'Creator:',
			},
			AddToFavourites: {
				add: 'Add to favourites',
				remove: 'Remove from favourites',
			},
			TileProject: {
				projectName: 'Project',
				customerName: 'Customer',
				creationDate: 'Creation date',
			},
			csv: {
				id: 'Id',
				issueName: 'Issue name',
				displayId: 'Display id',
				url: 'Url',
				type: 'Issue type',
				status: 'Status',
				time: 'Worked time',
			},
			reports: 'Download report',
			accepted: 'Acceptor',
			waitingForAcceptance: 'Waiting for acceptance',
			acceptanceStatus: 'Acceptance status',
			useTableData: {
				assignees: 'Assignees',
				name: 'Issue name',
				status: 'Issue status',
				project: 'Project',
				customer: 'Customer',
				creationDate: 'Creation date',
				issueAttachments: 'Attachments',
			},
		},
	},
};
