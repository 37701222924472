import { EKeys } from '@skillandchill/tasker-types/dist/types/common/keys';

import { KEY_DID_CHANGE, SESSION_FINISH_LOGIN } from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	key: '' as EKeys,
	actionNumber: 0,
	at: '',
};
export type IKeyState = typeof State;

const keyReducer = (state = State, action: ObjectAction): IKeyState => {
	switch (action.type) {
		case KEY_DID_CHANGE: {
			return {
				...state,
				key: action.key,
				actionNumber: state.actionNumber + 1,
				at: window.location.hash,
			};
		}
		case SESSION_FINISH_LOGIN: {
			return {
				...state,
				key: '' as EKeys,
				actionNumber: 0,
				at: '',
			};
		}
		default: {
			return state;
		}
	}
};

export default keyReducer;
