import { AsyncAction } from '@skillchill/redux-promisify';

import { ITaskerUser } from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';
import { ICreateForm, IFormUpdate } from '@skillandchill/tasker-types/dist/types/endPoints/form';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IFormInEdit } from 'view/OrganizationPanel/TabsContent/EndWorkDay/Details/Form/New/Form/Edit/model';
import { IBodyInEdit } from 'view/OrganizationPanel/TabsContent/EndWorkDay/Details/Form/New/Notification/Components/Body/model';

const basePath = 'Form';

export const FORM_FETCH: ActionType = 'FORM_FETCH';
export const SET_FORM_CREATE: ActionType = 'SET_FORM_CREATE';
export const FORM_FETCH_PAGE: ActionType = 'FORM_FETCH_PAGE';
export const FORM_DELETE_PAGE: ActionType = 'FORM_DELETE_PAGE';
export const FORM_CREATE_PAGE: ActionType = 'FORM_CREATE_PAGE';
export const FORM_UPDATE_PAGE: ActionType = 'FORM_UPDATE_PAGE';
export const SET_FORM_UPDATE: ActionType = 'SET_FORM_UPDATE';
export const SET_FORM_CREATE_TASKER_USERS: ActionType = 'SET_FORM_CREATE_TASKER_USERS';
export const SET_FORM_BODY_UPDATE: ActionType = 'SET_FORM_BODY_UPDATE';
export const SET_FORM_URL_UPDATE: ActionType = 'SET_FORM_URL_UPDATE';
export const SET_FORM_URL_CREATE: ActionType = 'SET_FORM_URL_CREATE';
export const SET_FORM_UPDATE_TASKER_USERS: ActionType = 'SET_FORM_UPDATE_TASKER_USERS';
export const RESET_CHECKLIST: ActionType = 'RESET_CHECKLIST';
export const GET_FORM_VALUES: ActionType = 'GET_FORM_VALUES';

function _fetchForm(data: IForm & { Values?: { [x: number]: ICheckListValue[] } }): Action {
	return {
		type: FORM_FETCH,
		data,
	};
}

function _fetchFormPage(data: IForm[]): Action {
	return {
		type: FORM_FETCH_PAGE,
		data,
	};
}

function _deleteFormPage(data: IForm[]): Action {
	return {
		type: FORM_DELETE_PAGE,
		data,
	};
}

function _createFormPage(data: IForm[]): Action {
	return {
		type: FORM_CREATE_PAGE,
		data,
	};
}

function _updateFormPage(data: IForm[]): Action {
	return {
		type: FORM_UPDATE_PAGE,
		data,
	};
}

function _getFormValues(data: ICheckListValue[]): Action {
	return {
		type: GET_FORM_VALUES,
		data,
	};
}

export function fetch_form(formId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchForm((json as IJson).data)));
		return axios().get(`${basePath}/${formId}`);
	};
}

export function fetch_formByUser(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchForm((json as IJson).data)));
		return axios().get(`${basePath}/byUser`);
	};
}

export function fetch_form_page(from = 0, count = 6): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchFormPage((json as IJson).data)));
		return axios().get(`${basePath}/${from}/${count}`);
	};
}

export function delete_form(formId: number, from = 0, count = 6): AsyncAction {
	if (!formId) throw 'No Id is provided';
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteFormPage((json as IJson).data)));
		return axios().delete(`${basePath}/${formId}/${from}/${count}`);
	};
}

export function create_form(body: Partial<ICreateForm>, from = 0, count = 6): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_createFormPage((json as IJson).data)));
		return axios().post(`${basePath}/${from}/${count}`, body);
	};
}

export function update_form(body: Partial<IFormUpdate>, id: number, from = 0, count = 6): AsyncAction {
	if (!id) throw 'No Id is provided';
	return function(context, dispatch) {
		context.then(json => dispatch(_updateFormPage((json as IJson).data)));
		return axios().patch(`${basePath}/${id}/${from}/${count}`, body);
	};
}

export function setCreateForm(data: ICreateForm, isEdit = false): Action {
	return { type: SET_FORM_CREATE, data: { data, isEdit } };
}

export function setUrlCreateForm(data: string): Action {
	return { type: SET_FORM_URL_CREATE, data };
}

export function setTaskerUsersInNewForm(data: Partial<ITaskerUser>[]): Action {
	return { type: SET_FORM_CREATE_TASKER_USERS, data };
}

export function setTaskerUsersInUpdateForm(data: Partial<ITaskerUser>[]): Action {
	return { type: SET_FORM_UPDATE_TASKER_USERS, data };
}

export function setUpdateForm(data: IFormInEdit): Action {
	return { type: SET_FORM_UPDATE, data };
}

export function setBodyEditForm(data: IBodyInEdit): Action {
	return { type: SET_FORM_BODY_UPDATE, data };
}

export function setUrlEditForm(data: string, isNew = false): Action {
	return { type: SET_FORM_URL_UPDATE, data, isNew };
}

export function resetChecklist(): Action {
	return { type: RESET_CHECKLIST };
}

export function getFormValues(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getFormValues((json as IJson).data)));
		return axios().patch(basePath);
	};
}
