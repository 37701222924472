import clsx from 'clsx';
import { useContext, useMemo } from 'react';

import { Typography } from '@mui/material';

import { EFieldDefinitionType, EInputTypes, IFieldValue } from '@skillandchill/tasker-types';
import { CustomInput } from '@skillandchill/tasker-widgets2';

import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { EIssueModalDetailsPageFields } from '../../../../model';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { FieldValueFactory } from '../utils/fieldValueFactory';
import { useHandleResetError } from '../utils/useHandleResetError';

import { Props } from './model';
import { useStyles } from './styles';

export const TextIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);

	const { setFieldValues, issueErrors, setIssueState, issueState } = useContext(issueModalDataContext);

	const isError = useMemo(() => {
		return (issueErrors.find(issueError => issueError.inputName === EIssueModalDetailsPageFields.properties)
			?.additionalData as number[])?.includes(issueFormSectionToIssueField?.AttributeDefinitionId as number);
	}, [issueErrors]);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;
	const initialFieldValue = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue;
	const initialInputValue = initialFieldValue?.length ? (initialFieldValue[0]?.ValueText as string) : '';

	const inputValue = (issueState?.Properties?.Text ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	// useEffect(() => {
	// 	// if (typeof inputValue === 'undefined') return;
	// 	if (
	// 		Object.keys(issueState?.Properties?.Text ?? {})?.includes(
	// 			String(issueFormSectionToIssueField?.AttributeDefinitionId)
	// 		)
	// 	) {
	// 		handleInputChange(initialInputValue);
	// 	}
	// 	// } else handleInputChange(initialInputValue);
	// }, []);

	const handleInputChange = (newValue: string) => {
		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				Text: {
					...(prevState?.Properties?.Text ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: newValue,
				},
			},
		}));

		handleResetInputError();
		handleFieldValuesChange(newValue);
	};

	const handleFieldValuesChange = (newValue: string) => {
		setFieldValues(prevState => {
			if (initialFieldValue?.length) {
				return {
					...prevState,
					fieldsValueToUpdate: [
						...(prevState?.fieldsValueToUpdate?.filter(
							getFieldValueWitouthFormSectionAttributeDefinitionId
						) ?? []),
						FieldValueFactory.create(
							EFieldDefinitionType.Text,
							AttributeDefinitionId as number,
							newValue,
							initialFieldValue[0].Id
						),
					],
				};
			}

			return {
				...prevState,
				fieldsValueToCreate: [
					...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ??
						[]),
					FieldValueFactory.create(EFieldDefinitionType.Text, AttributeDefinitionId as number, newValue),
				],
			};
		});
	};

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>
	) {
		return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.textContainer)}>
				<CustomInput
					type={EInputTypes.text}
					label=""
					className={clsx(isError ? classes.selectError : undefined)}
					handleChange={val => handleInputChange(val as string)}
					value={inputValue ?? initialInputValue}
					inEdit={inEdit}
					canSetInputInNonEdit
					// multiline={true}
					// rows={4}
					hideErrorMessage
					errorMessage={isError ? '-' : undefined}
					nonEditComponent={<Typography className={classes.valueContainer}>{initialInputValue}</Typography>}
				/>
			</div>
		</div>
	);
};
