import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		TagAdminPanel: {
			GroupPopup: {
				Actions: {
					saveButton: 'Zapisz',
					cancelButton: 'Anuluj',
				},
			},
		},
	},
	en: {
		TagAdminPanel: {
			GroupPopup: {
				Actions: {
					saveButton: 'Save',
					cancelButton: 'Cancel',
				},
			},
		},
	},
};
