import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { InfoOutlined } from '@mui/icons-material';
import { Divider, IconButton, Link, Tooltip } from '@mui/material';

import { EParameter, EUrlType, IIssue } from '@skillandchill/tasker-types';
import { StartPause } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IConActions = (props: Props): JSX.Element => {
	const { active, handleOnFinish, handleOnPause, handleOnStart, issue, disabled = false } = props;
	const { tString } = useTrans('Dashboard.WorklogStarter');
	const classes = useStyles();
	const history = useHistory();

	const preference = useSelector((x: IReducerState) => x?.Session?.preferences);
	const isMobile: boolean = useSelector((x: IReducerState) => x?.Session?.isMobile);

	const [canOpenUrl, setCanOpenUrl] = useState<boolean>(getCanOpenUrl());
	const [urls, setUrls] = useState<string[]>(getUrls());

	useEffect(() => {
		setCanOpenUrl(getCanOpenUrl());
	}, [preference]);

	useEffect(() => {
		setUrls(getUrls());
	}, [issue?.IssueUrl]);

	function getCanOpenUrl() {
		return (
			preference?.filter(x => x?.ParameterId == EParameter.User_OpenMeetWithStartClock && !!x?.ValueBoolean)
				?.length > 0
		);
	}

	function getUrls() {
		return issue?.IssueUrl?.filter(x => x?.UrlTypeId === EUrlType.Meet)?.map(y => y?.Url) ?? [];
	}

	return (
		<>
			<Divider orientation={Resources.GlobalResources.vertical} flexItem className={classes.divider} />
			<div className={classes.info_tooltip}>
				<StartPause
					disabled={disabled}
					active={active}
					handleFinish={handleOnFinish}
					handlePause={handleOnPause}
					handleStart={handleOnStart}
					urls={urls}
					canOpen={canOpenUrl}
				/>
			</div>
			<Divider orientation={Resources.GlobalResources.vertical} flexItem className={classes.divider} />

			<Link
				underline={Resources.GlobalResources.none}
				component={RouterLink}
				to={`${history?.location?.pathname}${Resources.issueId}${(issue as IIssue)?.Id}`}
			>
				<Tooltip title={tString('issueMore')}>
					<IconButton size={isMobile ? Resources.GlobalResources.small : undefined}>
						<InfoOutlined />
					</IconButton>
				</Tooltip>
			</Link>
		</>
	);
};
