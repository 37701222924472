import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles<Theme>(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			width: '50vw',
			height: '50vh',
		},
	}),
	{ name: `Dashboard_TopBar_FinalForm` }
);
