import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	wrap: 'wrap' as const,
	column: 'column' as const,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Portal: {
				CommentConfiguration: {
					user: 'Właściciel odpowiedzi: ',
					autoReplyTitle: 'Konfiguracja automatycznej odpowiedzi',
					autoReplySubTitle: 'Wybierz lub dodaj automatyczną odpowiedź do nowego zgłoszenia portalowego',
					save: 'Zapisz',
					close: 'Zamknij',
					add: 'Dodaj odpowiedź',
					table: {
						message: 'Treść odpowiedzi',
						isActive: 'Aktywna wiadomość',
						fromUser: 'Autor wiadomości',
						open: 'Przjedź do szczegółów wiadomości',
					},
					EditResponseComponent: {
						editTitle: 'Edytuj odpowiedź',
						editSubtitle: 'Edytuj i konfiguruj wiadomość automatycznej odpowiedzi',
						createTitle: 'Stwórz odpowiedź',
						createSubtitle: 'Stwórz i skonfiguruj wiadomość automatycznej odpowiedzi',
						messageTitle: 'Treść odpowiedzi',
						messageAuthor: 'Autor odpowiedzi',
						messageAuthorInfo: 'Ta osoba będzie autorem automatycznej wiadomości w zgłoszeniu portalowym',
						answerPeriod: 'Okres odpowiedzi',
						back: 'Wstecz',
						isActive: 'Aktywna wiadomość',
						isActiveInfo: 'Ta wiadomość będzie aktualną odpowiedzią na zgłoszenie portalowe',
						afterCreateIssue: 'Po stworzeniu zgłoszenia',
						AfterEachComment: 'Po każdym komentarzu',
						answerPeriodInfo: 'Moment, w którym automatyczna odpowiedź ma zostać wysyłana',
						save: 'Zapisz wiadomość',
						saved: 'Pomyślnie zapisano',
						deleted: 'Pomyślnie usunięto',
						delete: 'Usuń wiadomość',
						deleteConfirmationTitle: 'Czy na pewno?',
						deleteConfirmationSubtitle: 'Po usunięciu tej wiadomości nie będziesz mógł jej odzyskać',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Portal: {
				CommentConfiguration: {
					user: 'Message owner: ',
					autoReplyTitle: 'Auto response configuration',
					autoReplySubTitle: 'Choose or create auto reply message for new portal issue ',
					save: 'Save',
					close: 'Close',
					add: 'Add response',
					table: {
						message: 'Response message',
						isActive: 'Active message',
						fromUser: 'Message author',
						open: 'Go to message details',
					},
					EditResponseComponent: {
						messageTitle: 'Response message',
						messageAuthor: 'Response author',
						messageAuthorInfo: 'This person will be an author of the auto response in portal issue',
						answerPeriod: 'Answer period',
						back: 'Back',
						isActive: 'Active response',
						isActiveInfo: 'This message will be current response for portal issue',
						afterCreateIssue: 'After issue creation',
						AfterEachComment: 'After each comment',
						answerPeriodInfo: 'This is when auto response should be send',
						save: 'Save message',
						saved: 'Saved successfully',
						deleted: 'Deleted succesfully',
						delete: 'Delete message',
						deleteConfirmationTitle: 'Are you sure?',
						deleteConfirmationSubtitle: 'After deleting this message, you will not be able to recover it',
					},
				},
			},
		},
	},
};
