import { AsyncAction } from '@skillchill/redux-promisify';

import { Modifiable } from '@skillandchill/tasker-widgets2/dist/utils/types/global';

import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IJson } from '../ITypes';

export interface IssueConfiguration extends Modifiable {
	name: string;
	groupId: number;
	isPartial: boolean;
	showAsField: boolean;
}

export const ISSUE_CONFIGURATION_FETCH: ActionType = 'ISSUE_CONFIGURATION_FETCH';
export const ISSUE_CONFIGURATION_PUT: ActionType = 'ISSUE_CONFIGURATION_PUT';
export const ISSUE_CONFIGURATION_UPDATE: ActionType = 'ISSUE_CONFIGURATION_UPDATE';
export const ISSUE_CONFIGURATION_DELETE: ActionType = 'ISSUE_CONFIGURATION_DELETE';

function _fetchIssueConfigurationByProject(issueConfigurations: IssueConfiguration[]): Action {
	return {
		type: ISSUE_CONFIGURATION_FETCH,
		issueConfigurations,
	};
}

function _putIssueConfigurationByProject(issueConfigurations: Partial<IssueConfiguration>): Action {
	return {
		type: ISSUE_CONFIGURATION_PUT,
		issueConfigurations,
	};
}

function _updateIssueConfigurationByProject(issueConfigurations: Partial<IssueConfiguration>): Action {
	return {
		type: ISSUE_CONFIGURATION_UPDATE,
		issueConfigurations,
	};
}
function _deleteIssueConfigurationByProject(id: number): Action {
	return {
		type: ISSUE_CONFIGURATION_DELETE,
		id,
	};
}

export function issueConfigurationByProject_fetchTags(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.chain(json => (json as IJson).data);
		context.then(issueConfigurations =>
			dispatch(_fetchIssueConfigurationByProject(issueConfigurations as IssueConfiguration[]))
		);
		return axios().get(`tag/project/${projectId}`);
	};
}

export function issueConfigurationByProject_putTags(
	projectId: number,
	issueConfiguration: Partial<IssueConfiguration>
): AsyncAction {
	return function(context, dispatch) {
		context.then(data =>
			dispatch(_putIssueConfigurationByProject({ ...issueConfiguration, id: (data as { id: number })?.id }))
		);
		return axios().post(`tag/project/${projectId}`, [issueConfiguration]);
	};
}

export function issueConfigurationByProject_updateTag(
	projectId: number,
	issueConfiguration: Partial<IssueConfiguration>
): AsyncAction {
	return function(context, dispatch) {
		context.then(data =>
			dispatch(_updateIssueConfigurationByProject({ ...issueConfiguration, id: (data as { id: number })?.id }))
		);
		return axios().put(`tag/project/${projectId}`, issueConfiguration);
	};
}
