import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					title: 'Konfiguracja ról organizacji',
					description: 'Zarządzaj rolami organizacji, przypisuj uprawnienia i dostępy.',
					roleManagement: 'Zarządzanie rolami',
					featureSelection: 'Wybór funkcji systemu',
					permissionManagement: 'Zarządzanie uprawnieniami',
				},
			},
		},
	},
	en: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					title: 'Organization roles configuration',
					description: 'Manage organization roles, assign permissions and accesses.',
					roleManagement: 'Role management',
					featureSelection: 'System feature selection',
					permissionManagement: 'Permission management',
				},
			},
		},
	},
};
