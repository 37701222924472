import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	GeneralTab: 'general',
	string: 'string' as const,
	uuid: () => `OrganizationTile_${uuid()}`,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				ManageUsers: {
					search: 'Wyszukaj po imieniu lub nazwisku',
					titlePage: 'Zarządzaj użytkownikami',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				ManageUsers: {
					search: 'Search by first or last name',
					titlePage: 'Manage users',
				},
			},
		},
	},
};
