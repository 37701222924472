import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	divider: 'divider',
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					Tabs: {
						details: 'Szczegóły delegacji',
						acceptance: 'Akceptacja delegacji',
						accounting: 'Rozliczenie wydatków',
						advances: 'Zaliczki delegacji',
						elements: 'Elementy delegacji',
						settlement: 'Podsumowanie',
						historyAndComments: 'Historia i komentarze',
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					Tabs: {
						details: 'Delegation details',
						acceptance: 'Delegation acceptance',
						accounting: 'Expense settlement',
						advances: 'Delegation advances',
						elements: 'Delegation elements',
						settlement: 'Summary',
						historyAndComments: 'History and comments',
					},
				},
			},
		},
	},
};
