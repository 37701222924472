import { Theme } from '@mui/material';

import { store } from '@/App';
import { IReducerState } from '@/store/reducers/types';

export const getTextColor = (theme: Theme) => {
	const reducer: IReducerState = store.getState() as IReducerState;
	const darkMode = reducer?.Session?.darkMode ?? false;

	return darkMode ? '#FFFFFF' : theme.palette.text.primary;
};
