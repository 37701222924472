import clsx from 'clsx';
import React from 'react';

import StarIcon from '@mui/icons-material/Star';
import { Typography } from '@mui/material';

import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const FieldsRequiredHelperText = (props: Props) => {
	const { children, className } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModalNew.IssueModalActions.FieldsRequiredIndicator');

	return (
		<div className={clsx(classes.flexYCenter, className)}>
			<StarIcon className={classes.starIcon} />
			<Typography className={classes.text} variant={Resources.GlobalResources.body2}>
				{t('fieldsRequired')}
			</Typography>
			{children}
		</div>
	);
};
