import { applyLanguage } from '@skillandchill/tasker-widgets2/dist/utils';

const CommonResources = {
	selectArgs: {
		fullWidth: true,
		styles: { menu: (base: any) => ({ ...base, zIndex: 2 }) },
	},
};

const ResourcesSource = {
	'PL-PL': {
		noMappedTag1: 'Brak mapowania tagu statusowego do kolumny kanbana ',
		noMappedTag2: ', wybierz tag, który chcesz przypisać do kolumny a następnie kliknij ',
		noMappedTag3: 'aby dodać mapowanie tego pola na kolumnę',
		here: 'tutaj ',
	},
	'EN-EN': {
		noMappedTag1: 'No mapped status tag to kanban column  ',
		noMappedTag2: ', which you want assign to the column and then click  ',
		noMappedTag3: 'to assign this field to kanban column',
		here: 'here ',
	},
};

export const Resources = applyLanguage<typeof ResourcesSource, typeof CommonResources>(
	ResourcesSource,
	CommonResources
);
