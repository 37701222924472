import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		root: {
			'@media all and (-ms-high-contrast:none)': {
				height: 0, // IE11 fix
			},
		},
	}),
	{ name: `Error` }
);
