import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Typography } from '@mui/material';

import { IComment } from '@skillandchill/tasker-types';
import { getFullNameNew } from '@skillandchill/tasker-widgets2/dist/utils';
import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { comment_edit } from '@/store/actions';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { errorVariant } from '@/utils/snackBar';
import { getFirst } from '@/utils/utils';

import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const CommentCard = (props: Props): JSX.Element => {
	const { comment, onEditComment, onDelete, ifFakeComment, isOnIssue = true } = props;
	const { t } = useTrans(ETranslationPrefix.IssueModalEditorCommentCard);
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();

	const [inEdit, setInEdit] = useState<boolean>(false);
	const [commentState, setCommentState] = useState<Partial<IComment>>(comment);

	useEffect(() => {
		setCommentState(comment);
	}, [comment]);

	useEffect(() => {}, [commentState.Comment]);

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === Resources.GlobalResources.enterEvent) {
			event.preventDefault();
			updateComment();
			setInEdit(false);
		}
	};

	const updateComment = () => {
		dispatch(
			comment_edit(commentState?.Id as number, commentState?.Comment ?? '', commentState?.CommentHtml ?? '')
		).catch(e => snackbar.enqueueSnackbar(`${t('commentEditFailed')}${e}`, errorVariant));
	};

	const onClickEditWrapper = () => {
		if (onEditComment) onEditComment(comment);
	};

	return (
		<div className={classes.root}>
			<div className={classes.flexRow}>
				<Header
					avatar={comment?.AddedByTaskerUser?.AvatarPath ?? ''}
					user={comment?.AddedByTaskerUser ?? {}}
					createdAt={new Date(comment?.CreatedDateTime as string)}
					nickName={getFullNameNew(comment?.AddedByTaskerUser)}
					isDeleted={!!comment?.DeletedByTaskerUserId}
					isPortal={getFirst(comment?.IssueComment ?? [])?.IsPortal ?? false}
					isOnIssue={isOnIssue}
				>
					<Footer
						ifFakeComment={ifFakeComment}
						comment={commentState}
						onClickEdit={onEditComment ? onClickEditWrapper : undefined}
						onDelete={onDelete}
					/>
				</Header>
				{ifFakeComment ? (
					<Typography
						variant={Resources.GlobalResources.h6}
						className={clsx(classes.defaultCursor, classes.inlineFlex, classes.pinned)}
						color={Resources.GlobalResources.primary}
					>
						{t('pinned')}
					</Typography>
				) : (
					<div />
				)}
			</div>
			<Content
				comment={commentState?.CommentHtml ?? ''}
				inEdit={inEdit}
				setCommentText={(value: string) => setCommentState({ ...commentState, Comment: value })}
				handleKeyPress={handleKeyPress}
			/>
			{ifFakeComment ? <div className={classes.bottomLine} /> : <div />}
		</div>
	);
};
