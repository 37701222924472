import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		const dimension = '70%';
		return {
			...getGlobalStyles(),
		};
	},
	{ name: `IssueModalEditor` }
);
