import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close } from '@mui/icons-material';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { IconButton, Tooltip, Typography } from '@mui/material';

import { EParameter, EUrlType, IIssueUrl, IWorkLog } from '@skillandchill/tasker-types';
import { StartPause, TooltipTextBase, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { FavouriteIssue } from '@/layouts/Widgets/FavouriteIssue';
import { deleteIssueUrlMeet, work_log_finish_NEW, work_log_pause_NEW, work_log_start_NEW } from '@/store/actions';
import { archiveIssue, unArchiveIssue } from '@/store/actions/archivedIssuesActions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { getFirst } from '@/utils/utils';

import { issueModalDataContext } from '..';

import { Props } from './modal';
import { MoreOptions } from './MoreOptions';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueModalTitle = (props: Props) => {
	const { handleCloseModal, handleDeleteIssueWithConfirmation } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();

	const { t, tString } = useTrans('IssueModalNew.IssueModalTitle');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const currentUser = useSelector((state: IReducerState) => state?.Session?.profile);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const kanbanDetails: { IsKanban: boolean; KanbanId?: number } = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.kanbanDetails
	);
	const preference = useSelector((x: IReducerState) => x?.Session?.preferences);

	const [urls, setUrls] = useState<string[]>(getUrls());
	const [canOpenUrl, setCanOpenUrl] = useState<boolean | undefined>(getCanOpenUrl());

	const { isEdittingIssue } = useContext(issueModalDataContext);

	useEffect(() => {
		if (!preference) return;

		setCanOpenUrl(getCanOpenUrl());
	}, [preference]);

	useEffect(() => {
		setUrls(getUrls());
	}, [issue?.IssueUrl]);

	function getUrls() {
		return issue?.IssueUrl?.filter(x => x?.UrlTypeId === EUrlType.Meet)?.map(y => y?.Url) ?? [];
	}

	function getCanOpenUrl() {
		if (!preference) return;

		return (
			preference?.filter(x => x?.ParameterId == EParameter.User_OpenMeetWithStartClock && !!x?.ValueBoolean)
				?.length > 0
		);
	}

	const linkDidClick = (issueUrl: IIssueUrl, event: any) => {
		if (event.ctrlKey == true) dispatch(deleteIssueUrlMeet(issueUrl?.IssueId, issueUrl?.Id));
		else {
			if (issueUrl?.Url?.includes(Resources.https) || issueUrl?.Url?.includes(Resources.http))
				window.open(issueUrl?.Url, Resources.GlobalResources.blank);
			else window.open(`${Resources.https}${issueUrl?.Url}`, Resources.GlobalResources.blank);
		}
	};

	const handleManageArchive = () => {
		if (issue?.ArchivedIssues && (issue?.ArchivedIssues?.length ?? 0) > 0) {
			const id = getFirst(issue?.ArchivedIssues)?.Id;
			dispatch(unArchiveIssue(id));
		} else if (kanbanDetails?.KanbanId) {
			dispatch(archiveIssue(issue?.Id, kanbanDetails?.KanbanId));
		}
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.contentSpaceBetween)}>
			<div className={classes.flexYCenter}>
				<VisibilityControl
					condition={isEdittingIssue}
					alternative={<Typography variant={Resources.GlobalResources.h5}>{t('newIssue')}</Typography>}
				>
					{/* <VisibilityControl condition={!!kanbanDetails?.IsKanban}>
						<Tooltip title={tString('archive')}>
							<IconButton
								onClick={handleManageArchive}
								className={clsx(
									classes.starIconButton,
									issue?.ArchivedIssues &&
										(issue?.ArchivedIssues?.length ?? 0) > 0 &&
										classes.favouriteIssue
								)}
							>
								<CollectionsBookmarkIcon className={classes.starIcon} />
							</IconButton>
						</Tooltip>
					</VisibilityControl> */}
					<FavouriteIssue issue={issue} />
					<TooltipTextBase className={classes.title} text={`${issue?.DisplayId ?? ''} ${issue?.Title}`} />
				</VisibilityControl>
				{/* <CreateUrl issueId={issue?.Id ?? -1} issue={issue} linkDidClick={linkDidClick} /> */}
			</div>
			<div className={classes.flexYCenter}>
				<VisibilityControl condition={isEdittingIssue}>
					<MoreOptions handleDeleteIssueWithConfirmation={handleDeleteIssueWithConfirmation} />
				</VisibilityControl>
				<VisibilityControl
					condition={
						!!issue?.Members?.Assigned?.some(assignee => assignee?.Id == currentUser?.Id) &&
						!!(issue?.Id && issue?.Id > 0) &&
						isEdittingIssue
					}
				>
					<StartPause
						active={activeWorklogs?.some(worklog => worklog?.Issue?.Id === issue?.Id)}
						handleStart={() => dispatch(work_log_start_NEW(issue?.Id))}
						handlePause={() => dispatch(work_log_pause_NEW(issue?.Id))}
						handleFinish={() => dispatch(work_log_finish_NEW(issue?.Id))}
						urls={urls}
						canOpen={canOpenUrl}
					/>
				</VisibilityControl>
				{/* <MoreOptions /> */}
				<IconButton onClick={handleCloseModal}>
					<Close className={classes.closeIcon} />
				</IconButton>
			</div>
		</div>
	);
};
