import uuid from 'uuid';

import { ECurrency } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	number: 'number' as const,
	fixed: 'fixed' as const,
	getCurrencyKey: (item: ECurrency) => `Currency_${item}_${uuid()}`,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAccounting: {
						DelegationAccountingAttachment: {
							valueLabel: 'Kwota butto',
							taxLabel: '% VAT',
							netValueLabel: 'Kwota netto',
							currencyLabel: 'Waluta',
							saving: 'Zapisywanie załącznika',
							saved: 'Załącznik zapisany',
							header: 'Rozliczanie wydatku z załącznika',
						},
					},
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalContent: {
					DelegationAccounting: {
						DelegationAccountingAttachment: {
							valueLabel: 'Gross amount',
							taxLabel: '% VAT',
							netValueLabel: 'Net amount',
							currencyLabel: 'Currency',
							saving: 'Saving attachment',
							saved: 'Attachment saved',
							header: 'Expense settlement from attachment',
						},
					},
				},
			},
		},
	},
};
