import clsx from 'clsx';

import { Typography } from '@mui/material';

import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { Resources } from '../resources';

import { Props } from './model';
import { useStyles } from './styles';

export const ModalRecord = (props: Props): JSX.Element => {
	const { label, avatar, icon, children, zIndex, isMandatory } = props;
	const classes = useStyles();

	return (
		<div>
			<VisibilityControl condition={!!label}>
				<div className={classes.root}>
					<Typography variant={Resources.GlobalResources.body2}>{label}</Typography>
					<VisibilityControl condition={isMandatory ?? false}>
						<b className={classes.mandatorySign}>*</b>
					</VisibilityControl>
				</div>
			</VisibilityControl>
			<div
				className={clsx(classes.subRoot, classes.relative, classes.flexYCenter)}
				style={{ zIndex: zIndex ?? 1 }}
			>
				{avatar || icon}
				{children}
			</div>
		</div>
	);
};
