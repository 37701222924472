import { AsyncAction } from '@skillchill/redux-promisify';

import { IRolesChanges, IProjectUsersPage, IUserAddedToProject } from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action } from '@/store/types';
import axios from '@/utils/axios';

import { ActionType } from '../types';

export const SET_PROJECT_USERS: ActionType = 'SET_PROJECT_USERS';
export const CLEAR_TASKER_USERS: ActionType = 'CLEAR_TASKER_USERS';
export const SET_USERS_NOT_INVOLVED_IN_PROJECT = 'SET_USERS_NOT_INVOLVED_IN_PROJECT';

const TaskerUserPath = 'TaskerUser';
const ProjectRoleToProjectToUserPath = 'ProjectRoleToProjectToUser';

function _setProjectUsers(data: IProjectUsersPage): Action {
	return {
		type: SET_PROJECT_USERS,
		projectUsers: data.Data,
		projectUsersCount: data.Count,
		projectRoles: data.projectRoles,
	};
}

function _setUsersNotInvolvedInProject(data: IProjectUsersPage): Action {
	return {
		type: SET_USERS_NOT_INVOLVED_IN_PROJECT,
		usersNotInvolvedInProject: data.Data,
		countUsersNotInvolvedInProject: data.Count,
	};
}

export function deleteManyUsersFromProject(
	idsToDelete: number[],
	projectId: number,
	from: number,
	count: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));
		return axios().delete(`${ProjectRoleToProjectToUserPath}/deleteManyUsers`, {
			data: {
				idsToDelete,
				projectId,
				from,
				count,
			},
		});
	};
}

export function deleteAllUsersFromProject(projectId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));
		return axios().delete(`${ProjectRoleToProjectToUserPath}/deleteAll/${projectId}`);
	};
}

export function deleteUserFromProject(
	userId: number,
	projectId: number,
	from: number,
	count: number,
	searchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));
		return axios().post(`${ProjectRoleToProjectToUserPath}/deleteUser`, {
			userId,
			projectId,
			from,
			count,
			searchValue,
		});
	};
}

export function fetch_taskerUsers_involved_in_project(
	from: number,
	count: number,
	searchValue: string,
	projectId: number,
	taskerRoleFilterValues?: number[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));
		return axios().post(`${TaskerUserPath}/from/${from}/count/${count}`, {
			projectId: projectId,
			taskerRoleFilterValues: taskerRoleFilterValues,
			searchValue: searchValue,
		});
	};
}

export function updateUserProjectRoles(
	userId: number,
	projectId: number,
	usersRolesChanges: IRolesChanges,
	from: number,
	count: number,
	searchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));
		return axios().post(`${ProjectRoleToProjectToUserPath}/createOrDelete`, {
			userId,
			projectId,
			usersRolesChanges,
			from,
			count,
			searchValue,
		});
	};
}

export function get_users_not_involved_in_project(
	from: number,
	count: number,
	projectId: number,
	searchValue: string
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setUsersNotInvolvedInProject((json as IJson).data)));
		return axios().post(`${TaskerUserPath}/from/${from}/count/${count}/notInProject`, {
			projectId,
			searchValue,
		});
	};
}

export function add_many_users_to_project(projectId: number, usersToAdd: IUserAddedToProject[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setProjectUsers((json as IJson).data)));

		return axios().post(`${ProjectRoleToProjectToUserPath}/createMany`, { projectId, usersToAdd });
	};
}
