import { Translation as OrganizationPanel } from './resources';
import { Translation as CreateOrganization } from './TabsContent/CreateOrganization/resources';
import { Translation as Delegation } from './TabsContent/Delegation/resources';
import { Translation as CustomDictionaryForm } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryForm/resources';
import { Translation as CustomDictionaryItemForm } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryItemForm/resources';
import { Translation as CustomDictionaryDefaultToggle } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryItemList/CustomDictionaryDefaultToggle/resources';
import { Translation as CustomDictionaryItemListMenu } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryItemList/CustomDictionaryItemListMenu/resources';
import { Translation as CustomDictionaryItemList } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryItemList/hooks/resources';
import { Translation as CustomDictionaryListMenu } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryList/CustomDictionaryListMenu/resources';
import { Translation as CustomDictionaryList } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/CustomDictionaryList/hooks/resources';
import { Translation as CustomDictionariesConfiguration } from './TabsContent/Delegation/TabsContent/CustomDictionariesConfiguration/resources';
import { Translation as DelegationDefaultsConfiguration } from './TabsContent/Delegation/TabsContent/DefaultsConfiguration/resources';
import { Translation as DelegationDictionaryConfiguration } from './TabsContent/Delegation/TabsContent/DictionaryConfiguration/resources';
import { Translation as DelegationGeneral } from './TabsContent/Delegation/TabsContent/General/resources';
import { Translation as UsersRolesConfiguration } from './TabsContent/Delegation/TabsContent/UsersRolesConfiguration/resources';
import { Translation as DelegationVisibilityConfiguration } from './TabsContent/Delegation/TabsContent/VisibilityConfiguration/resources';
import { Translation as CreateForm } from './TabsContent/EndWorkDay/Details/Form/New/Form/Create/resources';
import { Translation as EditForm } from './TabsContent/EndWorkDay/Details/Form/New/Form/Edit/resources';
import { Translation as Info } from './TabsContent/EndWorkDay/Details/Form/New/Info/resources';
import { Translation as Body } from './TabsContent/EndWorkDay/Details/Form/New/Notification/Components/Body/resources';
import { Translation as Url } from './TabsContent/EndWorkDay/Details/Form/New/Notification/Components/Url/resources';
import { Translation as NewCreateNotification } from './TabsContent/EndWorkDay/Details/Form/New/Notification/Create/resources';
import { Translation as EditCreateNotification } from './TabsContent/EndWorkDay/Details/Form/New/Notification/Edit/resources';
import { Translation as Notification } from './TabsContent/EndWorkDay/Details/Form/New/Notification/resources';
import { Translation as NewForm } from './TabsContent/EndWorkDay/Details/Form/New/resources';
import { Translation as Users } from './TabsContent/EndWorkDay/Details/Form/New/Users/resources';
import { Translation as EndWorkDay } from './TabsContent/EndWorkDay/resources';
import { Translation as GeneralTabCardActions } from './TabsContent/GeneralTab/CardActions/resources';
import { Translation as InvoiceDetails } from './TabsContent/GeneralTab/InvoiceDetails/resources';
import { Translation as GeneralTab } from './TabsContent/GeneralTab/resources';
import { Translation as Integrations } from './TabsContent/Integrations/resources';
import { Translation as Discord } from './TabsContent/Integrations/TabsContent/Discord/resources';
import { Translation as GoogleDrive } from './TabsContent/Integrations/TabsContent/GoogleDrive/resources';
import { Translation as ManageUsers } from './TabsContent/ManageUsers/resources';
import { Translation as OrganizationStructureDetails } from './TabsContent/OrganizationStructure/Details/resources';
import { Translation as OrganizationStructure } from './TabsContent/OrganizationStructure/resources';
import { Translation as PasswordPolicyCardActions } from './TabsContent/PasswordPolicy/CardActions/resources';
import { Translation as PasswordPolicy } from './TabsContent/PasswordPolicy/resources';
import { Translation as Preferences } from './TabsContent/Preferences/resources';
import { Translation as UsersTabCardActions } from './TabsContent/UsersTab/CardActions/resources';
import { Translation as CreateUser } from './TabsContent/UsersTab/CreateUser/resources';
import { Translation as UsersTable } from './TabsContent/UsersTab/hooks/resources';
import { Translation as UsersTab } from './TabsContent/UsersTab/resources';
import { Translation as UserCard } from './TabsContent/UsersTab/UserCard/resources';
import { Translation as UserDetailsCardActions } from './TabsContent/UsersTab/UserDetails/CardActions/resources';
import { Translation as UserDetails } from './TabsContent/UsersTab/UserDetails/resources';

export const OrganizationPanelTranslationEN = [
	OrganizationPanel.en,
	GeneralTab.en,
	PasswordPolicy.en,
	CreateUser.en,
	UserCard.en,
	UsersTab.en,
	OrganizationStructure.en,
	OrganizationStructureDetails.en,
	EndWorkDay.en,
	NewForm.en,
	Url.en,
	Body.en,
	Users.en,
	Info.en,
	CreateForm.en,
	EditForm.en,
	Notification.en,
	Integrations.en,
	GoogleDrive.en,
	NewCreateNotification.en,
	EditCreateNotification.en,
	Discord.en,
	CreateOrganization.en,
	ManageUsers.en,
	Preferences.en,
	Delegation.en,
	DelegationVisibilityConfiguration.en,
	DelegationDefaultsConfiguration.en,
	UsersRolesConfiguration.en,
	DelegationDictionaryConfiguration.en,
	CustomDictionariesConfiguration.en,
	CustomDictionaryForm.en,
	CustomDictionaryList.en,
	CustomDictionaryItemList.en,
	CustomDictionaryItemForm.en,
	CustomDictionaryItemListMenu.en,
	CustomDictionaryListMenu.en,
	GeneralTabCardActions.en,
	InvoiceDetails.en,
	UsersTable.en,
	DelegationGeneral.en,
	PasswordPolicyCardActions.en,
	UsersTabCardActions.en,
	UserDetails.en,
	UserDetailsCardActions.en,
	CustomDictionaryDefaultToggle.en,
];

export const OrganizationPanelTranslationPL = [
	OrganizationPanel.pl,
	GeneralTab.pl,
	PasswordPolicy.pl,
	CreateUser.pl,
	UserCard.pl,
	UsersTab.pl,
	OrganizationStructure.pl,
	OrganizationStructureDetails.pl,
	EndWorkDay.pl,
	NewForm.pl,
	Url.pl,
	Body.pl,
	Users.pl,
	Info.pl,
	CreateForm.pl,
	EditForm.pl,
	Notification.pl,
	Integrations.pl,
	GoogleDrive.pl,
	NewCreateNotification.pl,
	EditCreateNotification.pl,
	Discord.pl,
	CreateOrganization.pl,
	ManageUsers.pl,
	Delegation.pl,
	DelegationVisibilityConfiguration.pl,
	DelegationDefaultsConfiguration.pl,
	UsersRolesConfiguration.pl,
	DelegationDictionaryConfiguration.pl,
	CustomDictionariesConfiguration.pl,
	CustomDictionaryForm.pl,
	CustomDictionaryList.pl,
	CustomDictionaryItemList.pl,
	CustomDictionaryItemForm.pl,
	CustomDictionaryItemListMenu.pl,
	CustomDictionaryListMenu.pl,
	Preferences.pl,
	GeneralTabCardActions.pl,
	InvoiceDetails.pl,
	UsersTable.pl,
	DelegationGeneral.pl,
	PasswordPolicyCardActions.pl,
	UsersTabCardActions.pl,
	UserDetails.pl,
	UserDetailsCardActions.pl,
	CustomDictionaryDefaultToggle.pl,
];
