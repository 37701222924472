import { ILeaveRequest, ILeaveType, IPage } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	page: { Data: [], Count: 0 } as IPage<ILeaveRequest[]>,
	leaveRequest: {} as Partial<ILeaveRequest>,
	leaveType: [] as Partial<ILeaveType>[],
};
export type ILeaveRequestState = typeof State;

const leaveRequestReducer = (state = State, action: ObjectAction): ILeaveRequestState => {
	switch (action.type) {
		case actionTypes.LEAVE_REQUEST_FETCH_PAGE: {
			return {
				...state,
				page: action?.data,
			};
		}
		case actionTypes.LEAVE_REQUEST_FETCH_SINGLE: {
			return {
				...state,
				leaveRequest: action?.data,
			};
		}
		case actionTypes.LEAVE_REQUEST_DELETE: {
			return {
				...state,
				page: {
					Data: state?.page?.Data?.filter(x => x?.Id != action?.data),
					Count: state?.page?.Count - 1,
				},
			};
		}
		case actionTypes.LEAVE_TYPE_ALL: {
			return {
				...state,
				leaveType: action?.data,
			};
		}
		default: {
			return state;
		}
	}
};

export default leaveRequestReducer;
