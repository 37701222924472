import { format } from 'date-fns';
import { t } from 'i18next';

import { EDictionaryItemType, IDictionaryItem, IFieldValue, ITag } from '@skillandchill/tasker-types';
import { IIssueFormSectionToIssueField } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/issueFormSectionToIssueField';
import { EFormats } from '@skillandchill/tasker-widgets2/dist/utils';

import { Resources } from './resources';

export class FieldValue implements Partial<IFieldValue> {
	// ValueTag: ITag;
	ValueTagId: number;
	AttributeDefinitionId: number;
	// AttributeDefinition: IFieldDefinition;

	constructor(tag: ITag, issueFormSectionToIssueField: IIssueFormSectionToIssueField) {
		// this.ValueTag = tag;
		this.ValueTagId = tag.Id;
		this.AttributeDefinitionId = issueFormSectionToIssueField.AttributeDefinitionId as number;
		// this.AttributeDefinition = issueFormSectionToIssueField.AttributeDefinition as IFieldDefinition;
	}
}

export const getDictionaryValue = (dictionaryItem: IDictionaryItem): string => {
	switch (dictionaryItem?.DictionaryItemTypeId) {
		case EDictionaryItemType?.Boolean:
			return returnSecured(
				dictionaryItem?.ValueBoolean ? t('IssueModelNew.valueTrue') : t('IssueModelNew.valueFalse')
			);
		case EDictionaryItemType?.Integer:
			return returnSecured(dictionaryItem?.ValueInteger?.toString());
		case EDictionaryItemType?.Decimal:
			return returnSecured(dictionaryItem?.ValueDecimal?.toString());
		case EDictionaryItemType?.Text:
			return returnSecured(dictionaryItem?.ValueText?.toString());
		case EDictionaryItemType?.Dictionary:
			return returnSecured(dictionaryItem?.ValueDictionary?.Name?.toString());
		case EDictionaryItemType?.TagGroup:
			return returnSecured(dictionaryItem?.ValueTagGroup?.Label?.toString());
		case EDictionaryItemType?.Date: {
			const dateString = dictionaryItem?.ValueDate?.toString() ?? Resources.GlobalResources.empty;
			if (isDateValid(dateString)) {
				return returnSecured(format(new Date(dateString), EFormats.EDateFNS.DayMonthYear));
			}
			return returnSecured();
		}
		case EDictionaryItemType?.DateTime: {
			const dateString = dictionaryItem?.ValueDateTime?.toString() ?? Resources.GlobalResources.empty;
			if (isDateValid(dateString)) {
				return returnSecured(format(new Date(dateString), EFormats.EDateFNS.DateFormatWithHours));
			}
			return returnSecured();
		}
		default:
			return returnSecured();
	}
};

export function isDateValid(dateStr: string): boolean {
	const date = new Date(dateStr);
	return !isNaN(date.getTime());
}

export function returnSecured(string?: string): string {
	return string ?? t('dictionaryValueNull');
}
