import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	divider: 'divider',
	...GlobalResources,
};

export const Translation = {
	pl: {
		NotificationPopover: {
			Tabs: {
				all: 'Wszystkie',
				notRead: 'Nowe',
				read: 'Przeczytane',
			},
		},
	},
	en: {
		NotificationPopover: {
			Tabs: {
				all: 'All',
				notRead: 'New',
				read: 'Archived',
			},
		},
	},
};
