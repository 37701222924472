import { AsyncAction } from '@skillchill/redux-promisify';

import { IJson } from '@/store/ITypes';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

export const REPORT_FETCH_RECKON: ActionType = 'REPORT_FETCH_RECKON';
export const REPORT_FETCH_TIME: ActionType = 'REPORT_FETCH_TIME';

function _fetchReckonReport(report: unknown): Action {
	return {
		type: REPORT_FETCH_RECKON,
		report,
	};
}

function _fetchTimeReport(report: unknown): Action {
	return {
		type: REPORT_FETCH_TIME,
		report,
	};
}

export function report_composeReckon(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchReckonReport((json as IJson).data)));
		return axios().get(`reports/developer/${id}`);
	};
}

export function report_composeProjectReckon(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchTimeReport((json as IJson).data)));
		return axios().get(`reports/project/${id}`);
	};
}
