import { IRole, ITaskerUser } from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const State = {
	taskerUsers: [] as Partial<ITaskerUser>[],
	roles: [] as IRole[],
};
export type IRolePanelEditorState = typeof State;

const rolePanelReducer = (state = State, action: ObjectAction): IRolePanelEditorState => {
	switch (action?.type) {
		case actionTypes.FETCH_ALL_USERS: {
			return {
				...state,
				taskerUsers: action.taskerUsers,
			};
		}
		case actionTypes.FETCH_ALL_ROLES_NEW:
		case actionTypes.SET_PROJECT_USERS: {
			return {
				...state,
				roles: action.roles,
			};
		}
		default: {
			return state;
		}
	}
};

export default rolePanelReducer;
