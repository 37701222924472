import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	oneWeek: 7,
	oneMonth: 30,
	id: 'simple-popover',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					TableOptions: {
						noAgreementsCheckedInfo: 'Zaznacz przynajmniej jedną umowę, by zobaczyć możliwe opcje',
						deleteSelectedAgreements: 'Usuń zaznaczone umowy',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					TableOptions: {
						noAgreementsCheckedInfo: 'Select at least one agreement to see possible options',
						deleteSelectedAgreements: 'Delete selected agreements',
					},
				},
			},
		},
	},
};
