import uuid from 'uuid';

import { ICreateIssueFormSection, IIssueFormCreate } from '@skillandchill/tasker-types';
import { IBody } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/body';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	issueForm: {
		Fields: [],
		TypeId: -1,
		FormName: '',
	} as IIssueFormCreate,
	path: '/issueForms',
	key: 'issueForm_Main_Section',
	dash: '_',
	fieldSectionBase: {
		Name: '',
		FieldDefinition: [],
		SectionChildren: [],
	} as ICreateIssueFormSection,
	boxBack: {
		display: 'flex',
		flexDirection: 'row',
		pt: 2,
	},
	boxForward: {
		flex: '1 1 auto',
	},
	body: { Key: '', Value: '' } as IBody,
	keyBody: (x: number, y: number) => `Body_${x}_${y}_${uuid()}`,
	standard: 'standard' as const,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							keyLabel: 'Klucz',
							firstStep: 'Konfiguracja formularza',
							secondStep: 'Konfiguracja powiadomień',
							thirdStep: 'Przypisywanie użytkowników',
							finish: 'Zapisz',
							next: 'Następny krok',
							back: 'Wróć',
							formCardTitle: 'Formularz zgłoszeniowy',
							pageTitle: 'Formularz',
							Header: {
								formPropertiesTitle: 'Konfiguracja właściwości formularza',
								subTitleNew: 'Nowy formularz',
							},
							createForm: 'Stwórz formularz',
							saveForm: 'Zapisz',
							cancel: 'Anuluj',
							addGroupIcon: 'Dodaj grupę',
							addRecord: 'Dodaj rekord',
							youShallNotPass: 'Aby przejść dalej musisz uzupełnić ten krok',
							youShallNotPassSame: 'Niemożna przejść na ten sam krok',
						},
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			EndWorkDay: {
				Details: {
					Form: {
						New: {
							keyLabel: 'Key',
							firstStep: 'Configure form',
							secondStep: 'Configure notification',
							thirdStep: 'Assign users',
							finish: 'Finish',
							next: 'Next',
							back: 'Back',
							formCardTitle: 'Issue form',
							pageTitle: 'Form',
							Header: {
								formPropertiesTitle: 'Configuration form properties',
								subTitleNew: 'New form',
							},
							createForm: 'Create form',
							saveForm: 'Save',
							cancel: 'Cancel',
							addGroupIcon: 'Dodaj grupę',
							addRecord: 'Add record',
							youShallNotPass: 'In case of going forward you have to complete this step.',
							youShallNotPassSame: 'Can not go to same step as is current on',
						},
					},
				},
			},
		},
	},
};

export enum EActiveStep {
	Form = 0,
	Notification = 1,
	Users = 2,
}
