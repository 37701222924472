import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		content: {
			marginLeft: theme.spacing(0.625),
		},
		deleteIcon: {
			'&:hover': {
				color: theme.palette.red.main,
				transition: 'all .2s ease',
			},
		},
	}),
	{ name: `IssueModalEditor_CommendCard_Footer` }
);
