import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { Permission } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';
import { ETranslationPrefix } from '@skillandchill/translate/dist/translationPrefix';

import { comment_delete } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { useTrans } from '@/utils/hooks/useTrans';
import { CheckRoles } from '@/utils/permissions';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const Footer = (props: Props): JSX.Element => {
	const { comment, onClickEdit, onDelete, ifFakeComment } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTrans(ETranslationPrefix.IssueModalEditorCommentCardFooter);

	const [canDelete, setCanDelete] = useState<boolean>(false);
	const [canEdit, setCanEdit2] = useState<boolean>(false);

	const currentUserPermissions = useSelector((state: IReducerState) => state.Session?.permissions);
	const currentProfile = useSelector((state: IReducerState) => state.Session?.profile);

	const setCanEdit = (x: boolean) => {
		setCanEdit2(x);
	};

	useEffect(() => {
		const isOwner = currentProfile?.Id === comment?.AddedByTaskerUserId;
		const canDelete = CheckRoles(currentUserPermissions, Permission.DeleteComment);
		const canEdit = CheckRoles(currentUserPermissions, Permission.UpdateComment);
		setCanDelete(canDelete && isOwner);
		setCanEdit(canEdit && isOwner);
	}, [comment?.AddedByTaskerUserId]);

	const onClickDelete = () => {
		if (!comment?.Id) return;
		if (onDelete) {
			onDelete(comment?.Id);
			return;
		}
		if (!ifFakeComment) dispatch(comment_delete(comment?.Id));
	};

	return (
		<div className={classes.content}>
			<VisibilityControl condition={!ifFakeComment}>
				<VisibilityControl condition={canDelete && !comment?.DeletedByTaskerUserId}>
					<IconButton
						size={Resources.GlobalResources.small}
						onClick={onClickDelete}
						aria-label={t('delete')}
						className={classes.deleteIcon}
					>
						<DeleteIcon fontSize={Resources.GlobalResources.small} />
					</IconButton>
				</VisibilityControl>
				<VisibilityControl condition={canEdit}>
					<IconButton
						size={Resources.GlobalResources.small}
						onClick={onClickEdit}
						aria-label={t('edit')}
						color={Resources.GlobalResources.primary}
					>
						<EditIcon />
					</IconButton>
				</VisibilityControl>
			</VisibilityControl>
		</div>
	);
};
