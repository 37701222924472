import clsx from 'clsx';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { EFieldDefinitionType, IDictionaryItem, IFieldValue } from '@skillandchill/tasker-types';
import { CustomSelect, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { issueModalDataContext } from '@/views/IssueModelNew';
import { IExtendedFieldValue } from '@/views/IssueModelNew/modal';

import { getDictionaryValue } from 'view/IssueModelNew/IssueModalContent/IssueDetailsPage/MainIssueData/DependentInputs/IssueFormSection/DictionaryIssueProperty/utils';

import { EIssueModalDetailsPageFields } from '../../../../model';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { FieldValueFactory } from '../utils/fieldValueFactory';
import { useHandleResetError } from '../utils/useHandleResetError';

import { DictionaryItemComponent } from './DictionaryItemComponent';
import { Props } from './model';
import { MultiSelectValueLabelCustom } from './MultiSelectValueLabelCustom';
import { CustomOption } from './Option';
import { Resources } from './resources';
import { SingleValueCustom } from './SingleValueCustom';
import { useStyles } from './styles';
import { ValueContainer } from './ValueContainer';

export const DictionaryIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();
	const { setFieldValues, issueErrors, setIssueState, issueState } = useContext(issueModalDataContext);
	const { handleResetInputError } = useHandleResetError(issueFormSectionToIssueField);

	const isError = useMemo(() => {
		return (issueErrors.find(issueError => issueError.inputName === EIssueModalDetailsPageFields.properties)
			?.additionalData as number[])?.includes(issueFormSectionToIssueField?.AttributeDefinitionId as number);
	}, [issueErrors]);

	const isDarkMode = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId;

	const isMultiValue = issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue;
	const initialFieldValues = issueFormSectionToIssueField?.AttributeDefinition?.FieldValue ?? [];
	const initialDictionaryItems = initialFieldValues?.map(
		fieldValue => fieldValue?.ValueDictionaryItem
	) as IDictionaryItem[];

	const selectValues = (issueState?.Properties?.DictionaryItems ?? {})[
		issueFormSectionToIssueField?.AttributeDefinitionId as number
	];

	// useEffect(() => {
	// 	if (typeof selectValues === 'undefined') return;

	// 	handleTagsChangeV3(initialDictionaryItems);
	// }, [inEdit, issueFormSectionToIssueField]);

	// useEffect(() => {
	// 	// if (typeof inputValue === 'undefined') return;
	// 	if (Object.keys(issueState?.Properties?.DictionaryItems ?? {})?.includes(String(AttributeDefinitionId))) {
	// 		handleTagsChangeV3(selectValues);
	// 	}
	// 	// } else handleTagsChangeV3(initialDictionaryItems);
	// }, []);

	// const getValue = () => {
	// 	if (selectValues) {
	// 		if (isMultiValue) return selectValues ?? [];
	// 		else {
	// 			return selectValues?.length ? selectValues[0] : undefined;
	// 		}
	// 	} else {
	// 		return initialDictionaryItems;
	// 	}
	// };

	const getValue = () => {
		if (selectValues) {
			if (isMultiValue) return selectValues ?? [];
			else {
				return selectValues?.length ? selectValues[0] : undefined;
			}
		} else {
			if (isMultiValue) return initialDictionaryItems ?? [];
			else {
				return initialDictionaryItems?.length ? initialDictionaryItems[0] : undefined;
			}
		}
	};

	const handleTagsChangeV3 = (newDictionaryItems: IDictionaryItem[] | IDictionaryItem | undefined) => {
		const hasSelectBeenReseted =
			!newDictionaryItems ||
			(Array.isArray(newDictionaryItems) && !(newDictionaryItems as IDictionaryItem[])?.length);

		let arr: IDictionaryItem[] = [];
		if (Array.isArray(newDictionaryItems)) arr = newDictionaryItems;
		else if (newDictionaryItems) arr = [newDictionaryItems];

		setIssueState(prevState => ({
			...prevState,
			Properties: {
				...(prevState?.Properties ?? {}),
				DictionaryItems: {
					...(prevState?.Properties?.DictionaryItems ?? {}),
					[String(issueFormSectionToIssueField?.AttributeDefinitionId)]: arr,
				},
			},
		}));
		handleResetInputError();

		// handleClearErrorOnIssueProperties();

		if (hasSelectBeenReseted) {
			handleClearSelect();
			return;
		}

		handleFieldValuesChange(arr);
	};

	const handleFieldValuesChange = (newDictionaryItems: IDictionaryItem[]) => {
		let arr: IDictionaryItem[] = [];

		if (Array.isArray(newDictionaryItems)) arr = newDictionaryItems;
		else arr = [newDictionaryItems];

		const addedDictionaryItems = arr?.filter(
			dictionaryItem =>
				!initialDictionaryItems?.map(dictionaryItem => dictionaryItem?.Id)?.includes(dictionaryItem?.Id)
		);
		const deletedDictionaryItems = initialDictionaryItems?.filter(
			dictionaryItem => !arr?.map(dictionaryItem => dictionaryItem?.Id)?.includes(dictionaryItem?.Id)
		);

		const fieldValuesToDelete = initialFieldValues?.filter(initialFieldValue =>
			deletedDictionaryItems
				?.map(deletedTag => deletedTag?.Id)
				?.includes(initialFieldValue?.ValueDictionaryItem?.Id as number)
		);

		setFieldValues(prevState => ({
			...prevState,
			fieldsValueToCreate: [
				...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ?? []),
				...addedDictionaryItems?.map(newTag =>
					FieldValueFactory.create(
						EFieldDefinitionType.Dictionary,
						AttributeDefinitionId as number,
						newTag.Id
					)
				),
			],
			fieldsValueToDelete: [
				...(prevState?.fieldsValueToDelete?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ?? []),
				...fieldValuesToDelete,
			],
		}));
	};

	const handleClearSelect = () => {
		setFieldValues(prevState => ({
			...prevState,
			fieldsValueToCreate: [
				...(prevState?.fieldsValueToCreate?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ?? []),
			],
			fieldsValueToDelete: [
				...(prevState?.fieldsValueToDelete?.filter(getFieldValueWitouthFormSectionAttributeDefinitionId) ?? []),
				...initialFieldValues,
			],
		}));
	};

	// const handleClearErrorOnIssueProperties = () => {
	// 	setIssueErrors(prevState =>
	// 		prevState?.filter(error => error.inputName !== EIssueModalDetailsPageFields.properties)
	// 	);
	// };

	function getFieldValueWitouthFormSectionAttributeDefinitionId(
		fieldValue: IFieldValue | Partial<IExtendedFieldValue>
	) {
		return fieldValue?.AttributeDefinitionId !== issueFormSectionToIssueField?.AttributeDefinitionId;
	}

	const getCustomComponents = () => {
		if (issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue) {
			return {
				ValueContainer: ValueContainer,
				MultiValueLabel: MultiSelectValueLabelCustom,
				Option: CustomOption,
			};
		} else {
			return {
				SingleValue: SingleValueCustom,
				Option: CustomOption,
			};
		}
	};

	const canClearSelect = useMemo(() => {
		return !issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory && selectValues?.length;
	}, [issueFormSectionToIssueField, initialDictionaryItems]);

	const getArgs = () => {
		return {
			['components']: {
				...getCustomComponents(),
			},
			valuesLimit: 1,
			menuPosition: Resources.fixed,
			isClearable: canClearSelect,
			isSearchable: false,
			closeMenuOnSelect: !issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue,
		};
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			{/* <VisibilityControl condition={!!issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory}>
				<StarIcon className={classes.starIcon} />
			</VisibilityControl>
			<Typography className={classes.text}>
				{issueFormSectionToIssueField?.AttributeDefinition?.Name ?? ''}
			</Typography> */}
			<IssuePropertyTitle {...props} />

			<div className={clsx(classes.flexGrow, classes.selectContainer)}>
				<CustomSelect
					isDarkMode={isDarkMode}
					inEdit={inEdit}
					label=""
					className={clsx(
						isError ? classes.selectError : undefined,
						!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue && classes.multiValueContainer
					)}
					items={
						(issueFormSectionToIssueField?.AttributeDefinition?.Dictionary
							?.DictionaryItem as IDictionaryItem[]) ?? []
					}
					getName={getDictionaryValue}
					getKey={(dictionaryItem: IDictionaryItem) => dictionaryItem?.Id}
					values={getValue()}
					onChange={handleTagsChangeV3}
					args={getArgs()}
					isMulti={!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue}
					canSetInputInNonEdit
					nonEditComponent={
						<div className={clsx(classes.flexYCenter, classes.valueContainer)}>
							<VisibilityControl
								condition={!!initialDictionaryItems?.length}
								alternative={<div className={clsx(classes.tag)}>-</div>}
							>
								{initialDictionaryItems?.slice(0, 2).map((dictionaryItem, i) => (
									<DictionaryItemComponent
										dictionaryItem={dictionaryItem}
										classNameText={classes.dictionaryItemText}
										className={clsx(
											i > 0 ? classes.dictionaryItemContainerMargin : undefined,
											classes.dictionaryItemContainer
										)}
										key={i}
										showComma={true}
									/>
								))}
								<VisibilityControl condition={initialDictionaryItems?.length > 2}>
									<div className={clsx(classes.flexYCenter, classes.counter)}>
										<AddIcon className={classes.icon} />
										<Typography className={classes.counterText}>
											{initialDictionaryItems?.length - 2}
										</Typography>
									</div>
								</VisibilityControl>
							</VisibilityControl>
						</div>
					}
				/>
			</div>
		</div>
	);
};
