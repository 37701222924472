import { IHolidayType } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	keys: {
		label: 'Label',
		description: 'Description',
		isActive: 'IsActive',
		isDefault: 'IsDefault',
	} as { [x: string]: keyof IHolidayType },

	holidayTypeBasic: {
		Label: '',
		Description: '',
		IsActive: true,
		IsDefault: false,
	} as Partial<IHolidayType>,
	listPath: '/admin/holidayType',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Holiday: {
			Type: {
				Detail: {
					pageTitle: 'Typ dnia wolnego',
					name: 'Nazwa',
					description: 'Opis',
					isActive: 'Jest aktywny',
					isDefault: 'Jest podstawowy',
					save: 'Zapisz',
					cancel: 'Anuluj',
					edit: 'Edytuj',
					delete: 'Usuń',
				},
			},
		},
	},
	en: {
		Holiday: {
			Type: {
				Detail: {
					pageTitle: 'Holiday type',
					name: 'Name',
					description: 'Description',
					isActive: 'Is active',
					isDefault: 'Is default',
					save: 'Save',
					cancel: 'Cancel',
					edit: 'Edit',
					delete: 'Delete',
				},
			},
		},
	},
};
