import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	menuId: 'long-menu',
	arialLabelledby: 'aria-labelledby',
	longButton: 'long-button',
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					SingleAgreement: {
						ActionsCell: {
							showDetails: 'Pokaż szczegóły umowy',
							deleteAgreement: 'Usuń umowę',
							edit: 'Przejdź do edycji umowy',
						},
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementsList: {
					SingleAgreement: {
						ActionsCell: {
							showDetails: 'Show details of agreement',
							deleteAgreement: 'Delete agreement',
							edit: 'Edit agreement',
						},
					},
				},
			},
		},
	},
};
