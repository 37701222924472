// @flow

import { useEffect } from 'react';

import { useQuery } from '@/utils/hooks/useQuery';

import { Props } from './model';

export const QueryListener = (props: Props): JSX.Element => {
	const { callback, query: queryParam } = props;
	const query = useQuery();

	useEffect(() => {
		callback(query[queryParam]);
	}, [query[queryParam]]);

	return <div />;
};
