import { getYear } from 'date-fns';
import { t } from 'i18next';
import range from 'lodash/range';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	Months: () => [
		t('Holiday.Event.Detail.January'),
		t('Holiday.Event.Detail.February'),
		t('Holiday.Event.Detail.March'),
		t('Holiday.Event.Detail.April'),
		t('Holiday.Event.Detail.May'),
		t('Holiday.Event.Detail.June'),
		t('Holiday.Event.Detail.July'),
		t('Holiday.Event.Detail.August'),
		t('Holiday.Event.Detail.September'),
		t('Holiday.Event.Detail.October'),
		t('Holiday.Event.Detail.November'),
		t('Holiday.Event.Detail.December'),
	],
	MonthsCode: () => [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	Years: range(1990, getYear(new Date()) + 50, 1),
	previous: 'react-datepicker__navigation react-datepicker__navigation--previous',
	previousSpan: 'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous',
	prev: '<',
	nextCN: 'react-datepicker__navigation react-datepicker__navigation--next',
	nextSpan: 'react-datepicker__navigation-icon react-datepicker__navigation-icon--next',
	next: '>',
	selectCN: 'custom-select-style',
	monthCode: 'MMMM',
};

export const Translation = {
	pl: {
		Holiday: {
			Event: {
				Detail: {
					January: 'Styczeń',
					February: 'Luty',
					March: 'Marzec',
					April: 'Kwiecień',
					May: 'Maj',
					June: 'Czerwiec',
					July: 'Lipiec',
					August: 'Sierpień',
					September: 'Wrzesień',
					October: 'Październik',
					November: 'Listopad',
					December: 'Grudzień',
					previous: 'Poprzedni miesiąc',
					next: 'Następny miesiąc',
				},
			},
		},
	},
	en: {
		Holiday: {
			Event: {
				Detail: {
					January: 'January',
					February: 'February',
					March: 'March',
					April: 'April',
					May: 'May',
					June: 'June',
					July: 'July',
					August: 'August',
					September: 'September',
					October: 'October',
					November: 'November',
					December: 'December',
					previous: 'Previous Month',
					next: 'Następny miesiąc',
				},
			},
		},
	},
};
