import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					edit: 'Edytuj delegację',
				},
			},
		},
	},
	en: {
		DelegationList: {
			DelegationModal: {
				DelegationModalActions: {
					edit: 'Edit delegation',
				},
			},
		},
	},
};
