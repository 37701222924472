import clsx from 'clsx';
import { useMemo } from 'react';

import { IIssue } from '@skillandchill/tasker-types';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { IConActions } from '@/layouts/Dashboard/WorklogStarter/IconActions';
import { FavouriteIssue } from '@/layouts/Widgets/FavouriteIssue';

import { SingleIssueInfo } from '../SingleIssioInfo';

import { Props } from './model';
import { useStyles } from './styles';

export const WorklogTile = (props: Props): JSX.Element => {
	const { issue, handleOnFinish, handleOnPause, handleOnStart, active, fullWidth, inEdit } = props;

	const classes = useStyles();

	return useMemo(
		() => (
			<div
				className={clsx(
					classes.padding,
					classes.fullHeight,
					inEdit ? classes.widthLEdit : fullWidth ? classes.fullWidth : classes.widthL
				)}
			>
				<div
					className={clsx(
						classes.flexYCenter,
						classes.contentSpaceBetween,
						classes.fullHeight,
						classes.flexRow,
						classes.flexTask,
						inEdit ? classes.widthLEdit : fullWidth ? classes.fullWidth : classes.widthL
					)}
				>
					<VisibilityControl condition={!!issue?.Id}>
						<div
							className={clsx(
								classes.defaultCursor,
								classes.flexYCenter,
								classes.titleContainer,
								classes.fullHeight
							)}
						>
							<SingleIssueInfo issue={issue as IIssue} />
						</div>

						<IConActions
							issue={issue as IIssue}
							active={active}
							handleOnFinish={handleOnFinish}
							handleOnPause={handleOnPause}
							handleOnStart={handleOnStart}
						/>

						<VisibilityControl condition={!active}>
							<FavouriteIssue issue={issue as IIssue} />
						</VisibilityControl>
					</VisibilityControl>
				</div>
			</div>
		),
		[issue?.Id, issue?.FavouriteIssues, active]
	);
};
