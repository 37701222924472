import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	rounded: 'rounded' as const,
};

export const Translation = {
	pl: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					AgreementDetailsGroup: {
						yes: 'Tak',
						no: 'Nie',
					},
				},
			},
		},
	},
	en: {
		Reckon: {
			Agreements: {
				AgreementDetails: {
					AgreementDetailsGroup: {
						yes: 'Yes',
						no: 'No',
					},
				},
			},
		},
	},
};
