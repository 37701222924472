import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Settings: {
			Preferences: {
				pageTitle: 'Ustawienia preferencji',
			},
		},
	},
	en: {
		Settings: {
			Preferences: {
				pageTitle: 'Preferences',
			},
		},
	},
};
