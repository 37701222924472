// Check if user has all the necessary roles

import { store } from '@/App';
import { IReducerState } from '@/store/reducers/types';

export const CheckRoles = (userPermissions?: number[], permission?: number): boolean => {
	if (!permission) return true;
	if (!userPermissions) return false;

	return userPermissions.includes(permission);
};

export const CheckMultiRoles = (permissionsToCheck: number[]): boolean => {
	const reducer: IReducerState = store?.getState() as IReducerState;
	const userPermissions: number[] = reducer?.Session?.permissions ?? [];
	if (!permissionsToCheck) return true;
	if (!userPermissions) return false;

	for (let i = 0; i < permissionsToCheck.length; i++) {
		const permissionToCheck = permissionsToCheck[i];
		if (CheckRoles(userPermissions, permissionToCheck)) return true;
	}

	return false;
};
