import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	selectProps: {
		menuPosition: 'fixed',
	},
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueFormList: {
					ChooseProject: {
						copy: 'Kopiuj',
						share: 'Udostępnij',
						copyTitle: 'Kopiuj formularz',
						shareTitle: 'Udostępnij formularz',
						copySubTitle: 'Kopiuj formularz do innego projektu',
						shareSubTitle: 'Udostępnij ten formularz do innego projektu',
						customer: 'Klient',
						project: 'Projekt',
						save: 'Zapisz',
						cancel: 'Anuluj',
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueFormList: {
					ChooseProject: {
						copy: 'Copy',
						share: 'Share',
						copyTitle: 'Copy form',
						shareTitle: 'Share form',
						copySubTitle: 'Copy form to another project',
						shareSubTitle: 'Share this form to different project',
						customer: 'Customer',
						project: 'Project',
						save: 'Save',
						cancel: 'Cancel',
					},
				},
			},
		},
	},
};
