import { IWorkLog } from '@skillandchill/tasker-types';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	fieldDefinition: 'fieldDefinition',
	sectionField: 'sectionField',
	key: (sectionId: number) => `subsection${sectionId}`,
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			Tabs: {
				General: {
					IssueForm: {
						IssueFormSectionWithCollapse: {
							gotToTagGroup: 'Przejdź do grupy tagów przypisanej do pól tego formularza',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			Tabs: {
				General: {
					IssueForm: {
						IssueFormSectionWithCollapse: {
							gotToTagGroup: 'Go to tag group assigned to this form fields ',
						},
					},
				},
			},
		},
	},
};

export const initialWorklog: Partial<IWorkLog> = {
	Description: '',
	Minutes: 0,
	DateTimeFrom: new Date(),
};
