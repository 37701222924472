import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			CommentCard: {
				commentEditFailed: 'Edycja komentarza zakończona niepowodzeniem, ponieważ: ',
				pinned: '(Przypięty)',
			},
		},
	},
	en: {
		IssueModalEditor: {
			CommentCard: {
				commentEditFailed: 'Edition of comment failure cause: ',
				pinned: '(Pinned)',
			},
		},
	},
};
