import { Translation as DictionaryContent } from 'views/DictionaryContent/resources';

import { Translation as DictionaryItemLabelDuringEdit } from './DictionaryContentTile/DictionaryItemLabelDuringEdit/resources';
import { Translation as DictionaryItemValue } from './DictionaryContentTile/DictionaryItemValue/resources';
import { Translation as DictionaryContentTile } from './DictionaryContentTile/resources';

export const DictionaryContentTranslationPL = [
	DictionaryContent.pl,
	DictionaryContentTile.pl,
	DictionaryItemLabelDuringEdit.pl,
	DictionaryItemValue.pl,
];

export const DictionaryContentTranslationEN = [
	DictionaryContent.en,
	DictionaryContentTile.en,
	DictionaryItemLabelDuringEdit.en,
	DictionaryItemValue.en,
];
