import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	filterIssue: {
		Text: '',
		SearchPartWord: true,
		TextBy: {
			Title: true,
			DisplayId: true,
			Description: false,
			Comment: false,
			User: false,
		},
	},
	customSelectArgs: {
		menuPosition: 'fixed' as const,
	},
	emptySpecialText: '!$empty$!',
};

export const Translation = {
	pl: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					IssueInfo: {
						OwnSelect: {
							DefaultSearchValue: 'Wpisz wyszukiwane zgłoszenie',
							SelectTooltip:
								'Istnieje więcej zgłoszeń niż zostało wyświetlone, aby je wyświetlić wpisz wyszukiwane zgłoszenie',
							noOptions: 'Brak wyników',
							label: 'Zgłoszenie',
						},
					},
				},
			},
		},
	},
	en: {
		TimeAssign: {
			Content: {
				GeneralTab: {
					IssueInfo: {
						OwnSelect: {
							DefaultSearchValue: 'Type searching issue',
							SelectTooltip: 'There are more issues, to see them type issue name in search bar',
							noOptions: 'No options',
							label: 'Issue',
						},
					},
				},
			},
		},
	},
};
