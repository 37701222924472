import React from 'react';
import { components } from 'react-select';

import { DictionaryItemComponent } from '../DictionaryItemComponent';

import { useStyles } from './styles';

export const SingleValueCustom = (props: any) => {
	const classes = useStyles();

	return (
		<components.SingleValue {...props}>
			<div>
				<DictionaryItemComponent dictionaryItem={props?.data?.object} />
			</div>
		</components.SingleValue>
	);
};
