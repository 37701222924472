import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		IssueModelNew: {
			dictionaryValueNull: 'Brak prawidłowej wartości',
			valueTrue: 'Prawda',
			valueFalse: 'Fałsz',
			deleteIssueSnackbarSuccess: 'Zgłoszenie usunięte',
			updateIssueSnackbarSuccess: 'Zgłoszenie zaktualizowane',
			confirmationTitle: 'Czy na pewno chcesz usunąć zgłoszenie ?',
			confirmationSubmitText: 'Tak, chcę usunąć',
			confirmationCancelText: 'Nie, anuluj',
			reqFields: 'Musisz dodać wymagane pole dla tego formularza',
			Worklogs: {
				worklogDeleted: 'Czas pracy został usunięty',
				worklogCreated: 'Dodano nowy czas pracy',
				worklogEditted: 'Edytowano czas pracy',
				error: 'Wystąpił błąd',
				onlyMine: 'Pokaż tylko mój czas pracy',
				addNewWorklog: 'Dodaj nowy czas pracy',
				CardHeader: {
					date: 'Data',
					from: 'Czas rozpoczęcia',
					to: 'Czas zakończenia',
					user: 'Użytkownik',
					issue: 'Zgłoszenie',
					description: 'Opis',
					logged: 'Zalogowano',
					actions: 'Akcje',
				},
				CardContent: {
					worklogStillActive: 'Aktywne',
					delete: 'Usuń',
					edit: 'Edytuj',
					workLogDelete: 'Usunięto wpis czasu pracy',
					errorWorkLogDelete: 'Wystąpił błąd podczas usuwania wpisu czasu pracy',
				},
				Form: {
					titleNewWorklog: 'Nowy czas pracy',
					titleUpdateWorklog: 'Edycja czasu pracy',
					issueName: 'Nazwa zgłoszenia',
					startDate: 'Data rozpoczęcia',
					endDate: 'Data zakończenia',
					time: 'Czas pracy w minutach',
					wantToSetEndDateUsingMinutes: 'Chcę podać czas pracy w minutach',
					desc: 'Opis',
					cancel: 'Anuluj',
					delete: 'Usuń',
					save: 'Zapisz',
					alert: 'Wprowadź poprawne dane formularza.',
				},
			},
			IssueTitle: {
				addAsFavourite: 'Dodaj zadanie do ulubionych',
				removeFromFavourite: 'Usuń zadanie z ulubionych',
				error: 'Wystąpił błąd',
			},
			Comments: {
				post: 'Wyślij',
				commentEditFailed: 'Dodanie komentarza zakończona niepowodzeniem, ponieważ: ',
				displayAllComments: 'Pokaż usunięte komentarze',
				advancedComment: 'Zaawansowany komentarz',
				portalPost: 'Wyślij na portal',
				comment: 'Komentarz',
				textIsToLong: 'Zbyt długi komentarz',
				commentAddSuccess: 'Pomyślnie dodano komentarz',
				commentDeleteSuccess: 'Usunięto komentarz',
				commentDeleteError: 'Błąd podczas usuwania',
				cancel: 'Anuluj',
				editTitle: 'Edytuj komentarz',
				edit: 'Edytuj',
				commentEditSuccess: 'Pomyślnie edytowano komentarz',
				noComments: 'Brak komentarzy',
			},
			IssueMainData: {
				issueProperties: 'Właściwości zgłoszenia',
			},
			dictionaryItemType: {
				1: 'Wartość logiczna',
				2: 'Liczba całkowita',
				3: 'Liczba dziesiętna',
				4: 'Wartość tekstowa',
				5: 'Słownik',
				6: 'Grupa tagów',
				7: 'Data',
				8: 'Data wraz z czasem',
			},
			Relation: {
				children: 'Podzadanie',
				parent: 'Zadanie nadrzędne',
				equivalent: 'Zadanie równorzędne',
				cancel: 'Anuluj',
				link: 'Dodaj powiązanie',
				tile: {
					project: 'Projekt',
					customer: 'Klient',
				},
				error: 'Niewystarczające dane do stworzenia relacji',
				successSave: 'Zapisano relacje',
				moreRecords: 'Istnieje więcej rekordów aby je zobaczyć wpisz więcej znaków',
				addSubTaks: 'Dodaj podzadanie',
				relationDelete: 'Zadanie usunięte z listy',
				noIssues: 'Brak zadań',
				placeholder: 'Nazwa zgłoszenia',
				add: 'Dodaj',
				addIssue: 'Dodaj zadanie',
				noOptionsText: 'Brak zadań',
				useTableData: {
					name: 'Nazwa',
					project: 'Projekt',
					creationDate: 'Data stworzenia',
					action: 'Akcje',
				},
			},
			kanbanCreationError: 'Brak skonfigurowanych mapowań w kanbanie',
			kanbanCreationErrorSubtitle1: 'Zgłoszenie wyświetli się w kanbanie po skonfigurowaniu mapowań',
			kanbanCreationErrorSubtitle2:
				'Przejdź do edycji kanbana i skonfiguruj mapowania aby móc dodawać zadania do kolumny',
			limit: 'Obecne zgłoszenie jest najnowsze lub najstarsze.',
		},
	},
	en: {
		IssueModelNew: {
			dictionaryValueNull: 'There is lack of correct value.',
			valueTrue: 'True',
			valueFalse: 'False',
			deleteIssueSnackbarSuccess: 'Issue has been deleted',
			updateIssueSnackbarSuccess: 'Issue has been updated',
			confirmationTitle: 'Are you sure you want to delete this issue',
			confirmationSubmitText: 'Yes, I want to delete',
			confirmationCancelText: 'No, cancel',
			reqFields: 'You need to add required fields',
			Worklogs: {
				worklogDeleted: 'Worklog has been deleted',
				worklogCreated: 'New worklog has been added',
				worklogEditted: 'Worklogh has been editted',
				error: 'Error occured',
				onlyMine: 'Show only my worklogs',
				addNewWorklog: 'Add new worklog',
				CardHeader: {
					date: 'Date',
					from: 'From',
					to: 'To',
					user: 'User',
					issue: 'Issue',
					description: 'Description',
					logged: 'Logged',
					actions: 'Actions',
				},
				CardContent: {
					worklogStillActive: 'Active',
					delete: 'Delete',
					edit: 'Edit',
					workLogDelete: 'Worklog deleted',
					errorWorkLogDelete: 'Error while deleting worklog',
				},
				Form: {
					titleNewWorklog: 'New worklog',
					titleUpdateWorklog: 'Editing worklog',
					issueName: 'Issue name',
					startDate: 'Start date',
					endDate: 'End date',
					time: 'Time',
					wantToSetEndDateUsingMinutes: 'I want to specify working time in minutes',
					desc: 'Description',
					cancel: 'Cancel',
					delete: 'Delete',
					save: 'Save',
					alert: 'Please enter correct values.',
				},
			},
			IssueTitle: {
				addAsFavourite: 'Add the task to your favorites',
				removeFromFavourite: 'Remove task from favourites',
				error: 'Error occured',
			},
			Comments: {
				post: 'Post',
				commentEditFailed: 'Add of comment failure cause: ',
				displayAllComments: 'Show deleted comments',
				advancedComment: 'Advanced comment',
				portalPost: 'Post on portal',
				comment: 'Comment',
				textIsToLong: 'Comment is to long',
				commentAddSuccess: 'Comment successfully added',
				commentDeleteSuccess: 'Comment has been deleted',
				commentDeleteError: 'Error while deleting comment',
				cancel: 'Cancel',
				editTitle: 'Edit a comment',
				edit: 'Edit',
				commentEditSuccess: 'Comment succesfully edited',
				noComments: 'No comments available',
			},
			IssueMainData: {
				issueProperties: 'Issue Properties',
			},
			dictionaryItemType: {
				1: 'Boolean',
				2: 'Integer',
				3: 'Decimal',
				4: 'Text',
				5: 'Dictionary',
				6: 'TagGroup',
				7: 'Date',
				8: 'DateTime',
			},
			Relation: {
				children: 'Subtask',
				parent: 'Parent issue',
				equivalent: 'Equivalent issue',
				cancel: 'Cancel',
				link: 'Link issue',
				tile: {
					project: 'Project',
					customer: 'Klient',
				},
				error: 'Not enough data to create connection',
				successSave: 'Relation saved',
				moreRecords: 'There is more records to find your please type more characters',
				relationDelete: 'Issue has been deleted from the list',
				noIssues: 'No issues',
				placeholder: 'Issue name',
				add: 'Add',
				addIssue: 'Add issue',
				noOptionsText: 'No issues',
				useTableData: {
					name: 'Name',
					project: 'Project',
					creationDate: 'Creation date',
					action: 'Actions',
				},
			},
			kanbanCreationError: 'No mappings configured in kanban',
			kanbanCreationErrorSubtitle1: 'The issue will appear in kanban after configuring the mappings',
			kanbanCreationErrorSubtitle2:
				'Go to the kanban edition mode and configure the mappings to be able to add issues to the column',
			limit: 'You already opened new newest or the oldest issue.',
		},
	},
};

export const defaultConfirmationMessageData = {
	title: Resources.GlobalResources.empty,
};
