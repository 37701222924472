import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => {
		return {
			...getGlobalStyles(),

			button: {
				alignSelf: 'flex-start',
				marginTop: theme.spacing(1),
				padding: '0 !important',
			},

			clickable: {
				cursor: 'pointer',
				width: '100%',
				height: '100%',
				padding: `${theme.spacing(0.75)} ${theme.spacing(2)}`,
			},
		};
	},
	{ name: `IssueModalEditor` }
);
