import uuid from 'uuid';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	fixed: 'fixed' as const,
	key: (x: number) => `${x}_Role_${uuid()}`,
};

export const Translation = {
	pl: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					RoleManagement: {
						add: 'Dodaj nową rolę',
						edit: 'Edytuj wybraną rolę',
						copy: 'Kopiuj wybraną rolę',
						delete: 'Usuń wybraną rolę',
						popup: {
							label: 'Nazwa roli',
							cancel: 'Anuluj',
							save: 'Zapisz rolę',
						},
						error: {
							created: 'Wystąpił błąd podczas tworzenia roli',
							updated: 'Wystąpił błąd podczas aktualizacji roli',
							copied: 'Wystąpił błąd podczas kopiowania roli',
							deleted: 'Wystąpił błąd podczas usuwania roli',
							unknown: 'Wystąpił nieznany błąd - brak id roli',
						},
						success: {
							created: 'Rola została utworzona',
							updated: 'Rola została zaktualizowana',
							copied: 'Rola została skopiowana',
							deleted: 'Rola została usunięta',
						},
						confirm: 'Czy na pewno chcesz usunąć wybraną rolę?',
					},
				},
			},
		},
	},
	en: {
		Roles: {
			TabsContent: {
				RoleConfiguration: {
					RoleManagement: {
						add: 'Add new role',
						edit: 'Edit selected role',
						copy: 'Copy selected role',
						delete: 'Delete selected role',
						popup: {
							label: 'Role name',
							cancel: 'Cancel',
							save: 'Save role',
						},
					},
				},
			},
		},
	},
};
