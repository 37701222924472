import { AsyncAction } from '@skillchill/redux-promisify';

import { _fetchIssueModalComments, _patchIssueModalComment } from '@/store/actions/issueModalActions';
import { IJson } from '@/store/ITypes';
import axios from '@/utils/axios';

const basePath = 'Comment';

export function comment_post(issueId: number, comment: string, commentHtml: string, isPortal = false): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssueModalComments((json as IJson).data)));
		return axios().post(`${basePath}/Issue/${issueId}`, {
			Comment: comment,
			CommentHtml: commentHtml,
			IsPortal: isPortal,
		});
	};
}

export function comment_edit(commentId: number, comment: string, commentHtml: string, isPortal = false): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_patchIssueModalComment((json as IJson).data)));
		return axios().patch(`${basePath}/${commentId}`, {
			Comment: comment,
			CommentHtml: commentHtml,
			IsPortal: isPortal,
		});
	};
}

export function comment_delete(commentId?: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchIssueModalComments((json as IJson).data, commentId)));
		return axios().delete(`${basePath}/${commentId}`);
	};
}
