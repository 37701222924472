import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	links: {
		empty: '/',
		toActivity: '/activity',
		issuePage: '#/issues',
		logo: process?.env?.PUBLIC_URL + '/images/logos/tb2.svg',
		logoWhite: process?.env?.PUBLIC_URL + '/images/logos/tb.svg',
	},
	logoAlternative: 'tb.svg',
	...GlobalResources,
};
