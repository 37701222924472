import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							DictionaryModal: {
								addRecord: 'Dodaj elements',
								save: 'Zapisz słownik',
								cancel: 'Anuluj',
								dictionaryName: 'Nazwa słownika',
							},
						},
					},
				},
			},
		},
	},
	en: {
		ProjectDetails: {
			Form: {
				IssueForm: {
					CommonComponents: {
						IssueFormField: {
							DictionaryModal: {
								addRecord: 'Add record',
								save: 'Save dictionary',
								cancel: 'Cancel',
								dictionaryName: 'Dictionary name',
							},
						},
					},
				},
			},
		},
	},
};
