import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		variantSuccess: { backgroundColor: `${theme.palette.primary.main} !important` },
		root: {
			'& .SnackbarContent-root': {
				minWidth: '0px',
			},
		},
		closeButton: {
			color: theme.palette.background.default,
		},
	}),
	{ name: 'SnackBarProviderWrapper' }
);
