import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		content: {
			marginLeft: '39px',
			marginRight: '39px',
			marginTop: '15px',
			wordBreak: 'break-word',
			'& p': {
				minHeight: theme.spacing(3),
			},
		},
	}),
	{ name: `IssueModalEditor_CommendCard_Content` }
);
