export const Translation = {
	pl: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						Form: {
							url: 'Adres URL',
							name: 'Nazwa',
							type: 'Typ',
							save: 'Zapisz',
							cancel: 'Anuluj',
						},
					},
				},
			},
		},
	},
	en: {
		IssueModalNew: {
			IssueModalTitle: {
				MoreOptions: {
					SocialMediaLinks: {
						Form: {
							url: 'URL address',
							name: 'Name',
							type: 'Type',
							save: 'Save',
							cancel: 'Cancel',
						},
					},
				},
			},
		},
	},
};
