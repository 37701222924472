import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	user: '/user/',
	...GlobalResources,
	invalidDate: 'Invalid Date',
};

export const Translation = {
	pl: {
		IssueModalEditor: {
			CommentCard: {
				Header: {
					deleted: 'Usunięto',
				},
			},
		},
	},
	en: {
		IssueModalEditor: {
			CommentCard: {
				Header: {
					deleted: 'Deleted',
				},
			},
		},
	},
};
