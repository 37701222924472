import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
	nameFirstNameTextField: 'firstName',
	nameLastNameTextField: 'lastName',
	nameEmailTextField: 'email',
	namePhoneNumberTextField: 'phoneNumber',
	checkboxName: 'checkbox',
	settings: '/settings/general',
};

export const Translation = {
	pl: {
		Settings: {
			General: {
				GeneralSettings: {
					cardHeaderTitle: 'Profil',
					updateSuccess: 'Zaktualizowano profil',
					updateFailed: 'Spróbuj ponownie później',
					submitButton: 'Zatwierdź',
				},
			},
		},
	},
	en: {
		Settings: {
			General: {
				GeneralSettings: {
					cardHeaderTitle: 'Profile',
					updateSuccess: 'Profile updated',
					updateFailed: 'Try again late',
					submitButton: 'Submit',
				},
			},
		},
	},
};
